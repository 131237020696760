import { RarityMeta } from '@/lib/collection/utils'
import { EsMapping } from '@/lib/es/mapping'

export const getEsKey = (esMapping: EsMapping, name: string) =>
  esMapping.find((a) => a.name === name)?.es_key ?? null

export const getAttrPercent = (
  label: string,
  value: string,
  rarityMeta: RarityMeta,
) => {
  const count = rarityMeta.rarity_distribution[label]?.[value]
  if (!count) return null
  return { percent: count / rarityMeta.total_count, total: count }
}

export const fmtAttrPropName = (a: string) =>
  a.replace(/[-_ ]+/g, ' ').toUpperCase()
