import { BigNumber, utils } from 'ethers'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

import {
  AccordionStep,
  AccordionStepItem,
  AccordionStepPanel,
} from '@/components/disclosure'
import { Button } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { TokenIcon } from '@/components/TokenIcon'
import { useDayjs, UseDisclosureReturn, useLoading } from '@/hooks'
import { CheckFilled } from '@/icons'
import { RegisteredUser } from '@/lib/auth/types/user'
import { fmtNumber } from '@/lib/formatter'
import { cancelOrder, getErrorMessage, sell } from '@/lib/market'
import { waitForDbIndex } from '@/lib/market/db'
import { OrderInfo } from '@/lib/nft/detail'
import toast from '@/lib/toast'

import { BundleWithPrice } from '../../nft/detail/BulkListModal'
import { NftItem } from '../shared'

export type RelistProps = {
  user: RegisteredUser
  orderId: number
  item: BundleWithPrice[0]
  price: BigNumber
  deadline: number
  isPrivate: boolean
  taker: string | null
  orderInfo: OrderInfo
  onDone: () => void
}
type Props = RelistProps & {
  disclosure: UseDisclosureReturn
}
export const RelistModal = ({
  disclosure,
  user,
  orderId,
  item,
  price,
  deadline,
  isPrivate,
  taker,
  orderInfo,
  onDone,
}: Props) => {
  const { t } = useTranslation()
  const dayjs = useDayjs()

  const { token: tokenContract, tokenId } = item.tokenPair
  const { networkId } = item.info

  const [step, setStep] = useState<0 | 1 | 2>(0)
  const { isLoading, setLoading, guardLoading } = useLoading()

  useEffect(() => {
    if (!disclosure.isOpen) {
      setStep(0)
    }
  }, [disclosure.isOpen])

  const handleError = (e: unknown) => {
    console.error(e)
    const error = getErrorMessage(t, e)
    toast({
      status: 'error',
      title: (
        <ReportToast
          user={user.web3Provider.getSigner()}
          networkId={networkId}
          tokenContract={tokenContract}
          tokenId={tokenId}
          error={error}
        />
      ),
    })
  }
  const onCancel = async () => {
    setLoading(true)
    try {
      await cancelOrder({
        networkId,
        user,
        items: [{ orderId }],
        handleErrors: undefined,
      })
      await waitForDbIndex(orderId)
      setStep(1)
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }
  const onRelist = async () => {
    setLoading(true)
    try {
      await sell({
        networkId,
        user,
        deadline,
        currency: item.currency,
        items: [
          {
            price,
            tokens: [item.tokenPair],
            royalty: orderInfo.royalty_fee_rate,
          },
        ],
        isBundle: false,
        bundleName: '',
        bundleDesc: '',
        isPrivate,
        taker: taker ?? '',
      })
      setStep(2)
      onDone()
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Change price')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-6 lg:space-y-8">
          {item && (
            <NftItem item={item} price={item.price} currency={item.currency} />
          )}
          <div>
            <div className="flex items-center space-x-3">
              <p className="ts-hairline-3 flex-1 text-gray-500">
                {t('Listing For')}
              </p>
              <div className="flex items-center space-x-1">
                <TokenIcon className="h-4 w-4" symbol="ETH" />
                <p className="ts-body-2 font-bold">
                  {fmtNumber(parseFloat(utils.formatEther(price)))}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <p className="ts-hairline-3 flex-1 text-gray-500">
                {t('Valid Until')}
              </p>
              <p className="ts-body-2 font-bold">
                {dayjs(deadline * 1000).format('lll')}
              </p>
            </div>
          </div>
          <AccordionStep index={Math.min(step, 1)}>
            <AccordionStepItem
              title={t('Cancel Previous Listing')}
              step={step}
              index={0}
            >
              <AccordionStepPanel className="space-y-6">
                <p>
                  <Trans t={t}>
                    Confirm the transaction to cancel the previous listing at a
                    lower price.
                  </Trans>
                </p>
                <Button
                  variant="outline"
                  isLoading={isLoading}
                  loadingText={t('Waiting for transaction…')}
                  onClick={onCancel}
                >
                  {t('Cancel')}
                </Button>
              </AccordionStepPanel>
            </AccordionStepItem>
            <AccordionStepItem
              title={t('Confirm Listing')}
              step={step}
              index={1}
            >
              <AccordionStepPanel className="flex flex-col space-y-6">
                <p>
                  <Trans t={t}>
                    Accept the signing request with your selling info.
                  </Trans>
                </p>
                <Button
                  colorScheme="primary-1"
                  isLoading={isLoading}
                  loadingText={t('Waiting for transaction…')}
                  leftIcon={
                    step < 2 ? undefined : <CheckFilled className="h-4 w-4" />
                  }
                  onClick={step < 2 ? onRelist : disclosure.onClose}
                >
                  {step < 2 ? t('Sign') : t('Your item is ready')}
                </Button>
              </AccordionStepPanel>
            </AccordionStepItem>
          </AccordionStep>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
