import { Dialog } from '@headlessui/react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { ReactNode } from 'react'

import { transitions, useModalCtx } from './Modal'

type DrawerContentProps = {
  className?: string
  children: ReactNode
}
export const DrawerContent = ({ className, children }: DrawerContentProps) => {
  const { motionPreset } = useModalCtx()
  return (
    <Dialog.Panel
      as={motion.section}
      className={clsx(
        className,
        'fixed inset-y-0 right-0 z-[1400] flex w-full flex-col overflow-y-auto bg-modal-bg',
      )}
      {...transitions[motionPreset]}
    >
      {children}
    </Dialog.Panel>
  )
}
