import * as Sentry from '@sentry/browser'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { NoEthereumProviderError } from '@web3-react/injected-connector'
import { constVoid, pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'next-i18next'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import ConnectWalletModal, {
  Handler as ConnectWalletModalHandler,
  OnSuccess as ConnectWalletModalOnSuccess,
} from '@/components/user/ConnectWalletModal'
import UserSignModal, {
  Handler as UserSignModalHandler,
  OnSuccess as UserSignModalOnSuccess,
} from '@/components/user/UserSignModal'
import { NETWORK_ETH } from '@/consts'
import * as api from '@/lib/api'
import { ON_NODE_PROD, ON_PROD } from '@/lib/env'
import { E, T, TE } from '@/lib/fp'
import * as store from '@/lib/store'
import toast from '@/lib/toast'
import { intFromStringDecoder } from '@/utils/codec'
import { DEFAULT_NETWORK } from '@/utils/network'

import { ConnectOption } from './types/connectOption'
import { ConnectType } from './types/connectType'
import * as U from './types/user'
import { isRabbyWallet } from './utils'
import { getWeb3Provider, INJECTED_PROVIDER_MAP } from './web3Provider'

class ServerError extends Error {
  constructor(msg: string) {
    super(msg)
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ServerError.prototype)
  }
}

interface AuthState {
  user: U.User
  web3SignIn: (_: {
    option: ConnectOption | null
    type: ConnectType
    triggeredByUser: boolean
  }) => Promise<
    { success: true; user: U.Web3RegisteredUser } | { success: false }
  >
  serverSignIn: (_: {
    refreshToken: string
    web3RegisteredUser: U.Web3RegisteredUser
  }) => Promise<{ success: true; user: U.RegisteredUser } | { success: false }>
  tryWeb3SignIn: (onSuccess?: ConnectWalletModalOnSuccess) => void
  tryServerSignIn: (
    onSuccess?: UserSignModalOnSuccess,
    u?: U.Web3RegisteredUser,
  ) => void
  reloadMeta: () => void
  signOut: () => void
}

const DEFAULT_AUTH_STATE: AuthState = {
  user: U.anonymous,
  web3SignIn: T.never,
  serverSignIn: T.never,
  tryWeb3SignIn: constVoid,
  tryServerSignIn: constVoid,
  reloadMeta: constVoid,
  signOut: constVoid,
}

export const AuthContext = createContext<AuthState>(DEFAULT_AUTH_STATE)
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<U.User>(U.pending)
  const w3ctx = useWeb3React()

  const { t } = useTranslation()

  const getWeb3User: AuthState['web3SignIn'] = useCallback(
    async ({ option, type, triggeredByUser }) => {
      if (type === 'injected') {
        // NOTE: in brave browser on IOS, the ethereum object is unassignable
        try {
          if (option && INJECTED_PROVIDER_MAP[option]) {
            ;(window as any).ethereum = INJECTED_PROVIDER_MAP[option]
          }
        } catch {}
      }
      // NOTE: Check if metamask locked, if so, fail the process
      // Reference: https://docs.metamask.io/guide/ethereum-provider.html#ethereum-metamask-isunlocked
      if (type === 'injected' && !triggeredByUser && !isRabbyWallet()) {
        try {
          const unlocked = await (window as any).ethereum._metamask.isUnlocked()
          if (unlocked === false) {
            return { success: false }
          }
        } catch {}
      }
      try {
        const web3Provider = await getWeb3Provider(w3ctx, type, {
          triggeredByUser,
        })
        const signer = web3Provider.getSigner()
        const address = await signer.getAddress()
        const userMetaResp = await api.addressUserMeta({ address })()
        if (E.isLeft(userMetaResp)) {
          throw new ServerError('server bad response')
        }
        const user = U.web3Registered({
          meta: userMetaResp.right,
          web3ConnType: type,
          web3Provider,
        })
        try {
          const windowEthereum = (window as any).ethereum as any
          if (windowEthereum) {
            // NOTE: network switch -> refresh page
            windowEthereum.once('chainChanged', () => {
              window.location.reload()
            })
            // NOTE: account switch -> refresh page
            windowEthereum.once('accountsChanged', () => {
              window.location.reload()
            })
          }
        } catch {}
        return { success: true, user }
      } catch (error) {
        // NOTE: if not triggerred by user, just fail silently
        if (!triggeredByUser) return { success: false }
        // NOTE: triggeredByUser && injected NoEthereumProviderError => fallback to wallet-connect
        if (
          triggeredByUser &&
          type === 'injected' &&
          error instanceof NoEthereumProviderError
        ) {
          return getWeb3User({
            option,
            type: 'wallet-connect',
            triggeredByUser,
          })
        }
        const toastTitle = (() => {
          if (error instanceof ServerError) {
            return t('Please try connect wallet later')
          } else if (error instanceof NoEthereumProviderError) {
            return t('No wallet was found')
          } else if (error instanceof UnsupportedChainIdError) {
            return t('Please switch to {{network}} network to proceed.', {
              network:
                DEFAULT_NETWORK === NETWORK_ETH
                  ? 'Ethereum'
                  : 'Ethereum Goerli testnet',
            })
          } else {
            return t('Please authorize to access your account')
          }
        })()
        toast({
          status: 'error',
          title: toastTitle,
        })
        return { success: false }
      }
    },
    [w3ctx, t],
  )

  const getServerUser: AuthState['serverSignIn'] = useCallback(
    async ({ refreshToken, web3RegisteredUser }) => {
      const task = pipe(
        TE.Do,
        TE.bind('token', () =>
          pipe(
            api.refreshToken({ refreshToken }),
            TE.map((a) => a.token),
          ),
        ),
      )
      const resp = await task()
      if (E.isLeft(resp)) {
        return { success: false }
      }
      const userIdMatched = pipe(
        E.tryCatch(
          () => decodeJwt(resp.right.token),
          () => 'jwt invalid',
        ),
        E.chain((a) => {
          return pipe(
            intFromStringDecoder.decode(a?.sub),
            E.mapLeft(() => 'jwt invalid'),
          )
        }),
        E.chain((subInJwt) => {
          if (web3RegisteredUser.meta.id !== subInJwt) {
            return E.left('user id unmatch')
          }
          return E.right(null)
        }),
        E.isRight,
      )
      if (!userIdMatched) {
        return { success: false }
      }

      const user = U.registered({
        ...web3RegisteredUser,
        ...resp.right,
        refreshToken,
      })
      return { success: true, user }
    },
    [],
  )

  const web3SignIn: AuthState['web3SignIn'] = useCallback(
    async (payload) => {
      const resp = await getWeb3User(payload)
      if (resp.success) {
        setUser(resp.user)
        if (payload.option) {
          store.prevWeb3ConnOption.set({ option: payload.option })()
        }
        store.prevWeb3ConnType.set({ type: payload.type })()
      }
      return resp
    },
    [getWeb3User],
  )

  const serverSignIn: AuthState['serverSignIn'] = useCallback(
    async (payload) => {
      const resp = await getServerUser(payload)
      if (resp.success) {
        setUser(resp.user)
        store.credential.set({ refreshToken: resp.user.refreshToken })()
      }
      return resp
    },
    [getServerUser],
  )

  const signOut = useCallback(() => {
    store.credential.clear()
    store.prevWeb3ConnOption.clear()
    store.prevWeb3ConnType.clear()
    setUser({ _tag: 'anonymous' })
  }, [])

  const inited = useRef(false)
  useEffect(() => {
    if (inited.current) return
    inited.current = true
    const retrieveUser = async () => {
      let web3ConnType = store.prevWeb3ConnType.get()
      if (E.isLeft(web3ConnType)) {
        const windowEthereum = (window as any).ethereum as any
        if (
          windowEthereum &&
          typeof windowEthereum === 'object' &&
          windowEthereum.isCoinbaseBrowser
        ) {
          web3ConnType = E.right({ type: 'injected' }) as E.Right<{
            type: ConnectType
          }>
        } else {
          return U.anonymous
        }
      }
      const getWeb3UserResp = await getWeb3User({
        option: E.getOrElseW(() => ({
          option: null,
        }))(store.prevWeb3ConnOption.get()).option,
        type: web3ConnType.right.type,
        triggeredByUser: false,
      })
      if (!getWeb3UserResp.success) {
        return U.anonymous
      }
      const credential = store.credential.get()
      if (E.isLeft(credential)) {
        return getWeb3UserResp.user
      }
      const refreshToken = credential.right.refreshToken
      const getServerUserResp = await getServerUser({
        refreshToken,
        web3RegisteredUser: getWeb3UserResp.user,
      })
      if (!getServerUserResp.success) {
        return getWeb3UserResp.user
      }
      return getServerUserResp.user
    }
    pipe(
      TE.tryCatch(retrieveUser, () => 'err'),
      TE.fold(() => T.of(U.anonymous), T.of),
      T.map((u) => {
        setUser(u)
        return null
      }),
      (task) => task(),
    )
  }, [getWeb3User, getServerUser])

  const connectWalletModalHandler = useRef<ConnectWalletModalHandler>(null)
  const tryWeb3SignIn = useCallback(
    (onSuccess?: ConnectWalletModalOnSuccess) => {
      if (user._tag !== 'anonymous') return
      connectWalletModalHandler.current?.show(onSuccess)
    },
    [user],
  )
  const userSignModalHandler = useRef<UserSignModalHandler>(null)
  const tryServerSignIn = useCallback(
    (onSuccess?: UserSignModalOnSuccess, u?: U.Web3RegisteredUser) => {
      const u_ = u ?? user
      if (u_._tag !== 'web3-registered') return
      userSignModalHandler.current?.show(u_, onSuccess)
    },
    [user],
  )

  const reloadMeta = useCallback(() => {
    if (user._tag === 'registered' || user._tag === 'web3-registered') {
      pipe(
        api.addressUserMeta({ address: user.meta.address }),
        TE.map((meta) => {
          setUser({ ...user, meta })
        }),
        (te) => te(),
      )
    }
  }, [user])

  // Sync sentry ctx
  useEffect(() => {
    if (!ON_PROD || !ON_NODE_PROD) return
    switch (user._tag) {
      case 'pending':
        break
      case 'anonymous':
        Sentry.setContext('user', { type: 'anonymous' })
        break
      case 'web3-registered':
        Sentry.setContext('user', {
          type: 'web3-registered',
          address: user.meta.address,
          web3ConnType: user.web3ConnType,
        })
        break
      case 'registered':
        Sentry.setContext('user', {
          type: 'registered',
          address: user.meta.address,
          web3ConnType: user.web3ConnType,
        })
        break
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        user,
        web3SignIn,
        serverSignIn,
        tryWeb3SignIn,
        tryServerSignIn,
        reloadMeta,
        signOut,
      }}
    >
      {children}
      <ConnectWalletModal ref={connectWalletModalHandler} />
      <UserSignModal ref={userSignModalHandler} />
    </AuthContext.Provider>
  )
}

// MARK: Utils

// Source: https://stackoverflow.com/a/38552302
const decodeJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    Buffer.from(base64, 'base64')
      .toString()
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  return JSON.parse(jsonPayload)
}
