import { HTMLMotionProps } from 'framer-motion'

import {
  TRANSITION_DEFAULTS,
  Variants,
  withDelay,
  WithTransitionConfig,
} from './utils'

export type FadeProps = WithTransitionConfig<HTMLMotionProps<'div'>>

const variants: Variants<FadeProps> = {
  enter: ({ transition, transitionEnd, delay } = {}) => ({
    opacity: 1,
    transition:
      transition?.enter ?? withDelay.enter(TRANSITION_DEFAULTS.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ transition, transitionEnd, delay } = {}) => ({
    opacity: 0,
    transition:
      transition?.exit ?? withDelay.exit(TRANSITION_DEFAULTS.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
}

export const fadeConfig: HTMLMotionProps<'div'> = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  variants,
}
