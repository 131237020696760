import clsx from 'clsx'
import React from 'react'

import { PublicUserInfoFragment } from '@/lib/gql/types'
import { extractNftMetadataAssets, NftMetadataAssets } from '@/lib/nft/metadata'

import Blockie from './Blockie'
import { metadataAssetsAsNftImageProps, NftImage } from './NftImage'

type Props = {
  className?: string
  data: Pick<PublicUserInfoFragment, 'address' | 'avatar_nft' | 'nickname'>
}
export const Avatar = ({ className, data }: Props) => {
  if (data.avatar_nft) {
    const assets: NftMetadataAssets = extractNftMetadataAssets(
      data.avatar_nft.metadata?.meta,
      data.avatar_nft.contract.display_options,
    )
    if (assets.image || assets.video) {
      return (
        <NftImage
          className={clsx(
            className,
            'overflow-hidden [mask-image:url("/mask.svg")] [mask-size:100%] [mask-position:center]',
          )}
          {...metadataAssetsAsNftImageProps(assets)}
          objectFit="cover"
        />
      )
    }
  }
  return (
    <Blockie
      className={className}
      seed={data.address}
      alt={data.nickname || ''}
    />
  )
}
