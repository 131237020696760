import { z } from 'zod'

export const currencySymbols = {
  CNY: '¥',
  EUR: '€',
  GBP: '£',
  HKD: '$',
  IDR: 'Rp',
  INR: '₹',
  JPY: '¥',
  KRW: '₩',
  RUB: '₽',
  USD: '$',
}
export type Currency = keyof typeof currencySymbols
type Currencies = [Currency, ...Currency[]]
export const CurrencyZ = z.enum(Object.keys(currencySymbols) as Currencies)

const noDigitCurrencies: Currency[] = ['JPY', 'KRW', 'IDR', 'INR', 'RUB']

export const currencyDigits = (currency: Currency) =>
  noDigitCurrencies.includes(currency) ? 0 : 2
