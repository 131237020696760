import { BigNumber, constants } from 'ethers'
import { useEffect, useState } from 'react'

import { Call, multicall } from '@/lib/multicall'
import { Offer } from '@/lib/offers'
import { WETH_CONTRACT_MAPS } from '@/lib/x2y2'

import abi from '../../../abi/ERC20.json'

type Props = {
  networkId: number
  offers: Offer[] | null
}
export const useInvalidOfferIds = ({ networkId, offers }: Props) => {
  const [invalidOfferIds, setInvalidOfferIds] = useState(new Set<number>())

  useEffect(() => {
    if (!offers) return
    const offersToCheck = offers.filter((a) => a.is_valid)
    if (offersToCheck.length === 0) return
    const action = async () => {
      try {
        const wethContract = WETH_CONTRACT_MAPS[networkId]
        const calls: Call[] = offersToCheck.map((a) => ({
          address: wethContract,
          name: 'balanceOf',
          params: [a.maker?.address ?? constants.AddressZero], //should not happen
        }))
        const r = await multicall(networkId, abi, calls)
        const ids = offersToCheck.map((a, i) => {
          const balance = BigNumber.from(r[i].toString())
          return balance.lt(a.price) ? a.id : ([] as number[])
        })
        setInvalidOfferIds(new Set(ids.flat()))
      } catch (e) {
        console.error(e)
      }
    }
    action()
  }, [networkId, offers])

  return invalidOfferIds
}
