import { StaticJsonRpcProvider } from '@ethersproject/providers'

import { NETWORK_ETH, NETWORK_GOERLI } from '@/consts'
import { ON_SERVER } from '@/lib/env'

export const getProviderURLByNetworkId = (
  networkId: number,
): string | undefined => {
  switch (networkId) {
    case NETWORK_ETH:
      return ON_SERVER
        ? process.env.EVM_JSONRPC_ENDPOINT_ETH
        : 'https://rpc.ankr.com/eth'
    case NETWORK_GOERLI:
      return 'https://goerli.infura.io/v3/73b43a43dfc343a8a9cfcbb3fa8ceaa2'
    default:
      return undefined
  }
}

const providers: Record<number, StaticJsonRpcProvider> = {}
export const getProviderByNetworkId = (networkId: number) => {
  const cached = providers[networkId]
  if (cached) return cached
  const url = getProviderURLByNetworkId(networkId)
  if (!url) throw new Error(`Unsupported networkId: ${networkId}`)
  const provider = new StaticJsonRpcProvider(url, networkId)
  providers[networkId] = provider
  return provider
}
