import {
  createContext,
  createElement,
  ReactNode,
  useContext,
  useMemo,
} from 'react'
import useSWRImmutable from 'swr/immutable'
import { z } from 'zod'

import { Currency, currencySymbols, CurrencyZ } from '@/lib/currency'
import { fmtCurrencyNumber } from '@/lib/formatter'
import { get } from '@/lib/xy3'

import { usePersistentState } from './usePersistentState'

export type ExchangeRates = Record<string, number>

export const fmtCurrency = (currency: Currency) => currencySymbols[currency]

const defaultCurrency = 'USD'

type CurrencyCtx = {
  isLoading: boolean
  currency: Currency
  setCurrency: (currency: Currency) => void
  symbol: string
  rates: ExchangeRates
  getUSDNumber: (num: number, coin: string) => number
  fmtCoinNumber: (num: number, coin: string) => string
}
const CurrencyContext = createContext<CurrencyCtx>({
  isLoading: false,
  currency: defaultCurrency,
  setCurrency: () => undefined,
  symbol: fmtCurrency(defaultCurrency),
  rates: {},
  getUSDNumber: () => 0,
  fmtCoinNumber: () => '',
})

const extPricesZ = z.record(z.record(z.number()))
type ExtPrices = z.infer<typeof extPricesZ>
const extPriceIds = {
  ETH: 'ethereum',
  WETH: 'weth',
  X2Y2: 'x2y2',
  MATIC: 'matic-network',
  USDT: 'tether',
  USDC: 'usd-coin',
  BNB: 'binancecoin',
}
const getRates = (extPrices: ExtPrices, currency: string) =>
  Object.fromEntries(
    Object.entries(extPriceIds).flatMap(([sym, id]) => {
      const r = extPrices[id]?.[currency]
      return r ? [[sym, r] as const] : []
    }),
  )

type Props = { children: ReactNode }
export const CurrencyProvider = ({ children }: Props): JSX.Element => {
  const [savedCurrency, setCurrency] = usePersistentState('currency', CurrencyZ)
  const { data, isValidating } = useSWRImmutable(
    { url: '/ext/prices', currency: savedCurrency ?? 'USD' },
    async ({ url, currency }) => {
      const r = await get(
        url,
        {
          ids: Object.values(extPriceIds).join(','),
          vs_currencies:
            currency === 'USD' ? 'usd' : `${currency.toLowerCase()},usd`,
        },
        null,
      )
      const d = extPricesZ.parse(r.data)
      return {
        currency,
        selectedRates: getRates(d, currency.toLowerCase()),
        usdRates: getRates(d, 'usd'),
      }
    },
  )

  const value = useMemo(() => {
    const { currency, selectedRates, usdRates } = data ?? {
      currency: 'USD',
      selectedRates: {},
      usdRates: {},
    }
    const symbol = fmtCurrency(currency)
    const getUSDNumber = (num: number, coin: string) =>
      usdRates[coin] ? usdRates[coin] * num : 0
    const fmtCoinNumber = (num: number, coin: string) =>
      selectedRates[coin]
        ? symbol + fmtCurrencyNumber(selectedRates[coin] * num, currency)
        : '-'
    return {
      isLoading: isValidating,
      currency,
      setCurrency,
      symbol,
      rates: usdRates,
      getUSDNumber,
      fmtCoinNumber,
    }
  }, [data, isValidating, setCurrency])
  return createElement(CurrencyContext.Provider, { value }, children)
}

const useCurrency = () => useContext(CurrencyContext)

export default useCurrency
