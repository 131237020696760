import { useTranslation } from 'next-i18next'

import { Loader } from '@/components/feedback'
import { CheckLine, InfoCircleLine } from '@/icons'

type Props = {
  isChecking: boolean
  invalid: number
  reported: number
  total: number
  onRemoveInvalid: () => void
  onRemoveReported: () => void
  onCheck: () => void
}
export const CheckingStatus = ({
  isChecking,
  invalid,
  reported,
  total,
  onRemoveInvalid,
  onRemoveReported,
  onCheck,
}: Props) => {
  const { t } = useTranslation()
  const hasRemovable = invalid + reported > 0
  return (
    <div className="flex flex-col space-y-2">
      {isChecking ? (
        <div className="flex items-center space-x-2">
          <Loader className="h-6 w-6 text-gray-500" />
          <p className="ts-body-3 flex-1 text-gray-700">
            {t('Checking status of all items.')}
          </p>
        </div>
      ) : hasRemovable ? (
        <>
          {invalid > 0 && (
            <div className="flex items-center space-x-2">
              <InfoCircleLine className="h-6 w-6 text-gray-500" />
              <p className="ts-body-3 flex-1 text-gray-700">
                {t('{{count}} items not available', { count: invalid })}
              </p>
              <button
                className="ts-button-2 text-primary-1 hover:opacity-60"
                onClick={onRemoveInvalid}
              >
                {t('Remove')}
              </button>
            </div>
          )}
          {reported > 0 && (
            <div className="flex items-center space-x-2">
              <InfoCircleLine className="h-6 w-6 text-gray-500" />
              <p className="ts-body-3 flex-1 text-gray-700">
                {t('{{count}} items reported on OpenSea', { count: reported })}
              </p>
              <button
                className="ts-button-2 text-primary-1 hover:opacity-60"
                onClick={onRemoveReported}
              >
                {t('Remove')}
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="flex items-center space-x-2">
          <CheckLine className="h-6 w-6 text-gray-500" />
          <p className="ts-body-3 flex-1 text-gray-700">
            {t('{{count}} items in cart', { count: total })}
          </p>
          <button
            className="ts-button-2 text-primary-1 hover:opacity-60"
            onClick={onCheck}
          >
            {t('Check')}
          </button>
        </div>
      )}
    </div>
  )
}
