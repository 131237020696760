import clsx from 'clsx'
import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react'

type Variant = 'outline' | 'filled'
type ColorScheme = 'gray'
type Size = 'sm' | 'sm-md' | 'md'

const baseStyles: Record<Variant, string> = {
  outline:
    /*tw:*/ 'form-input w-full border-2 bg-transparent focus:ring-0 disabled:cursor-not-allowed',
  filled:
    /*tw:*/ 'form-input w-full border-2 border-transparent bg-gray-200 focus:ring-0 disabled:cursor-not-allowed',
}

const variantStyles: Record<Variant, Partial<Record<ColorScheme, string>>> = {
  outline: {
    gray: /*tw:*/ 'hover:border-gray-400 focus:border-gray-500 disabled:text-gray-400 placeholder:text-gray-400',
  },
  filled: {
    gray: /*tw:*/ 'hover:border-gray-500 focus:border-gray-500 placeholder:text-gray-400',
  },
}

const sizeStyles: Record<Size, string> = {
  sm: /*tw:*/ 'ts-input-1 h-10 rounded-lg px-4',
  'sm-md': /*tw:*/ 'ts-input-1 h-10 rounded-lg px-4 lg:h-12',
  md: /*tw:*/ 'ts-input-1 h-12 rounded-lg px-4',
}

type InputProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size' | 'disabled'
> & {
  variant?: Variant
  colorScheme?: ColorScheme
  size?: Size
  htmlSize?: number
  isDisabled?: boolean
  isInvalid?: boolean
}
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      variant = 'outline',
      colorScheme = 'gray',
      size = 'md',
      htmlSize,
      isInvalid = false,
      isDisabled = false,
      ...props
    },
    ref,
  ) => {
    return (
      <input
        className={clsx(
          className,
          baseStyles[variant],
          variantStyles[variant][colorScheme],
          sizeStyles[size],
          isInvalid ? 'border-primary-3' : 'border-gray-300',
        )}
        ref={ref}
        size={htmlSize}
        disabled={isDisabled}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

type InputGroupProps = {
  className?: string
  children: ReactNode
}
export const InputGroup = ({ className, children }: InputGroupProps) => (
  <div className={clsx(className, 'relative')}>{children}</div>
)

type InputElementProps = {
  className?: string
  children: ReactNode
  isInteractable?: boolean
}
export const InputLeftElement = ({
  className,
  children,
  isInteractable = false,
}: InputElementProps) => (
  <div
    className={clsx(
      className,
      'absolute inset-y-0 left-0 flex items-center px-3',
      !isInteractable && 'pointer-events-none',
    )}
  >
    {children}
  </div>
)
export const InputRightElement = ({
  className,
  children,
  isInteractable = false,
}: InputElementProps) => (
  <div
    className={clsx(
      className,
      'absolute inset-y-0 right-0 flex items-center px-3',
      !isInteractable && 'pointer-events-none',
    )}
  >
    {children}
  </div>
)
