import { HTMLMotionProps } from 'framer-motion'

import { TRANSITION_DEFAULTS, Variants, withDelay } from './utils'

type ScaleFadeOptions = {
  /**
   * The initial scale of the element
   * @default 0.95
   */
  initialScale?: number
  /**
   * If `true`, the element will transition back to exit state
   * @default true
   */
  reverse?: boolean
}

const variants: Variants<ScaleFadeOptions> = {
  exit: ({ reverse, initialScale, transition, transitionEnd, delay }) => ({
    opacity: 0,
    ...(reverse
      ? { scale: initialScale, transitionEnd: transitionEnd?.exit }
      : { transitionEnd: { scale: initialScale, ...transitionEnd?.exit } }),
    transition:
      transition?.exit ?? withDelay.exit(TRANSITION_DEFAULTS.exit, delay),
  }),
  enter: ({ transitionEnd, transition, delay }) => ({
    opacity: 1,
    scale: 1,
    transition:
      transition?.enter ?? withDelay.enter(TRANSITION_DEFAULTS.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
}

export const scaleFadeConfig: HTMLMotionProps<'div'> = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  variants,
}
