import { serializeError } from 'eth-rpc-errors'
import { SerializedEthereumRpcError } from 'eth-rpc-errors/dist/classes'
import { TFunction } from 'next-i18next'

import { ethErrorMessages } from '@/lib/ethers'

import { LoanError, loanErrorMessages } from '../xy3/errors'
import { TraverseError } from './traverse'

export const ERR_0000 = 'ERR_0000' // Wrong Market
export const ERR_0001 = 'ERR_0001' // Unknown Error
export const ERR_0002 = 'ERR_0002' // New Sell Failed
export const ERR_0003 = 'ERR_0003' // New Buy Failed
export const ERR_0004 = 'ERR_0004' // New Auction Failed
export const ERR_0005 = 'ERR_0005' // Fetch Sell Failed
export const ERR_0006 = 'ERR_0006' // Fetch Buy Failed
export const ERR_0007 = 'ERR_0007' // Fetch Auction Failed
export const ERR_0008 = 'ERR_0008' // Cancel Order Failed
export const ERR_0009 = 'ERR_0009' // Bind Email Failed
export const ERR_0010 = 'ERR_0010' // Token is not approved(offer, buy)
export const ERR_0011 = 'ERR_0011' // Insufficient funds(offer, buy)
export const ERR_0012 = 'ERR_0012' // Something wrong with the buyer(Token is not approved)
export const ERR_0013 = 'ERR_0013' // Something wrong with the buyer(Insufficient funds)
export const ERR_0014 = 'ERR_0014' // Join ILO Failed
export const ERR_0015 = 'ERR_0015' // Failed to claim X2Y2
export const ERR_0016 = 'ERR_0016' // Failed to claim rewards
export const ERR_0095 = 'ERR_0095' // Fetch Sell Failed(Moonbirds)
export const ERR_0096 = 'ERR_0096' // Fetch Buy Failed(Moonbirds)

export const ERR_0101 = 'ERR_0101' // New Sell Failed(operator filtered)
export const ERR_0102 = 'ERR_0102' // New Sell Failed(execution reverted)
export const ERR_0103 = 'ERR_0103' // New Auction Failed(operator filtered)
export const ERR_0104 = 'ERR_0104' // New Auction Failed(execution reverted)

export const ERR_1000 = 'ERR_1000' // 站点只读维护状态，无法下单
export const ERR_1001 = 'ERR_1001' // 短时间 like/unlike 太多
export const ERR_1002 = 'ERR_1002' // 短时间 list/change price 操作太多
export const ERR_1003 = 'ERR_1003' // 短时间 buy offer 操作太多
export const ERR_1004 = 'ERR_1004' // 短时间签名请求操作太多
export const ERR_1005 = 'ERR_1005' // 一天内请求次数太多
export const ERR_1006 = 'ERR_1006' // 用户被平台ban了
export const ERR_1007 = 'ERR_1007' // 你想要交易的用户被平台ban了
export const ERR_1008 = 'ERR_1008' // 发送绑定邮件太频繁，请稍后再试

const ERR_2001 = 'ERR_2001' // nft 不存在/已销毁
const ERR_2002 = 'ERR_2002' // nft 合约/meta http url调用错误
const ERR_2003 = 'ERR_2003' // nft 合约不存在
const ERR_2004 = 'ERR_2004' // nft 已经不属于你
const ERR_2005 = 'ERR_2005' // nft/合约禁止出售
const ERR_2011 = 'ERR_2011' // order 不存在
const ERR_2012 = 'ERR_2012' // 该 nft 的 order 已存在
const ERR_2013 = 'ERR_2013' // 该 nft 的 owner 已经不是下单人
const ERR_2014 = 'ERR_2014' // 发起 offer 的钱包的余额不够或者 weth 没有授权
const ERR_2015 = 'ERR_2015' // nft(ERC1155)的balance不够
// const ERR_2016 = 'ERR_2016' // 卖家同意或拒绝的时候，买家已经cancel
// const ERR_2017 = 'ERR_2017' // auction 取消失败（已经有一个人出价）
// const ERR_2018 = 'ERR_2018' // bid 失败，auction 已经结束
// const ERR_2019 = 'ERR_2019' // 该 nft 的 order 已存在
const ERR_2020 = 'ERR_2020' // order 已经取消了（下单/签名/同意一个实际已经被取消的订单）
const ERR_2021 = 'ERR_2021' // order 已经成交了（下单/签名/同意一个实际已经成交的订单）
const ERR_2022 = 'ERR_2022' // auction 无法取消（已经有一个人出价）
const ERR_2023 = 'ERR_2023' // bid 失败，auction 已经结束
const ERR_2024 = 'ERR_2024' // bid 失败，auction 有了更高的出价
const ERR_2025 = 'ERR_2025' // auction collect 失败（已经 collect 了）
const ERR_2026 = 'ERR_2026' // 该合约无法下 auction 类型的订单
const ERR_2027 = 'ERR_2027' // 订单价格已经发生了变化
const ERR_2028 = 'ERR_2028' // 该合约无法下订单
const ERR_2029 = 'ERR_2029' // 出价货币不对(...复杂的规则解释)
const ERR_2030 = 'ERR_2030' // buy失败，listing已经结束
const ERR_2031 = 'ERR_2031' // accept offer 失败，offer已经结束

const ERR_2100 = 'ERR_2100' // 取消过授权的人无法领取任何奖励

export const ERR_3000 = 'ERR_3000' // 3xxx: 统一提示数据错误
const ERR_3001 = 'ERR_3001' // intention hash 不匹配
const ERR_3002 = 'ERR_3002' // intention sig 不匹配
const ERR_3003 = 'ERR_3003' // currency 不在支持的列表
const ERR_3004 = 'ERR_3004' // jwt 用户不存在/匹配
const ERR_3005 = 'ERR_3005' // 服务器错误

export const ORDER_ERRORS = [ERR_2020, ERR_2021, ERR_2028, ERR_2030]

export const SERVER_ERRORS: {
  [key: number]: string
} = {
  1000: ERR_1000,
  1001: ERR_1001,
  1002: ERR_1002,
  1003: ERR_1003,
  1004: ERR_1004,
  1005: ERR_1005,
  1006: ERR_1006,
  1007: ERR_1007,
  1008: ERR_1008,
  2001: ERR_2001,
  2002: ERR_2002,
  2003: ERR_2003,
  2004: ERR_2004,
  2005: ERR_2005,
  2011: ERR_2011,
  2012: ERR_2012,
  2013: ERR_2013,
  2014: ERR_2014,
  2015: ERR_2015,
  // 2016: ERR_2016,
  // 2017: ERR_2017,
  // 2018: ERR_2018,
  // 2019: ERR_2019,
  2020: ERR_2020,
  2021: ERR_2021,
  2022: ERR_2022,
  2023: ERR_2023,
  2024: ERR_2024,
  2025: ERR_2025,
  2026: ERR_2026,
  2027: ERR_2027,
  2028: ERR_2028,
  2029: ERR_2029,
  2030: ERR_2030,
  2031: ERR_2031,
  2100: ERR_2100,
}

export const DATA_ERRORS: {
  [key: number]: string
} = {
  3001: ERR_3001,
  3002: ERR_3002,
  3003: ERR_3003,
  3004: ERR_3004,
  3005: ERR_3005,
}

export class MarketError extends Error {
  public constructor(errorMsg: string) {
    super()
    this.name = this.constructor.name
    this.message = errorMsg
  }
}

export const errorMessages = (t: TFunction): Record<string, string> => ({
  [ERR_0000]: t('Unknown error occurred'),
  [ERR_0001]: t('Unknown error occurred'),
  [ERR_0002]: t('Failed to create a fixed price listing'),
  [ERR_0003]: t('Failed to create an offer'),
  [ERR_0004]: t('Failed to create an auction'),
  [ERR_0005]: t('Failed to fetch details of the fixed price listing'),
  [ERR_0006]: t('Failed to fetch details of the buy offer'),
  [ERR_0007]: t('Failed to fetch details of the auction'),
  [ERR_0008]: t('Failed to cancel the listing'),
  [ERR_0009]: t('Failed to bind email'),
  [ERR_0010]: t('Token has not been approved'),
  [ERR_0011]: t('Insufficient funds'),
  [ERR_0012]: t('Approval is revoked on buyer side'),
  [ERR_0013]: t('Insufficient funds on buyer side'),
  [ERR_0014]: t('Join ILO failed'),
  [ERR_0015]: t('Failed to Claim X2Y2'),
  [ERR_0016]: t('Failed to claim rewards'),
  [ERR_0095]: `${t(
    'Failed to fetch details of the fixed price listing',
  )}(E0000)`,
  [ERR_0096]: `${t('Failed to fetch details of the buy offer')}(E0000)`,

  [ERR_0101]: t(
    'Listing cancelled. An issue is found in the NFT contract (operator filtered)',
  ),
  [ERR_0102]: t(
    'Listing cancelled. An issue is found in the NFT contract (execution reverted)',
  ),
  [ERR_0103]: t(
    'Auction cancelled. An issue is found in the NFT contract (operator filtered)',
  ),
  [ERR_0104]: t(
    'Auction cancelled. An issue is found in the NFT contract (execution reverted)',
  ),

  [ERR_1000]: t(
    'The site is in "readonly" mode for maintenance. We expect to be back in several minutes.',
  ),
  [ERR_1001]: t(
    'You clicked the Like button too often, please try again later',
  ),
  [ERR_1002]: t(
    'You are cancelling/changing price too often, please try again later',
  ),
  [ERR_1003]: t('You are making offers too often, please try again later'),
  [ERR_1004]: t(
    'You are making signing request too often, please try again later',
  ),
  [ERR_1005]: t(
    'You can only send 2 requests each day. Please retry after 24 hours.',
  ),
  [ERR_1006]: t('This address is banned from X2Y2.'),
  [ERR_1007]: t('The user your are interacting with is banned already'),
  [ERR_1008]: t('You are binding email too often, please try again later'),

  [ERR_2001]: t('The NFT is burned'),
  [ERR_2002]: t('Failed to read the NFT meta data'),
  [ERR_2003]: t('The NFT is not valid'),
  [ERR_2004]: t('The NFT no longer belongs to you'),
  [ERR_2005]: t('The NFT is not allowed to be listed'),

  [ERR_2011]: t('The listing does not exist'),
  [ERR_2012]: t('The NFT has already been listed'),
  [ERR_2013]: t('The owner of the nft has changed'),
  [ERR_2014]: t('wETH balance(or allowance) is not enough'),
  [ERR_2015]: t('NFT(ERC1155) balance is not enough'),
  // [ERR_2016]: t('The requested buy offer is cancelled'),
  // [ERR_2017]: t('Unable to cancel an auction that already has bids'),
  // [ERR_2018]: t('The auction is already ended'),
  // [ERR_2019]: t('The NFT has already been listed'),
  [ERR_2020]: t('The requested listing is cancelled'),
  [ERR_2021]: t('The listing is already purchased'),
  [ERR_2022]: t('Unable to cancel an auction that already has bids'),
  [ERR_2023]: t('The auction has already ended'),
  [ERR_2024]: t('The auction has received another bid'),
  [ERR_2025]: t('The auction has been collected'),
  [ERR_2026]: t(`The contract can't be listed as auction`),
  [ERR_2027]: t(
    `Price has changed. Please refresh the page and confirm the new price`,
  ),
  [ERR_2028]: t(`The contract can not be traded via X2Y2`),
  [ERR_2029]: t(`The currency you selected is not supported`),
  [ERR_2030]: t(`The listing is already ended`),
  [ERR_2031]: t(`The buy offer is already ended`),

  [ERR_2100]: t(`Approve error! Can't claim the rewards`),

  [ERR_3000]: `${t('Invalid data detected')}(E3000)`,
  [ERR_3001]: `${t('Invalid data detected')}(E3001)`,
  [ERR_3002]: `${t('Invalid data detected')}(E3002)`,
  [ERR_3003]: `${t('Invalid data detected')}(E3003)`,
  [ERR_3004]: `${t('Invalid data detected')}(E3004)`,
  [ERR_3005]: `${t('The server encountered an error')}(E3005)`,
})

export const calcError = (
  err: unknown | undefined,
): { code: string; message: string; data?: unknown } | undefined => {
  if (err && typeof err === 'object' && !Array.isArray(err)) {
    const e = err as Record<string, unknown>
    if (
      Object.prototype.hasOwnProperty.call(e, 'code') &&
      Object.prototype.hasOwnProperty.call(e, 'message')
    ) {
      return {
        code: (e['code'] as any).toString(),
        message: e['message'] as string,
        data: e['data'],
      }
    }
  }
  return undefined
}

// eth
// const INSUFFICIENT_FUNDS_MSG_ETH = 'insufficient funds for'
// polygon, avax
const INSUFFICIENT_FUNDS_MSG = 'err: insufficient funds for gas * price + value'
// bsc
const INSUFFICIENT_FUNDS_TRANSFER = 'err: insufficient funds for transfer'
const INSUFFICIENT_FUNDS_GAS = 'err: insufficient funds for gas'
// ftm
const INSUFFICIENT_BALANCE_TRANSFER = 'insufficient balance for transfer'
const INSUFFICIENT_BALANCE_GAS = 'insufficient balance for gas'
// arbi
const NOT_ENOUGH_FOUNDS_MSG = 'not enough funds for gas'

export const matchErrorMessages = (err: string): string[] => {
  const messages = err.replace(/\\"/g, '"').match(/"message":"([^"]*)"/g)
  return messages
    ? messages.map((msg) => {
        return JSON.parse(`{${msg}}`).message as string
      })
    : []
}

export const getErrorMessage = (
  t: TFunction,
  e: unknown,
): string | SerializedEthereumRpcError => {
  if (e instanceof LoanError) {
    const errMsgs: Record<string, string> = loanErrorMessages(t)
    return errMsgs[e.message] ?? e.message
  }
  if (e instanceof MarketError) {
    const errMsgs: Record<string, string> = errorMessages(t)
    return errMsgs[e.message] ?? e.message
  }
  if (e instanceof TraverseError) {
    if ((e as TraverseError).message === 'Not supported') {
      return t('Can not traverse to an unsupported chain')
    }
    return t('Failed to traverse the token')
  }
  const ethErrMsgs: Record<string, string> = ethErrorMessages(t)
  const err = calcError(e)
  if (err) {
    if (err.code === 'UNPREDICTABLE_GAS_LIMIT') {
      try {
        const MESSAGE_PREFIX = 'execution reverted: '
        const messages = matchErrorMessages(err.message)
        // "execution reverted: ERC721: transfer caller is not owner nor approved" etc.
        if (messages && messages.length > 1) {
          const msg = messages[0].trim().replace(MESSAGE_PREFIX, '')
          if (msg === 'total fee cap exceeded') {
            return t(`Total fee rate excessed the contract allowance.`)
          }
          return msg ? msg : t(`Something's wrong`)
        }
      } catch (ignored) {}
      // Deal with Logger.errors.UNPREDICTABLE_GAS_LIMIT(ethers.js)
      // 'Unpredictable gas limit'
      return t('Unpredictable gas limit')
    } else if (err.code === 'INSUFFICIENT_FUNDS') {
      // 'Insufficient funds'
      return t('Insufficient funds')
    } else if (err.code === '-32603') {
      const errData = calcError(err['data'])
      if (errData) {
        const errorMessage = errData.message.toLowerCase()
        if (errData.code === '-32000') {
          if (
            errorMessage.startsWith(INSUFFICIENT_FUNDS_MSG) ||
            errorMessage === NOT_ENOUGH_FOUNDS_MSG
          ) {
            // 'Insufficient funds'
            return t('Insufficient funds')
          } else if (
            errorMessage.startsWith(INSUFFICIENT_FUNDS_TRANSFER) ||
            errorMessage === INSUFFICIENT_BALANCE_TRANSFER
          ) {
            // 'Insufficient funds for transfer'
            return t('Insufficient funds for transfer')
          } else if (
            errorMessage.startsWith(INSUFFICIENT_FUNDS_GAS) ||
            errorMessage === INSUFFICIENT_BALANCE_GAS
          ) {
            // 'Insufficient funds for gas'
            return t('Insufficient funds for gas')
          }
        } else if (errData.code === '-32603') {
          if (
            errorMessage.startsWith('execution fatal: ') &&
            errorMessage.includes('BalanceLow')
          ) {
            // 'Insufficient funds' on shiden
            return t('Insufficient funds')
          }
        }
      }
    }
  }
  // Deal with MetaMask errors
  const r = serializeError(e)
  // Show 4001 error message
  if (r.code === -32603) {
    if (
      [
        'cancelled',
        'Canceled',
        'User canceled',
        'MetaMask Personal Message Signature: User denied message signature.',
      ].includes(r.message)
    ) {
      return ethErrMsgs[4001]
    }
    if (r.message.startsWith('user rejected transaction')) {
      return ethErrMsgs[4001]
    }
  }
  return ethErrMsgs[r.code] ?? r
}

export const isInvalidOffer = (e: unknown) =>
  e instanceof MarketError && [ERR_0012, ERR_0013].includes(e.message)
