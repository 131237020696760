export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const pageview = (url: string): void => {
  if (!GTM_ID) return
  try {
    ;(window as any).dataLayer.push({
      event: 'pageview',
      page: url,
    })
  } catch {}
}
