import {
  NETWORK_ARBITRUM,
  NETWORK_AVAX,
  NETWORK_BSC,
  NETWORK_BSC_TESTNET,
  NETWORK_ETH,
  NETWORK_FANTOM,
  NETWORK_OPTIMISM,
  NETWORK_POLYGON,
  NETWORK_POLYGON_TESTNET,
  NETWORK_RINKEBY,
} from '@/consts'
import { ON_PROD } from '@/lib/env'

export type TRAVERSE_TYPE =
  | 'default'
  | 'receiver1'
  | 'receiver2'
  | 'receiver3'
  | 'receiver4'
  | 'receiver5'
  | 'receiver2022'
  | 'LayerZeroable1'
  | 'LayerZeroable2'
  | 'LayerZeroReceiver'
  | 'LzApp'
  | 'onft721Core'

export type TRAVERSE_CONTRACT = {
  networkId: number
  contract: string
}

// can traverse to tiny dinos summer 2022
export const tinydinos: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xd9b78a2f1dafc8bb9c60961790d2beefebee56f4',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xefb872050656d1f3efeb4643df71b716bbf812d5',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xaaeef52ad4695b8e3b758215ca6bbca4d7680c62',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xaaeef52ad4695b8e3b758215ca6bbca4d7680c62',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xaaeef52ad4695b8e3b758215ca6bbca4d7680c62',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xaaeef52ad4695b8e3b758215ca6bbca4d7680c62',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xaaeef52ad4695b8e3b758215ca6bbca4d7680c62',
  },
]

// can traverse to tiny dinos
export const tinydinosSummer2022: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x5a1190759c9e7cf42da401639016f8f60affd465',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xfeafdc67892a8d00869fd081b8307ef18eaaa62b',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x907d2a4e0dcd20d614850800ecf83b4f59b708df',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xfeafdc67892a8d00869fd081b8307ef18eaaa62b',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x907d2a4e0dcd20d614850800ecf83b4f59b708df',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x907d2a4e0dcd20d614850800ecf83b4f59b708df',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x907d2a4e0dcd20d614850800ecf83b4f59b708df',
  },
]

const gh0stlyGh0sts: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xa74ae2c6fca0cedbaef30a8ceef834b247186bcf',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xec1df7edfcdc2e2042c63252c1cef480f64f9189',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x6047782281c8a3ec7f847eb2da5222376e1c13e0',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xec1df7edfcdc2e2042c63252c1cef480f64f9189',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xec1df7edfcdc2e2042c63252c1cef480f64f9189',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xec1df7edfcdc2e2042c63252c1cef480f64f9189',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xec1df7edfcdc2e2042c63252c1cef480f64f9189',
  },
]

const coolBears: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xce4e591c738a128a5e1dda6beb840d17c5d831f9',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x27e9f60726f82f48bb5487ce81f1036b601d5100',
  },
]

const titanWorld: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xc907ea25e5183eaa41d4737421928163174d81ce',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xc907ea25e5183eaa41d4737421928163174d81ce',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xc907ea25e5183eaa41d4737421928163174d81ce',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xc907ea25e5183eaa41d4737421928163174d81ce',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xc907ea25e5183eaa41d4737421928163174d81ce',
  },
]

const raidProject: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xdff484a2bc81c3ab7368dc58f3e5894d426f957c',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x400f70b7f49012e1aeadcb49c62054523c16a783',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xf2ca9d4d6fa28d7762401d55be5040f81f4a8619',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xf2ca9d4d6fa28d7762401d55be5040f81f4a8619',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xf2ca9d4d6fa28d7762401d55be5040f81f4a8619',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x400f70b7f49012e1aeadcb49c62054523c16a783',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x400f70b7f49012e1aeadcb49c62054523c16a783',
  },
]

const beta: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x7a676be8344a282be2cfce69d172b11ac2fbd812',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x7a676be8344a282be2cfce69d172b11ac2fbd812',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x4e8dd1996262fbdffb66f7ae45e77871d6e5a389',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xa4b00887accd7b5f5325732b4e82b98630129b67',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x4e8dd1996262fbdffb66f7ae45e77871d6e5a389',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xa4b00887accd7b5f5325732b4e82b98630129b67',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xe09efa9dcacc444f08468e8a13c3a72abc138ade',
  },
]

const theMeld: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xf807125954ef2707d8bca4de1dc4a39db9444d1e',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x67738224ccc19a662a23df32379842beefe879ee',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xe3428052aed5e6702e5c850a215ea35de4127957',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x67738224ccc19a662a23df32379842beefe879ee',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xe3428052aed5e6702e5c850a215ea35de4127957',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x0057540158a99451d26b165c436eedce88ef2890',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xf1156f7374d9d38d7b29a5e42f11a7aba2718ba8',
  },
]

const zeroApe: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x7b89890989d50b631322c6977c7937531460957a',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xa351c7b0ee07e1341625e3fe30066841cd3b0f30',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xa351c7b0ee07e1341625e3fe30066841cd3b0f30',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xa351c7b0ee07e1341625e3fe30066841cd3b0f30',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xa351c7b0ee07e1341625e3fe30066841cd3b0f30',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xa351c7b0ee07e1341625e3fe30066841cd3b0f30',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xa351c7b0ee07e1341625e3fe30066841cd3b0f30',
  },
]

const kev0: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xe0ddc558eb8d642453bd2a0cb31f01981550287a',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x6835e6539bbd0975a324331d4ebd26dc16031f68',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xb49a6213da096f72c6f83c63a68f43caabb113ad',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x06ac8de13fdc96dafa3b3772b7ea49f354ad8a9a',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xf7af7c6c078daece5c002cabd1ac7fd3c80ac573',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xb49a6213da096f72c6f83c63a68f43caabb113ad',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xb49a6213da096f72c6f83c63a68f43caabb113ad',
  },
]

const xZeros: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xae888c9cb9d6a46c993c9d298f710a44063040c9',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x936b38554a20cde6ccc0efc4378c5b6a041abd11',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x84b1fd5d4e0afe446407fd27ee90c964f2f12875',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x5684dd49cf17ae6c633eb94fcb0092816d921050',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x3bc1ceabdfe53c066c37f60b486dd484989aa882',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x12a8e658792e940bdb344264318dd2af9e5c25b2',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x84b1fd5d4e0afe446407fd27ee90c964f2f12875',
  },
]

const boxer: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xb03a572ee91aecbdfa8cef8196bf140a1e7410df',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xb03a572ee91aecbdfa8cef8196bf140a1e7410df',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xb03a572ee91aecbdfa8cef8196bf140a1e7410df',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xd560b93a6779dc5b224fcc888895fa1584b84a25',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xb03a572ee91aecbdfa8cef8196bf140a1e7410df',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xb03a572ee91aecbdfa8cef8196bf140a1e7410df',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x6b7767a448f7d87f434e96d9da7d08398a1c39c4',
  },
]

const omniMosquitoes: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xdb6fd84921272e288998a4b321b6c187bbd2ba4c',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xe124e4d4015c8f3ea1f302a2f13d0ff15ea989b3',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xe124e4d4015c8f3ea1f302a2f13d0ff15ea989b3',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xe124e4d4015c8f3ea1f302a2f13d0ff15ea989b3',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xe124e4d4015c8f3ea1f302a2f13d0ff15ea989b3',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xe124e4d4015c8f3ea1f302a2f13d0ff15ea989b3',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xe124e4d4015c8f3ea1f302a2f13d0ff15ea989b3',
  },
]

const omniBird: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x01ae9df9cc72ed928c6f34aa74a3449e8d5ab134',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x0fac187504becbbfb7d5cecf4f145767c6361c25',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x0fac187504becbbfb7d5cecf4f145767c6361c25',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x0fac187504becbbfb7d5cecf4f145767c6361c25',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x0fac187504becbbfb7d5cecf4f145767c6361c25',
  },
]

const omniRobotics: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xad50463997caa189e9700408050c7c105d364ca6',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xad50463997caa189e9700408050c7c105d364ca6',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xf9be4ac1459b64ae75986df72d1bd539e83375d5',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x2f202022450adb16d4650e91ef4b7637464e106f',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xad50463997caa189e9700408050c7c105d364ca6',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xad50463997caa189e9700408050c7c105d364ca6',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xad50463997caa189e9700408050c7c105d364ca6',
  },
]

const layerZeroPunks: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x4a9271911a7fcdef1159a8e6bf9874b28dced5c6',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xf5b2f191817cbadc9ecd0c61718309fba0fdf99f',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x85e5178cdc33101b4da22f4b53eeaa8ee4951302',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xf5b2f191817cbadc9ecd0c61718309fba0fdf99f',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xf5b2f191817cbadc9ecd0c61718309fba0fdf99f',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xf5b2f191817cbadc9ecd0c61718309fba0fdf99f',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xf5b2f191817cbadc9ecd0c61718309fba0fdf99f',
  },
]

const omniApes: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x201414c33648572e848419f7f692afd587af1d06',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x201414c33648572e848419f7f692afd587af1d06',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x201414c33648572e848419f7f692afd587af1d06',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x201414c33648572e848419f7f692afd587af1d06',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x201414c33648572e848419f7f692afd587af1d06',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x08079cc597cead1c566de3596e33d9801f131370',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x201414c33648572e848419f7f692afd587af1d06',
  },
]

const punksUnchained: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xf8eb22e806332c717307a9fd3246220c252a8f1b',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xf8eb22e806332c717307a9fd3246220c252a8f1b',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xf8eb22e806332c717307a9fd3246220c252a8f1b',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x061d2e46bb3b6666953218bf737a74d8e8fa7f1d',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xf8eb22e806332c717307a9fd3246220c252a8f1b',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x061d2e46bb3b6666953218bf737a74d8e8fa7f1d',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x061d2e46bb3b6666953218bf737a74d8e8fa7f1d',
  },
]

const omniSneaker: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x0d2e6ec50924fd7a9f763ae69de2e1eef1d6f466',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x0d2e6ec50924fd7a9f763ae69de2e1eef1d6f466',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x0d2e6ec50924fd7a9f763ae69de2e1eef1d6f466',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x0d2e6ec50924fd7a9f763ae69de2e1eef1d6f466',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x0d2e6ec50924fd7a9f763ae69de2e1eef1d6f466',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x0d2e6ec50924fd7a9f763ae69de2e1eef1d6f466',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x0d2e6ec50924fd7a9f763ae69de2e1eef1d6f466',
  },
]

const billionaireSkullClub: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x6bf7230ec3faff46bffedfbf765b54ac622bb3a6',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xbbbd1c7bb0a62b1e6b892bef552cecb4598cba2f',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xbbbd1c7bb0a62b1e6b892bef552cecb4598cba2f',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xbbbd1c7bb0a62b1e6b892bef552cecb4598cba2f',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xbbbd1c7bb0a62b1e6b892bef552cecb4598cba2f',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xbbbd1c7bb0a62b1e6b892bef552cecb4598cba2f',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xbbbd1c7bb0a62b1e6b892bef552cecb4598cba2f',
  },
]

const oneHandCleans: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x0dea7e8b0b38d20b2807d657e121430fd55789b0',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x0dea7e8b0b38d20b2807d657e121430fd55789b0',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x0dea7e8b0b38d20b2807d657e121430fd55789b0',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x0dea7e8b0b38d20b2807d657e121430fd55789b0',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x0dea7e8b0b38d20b2807d657e121430fd55789b0',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x0dea7e8b0b38d20b2807d657e121430fd55789b0',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x0dea7e8b0b38d20b2807d657e121430fd55789b0',
  },
]

const comelyTurtles: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xe0e28f13d59b3f6c37e49c8447df3c9d418f0188',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xe0e28f13d59b3f6c37e49c8447df3c9d418f0188',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xe0e28f13d59b3f6c37e49c8447df3c9d418f0188',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xe0e28f13d59b3f6c37e49c8447df3c9d418f0188',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xe0e28f13d59b3f6c37e49c8447df3c9d418f0188',
  },
]

const ozuki: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xfd31d6655c220520609d5a3105150fffb1458492',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xfd31d6655c220520609d5a3105150fffb1458492',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xfd31d6655c220520609d5a3105150fffb1458492',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xe0e28f13d59b3f6c37e49c8447df3c9d418f0188',
  },
]

const littleMouse: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x95916321280889c122e0cd49faa89a1a1cc97046',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xf1d7b209c0433b64486b4ede5aae9bfb39fe40f4',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xf1d7b209c0433b64486b4ede5aae9bfb39fe40f4',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x95916321280889c122e0cd49faa89a1a1cc97046',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x63061647f5ccc3c32899c6bb2a8f2ff76a50ed17',
  },
]

const omniPepe: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x937d65c1a4b7b3d26679d41781c64f941f1f4198',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xd11fbbc3c2d3a70351d771ff57b64949d77bdc96',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x0a6a2ffd3af808e54d3f8928a564f7004e6f1002',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x7c5610f70e42e996eecd0e7c5c3d76f69cfc79e4',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x68101d89416c8df99dd96418971f2402d3bde9f9',
  },
]

const goku: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x3632964d67a0df1ecf5b8bceba01cb04d3b454cc',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x6efbb15fe56f0b8f9b206cf4b4da452bf0378ee3',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xa395f54807ee591e28053083381bcafc4c883959',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xc9e391cd7cc125079bf2489635757eace596d5a4',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xc9e391cd7cc125079bf2489635757eace596d5a4',
  },
]

const omniA1iens: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x1186197f8ccb9b744d2f298535661321ab04da39',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x0d52a5b6ba33c94ffcd177ae4dec958e06bca1d3',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x2c445e2e47584fce9e413dc4b085402590d0c047',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x1186197f8ccb9b744d2f298535661321ab04da39',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xb8b7464c40075d2940d331df6bde28528c85f483',
  },
]

const omniChicks: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x837d420bbb100a12d8483b3cb5a7291261bfe710',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x1a0a9864e6607e163bb41f831a81d518dfe1cdce',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x32225dd6f88f2e2b890b55be5ee8e4b67ec635bc',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x32225dd6f88f2e2b890b55be5ee8e4b67ec635bc',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x1a0a9864e6607e163bb41f831a81d518dfe1cdce',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x1a0a9864e6607e163bb41f831a81d518dfe1cdce',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x32225dd6f88f2e2b890b55be5ee8e4b67ec635bc',
  },
]

const omniBoys: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_BSC,
    contract: '0xc3a119d5c5a8a9411b767ad1e5276d2f7361c685',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xc3a119d5c5a8a9411b767ad1e5276d2f7361c685',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xc3a119d5c5a8a9411b767ad1e5276d2f7361c685',
  },
]

const tinyDinos3D: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x075b6eb71ccf661c47bf23c287132b2f68990efe',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x075b6eb71ccf661c47bf23c287132b2f68990efe',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x075b6eb71ccf661c47bf23c287132b2f68990efe',
  },
]

const acidCats: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_BSC,
    contract: '0xd76a71f5452aa20207961b6152637ac9232cebab',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x794dd7adfd4508057ab4c182bcb3c0231c55345d',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x3dd927a8e88ed180249dffe8fc83d505695d6e15',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xa0e1a63e39d2c97d93c79115234c4cdfe6f33067',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xa81c9d00d29afdc83b7e788a29ec96a5bd81a8ea',
  },
]

const omniBeast: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x47e60ee1721c1ba5f1188b276bd3f49792229581',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xad934f56bbe66c418edead15b846397a3270175c',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xad934f56bbe66c418edead15b846397a3270175c',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x12e78b515b7bde43e0e774f0036bd88c2fc4c5b7',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xad934f56bbe66c418edead15b846397a3270175c',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xad934f56bbe66c418edead15b846397a3270175c',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xad934f56bbe66c418edead15b846397a3270175c',
  },
]

const ponzu: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x78b8be05e7f26a7eb82cf0cfaf4c8bca562699b7',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x09616915e91e3ff1c74f504b5c5f349c6ee2a4c9',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x78b8be05e7f26a7eb82cf0cfaf4c8bca562699b7',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x31791c3b80674de3b59734b0b4f127fc65b9d1eb',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x78b8be05e7f26a7eb82cf0cfaf4c8bca562699b7',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x78b8be05e7f26a7eb82cf0cfaf4c8bca562699b7',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xd2393193fabeae764d96c45518cee64d12d7a946',
  },
]

const omniKongz: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_BSC,
    contract: '0x51b96fc81a4a2416843a9bc2503f32ea4680d60d',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x51b96fc81a4a2416843a9bc2503f32ea4680d60d',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x51b96fc81a4a2416843a9bc2503f32ea4680d60d',
  },
]

const yakuzaPandas: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xacf63e56fd08970b43401492a02f6f38b6635c91',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xacf63e56fd08970b43401492a02f6f38b6635c91',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xacf63e56fd08970b43401492a02f6f38b6635c91',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xacf63e56fd08970b43401492a02f6f38b6635c91',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xacf63e56fd08970b43401492a02f6f38b6635c91',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xacf63e56fd08970b43401492a02f6f38b6635c91',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xacf63e56fd08970b43401492a02f6f38b6635c91',
  },
]

const omniFrogs: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xbe10f4f14aaae197d27f3a290ca3b8bc34cb8d10',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x44b78525ecb574d1d5abb74d6d5f34c915440488',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x5115358d3616df2e60fa94fcc2364f13b626848c',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x44b78525ecb574d1d5abb74d6d5f34c915440488',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x44b78525ecb574d1d5abb74d6d5f34c915440488',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x44b78525ecb574d1d5abb74d6d5f34c915440488',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x44b78525ecb574d1d5abb74d6d5f34c915440488',
  },
]

const omniCats: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x1d439cfc5c11782b58bf030c552d298b2fcb33d6',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x2c76df0fcebd5cfd67bf8b93ff8cc3dfbfa855a3',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xe953ddd0f342356c8fd15618c6d1fbdf4ef2f9de',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x1d439cfc5c11782b58bf030c552d298b2fcb33d6',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xd5c6bfdcc64edad1612534a72b85c624caa1df33',
  },
]

const ashesOfLight: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xef0e8ba283b209cbbd0f1fbf958a436d180fbee2',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xef0e8ba283b209cbbd0f1fbf958a436d180fbee2',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x4aa4625737442863401567525194dcf932f072bb',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xef0e8ba283b209cbbd0f1fbf958a436d180fbee2',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xef0e8ba283b209cbbd0f1fbf958a436d180fbee2',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xef0e8ba283b209cbbd0f1fbf958a436d180fbee2',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xef0e8ba283b209cbbd0f1fbf958a436d180fbee2',
  },
]

const omniMonke: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x4ce3f32c1091a57e2b475f660fba211ff7815c18',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x4ce3f32c1091a57e2b475f660fba211ff7815c18',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x4ce3f32c1091a57e2b475f660fba211ff7815c18',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x4ce3f32c1091a57e2b475f660fba211ff7815c18',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x4ce3f32c1091a57e2b475f660fba211ff7815c18',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x4ce3f32c1091a57e2b475f660fba211ff7815c18',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x4ce3f32c1091a57e2b475f660fba211ff7815c18',
  },
]

const METAKAYS: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x151c384c9d8aece2dfb50e61890217128f90c321',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x151c384c9d8aece2dfb50e61890217128f90c321',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x151c384c9d8aece2dfb50e61890217128f90c321',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x151c384c9d8aece2dfb50e61890217128f90c321',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x7e79c121a912ebebb63af676b4f277fb1701970b',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x151c384c9d8aece2dfb50e61890217128f90c321',
  },
]

const buildABuddy: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xb00f89145b0f74dedadaba9fb78cb656666b4459',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x4bb61530af96ae633befb456b1cd2e31d41359b7',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x4bb61530af96ae633befb456b1cd2e31d41359b7',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x4bb61530af96ae633befb456b1cd2e31d41359b7',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xc7c5bcc72153708d1212579805954922277bf4ff',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xb00f89145b0f74dedadaba9fb78cb656666b4459',
  },
]

const omniFoods: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x6d30e28f10b4370a1e15d20bf089cbf8f9313866',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x6d30e28f10b4370a1e15d20bf089cbf8f9313866',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x6d30e28f10b4370a1e15d20bf089cbf8f9313866',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x6d30e28f10b4370a1e15d20bf089cbf8f9313866',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x6d30e28f10b4370a1e15d20bf089cbf8f9313866',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x6d30e28f10b4370a1e15d20bf089cbf8f9313866',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x1b0622929b57ff6bd7ad4817e470452bbcf12d5e',
  },
]

const st0ked: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x0d87d35dc73056484e4e41300a810dc58ad1d668',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x1325cba8123f2bf381911ef36e23738f2e2e4697',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x1325cba8123f2bf381911ef36e23738f2e2e4697',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x086bb1bccc2b4b0d2ea48d392f27d2c7b94ca9cd',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x086bb1bccc2b4b0d2ea48d392f27d2c7b94ca9cd',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x086bb1bccc2b4b0d2ea48d392f27d2c7b94ca9cd',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x086bb1bccc2b4b0d2ea48d392f27d2c7b94ca9cd',
  },
]

const deadFreaks: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x37337e16b5893278378ad5ad79c52ba84ae2e6c1',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x32cdc61e370536cbe4c32f759aea717e493715c3',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xb4932f7d2f391c61b0ee578aade52b6701639c4e',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xffb4c26595ab2470b212a9c543bb9d6c9622fc1c',
  },
]

const blackCatDead: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_AVAX,
    contract: '0xbdcc8a9461ed33eed78b1943c404471218696629',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x5612f86d1c0cf420b8217f08d55db209311a7948',
  },
]

const tozokuNFT: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x73569e43f4c85abfe0d1409dbdb2f9a92a344d2a',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xc79aa1b6f20f10c0d27bea9a0e57b2daf0788755',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xc79aa1b6f20f10c0d27bea9a0e57b2daf0788755',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x73569e43f4c85abfe0d1409dbdb2f9a92a344d2a',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x2a7f56a631a608fd384dbb4d2fafd5398cb8df7b',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xc79aa1b6f20f10c0d27bea9a0e57b2daf0788755',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xc79aa1b6f20f10c0d27bea9a0e57b2daf0788755',
  },
]

const nb4: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x4b58800b34ffbc6e08fe71510eb1c7d951cbcacf',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x2535e11f267ccdb9e194cba4a7a13ebc9ba3e172',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x4b58800b34ffbc6e08fe71510eb1c7d951cbcacf',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x4b58800b34ffbc6e08fe71510eb1c7d951cbcacf',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x4b58800b34ffbc6e08fe71510eb1c7d951cbcacf',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x4b58800b34ffbc6e08fe71510eb1c7d951cbcacf',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x4b58800b34ffbc6e08fe71510eb1c7d951cbcacf',
  },
]

const huntersOfEvil: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_AVAX,
    contract: '0xc991eb54dea1cc20d5b945aea63ffe69192cb3cf',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xd813da9995c753145927e25b8ecc18e6bf2b6ae0',
  },
]

const tinyNinjas: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xfd16ccc0cbb6aeffd7e340df98d1764679d5e9db',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xf41096a3792476f0957d27e56fe894a83b2224d5',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xf41096a3792476f0957d27e56fe894a83b2224d5',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xf41096a3792476f0957d27e56fe894a83b2224d5',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xf41096a3792476f0957d27e56fe894a83b2224d5',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xf41096a3792476f0957d27e56fe894a83b2224d5',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xf41096a3792476f0957d27e56fe894a83b2224d5',
  },
]

const tinyBoys: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x64c9f79db24840423665c96cc6fdf4e3508caad8',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x64c9f79db24840423665c96cc6fdf4e3508caad8',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x64c9f79db24840423665c96cc6fdf4e3508caad8',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x64c9f79db24840423665c96cc6fdf4e3508caad8',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x481c93330aacb6ea2a792703c962725c9b70a592',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x64c9f79db24840423665c96cc6fdf4e3508caad8',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x64c9f79db24840423665c96cc6fdf4e3508caad8',
  },
]

const flekos: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x16dfc67641218a1d6404b2b91350ac78110d56ac',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xaab7a7a301f19b8482d6c4942e0ef977d4361e42',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xaab7a7a301f19b8482d6c4942e0ef977d4361e42',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xaab7a7a301f19b8482d6c4942e0ef977d4361e42',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xaab7a7a301f19b8482d6c4942e0ef977d4361e42',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xaab7a7a301f19b8482d6c4942e0ef977d4361e42',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xaab7a7a301f19b8482d6c4942e0ef977d4361e42',
  },
]

const omniOkayBears: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_BSC,
    contract: '0x8cf1e498f23fec83f60314c09efa7ae2b9d85d6d',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x8cf1e498f23fec83f60314c09efa7ae2b9d85d6d',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x8cf1e498f23fec83f60314c09efa7ae2b9d85d6d',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x8cf1e498f23fec83f60314c09efa7ae2b9d85d6d',
  },
]

const oni: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x6f61f45f7a3f64b0f2098cdad3bac5dc6aed8354',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x5cb6cd0bae76a11130015de705f38c02ac611f2f',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x5cb6cd0bae76a11130015de705f38c02ac611f2f',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x5cb6cd0bae76a11130015de705f38c02ac611f2f',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x5cb6cd0bae76a11130015de705f38c02ac611f2f',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x5cb6cd0bae76a11130015de705f38c02ac611f2f',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x7770a86373cfbae7b24c19094c3a7dc9c93d95c8',
  },
]

const dagongrenNFT: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x3e7cc0fe837de855f139348a7f6f72f6c69c68a3',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x43dcd6e5852b438b034b81f3e7c30d6302f89e11',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xe605f5773362dcd0523e2dcc8c40c73c034dee9c',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x7ea32e38e2f7d3784a5f3853d9f2ce77ac82e587',
  },
]

const nekroDemonz: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_AVAX,
    contract: '0xbef231d0138547c77807aa9145a91d09e99a2667',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x0c60c064fd949e6ceaa0ef9f7e4511fe70eb4189',
  },
]

const obsession: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_AVAX,
    contract: '0x19a166ca2a735851a08fdd7732d105e97ce64903',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x96ae96b5f9068dcb6a532018ee60ffcdd4f33085',
  },
]

const geneXProject: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x959e0667313051f573927c0ca8ae091b077da6a0',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x3a1e4cca3d617e76ed0fc77972852230b595445b',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x3a1e4cca3d617e76ed0fc77972852230b595445b',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x3a1e4cca3d617e76ed0fc77972852230b595445b',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x3a1e4cca3d617e76ed0fc77972852230b595445b',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x3a1e4cca3d617e76ed0fc77972852230b595445b',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x3a1e4cca3d617e76ed0fc77972852230b595445b',
  },
]

const uninvitedElephant: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x5ed57513c478101e5beb8019b1e3284b53108af4',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xf4fd01ef4302a985aac2e5f18f091ed8a960e3d4',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xf4fd01ef4302a985aac2e5f18f091ed8a960e3d4',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xf4fd01ef4302a985aac2e5f18f091ed8a960e3d4',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xf4fd01ef4302a985aac2e5f18f091ed8a960e3d4',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xf4fd01ef4302a985aac2e5f18f091ed8a960e3d4',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x6151eb96e6376c1dbff3126c24f57cd140cda499',
  },
]

const moodyKittens: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xb5c4fc379064258c4c9b197e99fd3efd9f2f21e3',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xb5c4fc379064258c4c9b197e99fd3efd9f2f21e3',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xb5c4fc379064258c4c9b197e99fd3efd9f2f21e3',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x0115a859d30bc89005a36fc4f726c4bad56e06f8',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xb5c4fc379064258c4c9b197e99fd3efd9f2f21e3',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xb5c4fc379064258c4c9b197e99fd3efd9f2f21e3',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xb5c4fc379064258c4c9b197e99fd3efd9f2f21e3',
  },
]

const r0cketflippers: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x23dcf3a5f8b3327c3d8c01974d41491a1791cdc2',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x7eb26b6145f0c11da4ed95609f347a644062852e',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xa643e1778c82e213b90b430e9ae96c59ffcf5924',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xbcc0fbcc982ab58dabd0e0ca8fb8d1a08410ce7c',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xb16ed82e07d93882378357d3cf7e3e92efd5abfb',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x05b2fc8ab800a1650a4989203bfdeec8b9731440',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xbcc0fbcc982ab58dabd0e0ca8fb8d1a08410ce7c',
  },
]

const pozzlenautsONFT: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xa11937c76f8569fc47fe2d7d3eb80288812ef380',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xa11937c76f8569fc47fe2d7d3eb80288812ef380',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xa11937c76f8569fc47fe2d7d3eb80288812ef380',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xa11937c76f8569fc47fe2d7d3eb80288812ef380',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xa11937c76f8569fc47fe2d7d3eb80288812ef380',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x667f965913e10ad3f1b4804954a814581359251f',
  },
]

const bunnyBatsV2: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xbba1e60ba8b77e26d8fe7cf8f9c11b2a14cc8365',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xbb5518859ab064bd484fb632a475968e7592e2e3',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xbb5518859ab064bd484fb632a475968e7592e2e3',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xbb5518859ab064bd484fb632a475968e7592e2e3',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xbb5518859ab064bd484fb632a475968e7592e2e3',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xbb5518859ab064bd484fb632a475968e7592e2e3',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xbb5518859ab064bd484fb632a475968e7592e2e3',
  },
]

const kidStory: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xe2d18960a3440d0fa0ffcec6b665a09b1c429435',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xe954a4da95853a1b15608365aa52a8504e4badd0',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xcd70bc918e00ca2331006112c1de9e960a8e5194',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xe2d18960a3440d0fa0ffcec6b665a09b1c429435',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xe2d18960a3440d0fa0ffcec6b665a09b1c429435',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xe2d18960a3440d0fa0ffcec6b665a09b1c429435',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xe2d18960a3440d0fa0ffcec6b665a09b1c429435',
  },
]

const gregs: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x7ffe2672c100bfb0094ad0b4d592dd9f9416f1ac',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xc5f4f67442e688bc4da2d9d8a055374e642490a4',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x018bb96d00309236e6d56046bbd8e9e083cc8ce9',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x54417f05c4d5e08b079bd671d0158ff2854a4a88',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x6c25c2c42928ee8d65d2c3b0a29571bd4549a96b',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xbb2e4b6e10fe9ccebfdca805cdcf9fa9fb65248f',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x165865de32ba3d9552ff814c2f283964c2b61a7d',
  },
]

const royalCrows: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x01b6d10c09bc60d5e043d888ead896a395e210ca',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x3ce2ad7261cd6ea10a0bdbbefce4b9e4f1b0dc70',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x3ce2ad7261cd6ea10a0bdbbefce4b9e4f1b0dc70',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x3ce2ad7261cd6ea10a0bdbbefce4b9e4f1b0dc70',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xb32b3bb699634c7f5ed0b39ce7f2290b0e8cd601',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xb32b3bb699634c7f5ed0b39ce7f2290b0e8cd601',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x3ce2ad7261cd6ea10a0bdbbefce4b9e4f1b0dc70',
  },
]

const gutterPunks: TRAVERSE_CONTRACT[] = [
  // {
  //   networkId: NETWORK_ETH,
  //   contract: '0xe9dc24fbc8e53fe562eb9443885610b51f84a441',
  // },
  {
    networkId: NETWORK_BSC,
    contract: '0x45eaf47ac69b89b55b3f78950ff03a9a023c6f7a',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x45eaf47ac69b89b55b3f78950ff03a9a023c6f7a',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x2aa8170c659a4b27f6c954233369cfcef6870398',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xdd0648ea8ff8ee5940c5909715d9778ec00b2ee4',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xdd0648ea8ff8ee5940c5909715d9778ec00b2ee4',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xdd0648ea8ff8ee5940c5909715d9778ec00b2ee4',
  },
]

const weSeeGhosts: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x496f441bc37f896b033af00fc7061dd4ad097471',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x27d373d74bb43eebd0fbd9f8424fbbdca31228e1',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0x27d373d74bb43eebd0fbd9f8424fbbdca31228e1',
  },
]

const oOCrazySnails: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0xb20b4d45640fe8a71ca2329f6c7ec415a2a26b51',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0xb20b4d45640fe8a71ca2329f6c7ec415a2a26b51',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0xb20b4d45640fe8a71ca2329f6c7ec415a2a26b51',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0xb20b4d45640fe8a71ca2329f6c7ec415a2a26b51',
  },
  {
    networkId: NETWORK_ARBITRUM,
    contract: '0xb20b4d45640fe8a71ca2329f6c7ec415a2a26b51',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0xb20b4d45640fe8a71ca2329f6c7ec415a2a26b51',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0xb20b4d45640fe8a71ca2329f6c7ec415a2a26b51',
  },
]

const tinyDaemons: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x8bb765ae3e2320fd9447889d10b9dc7ce4970da5',
  },
  {
    networkId: NETWORK_BSC,
    contract: '0x8bb765ae3e2320fd9447889d10b9dc7ce4970da5',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x8bb765ae3e2320fd9447889d10b9dc7ce4970da5',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x8bb765ae3e2320fd9447889d10b9dc7ce4970da5',
  },
  {
    networkId: NETWORK_OPTIMISM,
    contract: '0x8bb765ae3e2320fd9447889d10b9dc7ce4970da5',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x8bb765ae3e2320fd9447889d10b9dc7ce4970da5',
  },
]

const knightsTemplarNFT: TRAVERSE_CONTRACT[] = [
  {
    networkId: NETWORK_ETH,
    contract: '0x3258af46d6f86ce85364ffef77e346565664b19e',
  },
  {
    networkId: NETWORK_AVAX,
    contract: '0x3258af46d6f86ce85364ffef77e346565664b19e',
  },
  {
    networkId: NETWORK_POLYGON,
    contract: '0x3258af46d6f86ce85364ffef77e346565664b19e',
  },
  {
    networkId: NETWORK_FANTOM,
    contract: '0x3258af46d6f86ce85364ffef77e346565664b19e',
  },
]

const testNFT = [
  {
    networkId: NETWORK_RINKEBY,
    contract: '0xd405b8baa09187059fc1f922e5fa25f5d2d7cb97',
  },
  {
    networkId: NETWORK_BSC_TESTNET,
    contract: '0xcdb6e2c980f0575b77aa3efcae869b925c384b0d',
  },
  {
    networkId: NETWORK_POLYGON_TESTNET,
    contract: '0x1298a41427e599508affbad1a1a211fad91a2420',
  },
]

export const TRAVERSE_CONTRACTS: {
  [key: string]: TRAVERSE_CONTRACT[][]
} = ON_PROD
  ? {
      default: [
        acidCats,
        ashesOfLight,
        beta,
        billionaireSkullClub,
        blackCatDead,
        buildABuddy,
        bunnyBatsV2,
        coolBears,
        dagongrenNFT,
        deadFreaks,
        flekos,
        geneXProject,
        gh0stlyGh0sts,
        goku,
        huntersOfEvil,
        kidStory,
        knightsTemplarNFT,
        METAKAYS,
        moodyKittens,
        nb4,
        nekroDemonz,
        obsession,
        omniA1iens,
        omniApes,
        omniBeast,
        omniCats,
        omniChicks,
        omniKongz,
        omniMosquitoes,
        omniPepe,
        omniSneaker,
        oneHandCleans,
        oni,
        ozuki,
        ponzu,
        punksUnchained,
        r0cketflippers,
        raidProject,
        theMeld,
        tinydinos,
        tinydinosSummer2022,
        tinyDinos3D,
        titanWorld,
        tozokuNFT,
        uninvitedElephant,
        weSeeGhosts,
        zeroApe,
      ],
      receiver1: [comelyTurtles, layerZeroPunks, littleMouse, omniFoods],
      receiver2: [omniBird, omniFrogs],
      receiver3: [omniRobotics],
      receiver4: [omniOkayBears],
      receiver5: [tinyDaemons],
      receiver2022: [st0ked, tinyNinjas],
      LayerZeroable1: [kev0],
      LayerZeroable2: [xZeros],
      LayerZeroReceiver: [boxer],
      LzApp: [gregs, omniBoys, omniMonke, yakuzaPandas],
      onft721Core: [
        gutterPunks,
        oOCrazySnails,
        pozzlenautsONFT,
        royalCrows,
        tinyBoys,
      ],
    }
  : { default: [testNFT] }
