import { ethers } from 'ethers'

import { DEFAULT_USER_TAB, Tab } from '@/components/user/tabs/utils'

import { toChecksumAddress } from '../web3'
import * as activities from './activities'
import * as items from './items'
import * as offers from './offers'

export type PageUrlPayload =
  | {
      tab: Extract<Tab, 'portfolio'>
    }
  | {
      tab: Extract<Tab, 'items'>
      query?: items.Query
    }
  | {
      tab: Extract<Tab, 'favorites'>
      page?: number
    }
  | {
      tab: Extract<Tab, 'offers'>
      query?: offers.Query
      page?: number
    }
  | {
      tab: Extract<Tab, 'activities'>
      query?: activities.Query
    }

export const pageURL = (addr: string, payload?: PageUrlPayload) => {
  const tab = payload?.tab ?? 'items'
  const query = (() => {
    if (!payload) return ''
    switch (payload.tab) {
      case 'portfolio':
        return ''
      case 'items':
        return items.urlQueryCodec.encode(payload.query ?? items.DEFAULT_QUERY)
      case 'favorites': {
        if (payload.page && payload.page > 1) return `?page=${payload.page}`
        return ''
      }
      case 'offers':
        return offers.urlQueryCodec.encode(payload)
      case 'activities':
        return activities.urlQueryCodec.encode(payload.query ?? {})
    }
  })()
  return `/user/${
    ethers.utils.isAddress(addr) ? toChecksumAddress(addr) : addr
  }/${tab}${query}`
}

export const pageURLMatcher = (addr: string, payload?: PageUrlPayload) => {
  return new RegExp(
    `^/user/${toChecksumAddress(addr)}/${payload?.tab ?? DEFAULT_USER_TAB}`,
  )
}
