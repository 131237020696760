import { useTranslation } from 'next-i18next'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  PopoverSelect,
  Textarea,
} from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import { UseDisclosureReturn, useLoading } from '@/hooks'
import { FlagLine } from '@/icons'
import * as api from '@/lib/api'
import { useUser } from '@/lib/auth'
import { getUserAddress } from '@/lib/auth/utils'
import { E, O } from '@/lib/fp'
import { getErrorMessage } from '@/lib/market'
import toast from '@/lib/toast'

const reasons = ['copyright', 'sensitive', 'other'] as const
type Reason = (typeof reasons)[number]

interface Props {
  disclosure: UseDisclosureReturn
  nftId: number
}

export const ReportModal = ({ disclosure, nftId }: Props) => {
  const { t } = useTranslation()

  const [type, setType] = useState<Reason>(reasons[0])
  const [url, setUrl] = useState('')
  const [desc, setDesc] = useState('')

  const user = useUser()
  const { isLoading, setLoading, guardLoading } = useLoading()

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const reasonTitles: Record<Reason, string> = {
    copyright: t('Fake collection or possible scam'),
    sensitive: t('Explicit and sensitive content'),
    other: t('Other'),
  }
  const errMsgs: Record<string, string> = {
    INVALID_URL: t('The URL is invalid.'),
    MISSING_COMMENT: t('Please provide additional comment.'),
    COMMENT_TOO_LONG: 'The comment needs to be within 240 characters.',
  }

  const onSubmit = async () => {
    if (!recaptchaRef.current) return
    setLoading(true)
    try {
      const token = await recaptchaRef.current.executeAsync()
      const address = O.toNullable(getUserAddress(user))
      const body = { type, url, desc, nftId, token, address }
      const data = await api.marketReport(body)()
      if (E.isLeft(data)) throw new Error(data.left)
      toast({
        title: t('This item has been reported.'),
        status: 'success',
      })
      disclosure.onClose()
    } catch (e) {
      const error =
        (e instanceof Error ? errMsgs[e.message] : null) ??
        getErrorMessage(t, e)
      toast({
        status: 'error',
        title: typeof error === 'string' ? error : t('Something is wrong'),
      })
    } finally {
      recaptchaRef.current.reset()
      setLoading(false)
      setUrl('')
      setDesc('')
    }
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Report')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-6 lg:space-y-8">
          <FormControl>
            <FormLabel htmlFor="type">{t('Reason')}</FormLabel>
            <PopoverSelect
              className="h-12"
              items={reasons.map((a) => ({
                key: a,
                title: reasonTitles[a],
                value: a,
              }))}
              value={type}
              onChange={(value) => setType(value)}
            />
          </FormControl>
          {type === 'copyright' && (
            <FormControl>
              <FormLabel htmlFor="url">{t('Original Collection')}</FormLabel>
              <Input
                id="url"
                placeholder="https://x2y2.io"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormControl>
          )}
          <FormControl>
            <FormLabel htmlFor="desc">{t('Additional Comments')}</FormLabel>
            <Textarea
              className="h-24 w-full"
              id="desc"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </FormControl>
          <Button
            colorScheme="primary-1"
            size="md"
            leftIcon={<FlagLine className="h-4 w-4" />}
            isLoading={isLoading}
            onClick={onSubmit}
          >
            {t('Report')}
          </Button>
        </ModalBody>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? ''}
        />
      </ModalContent>
    </Modal>
  )
}
