import { useTranslation } from 'next-i18next'

import useCurrency from '@/hooks/useCurrency'

type Props = {
  price: string
  symbol: string
}
export const FiatPricePreview = ({ price, symbol }: Props) => {
  const { t } = useTranslation()
  const { fmtCoinNumber } = useCurrency()
  const value = parseFloat(price)
  return (
    <p className="ts-caption-2 mt-1 text-gray-500">
      {t('{{price}} total', {
        price: fmtCoinNumber(Number.isFinite(value) ? value : 0, symbol),
      })}
    </p>
  )
}
