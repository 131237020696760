import { Trans, useTranslation } from 'next-i18next'

import { Button } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@/components/overlay'
import { UseDisclosureReturn } from '@/hooks'

import { Order } from '../tabs'

type WarningProps = {
  sellOrder: Order
  offer: Order
  fmtCurrency: (a: string) => string
  fmtPrice: (a: Order) => string
  fmtPriceUsd: (a: Order) => string
  onContinue: () => void
}

type WarningModalProps = WarningProps & {
  disclosure: UseDisclosureReturn
}

export const DifferentCurrencyWarningModal = ({
  disclosure,
  sellOrder,
  offer,
  fmtCurrency,
  fmtPrice,
  fmtPriceUsd,
  onContinue,
}: WarningModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-6 lg:space-y-8">
          <div className="flex flex-col space-y-3 pt-10">
            <p className="ts-headline-3 text-center lg:ts-headline-2">
              {t('Different currency')}
            </p>
            <p className="ts-body-3 text-center lg:ts-body-2">
              <Trans t={t}>
                The price of this offer {{ offerPrice: fmtPrice(offer) }} (
                {{ offerPriceUsd: fmtPriceUsd(offer) }}) is in a different
                currency {{ offerCurrency: fmtCurrency(offer.currency) }} from
                your listing price {{ listingPrice: fmtPrice(sellOrder) }} (
                {{ listingPriceUsd: fmtPriceUsd(sellOrder) }}
                ). Make sure it is what you want to accept.
              </Trans>
            </p>
          </div>
          <div className="flex space-x-2.5">
            <Button
              className="flex-1"
              colorScheme="primary-3"
              onClick={() => {
                disclosure.onClose()
                onContinue()
              }}
            >
              {t('Continue')}
            </Button>
            <Button
              className="flex-1"
              variant="outline"
              onClick={disclosure.onClose}
            >
              {t('Cancel')}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
