import { Disclosure } from '@headlessui/react'
import { Fragment, ReactElement, ReactNode } from 'react'

import { Collapse } from '@/components/transition'
import { ArrowDownSimpleLine } from '@/icons'

type AccordionItemProps = {
  children: ReactNode | ((props: { open: boolean }) => ReactElement)
  defaultOpen?: boolean
}
export const AccordionItem = ({
  children,
  defaultOpen,
}: AccordionItemProps) => (
  <Disclosure as="div" className="flex flex-col" defaultOpen={defaultOpen}>
    {children}
  </Disclosure>
)

type AccordionButtonProps = {
  children: ReactNode
}
export const AccordionButton = ({ children }: AccordionButtonProps) => (
  <Disclosure.Button className="flex items-center overflow-hidden py-3 text-start hover:opacity-50">
    {children}
  </Disclosure.Button>
)

export const AccordionIcon = () => (
  <ArrowDownSimpleLine className="h-6 w-6 text-gray-500 ui-open:rotate-180 motion-safe:duration-200" />
)

type AccordionPanelProps = {
  children: ReactNode
}
export const AccordionPanel = ({ children }: AccordionPanelProps) => {
  return (
    <Disclosure.Panel as={Fragment} static>
      {({ open }) => (
        <Collapse in={open}>
          <div className="border-b py-3" role="region">
            {children}
          </div>
        </Collapse>
      )}
    </Disclosure.Panel>
  )
}
