import { createContext, useContext } from 'react'

import { ColorModeContextType } from './types'

export const ColorModeContext = createContext({} as ColorModeContextType)

ColorModeContext.displayName = 'ColorModeContext'

export const useColorMode = () => useContext(ColorModeContext)

export function useColorModeValue<TLight, TDark>(light: TLight, dark: TDark) {
  const { colorMode } = useColorMode()
  return colorMode === 'dark' ? dark : light
}
