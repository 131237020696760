import { BigNumber, constants } from 'ethers'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

import { useAuth } from '@/lib/auth'
import { isNonAnynomousUser } from '@/lib/auth/types/user'
import { ERC20__factory } from '@/lib/contract'
import { min } from '@/lib/ethers'
import toast from '@/lib/toast'
import { WETH_CONTRACT_MAPS } from '@/lib/x2y2'
import { DEFAULT_NETWORK, getProviderByNetworkId } from '@/utils/network'

const wethContract = WETH_CONTRACT_MAPS[DEFAULT_NETWORK]

type Props = {
  isActive: boolean
  totalPrice: BigNumber
}
export const usePayWithBoth = ({ isActive, totalPrice }: Props) => {
  const { t } = useTranslation()

  const { user } = useAuth()

  const [balanceEth, setBalanceEth] = useState(constants.Zero)
  const [balanceWeth, setBalanceWeth] = useState(constants.Zero)
  useEffect(() => {
    if (!isActive) return
    if (!isNonAnynomousUser(user)) return
    const action = async () => {
      try {
        const userChainId = (await user.web3Provider.getNetwork()).chainId
        if (userChainId !== DEFAULT_NETWORK) return

        const provider = getProviderByNetworkId(DEFAULT_NETWORK)
        const address = user.meta.address
        const balanceEth = await provider.getBalance(address)
        const balanceWeth = await ERC20__factory.connect(
          wethContract,
          provider,
        ).balanceOf(address)
        setBalanceEth(balanceEth)
        setBalanceWeth(balanceWeth)
      } catch (e) {
        console.error(e)
        toast({
          status: 'error',
          title: t(`Something's wrong`),
        })
      }
    }
    action()
  }, [t, user, isActive])

  const [payWithEth, setPayWithEth] = useState(true)
  const [payWithWeth, setPayWithWeth] = useState(false)

  const calcAmounts = () => {
    const providedEth = payWithEth ? balanceEth : constants.Zero
    const providedWeth = payWithWeth ? balanceWeth : constants.Zero
    // RULE: Prefer WETH to ETH
    if (providedWeth.gte(totalPrice)) {
      return {
        amountToEth: constants.Zero,
        amountToWeth: totalPrice,
      }
    } else {
      return {
        amountToEth: min(totalPrice.sub(providedWeth), providedEth),
        amountToWeth: providedWeth,
      }
    }
  }
  const { amountToEth, amountToWeth } = calcAmounts()
  const amountTotal = amountToEth.add(amountToWeth)
  const amountInsufficient = amountTotal.lt(totalPrice)
    ? totalPrice.sub(amountTotal)
    : constants.Zero
  return {
    balanceEth,
    balanceWeth,
    payWithEth,
    setPayWithEth,
    payWithWeth,
    setPayWithWeth,
    amountToEth,
    amountToWeth,
    amountTotal,
    amountInsufficient,
  }
}
