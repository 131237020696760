import { NETWORK_ETH, NETWORK_GOERLI } from '@/consts'

const BASE_URLS: Record<number, string> = {
  [NETWORK_ETH]: 'https://etherscan.io',
  [NETWORK_GOERLI]: 'https://goerli.etherscan.io',
}

export const scannerBaseURL = (networkId: number) => {
  const url = BASE_URLS[networkId]
  if (!url) throw new Error(`Unsupported networkId: ${networkId}`)
  return url
}

export const scannerTxURL = (hash: string, networkId: number) => {
  return `${scannerBaseURL(networkId)}/tx/${hash}`
}

export const scannerContractURL = (address: string, networkId: number) => {
  return `${scannerBaseURL(networkId)}/token/${address}`
}

export const scannerTokenURL = (
  address: string,
  tokenId: string,
  networkId: number,
) => {
  return `${scannerContractURL(address, networkId)}?a=${tokenId}`
}
