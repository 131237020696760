import {
  BigNumber,
  constants,
  ContractTransaction,
  ethers,
  PayableOverrides,
  providers,
} from 'ethers'

import * as api from '@/lib/api'
import {
  ERC721__factory,
  ERC721Delegate__factory,
  ERC1155__factory,
  ERC1155Delegate__factory,
  X2Y2R1,
  X2Y2R1__factory,
} from '@/lib/contract'
import { E } from '@/lib/fp'
import {
  ERC721_DELEGATE_CONTRACT_MAPS,
  ERC1155_DELEGATE_CONTRACT_MAPS,
  MARKET_CONTRACT_MAPS,
  WETH_CONTRACT_MAPS,
} from '@/lib/x2y2'

import { OfferItem } from '../loan'
import { stripStr } from '../string'
import { downPay, LOAN_X2Y2_EXCHANGE_CONTRACT_MAP } from '../xy3'
import {
  checkListingNfts,
  MarketError,
  NFTToken,
  Order,
  OrderDetailResp,
  RunInput,
} from './'
import * as Err from './errors'
import { checkNestingNfts } from './nft'
import {
  CHECK_RESULT_APPROVE,
  CHECK_RESULT_BALANCE,
  CHECK_RESULT_OK,
  checkBalance,
} from './token'
import {
  AcceptBuyParam,
  AuctionParam,
  BidParam,
  BuyNowParam,
  BuyOfferParam,
  CancelInput,
  CancelOrderParam,
  ChangePriceParam,
  CompleteAuctionParam,
  OrderError,
  RefundAuctionParam,
  SellOfferParam,
} from './types'
import {
  decodeCancelInput,
  decodeRunInput,
  encodeItemData,
  encodeOrder,
  fixDeadline,
  fixSignature,
  is1155ItemData,
  is1155Order,
  randomSalt,
  signOrderData,
} from './utils'

export const INTENT_SELL = 1
export const INTENT_AUCTION = 2
export const INTENT_BUY = 3

// const OP_INVALID = 0 // INVALID
// off-chain
const OP_COMPLETE_SELL_OFFER = 1 // COMPLETE_SELL_OFFER
const OP_COMPLETE_BUY_OFFER = 2 // COMPLETE_BUY_OFFER
const OP_CANCEL_OFFER = 3 // CANCEL_OFFER
// auction
const OP_BID = 4 // BID
const OP_COMPLETE_AUCTION = 5 // COMPLETE_AUCTION
const OP_REFUND_AUCTION = 6 // REFUND_AUCTION
const OP_REFUND_AUCTION_STUCK_ITEM = 7 // REFUND_AUCTION_STUCK_ITEM

export const TOKEN_721 = 1
export const TOKEN_1155 = 2
export const DELEGATION_TYPE_INVALID = 0
export const DELEGATION_TYPE_ERC721 = 1
export const DELEGATION_TYPE_ERC1155 = 2

export const ORDER_TYPE_AUCTION = 'auction'
export const ORDER_TYPE_BUY = 'buy'
export const ORDER_TYPE_SELL = 'sell'

const AMOUNT_0 = BigNumber.from(0)

export const isNative = (currency: string): boolean => {
  return !currency || currency === constants.AddressZero
}

export const handleOrderResp = (
  orderResp: E.Either<string, OrderDetailResp>,
  defaultError: string,
): OrderError[] => {
  let errorMsg = ''
  const orderErrors: OrderError[] = []
  if (E.isLeft(orderResp)) {
    errorMsg = defaultError
  } else {
    const resp = orderResp.right
    if (!resp.success) {
      if (resp.errors) {
        resp.errors.forEach((e) => {
          const serverError = Err.SERVER_ERRORS[e.code]
          if (serverError) {
            e.error = serverError
          } else {
            e.error = Err.DATA_ERRORS[resp.code] ?? ''
          }
        })
      } else {
        const serverError = Err.SERVER_ERRORS[resp.code]
        if (serverError) {
          errorMsg = serverError
        } else {
          errorMsg = Err.DATA_ERRORS[resp.code] ?? Err.ERR_0001
        }
      }
    }
  }
  if (errorMsg) {
    throw new MarketError(errorMsg)
  }
  return orderErrors
}

type ParamsOfPrepareMarketRunParams = {
  data: { input: string } | undefined
  targetOp: number
  targetError: string
} & (
  | {
      withOptions: true
      market: X2Y2R1
      handleWarning: ((error: any) => any) | undefined
    }
  | {
      withOptions: false
      market?: never
      handleWarning?: never
    }
)
const prepareMarketRunParams = async ({
  data,
  withOptions,
  market,
  targetOp,
  targetError,
  handleWarning,
}: ParamsOfPrepareMarketRunParams) => {
  const runInput: RunInput | undefined = data
    ? decodeRunInput(data.input)
    : undefined
  if (!runInput || !runInput.orders.length || !runInput.details.length) {
    throw new MarketError(targetError)
  }
  let value: BigNumber = AMOUNT_0
  runInput.details.forEach((detail) => {
    const order = runInput.orders[(detail.orderIdx as BigNumber).toNumber()]
    const orderItem = order?.items[(detail.itemIdx as BigNumber).toNumber()]
    if (detail.op !== targetOp || !orderItem) {
      throw new MarketError(targetError)
    }
    if (
      isNative(order.currency) &&
      [OP_BID, OP_COMPLETE_SELL_OFFER].includes(targetOp)
    ) {
      value = value.add(detail.price)
    }
  })
  if (
    runInput.shared.amountToEth &&
    (runInput.shared.amountToEth as BigNumber).gt(AMOUNT_0)
  ) {
    const amount = runInput.shared.amountToEth as BigNumber
    value = value.gt(amount) ? value.sub(amount) : AMOUNT_0
  } else if (
    runInput.shared.amountToWeth &&
    (runInput.shared.amountToWeth as BigNumber).gt(AMOUNT_0)
  ) {
    const amount = runInput.shared.amountToWeth as BigNumber
    value = value.add(amount)
  }
  fixSignature(runInput)
  runInput.orders.forEach((order) => fixSignature(order))
  const options: PayableOverrides = {}
  if (withOptions) {
    options.value = value
    try {
      const gasLimit = await market.estimateGas.run(runInput, options)
      // options.gasLimit = runInput.shared.canFail
      //   ? gasLimit.mul(6).div(5)
      //   : gasLimit.mul(6).div(5)
      options.gasLimit = gasLimit
    } catch (e) {
      if (handleWarning) {
        handleWarning(e)
      }
    }
  }
  return { runInput, runOptions: options }
}
const run = async (
  market: X2Y2R1,
  data: { input: string } | undefined,
  targetOp: number,
  targetError: string,
  handleWarning: ((error: any) => any) | undefined,
): Promise<ContractTransaction> => {
  const { runInput, runOptions } = await prepareMarketRunParams({
    data,
    targetOp,
    targetError,
    withOptions: true,
    handleWarning,
    market,
  })
  return market.run(runInput, runOptions)
}

export const sell = async (
  sellOffer: SellOfferParam,
): Promise<OrderError[]> => {
  await checkListingNfts(sellOffer.user, sellOffer.items, [
    Err.ERR_0101,
    Err.ERR_0102,
  ])
  const { web3Provider, token } = sellOffer.user
  const signer = web3Provider.getSigner()
  const seller = await signer.getAddress()
  const salt = randomSalt()
  const delegateType = is1155Order(sellOffer.items)
    ? DELEGATION_TYPE_ERC1155
    : DELEGATION_TYPE_ERC721
  const order: Order = {
    salt,
    user: seller,
    network: sellOffer.networkId,
    intent: INTENT_SELL,
    delegateType,
    deadline: fixDeadline(sellOffer.deadline),
    currency: sellOffer.currency,
    dataMask: '0x',
    items: sellOffer.items.map((item) => ({
      price: item.price,
      data: encodeItemData(item.tokens),
    })),
    r: '',
    s: '',
    v: 0,
    signVersion: 1,
  }
  await signOrderData(web3Provider, order)

  // post encode order(INTENT_SELL)... to server
  const marketResp = await api.newOrder(
    {
      order: encodeOrder(order),
      isBundle: sellOffer.isBundle,
      bundleName: sellOffer.bundleName,
      bundleDesc: sellOffer.bundleDesc,
      orderIds: [],
      royalties: sellOffer.items.map((item) => item.royalty),
      changePrice: false,
      isCollection: false,
      isPrivate: sellOffer.isPrivate,
      taker: sellOffer.taker ? sellOffer.taker : null,
    },
    token,
  )()
  return handleOrderResp(marketResp, Err.ERR_0002)
}

export const changePrice = async (
  cpData: ChangePriceParam,
): Promise<OrderError[]> => {
  const { web3Provider, token } = cpData.user
  const signer = web3Provider.getSigner()
  const seller = await signer.getAddress()
  const salt = randomSalt()
  const delegateType = is1155Order(cpData.items)
    ? DELEGATION_TYPE_ERC1155
    : DELEGATION_TYPE_ERC721
  const order: Order = {
    salt,
    user: seller,
    network: cpData.networkId,
    intent: INTENT_SELL,
    delegateType,
    deadline: fixDeadline(cpData.deadline),
    currency: cpData.currency,
    dataMask: '0x',
    items: cpData.items.map((item) => ({
      price: item.price,
      data: encodeItemData(item.tokens),
    })),
    r: '',
    s: '',
    v: 0,
    signVersion: 1,
  }
  await signOrderData(web3Provider, order)

  // post encode order... to server
  const marketResp = await api.newOrder(
    {
      order: encodeOrder(order),
      isBundle: cpData.isBundle,
      bundleName: '',
      bundleDesc: '',
      orderIds: cpData.items.map((item) => item.orderId),
      royalties: [],
      changePrice: true,
      isCollection: false,
      isPrivate: cpData.isPrivate,
      taker: cpData.taker ? cpData.taker : null,
    },
    token,
  )()
  return handleOrderResp(marketResp, Err.ERR_0002)
}

type BuyNowOptions =
  | {
      mode: 'buy'
      loanOffer?: never
    }
  | {
      mode: 'borrowToBuy'
      loanOffer: OfferItem
    }
export const buyNow = async (
  bnData: BuyNowParam,
  { mode, loanOffer }: BuyNowOptions = { mode: 'buy' },
): Promise<ContractTransaction> => {
  const { web3Provider, token } = bnData.user
  const items = bnData.items.map((item) => ({
    token: item.contract,
    tokenId: item.tokenId,
  }))
  await checkNestingNfts(web3Provider, items, Err.ERR_0095)
  const marketContract = MARKET_CONTRACT_MAPS[bnData.networkId]
  const weth = WETH_CONTRACT_MAPS[bnData.networkId]
  if (!marketContract || marketContract === constants.AddressZero) {
    throw new MarketError(Err.ERR_0000)
  }
  const tokenMap: Record<string, BigNumber> = {}
  let ethAmount: BigNumber = AMOUNT_0
  bnData.items.forEach((item) => {
    if (item.price.gt(AMOUNT_0)) {
      if (item.currency !== constants.AddressZero) {
        tokenMap[item.currency.toLowerCase()] = (
          tokenMap[item.currency.toLowerCase()] ?? AMOUNT_0
        ).add(item.price)
      } else {
        ethAmount = ethAmount.add(item.price)
      }
    }
  })
  const wethAmount = tokenMap[weth.toLowerCase()] ?? AMOUNT_0
  let amountToEth = AMOUNT_0
  let amountToWeth = AMOUNT_0
  if (bnData.amountToEth.gt(AMOUNT_0) || bnData.amountToWeth.gt(AMOUNT_0)) {
    amountToEth = ethAmount.gt(bnData.amountToEth)
      ? ethAmount.sub(bnData.amountToEth)
      : AMOUNT_0
    amountToWeth = wethAmount.gt(bnData.amountToWeth)
      ? wethAmount.sub(bnData.amountToWeth)
      : AMOUNT_0
  }
  if (amountToWeth.gt(AMOUNT_0)) {
    // amountToWeth
    Object.keys(tokenMap).forEach((currency: string) => {
      if (currency.toLowerCase() === weth.toLowerCase()) {
        const value = tokenMap[currency.toLowerCase()]
        tokenMap[currency.toLowerCase()] = value.gt(amountToWeth)
          ? value.sub(amountToWeth)
          : AMOUNT_0
      }
    })
  } else if (amountToEth.gt(AMOUNT_0)) {
    // amountToEth
    tokenMap[weth.toLowerCase()] = (
      tokenMap[weth.toLowerCase()] ?? AMOUNT_0
    ).add(amountToEth)
  }
  if (mode === 'buy' && Object.keys(tokenMap)) {
    const checkResult = await Promise.all(
      Object.keys(tokenMap).map((currency: string) =>
        checkBalance(
          bnData.networkId,
          currency,
          tokenMap[currency],
          web3Provider.getSigner(),
          '',
          false,
        ),
      ),
    )
    checkResult.forEach((r) => {
      if (r === CHECK_RESULT_APPROVE) {
        throw new MarketError(Err.ERR_0010)
      } else if (r === CHECK_RESULT_BALANCE) {
        throw new MarketError(Err.ERR_0011)
      }
    })
  }
  const user = web3Provider.getSigner()
  const loanExchange = LOAN_X2Y2_EXCHANGE_CONTRACT_MAP[bnData.networkId]
  const caller = mode === 'borrowToBuy' ? loanExchange : await user.getAddress()
  // complete sell offer
  const op = OP_COMPLETE_SELL_OFFER

  // post orderId & caller to server, get orderdetail(OP_COMPLETE_SELL_OFFER)
  const orderDetailResp = await api.orderDetail(
    {
      caller,
      op,
      amountToEth: amountToEth.toString(),
      amountToWeth: amountToWeth.toString(),
      items: bnData.items.map((item) => ({
        orderId: item.orderId,
        currency: item.currency,
        price: item.price.toString(),
        royalty: item.royalty,
        payback: item.payback,
      })),
    },
    token,
  )()
  handleOrderResp(orderDetailResp, Err.ERR_0005)
  const data = E.isRight(orderDetailResp)
    ? orderDetailResp.right.data
    : undefined

  if (mode === 'borrowToBuy') {
    const inputArr = data as unknown as {
      input: string
    }[]
    if (!inputArr || !inputArr.length) {
      throw new MarketError(Err.ERR_0005)
    } else {
      const { runInput } = await prepareMarketRunParams({
        data: inputArr[0],
        targetOp: op,
        targetError: Err.ERR_0005,
        withOptions: false,
      })
      return downPay(
        bnData.networkId,
        bnData.user,
        loanOffer,
        {
          offerId: loanOffer.offerId,
          tokenId: loanOffer.nft?.tokenId ?? bnData.items[0].tokenId,
        },
        runInput,
      )
    }
  } else {
    const market = X2Y2R1__factory.connect(marketContract, user)
    return run(market, data, op, Err.ERR_0005, bnData.handleWarning)
  }
}

export const buyOffer = async (
  boData: BuyOfferParam,
): Promise<OrderError[]> => {
  const { web3Provider, token } = boData.user
  if (boData.currency !== constants.AddressZero) {
    const amount = boData.items.reduce((r, c, _i) => {
      r = r.add(c.price)
      return r
    }, AMOUNT_0)
    if (amount.gt(AMOUNT_0)) {
      const checkResult = await checkBalance(
        boData.networkId,
        boData.currency,
        amount,
        web3Provider.getSigner(),
        '',
        false,
      )
      if (checkResult === CHECK_RESULT_APPROVE) {
        throw new MarketError(Err.ERR_0010)
      } else if (checkResult === CHECK_RESULT_BALANCE) {
        throw new MarketError(Err.ERR_0011)
      }
    }
  }
  const user = await web3Provider.getSigner().getAddress()
  const salt = randomSalt()
  const delegateType = is1155Order(boData.items)
    ? DELEGATION_TYPE_ERC1155
    : DELEGATION_TYPE_ERC721
  const dataMask = [
    {
      token: ethers.constants.AddressZero,
      tokenId: '0x' + '1'.repeat(64),
      amount: 0,
      kind: delegateType === DELEGATION_TYPE_ERC1155 ? TOKEN_1155 : TOKEN_721,
    },
  ]
  const order: Order = {
    salt,
    user,
    network: boData.networkId,
    intent: INTENT_BUY,
    delegateType,
    deadline: fixDeadline(boData.deadline),
    currency: boData.currency,
    dataMask: boData.isCollection ? encodeItemData(dataMask) : '0x',
    items: boData.items.map((item) => ({
      price: item.price,
      data: encodeItemData(item.tokens),
    })),
    r: '',
    s: '',
    v: 0,
    signVersion: 1,
  }
  await signOrderData(web3Provider, order)

  // post encode order(INTENT_BUY)... to server
  const marketResp = await api.newOrder(
    {
      order: encodeOrder(order),
      isBundle: boData.isBundle,
      bundleName: '',
      bundleDesc: '',
      orderIds: boData.isBundle ? [boData.orderId] : [],
      royalties: [],
      changePrice: false,
      isCollection: boData.isCollection,
      isPrivate: false,
      taker: null,
    },
    token,
  )()
  return handleOrderResp(marketResp, Err.ERR_0003)
}

export const acceptBuy = async (
  abData: AcceptBuyParam,
): Promise<ContractTransaction> => {
  const { web3Provider, token } = abData.user
  await checkNestingNfts(
    web3Provider,
    [{ token: abData.contract, tokenId: abData.tokenId }],
    Err.ERR_0096,
  )
  const marketContract = MARKET_CONTRACT_MAPS[abData.networkId]
  if (!marketContract || marketContract === constants.AddressZero) {
    throw new MarketError(Err.ERR_0000)
  }
  if (
    abData.currency !== '' &&
    abData.currency !== constants.AddressZero &&
    abData.maker
  ) {
    let checkResult: number = CHECK_RESULT_OK
    try {
      const amount = ethers.utils.parseEther(abData.price)
      if (amount.gt(AMOUNT_0)) {
        checkResult = await checkBalance(
          abData.networkId,
          abData.currency,
          amount,
          web3Provider.getSigner(),
          abData.maker,
          false,
        )
      }
    } catch (ignored) {}
    if (checkResult === CHECK_RESULT_APPROVE) {
      throw new MarketError(Err.ERR_0012)
    } else if (checkResult === CHECK_RESULT_BALANCE) {
      throw new MarketError(Err.ERR_0013)
    }
  }
  const user = web3Provider.getSigner()
  const market = X2Y2R1__factory.connect(marketContract, user)
  const caller = await user.getAddress()
  const op = OP_COMPLETE_BUY_OFFER

  // post orderId & caller to server, get orderDetail(OP_ACCEPT_BUY)
  const orderDetailResp = await api.orderDetail(
    {
      caller,
      op,
      amountToEth: '0',
      amountToWeth: '0',
      items: [
        {
          orderId: abData.orderId,
          royalty: abData.royalty,
          contract: abData.contract,
          tokenId: abData.tokenId,
        },
      ],
    },
    token,
  )()
  handleOrderResp(orderDetailResp, Err.ERR_0006)
  const data = E.isRight(orderDetailResp)
    ? orderDetailResp.right.data
    : undefined

  // call market.run()
  return run(market, data, op, Err.ERR_0006, abData.handleWarning)
}

export const newAuction = async (auction: AuctionParam) => {
  await checkListingNfts(auction.user, auction.items, [
    Err.ERR_0103,
    Err.ERR_0104,
  ])
  const { web3Provider, token } = auction.user
  const signer = web3Provider.getSigner()
  const seller = await signer.getAddress()
  const salt = randomSalt()
  const delegateType = is1155Order(auction.items)
    ? DELEGATION_TYPE_ERC1155
    : DELEGATION_TYPE_ERC721
  const order: Order = {
    salt,
    user: seller,
    network: auction.networkId,
    intent: INTENT_AUCTION,
    delegateType,
    deadline: fixDeadline(auction.deadline),
    currency: auction.currency,
    dataMask: '0x',
    items: auction.items.map((item) => ({
      price: item.price,
      data: encodeItemData(item.tokens),
    })),
    r: '',
    s: '',
    v: 0,
    signVersion: 1,
  }
  await signOrderData(web3Provider, order)

  // post encode order(INTENT_AUCTION)... to server
  const marketResp = await api.newOrder(
    {
      order: encodeOrder(order),
      isBundle: auction.isBundle,
      bundleName: auction.bundleName,
      bundleDesc: auction.bundleDesc,
      orderIds: [],
      royalties: auction.items.map((item) => item.royalty),
      changePrice: false,
      isCollection: false,
      isPrivate: false,
      taker: null,
    },
    token,
  )()
  handleOrderResp(marketResp, Err.ERR_0004)
}

export const bid = async (bidData: BidParam): Promise<ContractTransaction> => {
  const marketContract = MARKET_CONTRACT_MAPS[bidData.networkId]
  if (!marketContract || marketContract === constants.AddressZero) {
    throw new MarketError(Err.ERR_0000)
  }
  const { web3Provider, token } = bidData.user
  const signer = web3Provider.getSigner()
  const market = X2Y2R1__factory.connect(marketContract, signer)
  const caller = await signer.getAddress()
  const op = OP_BID

  // post orderId & caller to server, get orderDetail(OP_BID)
  const orderDetailResp = await api.orderDetail(
    {
      caller,
      op,
      amountToEth: '0',
      amountToWeth: '0',
      items: [{ orderId: bidData.orderId, price: bidData.price.toString() }],
    },
    token,
  )()
  handleOrderResp(orderDetailResp, Err.ERR_0007)
  const data = E.isRight(orderDetailResp)
    ? orderDetailResp.right.data
    : undefined

  // call market.run()
  return run(market, data, op, Err.ERR_0007, bidData.handleWarning)
}

export const completeAuction = async (
  caData: CompleteAuctionParam,
): Promise<ContractTransaction> => {
  const marketContract = MARKET_CONTRACT_MAPS[caData.networkId]
  if (!marketContract || marketContract === constants.AddressZero) {
    throw new MarketError(Err.ERR_0000)
  }
  const { web3Provider, token } = caData.user
  const signer = web3Provider.getSigner()
  const market = X2Y2R1__factory.connect(marketContract, signer)
  const caller = await signer.getAddress()
  const op = OP_COMPLETE_AUCTION

  // post orderId, caller to server, get orderDetail(OP_COMPLETE_AUCTION)
  const orderDetailResp = await api.orderDetail(
    {
      caller,
      op,
      amountToEth: '0',
      amountToWeth: '0',
      items: [{ orderId: caData.orderId }],
    },
    token,
  )()
  handleOrderResp(orderDetailResp, Err.ERR_0007)
  const data = E.isRight(orderDetailResp)
    ? orderDetailResp.right.data
    : undefined

  // call market.run()
  return run(market, data, op, Err.ERR_0007, caData.handleWarning)
}

export const cancelOrder = async (
  coParam: CancelOrderParam,
): Promise<ContractTransaction> => {
  const marketContract = MARKET_CONTRACT_MAPS[coParam.networkId]
  if (!marketContract || marketContract === constants.AddressZero) {
    throw new MarketError(Err.ERR_0000)
  }
  const { web3Provider, token } = coParam.user
  const signer = web3Provider.getSigner()
  const caller = await signer.getAddress()
  const op = OP_CANCEL_OFFER

  // post orderId & caller to server, get orderDetail(OP_CANCEL_BUY)
  const cancelOrderResp = await api.cancelOrder(
    { caller, op, items: coParam.items },
    token,
  )()

  const orderErorrs = handleOrderResp(cancelOrderResp, Err.ERR_0008)
  const data = E.isRight(cancelOrderResp)
    ? cancelOrderResp.right.data
    : undefined

  // call market.cancel()
  const market = X2Y2R1__factory.connect(marketContract, signer)
  const ci: CancelInput | undefined = data
    ? decodeCancelInput(data.input)
    : undefined
  if (!ci) {
    throw new MarketError(Err.ERR_0008)
  }
  fixSignature(ci)
  const cancelInput = [ci.itemHashes, ci.deadline, ci.v, ci.r, ci.s] as const
  const options: PayableOverrides = { value: 0 }
  try {
    const gasLimit = await market.estimateGas.cancel(...cancelInput, options)
    // options.gasLimit = gasLimit.mul(6).div(5)
    options.gasLimit = gasLimit
  } catch (e) {
    // TODO: show a warning toast
    console.error(e)
  }
  if (coParam.handleErrors) {
    coParam.handleErrors(orderErorrs)
  }
  return market.cancel(...cancelInput, options)
}

export const refundAuction = async (
  raData: RefundAuctionParam,
): Promise<ContractTransaction> => {
  const marketContract = MARKET_CONTRACT_MAPS[raData.networkId]
  if (!marketContract || marketContract === constants.AddressZero) {
    throw new MarketError(Err.ERR_0000)
  }
  const { web3Provider, token } = raData.user
  const signer = web3Provider.getSigner()
  const market = X2Y2R1__factory.connect(marketContract, signer)
  const caller = await signer.getAddress()
  const op = raData.stuckItem ? OP_REFUND_AUCTION_STUCK_ITEM : OP_REFUND_AUCTION

  // post orderId, caller to server, get orderDetail(OP_REFUND_AUCTION_STUCK_ITEM / OP_REFUND_AUCTION)
  const orderDetailResp = await api.orderDetail(
    {
      caller,
      op,
      amountToEth: '0',
      amountToWeth: '0',
      items: [{ orderId: raData.orderId }],
    },
    token,
  )()
  handleOrderResp(orderDetailResp, Err.ERR_0007)
  const data = E.isRight(orderDetailResp)
    ? orderDetailResp.right.data
    : undefined

  // call market.run()
  return run(market, data, op, Err.ERR_0007, raData.handleWarning)
}

export const getMinBidPrice = async (
  _orderId: number,
  price: BigNumber,
  topBid: BigNumber | null,
): Promise<BigNumber> => {
  if (!topBid) {
    return price
  }
  const minInc = 5e4
  const rateBase = 1e6
  // price * (1 + market.minAuctionIncrement / market.RATE_BASE)
  // NOTE: Use alternative approach since no decimal support in BigNumber
  const incPrice = price.mul(minInc).div(rateBase)
  const ceilPrice = price.mul(minInc).mod(rateBase).gt(0) ? 1 : 0
  return price.add(incPrice).add(ceilPrice)
}

const sendErc721 = async (
  networkId: number,
  user: providers.JsonRpcSigner,
  tokens: NFTToken[],
  to: string,
): Promise<ContractTransaction> => {
  if (tokens.length === 1) {
    const address = await user.getAddress()
    const contract = tokens[0].token
    const tokenId = tokens[0].tokenId
    const erc721Contract = ERC721__factory.connect(contract, user)
    return erc721Contract['safeTransferFrom(address,address,uint256)'](
      address,
      to,
      tokenId,
    )
  } else {
    const erc721Delegate = ERC721_DELEGATE_CONTRACT_MAPS[networkId]
    if (!erc721Delegate || erc721Delegate === constants.AddressZero) {
      throw new MarketError(Err.ERR_0000)
    }
    const delegateContract = ERC721Delegate__factory.connect(
      erc721Delegate,
      user,
    )
    return delegateContract.transferBatch(tokens, to)
  }
}

const sendErc1155 = async (
  networkId: number,
  user: providers.JsonRpcSigner,
  tokens: NFTToken[],
  to: string,
): Promise<ContractTransaction> => {
  if (tokens.length === 1) {
    const address = await user.getAddress()
    const erc1155Contract = ERC1155__factory.connect(tokens[0].token, user)
    return erc1155Contract.safeTransferFrom(
      address,
      to,
      tokens[0].tokenId,
      tokens[0].amount,
      '0x',
    )
  } else {
    const erc1155Delegate = ERC1155_DELEGATE_CONTRACT_MAPS[networkId]
    if (!erc1155Delegate || erc1155Delegate === constants.AddressZero) {
      throw new MarketError(Err.ERR_0000)
    }
    const delegateContract = ERC1155Delegate__factory.connect(
      erc1155Delegate,
      user,
    )
    return delegateContract.transferBatch(tokens, to)
  }
}

export const send = async (
  networkId: number,
  user: providers.JsonRpcSigner,
  tokens: NFTToken[],
  to: string,
): Promise<ContractTransaction> => {
  if (is1155ItemData(tokens)) {
    return sendErc1155(networkId, user, tokens, to)
  } else {
    return sendErc721(networkId, user, tokens, to)
  }
}

export const reportError = async ({
  user,
  networkId,
  contract,
  tokenId,
  desc,
}: {
  user: providers.JsonRpcSigner
  networkId: number
  contract: string
  tokenId?: string
  desc: string
}): Promise<string> => {
  try {
    const address = await user.getAddress()
    const error = {
      address,
      networkId,
      contract,
      tokenId,
      desc: stripStr(desc, 512),
    }
    await api.marketReportError(error)()
  } catch (e) {
    return 'err'
  }
  return 'ok'
}
