import { useEffect, useState } from 'react'

import { getFloorPrice } from '@/lib/opensea'

export const useOpenSeaFloorPrice = (collection: string | null) => {
  const [osFloorPrice, setOsFloorPrice] = useState(0)
  useEffect(() => {
    if (!collection) return
    const action = async () => {
      try {
        const r = await getFloorPrice(collection)
        setOsFloorPrice(r)
      } catch (e) {
        console.warn(e)
      }
    }
    action()
  }, [collection])
  return osFloorPrice
}
