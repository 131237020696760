import { DetailedHTMLProps, forwardRef, ImgHTMLAttributes } from 'react'

export type ImgProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

/**
 * Fallback component for most SSR users who want to use the native `img` with
 * support for chakra props
 */
export const Img = forwardRef<HTMLImageElement, ImgProps>(
  // eslint-disable-next-line @next/next/no-img-element
  ({ alt, ...props }, ref) => <img ref={ref} alt={alt} {...props} />,
)

Img.displayName = 'Img'
