import clsx from 'clsx'
import dynamic from 'next/dynamic'
import {
  CSSProperties,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Skeleton } from '@/components/feedback'
import { AspectRatio } from '@/components/layout'
import { useUFO } from '@/images/UFOImage'
import { TE } from '@/lib/fp'
import { NftMetadataAssets } from '@/lib/nft/metadata'
import { UnimetaConfig } from '@/lib/unimeta'

import { CdnImage } from './CdnImage'
import { ImgProps } from './Img'
import { NftSprite } from './NftSprite'
import ProxiedImage, { buildUrl as buildProxiedImageUrl } from './ProxiedImage'

export { buildProxiedImageUrl }

export const metadataAssetsAsNftImageProps = (
  assets: NftMetadataAssets,
  displayMode: 'simple' | 'detailed' = 'simple',
): Pick<
  NftImageProps,
  'src' | 'srcType' | 'srcConfig' | 'fallbackImage' | 'displayMode'
> => {
  const data = ((): Pick<NftImageProps, 'src' | 'srcType'> => {
    if (displayMode === 'simple') {
      if (assets.collectionConfig?.use_gif && assets.gifImage) {
        return {
          src: assets.gifImage,
          srcType: 'gifImage',
        }
      }
      if (assets.image) {
        return {
          src: assets.image,
          srcType: 'image',
        }
      }
      if (assets.video) {
        return {
          src: assets.video,
          srcType: 'image',
        }
      }
    }
    if (displayMode === 'detailed') {
      if (assets.iframe) {
        return {
          src: assets.iframe,
          srcType: 'iframe',
        }
      }
      if (assets.spriteImage) {
        return {
          src: assets.spriteImage,
          srcType: 'spriteImage',
        }
      }
      if (assets.gltf) {
        return {
          src: assets.gltf,
          srcType: 'gltf',
        }
      }
      if (assets.video) {
        return {
          src: assets.video,
          srcType: 'video',
        }
      }
      if (assets.gifImage) {
        return {
          src: assets.gifImage,
          srcType: 'gifImage',
        }
      }
      if (assets.image) {
        return {
          src: assets.image,
          srcType: 'image',
        }
      }
    }
    return {}
  })()
  return {
    ...data,
    srcConfig: assets.config,
    displayMode,
    fallbackImage: assets.image ?? assets.video,
  }
}

const NftGLTF = dynamic(() => import('./NftGLTF'), { ssr: false })

export type NftImageProps = {
  className?: string

  src?: string
  srcType?: 'image' | 'gifImage' | 'spriteImage' | 'video' | 'gltf' | 'iframe'
  srcConfig?: UnimetaConfig
  fallbackImage?: string
  displayMode?: 'simple' | 'detailed'

  videoAutoPlay?: boolean
  autoAspectRatio?: boolean
  objectFit?: CSSProperties['objectFit']
  imageSizes?: string[]
  imageAlt?: string
  muted?: boolean
  skipVideoObjectFit?: boolean
}

export const NftImage = (props: NftImageProps) => {
  return <Inner key={props.src} {...props} />
}

const Inner = ({
  className,

  src,
  srcType = 'image',
  srcConfig,
  fallbackImage,
  // displayMode = 'simple',

  videoAutoPlay = false,
  autoAspectRatio = false,
  objectFit = 'contain',
  imageSizes = ['250px'],
  imageAlt,
  muted = true,
  skipVideoObjectFit = false,
}: NftImageProps) => {
  // const noGif = (() => {
  //   switch (displayMode) {
  //     case 'simple':
  //       return srcType !== 'gifImage'
  //     case 'detailed':
  //       return false
  //   }
  // })()
  const videoRef = useRef<HTMLVideoElement>(null)

  const [ratio, setRatio] = useState(srcConfig?.ratio ?? 1)
  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(
    src &&
      (srcType === 'image' || srcType === 'gifImage' || srcType === 'iframe'),
  )
  // Work around of this issue: https://github.com/chakra-ui/chakra-ui/issues/2563
  // TODO: Encapsulation
  const imageRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    if (isLoading && imageRef.current?.complete) {
      setLoading(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (videoAutoPlay) {
      TE.tryCatch(
        async () => {
          if (videoRef.current) await videoRef.current.play()
        },
        () => 'autoplay failed',
      )()
    }
  }, [videoAutoPlay])
  useEffect(() => {
    if (
      src &&
      (srcType === 'image' || srcType === 'gifImage' || srcType === 'video') &&
      autoAspectRatio &&
      !srcConfig?.ratio
    ) {
      const img = new Image()
      // img.src = src.startsWith('data') ? src : buildProxiedImageUrl(src, 720)
      img.src = buildProxiedImageUrl(src, 720)
      img.onload = (ev: Event) => {
        const t = ev.target as HTMLImageElement
        if (t.naturalHeight && t.naturalWidth) {
          const r = t.naturalWidth / t.naturalHeight
          setRatio(r)
        }
      }
    }
  }, [src, srcType, autoAspectRatio, srcConfig])

  const ufoImage = useUFO()
  const placeholderImage = useMemo(
    () => (
      <CdnImage
        sizes={['300px']}
        objectFit={objectFit}
        alt={'Default placeholder image'}
        cdnSrc={ufoImage}
      />
    ),
    [objectFit, ufoImage],
  )

  const body = (() => {
    if (!src) return placeholderImage
    switch (srcType) {
      case 'iframe':
        return (
          <iframe
            src={src}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            sandbox="allow-scripts"
            onLoad={() => setLoading(false)}
          />
        )
      // Example: https://tofunft.com/nft/bsc/0xC509dEe1E5B4DbDbC08aCe7985FFd831538132B6/617
      case 'spriteImage':
        if (!srcConfig?.sprite_animation) return placeholderImage
        return (
          <div className="z-[1] h-full w-full">
            <NftSprite src={src} config={srcConfig?.sprite_animation} />
          </div>
        )
      // Example: https://tofunft.com/nft/bsc/0x372360677eC2B72cfc5aD89fc3C14b43E2e1A2F5/6416
      case 'gltf': {
        return (
          <div className="z-[1] h-full w-full">
            <Suspense fallback={null}>
              <NftGLTF src={src} defaultImage={fallbackImage} />
            </Suspense>
          </div>
        )
      }
      case 'video':
        return (
          <div className="z-[1] h-full w-full">
            <video
              className="h-full w-full"
              style={{
                objectFit: skipVideoObjectFit ? undefined : 'cover',
                transform: srcConfig?.transform,
              }}
              ref={videoRef}
              src={src}
              loop
              muted={muted}
              playsInline
            />
          </div>
        )
      case 'image':
      case 'gifImage': {
        if (error) return placeholderImage
        const imgProps: Omit<ImgProps, 'ref'> = {
          onLoad: () => setLoading(false),
          onError: () => {
            setLoading(false)
            setError(true)
          },
          alt: imageAlt,
          style: {
            objectFit,
            imageRendering: srcConfig?.pixelated ? 'pixelated' : undefined,
            transform: srcConfig?.transform ?? undefined,
          },
        }
        // if (src.startsWith('data')) {
        //   return (
        //     <div>
        //       <Img
        //         ref={imageRef}
        //         className="h-full w-full"
        //         src={src}
        //         {...imgProps}
        //       />
        //     </div>
        //   )
        // } else {
        return (
          <ProxiedImage
            className="h-full w-full"
            src={src}
            // noGif={noGif}
            // useOriginal={srcConfig?.pixelated ?? false}
            sizes={imageSizes}
            imgRef={imageRef}
            imgProps={imgProps}
          />
        )
        // }
      }
    }
  })()

  return (
    <div
      className={clsx(className, 'relative z-[1] bg-gray-200')}
      style={{ backgroundColor: srcConfig?.background_color }}
    >
      {isLoading && (
        <Skeleton className="absolute inset-0 z-10 !rounded-none" />
      )}
      {srcConfig?.foreground_image && (
        <ProxiedImage
          className="absolute inset-0 z-[2]"
          src={srcConfig.foreground_image}
          sizes={imageSizes}
        />
      )}
      <AspectRatio
        className={clsx(
          'overflow-hidden',
          // max height = 100vh - space - navbar
          autoAspectRatio && 'sm:max-h-[calc(100vh-64px*2-80px)]',
        )}
        style={{ background: srcConfig?.background_image }}
        ratio={ratio}
      >
        {body}
      </AspectRatio>
    </div>
  )
}
