import { D } from './fp'

export const featurePromptOptionsDecoder = D.partial({
  nftLoan: D.boolean,
  cartModeSwitch: D.boolean,
})
export type FeaturePromptOptions = D.TypeOf<typeof featurePromptOptionsDecoder>

export const featurePromptDefaultOptions: Required<FeaturePromptOptions> = {
  nftLoan: true,
  cartModeSwitch: true,
}
