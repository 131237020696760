import * as blockies from 'blockies-ts'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { Img } from './Img'

type Props = {
  className?: string
  seed: string
  alt?: string
}
const Blockie = ({ className, seed, alt }: Props) => {
  const [src, setSrc] = useState('')
  useEffect(
    () => setSrc(blockies.create({ seed, scale: 16 }).toDataURL()),
    [seed],
  )
  return src ? (
    <Img
      className={clsx(className, 'rounded-full')}
      src={src}
      alt={`${alt}'s user avatar`}
    />
  ) : (
    <div className={clsx(className, 'rounded-full')} />
  )
}

export default Blockie
