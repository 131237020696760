import { keyframes } from 'goober'
import { Toaster as HotToaster, ToastBar } from 'react-hot-toast'

import { WarningLine } from '@/icons'

// TODO: Use tailwind if possible
const enterAnimation = keyframes`
0% {transform: translate3d(200%,0,0) scale(.6); opacity:.5;}
100% {transform: translate3d(0,0,0) scale(1); opacity:1;}
`
const exitAnimation = keyframes`
0% {transform: translate3d(0,0,-1px) scale(1); opacity:1;}
100% {transform: translate3d(150%,0,-1px) scale(.6); opacity:0;}
`

export const Toaster = () => (
  <HotToaster
    toastOptions={{ className: 'hot-toast' }}
    position="top-right"
    containerStyle={{ inset: '130px 40px 40px' }}
  >
    {(current) => (
      <ToastBar
        toast={current}
        style={{
          animation: current.visible
            ? `${enterAnimation} 0.35s cubic-bezier(.21,1.02,.73,1) forwards`
            : `${exitAnimation} 0.4s forwards cubic-bezier(.06,.71,.55,1)`,
        }}
      />
    )}
  </HotToaster>
)

export const warningIcon = (
  <WarningLine className="h-5 w-5 animate-circle opacity-0" />
)
