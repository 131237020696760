import {
  BigNumber,
  BigNumberish,
  CallOverrides,
  providers,
  utils,
} from 'ethers'

import * as api from '@/lib/api'
import { RegisteredUser } from '@/lib/auth/types/user'
import {
  ERC721,
  ERC721__factory,
  ERC1155,
  ERC1155__factory,
  Moonbirds__factory,
} from '@/lib/contract'
import { E } from '@/lib/fp'
import { http } from '@/lib/http'
import { multicall } from '@/lib/multicall'
import { MOONBIRDS_CONTRACT } from '@/lib/opensea'
import {
  ERC721_DELEGATE_CONTRACT_MAPS,
  ERC1155_DELEGATE_CONTRACT_MAPS,
  TokenStandard,
} from '@/lib/x2y2'
import { parseKindToTokenStandard } from '@/lib/x2y2/utils'
import {
  approveNft721 as approve721,
  approveNft1155 as approve1155,
  isApprovedNft721 as isApproved721,
  isApprovedNft1155 as isApproved1155,
} from '@/lib/xy3'
import { requestConfig } from '@/utils/http'
import { getProviderByNetworkId } from '@/utils/network'

import { MarketError, TOKEN_721, TOKEN_1155 } from './'
import * as Err from './errors'
import { ApproveTarget, NftResp, NFTToken, OfferItem, TokenKind } from './types'

const handleNftResp = (nftResp: E.Either<string, NftResp>): string => {
  if (E.isLeft(nftResp)) {
    return Err.ERR_0001
  }
  const resp = nftResp.right
  if (!resp.success) {
    const serverError = Err.SERVER_ERRORS[resp.code]
    if (serverError) {
      return serverError
    }
    return Err.DATA_ERRORS[resp.code] ?? Err.ERR_0001
  }
  return ''
}

export const ownerOfNft721 = async (
  provider: providers.JsonRpcProvider,
  tokenContract: string,
  tokenId: string,
) => {
  const nft = ERC721__factory.connect(tokenContract, provider)
  const owner = await nft.ownerOf(tokenId)
  return owner
}

export const balanceOfNft721 = async (
  provider: providers.JsonRpcProvider,
  tokenContract: string,
  account: string,
) => {
  const nft = ERC721__factory.connect(tokenContract, provider)
  const balance = await nft.balanceOf(account)
  return balance
}

export const balanceOfNft1155 = async (
  provider: providers.JsonRpcProvider,
  tokenContract: string,
  tokenId: string,
  account: string,
) => {
  const nft = ERC1155__factory.connect(tokenContract, provider)
  const balance = await nft.balanceOf(account, tokenId)
  return balance
}

export const checkTransfer = async (
  networkId: number,
  tokenContract: string,
  tokenKind: TokenKind,
  tokenIds: string[],
  owner: string,
) => {
  const provider = getProviderByNetworkId(networkId)
  try {
    if (tokenKind === TOKEN_1155) {
      const nft = ERC1155__factory.connect(tokenContract, provider)
      const operator = ERC1155_DELEGATE_CONTRACT_MAPS[networkId]
      await Promise.all(
        tokenIds.map((tokenId) =>
          nft.estimateGas.safeTransferFrom(owner, operator, tokenId, 1, '0x', {
            from: owner,
          }),
        ),
      )
    } else {
      const nft = ERC721__factory.connect(tokenContract, provider)
      const operator = ERC721_DELEGATE_CONTRACT_MAPS[networkId]
      await Promise.all(
        tokenIds.map((tokenId) =>
          nft.estimateGas.transferFrom(owner, operator, tokenId, {
            from: owner,
          }),
        ),
      )
    }
  } catch (e) {
    console.error(e)
    return false
  }
  return true
}

export const isApprovedNft721 = async (
  networkId: number,
  tokenContract: string,
  user: providers.JsonRpcSigner,
  target: ApproveTarget,
) => {
  const nft = ERC721__factory.connect(tokenContract, user)
  const owner = await user.getAddress()
  if (target === 'xy3') {
    return isApproved721(networkId, nft, owner)
  }
  const operator = ERC721_DELEGATE_CONTRACT_MAPS[networkId]
  const isApproved = await nft.isApprovedForAll(owner, operator)
  return isApproved
}

export const approveNft721 = async (
  networkId: number,
  tokenContract: string,
  user: providers.JsonRpcSigner,
  target: ApproveTarget,
) => {
  const nft = ERC721__factory.connect(tokenContract, user)
  if (target === 'xy3') {
    await approve721(networkId, nft)
  } else {
    const operator = ERC721_DELEGATE_CONTRACT_MAPS[networkId]
    const tx = await nft.setApprovalForAll(operator, true)
    await tx.wait()
  }
}

export const isApprovedNft1155 = async (
  networkId: number,
  tokenContract: string,
  user: providers.JsonRpcSigner,
  target: ApproveTarget,
) => {
  const nft = ERC1155__factory.connect(tokenContract, user)
  const owner = await user.getAddress()
  if (target === 'xy3') {
    return isApproved1155(networkId, nft, owner)
  }
  const operator = ERC1155_DELEGATE_CONTRACT_MAPS[networkId]
  const isApproved = await nft.isApprovedForAll(owner, operator)
  return isApproved
}

export const approveNft1155 = async (
  networkId: number,
  tokenContract: string,
  user: providers.JsonRpcSigner,
  target: ApproveTarget,
) => {
  const nft = ERC1155__factory.connect(tokenContract, user)
  if (target === 'xy3') {
    await approve1155(networkId, nft)
  } else {
    const operator = ERC1155_DELEGATE_CONTRACT_MAPS[networkId]
    const tx = await nft.setApprovalForAll(operator, true)
    await tx.wait()
  }
}

export const likeNft = async (
  userToken: string,
  nftId: number,
): Promise<string> => {
  const nftResp = await api.likeNft({ nftId, like: true }, userToken)()
  return handleNftResp(nftResp)
}

export const unlikeNft = async (
  userToken: string,
  nftId: number,
): Promise<string> => {
  const nftResp = await api.likeNft({ nftId, like: false }, userToken)()
  return handleNftResp(nftResp)
}

export const refreshNft = async (
  networkId: number,
  tokenContract: string,
  tokenId: string,
): Promise<string> => {
  const nftResp = await api.refreshNft({
    networkId: networkId,
    contract: tokenContract,
    tokenId,
  })()
  return handleNftResp(nftResp)
}

export const indexNft = async (
  networkId: number,
  tokenContract: string,
  tokenId: string,
) => {
  try {
    const resp = await http.post(
      '/api/nfts/index',
      {
        network_id: networkId,
        contract: tokenContract,
        token_id: tokenId,
      },
      requestConfig(),
    )
    if (resp.status !== 200) {
      console.error('indexNft failed:', resp.statusText)
    }
  } catch (e) {
    console.error('indexNft failed:', e)
  }
}

// Contracts that fail when CheckTransfer specifies gasLimit(500_000)
const CONTRACTS = ['0x43ef91b82c63f86b1900e85941c9a89bcea1ece17']

const transferPromises = (
  networkId: number,
  provider: providers.StaticJsonRpcProvider,
  owner: string,
  items: NFTToken[],
): Promise<BigNumber>[] => {
  const to = utils.hexZeroPad('0xdead', 20)
  const result: Promise<BigNumber>[] = []
  const erc721Delegate = ERC721_DELEGATE_CONTRACT_MAPS[networkId]
  const erc1155Delegate = ERC1155_DELEGATE_CONTRACT_MAPS[networkId]
  const nft721Map: { [key: string]: ERC721 } = {}
  const nft1155Map: { [key: string]: ERC1155 } = {}
  items.forEach((token: NFTToken) => {
    const contract = token.token.toLowerCase()
    const from = token.kind === TOKEN_1155 ? erc1155Delegate : erc721Delegate
    const overrides: CallOverrides = { from }
    if (!CONTRACTS.includes(contract)) overrides.gasLimit = 500_000
    if (token.kind === TOKEN_1155) {
      if (!nft1155Map[contract]) {
        nft1155Map[contract] = ERC1155__factory.connect(contract, provider)
      }
      const params = [owner, to, token.tokenId, token.amount, '0x'] as const
      result.push(
        nft1155Map[contract].estimateGas.safeTransferFrom(...params, overrides),
      )
    } else {
      if (!nft721Map[contract]) {
        nft721Map[contract] = ERC721__factory.connect(contract, provider)
      }
      const params = [owner, to, token.tokenId] as const
      result.push(
        nft721Map[contract].estimateGas.transferFrom(...params, overrides),
      )
    }
  })
  return result
}

export const checkTransferNfts = async (payload: {
  networkId: number
  owner: string
  data: NFTToken[]
}): Promise<string> => {
  const provider = getProviderByNetworkId(payload.networkId)
  try {
    await Promise.all(
      transferPromises(
        payload.networkId,
        provider,
        payload.owner,
        payload.data,
      ),
    )
  } catch (e) {
    const err = Err.calcError(e)
    if (err && err.code === 'UNPREDICTABLE_GAS_LIMIT') {
      let messages
      try {
        messages = Err.matchErrorMessages(err.message)
      } catch (ignored) {}
      if (messages) {
        const revertedMessage = messages.find(
          (msg) => msg && msg.includes('execution reverted'),
        )
        if (revertedMessage) {
          return revertedMessage
        }
      }
    }
  }
  return ''
}

export const checkOperatorFilters = async (
  networkId: number,
  items: { contract: string; tokenStandard: TokenStandard }[],
): Promise<boolean[]> => {
  const erc721Delegate = ERC721_DELEGATE_CONTRACT_MAPS[networkId]
  const erc1155Delegate = ERC1155_DELEGATE_CONTRACT_MAPS[networkId]
  const abi = [
    {
      inputs: [
        { internalType: 'address', name: 'registrant', type: 'address' },
        { internalType: 'address', name: 'operator', type: 'address' },
      ],
      name: 'isOperatorFiltered',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
  ]
  const address = '0x000000000000aaeb6d7670e522a718067333cd4e'
  const multicallResp: boolean[][] = await multicall(
    networkId,
    abi,
    items.map((item: { contract: string; tokenStandard: TokenStandard }) => ({
      address,
      name: 'isOperatorFiltered',
      params: [
        item.contract,
        item.tokenStandard === 'erc1155' ? erc1155Delegate : erc721Delegate,
      ],
    })),
    true,
  )
  return multicallResp.map((r) => r[0])
}

export const checkListingNfts = async (
  user: RegisteredUser,
  items: OfferItem[],
  defaultError: string[],
) => {
  const web3Provider = user.web3Provider
  const networkId = web3Provider.network.chainId
  const owner = await web3Provider.getSigner().getAddress()
  let revertedMessage
  try {
    const data: NFTToken[] = []
    items.forEach((item) => {
      item.tokens.forEach((token) => {
        data.push({
          token: token.token,
          tokenId: token.tokenId,
          amount: token.amount,
          kind: token.kind,
        })
      })
    })
    const result = await api.checkNfts({ networkId, owner, data })()
    revertedMessage = E.isRight(result) ? result.right : result.left
  } catch (ignored) {}
  if (revertedMessage) {
    throw new MarketError(defaultError[1])
  }
  const checkItems: { contract: string; tokenStandard: TokenStandard }[] = []
  items.forEach((item: OfferItem) => {
    item.tokens.forEach((nftToken: NFTToken) => {
      checkItems.push({
        contract: nftToken.token,
        tokenStandard: parseKindToTokenStandard(
          nftToken.kind === 2 ? TOKEN_1155 : TOKEN_721,
        ),
      })
    })
  })
  const res = await checkOperatorFilters(networkId, checkItems)
  res.forEach((r) => {
    if (r) throw new MarketError(defaultError[0])
  })
}

export const checkNestingNfts = async (
  provider: providers.StaticJsonRpcProvider,
  items: { token: string; tokenId: BigNumberish }[],
  defaultError: string,
) => {
  let hasNesting = false
  const contract = Moonbirds__factory.connect(MOONBIRDS_CONTRACT, provider)
  const moonbirds = items.filter(
    (item) => item.token.toLowerCase() === MOONBIRDS_CONTRACT,
  )
  if (moonbirds.length > 0) {
    try {
      const nestings = await Promise.all(
        moonbirds.map((mb) => contract.nestingPeriod(mb.tokenId)),
      )
      nestings.forEach((np) => {
        if (np.nesting) {
          hasNesting = true
        }
      })
    } catch (ignored) {}
  }
  if (hasNesting) {
    throw new MarketError(defaultError)
  }
}

export const isContract = async (networkId: number, address: string) => {
  const provider = getProviderByNetworkId(networkId)
  try {
    const code = await provider.getCode(address)
    return code !== '0x'
  } catch (e) {
    console.error(e)
  }
  return false
}

export const checkIfContractIsEns = (contract: string) =>
  // ens contract
  contract.toLowerCase() === '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85'

export const checkIfContractIsArtBlocks = (contract: string) =>
  // art blocks contract
  contract.toLowerCase() === '0xa7d8d9ef8D8Ce8992Df33D8b8CF4Aebabd5bD270'

export const hasEnsWarning = (contract: string, desc: string): string => {
  if (checkIfContractIsEns(contract)) {
    const warning = desc.match(/(⚠️ ATTENTION: [^\.]*.)/)
    if (warning) {
      return warning[0]
    }
  }
  return ''
}
