import { HTMLMotionProps } from 'framer-motion'

import { TRANSITION_EASINGS, Variants, withDelay } from './utils'

const defaultTransition = {
  exit: {
    duration: 0.15,
    ease: TRANSITION_EASINGS.easeInOut,
  },
  enter: {
    type: 'spring',
    damping: 25,
    stiffness: 180,
  },
}

export const TRANSITION_VARIANTS = {
  slideRight: {
    position: { right: 0, top: 0, bottom: 0, width: '100%' },
    enter: { x: 0, y: 0 },
    exit: { x: '100%', y: 0 },
  },
}

export type SlideDirection = 'top' | 'left' | 'bottom' | 'right'

export const getSlideTransition = (options?: {
  direction?: SlideDirection
}) => {
  const side = options?.direction ?? 'right'
  switch (side) {
    default:
      return TRANSITION_VARIANTS.slideRight
  }
}

const variants: Variants<SlideOptions> = {
  exit: ({ direction, transition, transitionEnd, delay }) => {
    const { exit: exitStyles } = getSlideTransition({ direction })
    return {
      ...exitStyles,
      transition:
        transition?.exit ?? withDelay.exit(defaultTransition.exit, delay),
      transitionEnd: transitionEnd?.exit,
    }
  },
  enter: ({ direction, transitionEnd, transition, delay }) => {
    const { enter: enterStyles } = getSlideTransition({ direction })
    return {
      ...enterStyles,
      transition:
        transition?.enter ?? withDelay.enter(defaultTransition.enter, delay),
      transitionEnd: transitionEnd?.enter,
    }
  },
}

export interface SlideOptions {
  /**
   * The direction to slide from
   * @default "right"
   */
  direction?: SlideDirection
}

export const slideConfig: HTMLMotionProps<'div'> = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  variants,
}
