import clsx from 'clsx'
import { BigNumber, utils } from 'ethers'
import { useTranslation } from 'next-i18next'
import { Dispatch, SetStateAction } from 'react'

import { FormLabelLike } from '@/components/form'
import { TokenIcon } from '@/components/TokenIcon'
import { fmtNumber } from '@/lib/formatter'

import { TotalDisplay } from './TotalDisplay'

const fmtNumberMaxDecimals = 4

type Props = {
  className?: string
  style: 'buy-modal' | 'shopping-cart'
  balanceEth: BigNumber
  balanceWeth: BigNumber
  payWithEth: boolean
  payWithWeth: boolean
  setPayWithEth: Dispatch<SetStateAction<boolean>>
  setPayWithWeth: Dispatch<SetStateAction<boolean>>
  amountToEth: BigNumber
  amountToWeth: BigNumber
  amountTotal: BigNumber
}
export const PayWithBoth = ({
  className,
  style,
  balanceEth,
  balanceWeth,
  payWithEth,
  payWithWeth,
  setPayWithEth,
  setPayWithWeth,
  amountToEth,
  amountToWeth,
  amountTotal,
}: Props) => {
  const { t } = useTranslation()

  const payments = [
    {
      symbol: 'ETH',
      amount: balanceEth,
      selected: payWithEth,
      onClick: () => setPayWithEth((a) => !a),
    },
    {
      symbol: 'WETH',
      amount: balanceWeth,
      selected: payWithWeth,
      onClick: () => setPayWithWeth((a) => !a),
    },
  ]

  const isBuyModal = style === 'buy-modal'

  return (
    <div className={clsx('space-y-6', isBuyModal && 'lg:space-y-8', className)}>
      <div>
        {/* TODO: use FormLabel */}
        <FormLabelLike className="mb-3">{t('Pay With')}</FormLabelLike>
        <div
          className={clsx(
            'grid grid-cols-1 gap-3',
            isBuyModal && 'lg:grid-cols-2',
          )}
        >
          {payments.map((a, i) => (
            <button
              className={clsx(
                'ts-button-1 flex items-center rounded-lg border-2 px-[22px] py-3.5 text-left lg:hover:opacity-70',
                a.selected
                  ? 'border-gray-700 bg-gray-700 text-gray-100'
                  : 'border-gray-300 text-gray-700',
              )}
              onClick={a.onClick}
              key={i}
            >
              <span className="flex-1">{a.symbol}</span>
              <TokenIcon className="ml-2 h-4 w-4 shrink-0" symbol={a.symbol} />
              <span className="ml-1 truncate">
                {fmtNumber(parseFloat(utils.formatEther(a.amount)), {
                  max: fmtNumberMaxDecimals,
                })}
              </span>
            </button>
          ))}
        </div>
      </div>
      <hr />
      <TotalDisplay
        {...{ isBuyModal, amountToEth, amountToWeth, amountTotal }}
      />
    </div>
  )
}
