import { pipe } from 'fp-ts/lib/function'
import { ParsedUrlQuery } from 'querystring'

import { SearchPayload } from '@/lib/es/activity'
import { A } from '@/lib/fp'
import {
  activityCategoriesURLQueryCodec,
  kvToUrlQuery,
} from '@/lib/search/utils'

export type Query = Pick<SearchPayload, 'filters'>

export const urlQueryCodec = {
  decode: (q: ParsedUrlQuery): Pick<SearchPayload, 'filters'> => {
    return {
      filters: {
        categories: activityCategoriesURLQueryCodec.decode(q),
      },
    }
  },
  encode: (query: SearchPayload) => {
    return pipe(
      [pipe(activityCategoriesURLQueryCodec.encode(query.filters?.categories))],
      A.compact,
      kvToUrlQuery,
    )
  },
}
