import { pipe } from 'fp-ts/lib/function'

import { A, D, O } from '@/lib/fp'
import { TOKEN_721, TOKEN_1155 } from '@/lib/market'
import { getTokenMeta } from '@/lib/token'

export const infoDecoder = D.partial({
  desc: D.string,
  cover_url: D.string,
  links: D.partial({
    discord: D.string,
    facebook: D.string,
    homepage: D.string,
    instagram: D.string,
    medium: D.string,
    reddit: D.string,
    telegram: D.string,
    twitter: D.string,
  }),
})
export const rarityMetaDecoder = D.struct({
  total_count: D.number,
  bypass_traits: D.array(D.string),
  rarity_distribution: D.record(D.record(D.number)),
})
export type RarityMeta = D.TypeOf<typeof rarityMetaDecoder>

export const extractFloorPrice = (
  prices: Record<string, number>,
  rates: Record<string, number>,
  networkId: number,
) => {
  let res: {
    value: number
    usdValue: number
    currency: string
    symbol: string
  } | null = null
  for (const [currency, value] of Object.entries(prices)) {
    const symbol = getTokenMeta(networkId, currency).symbol
    const rate = rates[symbol]
    if (!rate) continue
    const usdValue = rate * value
    if (!res || res.usdValue > usdValue) {
      res = { value, usdValue, currency, symbol }
    }
  }
  if (!res) return null
  return {
    value: res.value,
    currency: res.currency,
    symbol: res.symbol,
  }
}

export const mergeFloorPrices = (
  floorPrices: {
    value: number
    currency: string
    symbol: string
  }[],
  rates: Record<string, number>,
) => {
  let res: {
    value: number
    usdValue: number
    currency: string
    symbol: string
  } | null = null
  for (const { value, currency, symbol } of floorPrices) {
    const rate = rates[symbol]
    if (!rate) continue
    const usdValue = rate * value
    if (!res || res.usdValue > usdValue) {
      res = { value, usdValue, currency, symbol }
    }
  }
  return res
}

export const mergeStats = (
  stats: {
    onsale_number: number
    player_count: string
    market_vol: string
    market_sold: number
    market_floor_price?: unknown | null
  }[],
  rates: Record<string, number>,
  networkId: number,
) => {
  return {
    onsale_number: pipe(
      stats,
      A.map((a) => a.onsale_number),
      A.reduce(0, (a, b) => a + b),
    ),
    player_count: pipe(
      stats,
      A.map((a) => parseInt(a.player_count)),
      A.reduce(0, (a, b) => a + b),
      (a) => a.toString(),
    ),
    market_vol: pipe(
      stats,
      A.map((a) => parseFloat(a.market_vol)),
      A.reduce(0, (a, b) => a + b),
      (a) => a.toString(),
    ),
    market_sold: pipe(
      stats,
      A.map((a) => a.market_sold),
      A.reduce(0, (a, b) => a + b),
    ),
    market_floor_price: pipe(
      stats,
      A.filterMap((a) => {
        return pipe(
          D.record(D.number).decode(a.market_floor_price),
          O.fromEither,
          O.chainNullableK((p) => extractFloorPrice(p, rates, networkId)),
        )
      }),
      (xs) => mergeFloorPrices(xs, rates),
    ),
  }
}

export const ERC_TYPE_721 = 0
export const ERC_TYPE_1155 = 1

export const ercTypeToTokenKind = (num: number) => {
  switch (num) {
    case ERC_TYPE_721:
      return TOKEN_721
    case ERC_TYPE_1155:
      return TOKEN_1155
    default:
      return null
  }
}
