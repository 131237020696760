import { identity, pipe } from 'fp-ts/function'
import * as uuid from 'uuid'

import { E, TE } from '@/lib/fp'

export const getKey = () =>
  Buffer.from('1b74605c06a8088d6865f58dd3391034', 'hex')
export const getIV = () =>
  Buffer.from('ec03f33b265acb43e22e5f3f5c74e7b9', 'hex')
export const UUID_V4_LENGTH = 36
export const SIGNATURE_HEADER = 'x-x2y2-signature'

const createSignature = async () => {
  const uuidStr = uuid.v4()
  const signature = await crypto.subtle.encrypt(
    { name: 'AES-CBC', length: 128, iv: getIV() },
    await crypto.subtle.importKey(
      'raw',
      getKey(),
      {
        name: 'AES-CBC',
        length: 128,
      },
      false,
      ['encrypt'],
    ),
    Buffer.from(uuidStr, 'utf8'),
  )
  const signatureTxt = Buffer.from(signature).toString('base64')
  return `${uuidStr}${signatureTxt}`
}

export const rawTaskGen = <Resp>(reqGen: () => Promise<Response>) =>
  pipe(
    TE.tryCatch(reqGen, (e) => {
      console.error(e)
      return 'bad response'
    }),
    TE.filterOrElse(
      (a) => a.ok,
      () => 'bad response',
    ),
    TE.chain((a) =>
      TE.tryCatch(
        async () => (await a.json()) as E.Either<string, Resp>,
        (e) => {
          console.error(e)
          return 'bad response'
        },
      ),
    ),
    TE.chainEitherK(identity),
  )

export type Entry<Payload, Resp> = (
  payload: Payload,
) => TE.TaskEither<string, Resp>
export const taskGen =
  <Payload, Resp>(path: string) =>
  (payload: Payload) =>
    rawTaskGen<Resp>(async () => {
      const signature = await createSignature()
      return fetch(`/api${path}`, {
        method: 'POST',
        headers: {
          [SIGNATURE_HEADER]: signature,
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(payload),
      })
    })

export type AuthEntry<Payload, Resp> = (
  payload: Payload,
  token: string,
) => TE.TaskEither<string, Resp>
export const authTaskGen =
  <Payload, Resp>(path: string) =>
  (payload: Payload, token: string) =>
    rawTaskGen<Resp>(async () => {
      const signature = await createSignature()
      return fetch(`/api${path}`, {
        method: 'POST',
        headers: {
          [SIGNATURE_HEADER]: signature,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
    })
