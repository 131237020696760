const apiPathPrefix = '/xy3/v3'

export const LOAN_STATS_URL = `${apiPathPrefix}/sys/stats` as const
export const LOAN_PARAMS_URL = `${apiPathPrefix}/sys/params` as const
export const LOAN_WHITELIST_URL = `${apiPathPrefix}/sys/whitelist` as const
export const LOAN_COLLECTION_STATUS_URL =
  `${apiPathPrefix}/collection/{address}` as const
export const LOAN_NFT_STATUS_URL =
  `${apiPathPrefix}/collection/{address}/{tokenId}/property` as const
export const LOAN_COLLECTION_SUPPORT_URL =
  `${apiPathPrefix}/collections/support` as const
export const LOAN_OFFER_LIST_URL = `${apiPathPrefix}/offer/list` as const
export const LOAN_OFFER_QUOTE_URL = `${apiPathPrefix}/offer/quote` as const
export const LOAN_USER_TOKENS_SUPPORT_URL =
  `${apiPathPrefix}/user/{address}/tokens/support` as const
export const LOAN_BATCH_LOAN_GET_TERMS_INFO_URL =
  `${apiPathPrefix}/terms/batch_info` as const
export const LOAN_SET_TERMS_URL = `${apiPathPrefix}/terms/set` as const
export const LOAN_CANCEL_TERMS_URL = `${apiPathPrefix}/terms/cancel` as const
export const LOAN_GET_TERMS_INFO_URL = `${apiPathPrefix}/terms/info` as const
export const LOAN_GET_TERMS_LIST_URL = `${apiPathPrefix}/terms/list` as const
export const LOAN_ORDERS_LIST_URL = `${apiPathPrefix}/orders/list` as const
export const LOAN_ORDERS_LATEST_URL = `${apiPathPrefix}/orders/latest` as const
export const LOAN_USER_CURRENT_LEND_ORDER_LIST_URL =
  `${apiPathPrefix}/user/{address}/order/lend/current` as const
export const LOAN_USER_HISTORY_LEND_ORDER_LIST_URL =
  `${apiPathPrefix}/user/{address}/order/lend/history` as const
export const LOAN_USER_CURRENT_BORROW_ORDER_LIST_URL =
  `${apiPathPrefix}/user/{address}/order/borrow/current` as const
export const LOAN_USER_HISTORY_BORROW_ORDER_LIST_URL =
  `${apiPathPrefix}/user/{address}/order/borrow/history` as const
export const LOAN_USER_OFFER_LIST_URL =
  `${apiPathPrefix}/user/{address}/offer/all` as const
export const LOAN_USER_COLLECTION_OFFER_LIST_URL =
  `${apiPathPrefix}/user/{address}/offer/collection` as const
export const LOAN_MAKE_OFFER2_URL = `${apiPathPrefix}/offer/make2` as const
export const LOAN_CANCEL_OFFER_URL = `${apiPathPrefix}/offer/cancel` as const
export const LOAN_CANCEL_ALL_OFFER_URL =
  `${apiPathPrefix}/offer/cancel_all` as const
export const LOAN_SERVER_SIGN_URL =
  `${apiPathPrefix}/offer/server_sign` as const
export const LOAN_UNLOCK_OFFER_URL = `${apiPathPrefix}/offer/unlock` as const
