import { BigNumber, constants, ethers } from 'ethers'
import { constVoid, pipe } from 'fp-ts/lib/function'
import { createContext, ReactNode, useContext, useMemo } from 'react'

import useOptionalTask from '@/hooks/useOptionalTask'
import { getUserAddress, useAuth } from '@/lib/auth'
import { ERC20__factory } from '@/lib/contract'
import { A, O, RD, TE } from '@/lib/fp'
import { WETH_CONTRACT_MAPS, X2Y2_TOKEN_CONTRACT_MAPS } from '@/lib/x2y2'
import { DEFAULT_NETWORK, getProviderByNetworkId } from '@/utils/network'

export type Coin = {
  symbol: string
  digits: number
  contract: string
  network: number
  name: string
}

export type Balance = Coin & {
  balance: BigNumber
}

export const coins: Coin[] = [
  {
    symbol: 'ETH',
    contract: ethers.constants.AddressZero,
    digits: 18,
    network: DEFAULT_NETWORK,
    name: 'Ethereum',
  },
  {
    symbol: 'WETH',
    contract: WETH_CONTRACT_MAPS[DEFAULT_NETWORK],
    digits: 18,
    network: DEFAULT_NETWORK,
    name: 'Wrapped Ethereum',
  },
  {
    symbol: 'X2Y2',
    contract: X2Y2_TOKEN_CONTRACT_MAPS[DEFAULT_NETWORK],
    digits: 18,
    network: DEFAULT_NETWORK,
    name: 'X2Y2 Token',
  },
]

interface PortfolioState {
  balance: Balance[]
  refresh: () => void
}

const DEFAULT_PORTFOLIO_STATE: PortfolioState = {
  balance: [],
  refresh: constVoid,
}
export const PorfolioContext = createContext<PortfolioState>(
  DEFAULT_PORTFOLIO_STATE,
)
export const usePortfolio = () => useContext(PorfolioContext)

export const PortfolioProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()
  const task = useMemo(() => {
    return pipe(
      getUserAddress(user),
      O.map((address) => {
        return pipe(
          coins,
          A.map((coin) => {
            return TE.tryCatch(
              async (): Promise<Balance> => {
                const provider = getProviderByNetworkId(coin.network)
                let balance: BigNumber
                if (coin.contract === ethers.constants.AddressZero) {
                  balance = await provider.getBalance(address)
                } else if (coin.contract) {
                  const contract = ERC20__factory.connect(
                    coin.contract,
                    provider,
                  )
                  balance = await contract.balanceOf(address)
                } else {
                  balance = constants.Zero
                }
                return { ...coin, balance }
              },
              (e) => {
                console.error(e)
                return 'bad response'
              },
            )
          }),
          TE.sequenceArray,
          TE.map((xs) => [...xs]),
        )
      }),
    )
  }, [user])
  const [taskResp, refresh] = useOptionalTask(task, { skipPending: true })
  const balance = useMemo(
    () =>
      pipe(
        taskResp,
        RD.toOption,
        O.getOrElse((): Balance[] => []),
      ),
    [taskResp],
  )
  return (
    <PorfolioContext.Provider
      value={{
        balance,
        refresh,
      }}
    >
      {children}
    </PorfolioContext.Provider>
  )
}
