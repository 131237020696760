import clsx from 'clsx'
import { BigNumber, utils } from 'ethers'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'

import { Loading } from '@/components/feedback'
import { Button } from '@/components/form'
import { FormLabelLike } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import { NETWORK_ETH } from '@/consts'
import { UseDisclosureReturn, useLoading } from '@/hooks'
import { Crossmint, Paper, ShoppingBagLine, Winter } from '@/icons'
import { enableBuyWithCrossmint } from '@/lib/features'
import { NEA } from '@/lib/fp'
import { Bundle } from '@/lib/nft/detail'
import { getTokenMeta } from '@/lib/token'
import { OpenSeaMetadata } from '@/lib/unimeta'
import { DEFAULT_NETWORK } from '@/utils/network'

import { NftItemList } from '../shared'
import { CreditCard } from './CreditCard'

export type BundleWithMeta = NEA.NonEmptyArray<
  Bundle[0] & { meta: OpenSeaMetadata }
>

export type BuyProps = {
  bundle: BundleWithMeta
  price: BigNumber
  currency: string
  display: {
    showBuyWithWinterCard: boolean
    showBuyWithCrossmintCard: boolean
    showBuyWithPaperCard: boolean
  }
}
type Props = BuyProps & {
  disclosure: UseDisclosureReturn
}

export type CardType = 'Crossmint' | 'Winter' | 'Paper' | null

export type Fee = number | null | undefined

type PaymentsItem = {
  label: CardType
  fee: number | null
}

const selectCard = (a: PaymentsItem, b: PaymentsItem) =>
  a.fee === null && b.fee === null
    ? null
    : a.fee === null
    ? b.label
    : b.fee === null
    ? a.label
    : a.fee <= b.fee
    ? a.label
    : b.label

export const BuyWithCreditCardModal = ({
  disclosure,
  bundle,
  price,
  currency,
  display,
}: Props) => {
  const { t } = useTranslation()

  const { token: tokenContract, tokenId } = bundle[0].tokenPair
  const { networkId } = bundle[0].info
  const { decimals } = getTokenMeta(networkId, currency)
  const { guardLoading } = useLoading()
  const [selectedCard, setSelectedCard] = useState<CardType>(null)
  const [showModal, setShowModal] = useState<CardType>(null)
  const nativePrice = utils.formatUnits(price, decimals)

  const [crossmintFee, setCrossmintFee] = useState<Fee>(undefined)
  const [winterFee, setWinterFee] = useState<Fee>(undefined)
  const [paperFee, setPaperFee] = useState<Fee>(undefined)

  useEffect(() => {
    const action = () => {
      try {
        const mintConfig = encodeURI(
          JSON.stringify({
            contractAddress: tokenContract,
            tokenId: tokenId,
          }),
        )
        const prefix = DEFAULT_NETWORK === NETWORK_ETH ? 'www' : 'staging'
        const url = `https://${prefix}.crossmint.com/api/v1-alpha1/quote?nativePrice=${nativePrice}&quoteCurrency=usd&mintConfig=${mintConfig}`
        const options = {
          method: 'GET',
          headers: {
            'X-CLIENT-ID': process.env.NEXT_PUBLIC_CROSSMINTCLIENTID ?? '',
          },
        }
        fetch(url, options)
          .then(async (res) => {
            const crossmintData = await res.json()
            const crossmintFee =
              typeof crossmintData?.price === 'number' &&
              typeof crossmintData?.gasFee === 'number'
                ? (crossmintData.price + crossmintData.gasFee).toFixed(2)
                : null
            setCrossmintFee(crossmintFee)
          })
          .catch((e) => {
            console.log(e)
            setCrossmintFee(null)
          })

        const winterUrl = `https://marketplace-api.usewinter.com/getTokenDetails?contractAddress=${tokenContract}&tokenId=${tokenId}&fillSource=x2y2.io&orderSource=x2y2.io`
        fetch(winterUrl)
          .then(async (res) => {
            const winterData = await res.json()
            const winterFee = winterData?.totalPrice ?? null
            setWinterFee(winterFee)
          })
          .catch((e) => {
            console.log(e)
            setWinterFee(null)
          })
      } catch (e) {
        console.error(e)
      }
    }
    action()
  }, [nativePrice, tokenContract, tokenId])

  const fmtFee = (fee: Fee) =>
    fee === undefined ? (
      <Loading label="" size="md" />
    ) : fee === null ? null : (
      `$${fee}`
    )
  const paymentItems = [
    {
      icon: Crossmint,
      label: 'Crossmint' as CardType,
      fee: fmtFee(crossmintFee),
      showCard: display.showBuyWithCrossmintCard,
    },
    {
      icon: Winter,
      label: 'Winter' as CardType,
      fee: fmtFee(winterFee),
      showCard: display.showBuyWithWinterCard,
    },
    {
      icon: Paper,
      label: 'Paper' as CardType,
      fee: fmtFee(paperFee),
      showCard: display.showBuyWithPaperCard,
    },
  ]

  useEffect(() => {
    if (
      crossmintFee !== undefined &&
      winterFee !== undefined &&
      paperFee !== undefined
    ) {
      const payments: PaymentsItem[] = [
        {
          label: 'Crossmint' as CardType,
          fee: crossmintFee,
        },
        {
          label: 'Winter' as CardType,
          fee: winterFee,
        },
        {
          label: 'Paper' as CardType,
          fee: paperFee,
        },
      ]
      const cheapest = payments.reduce((a, b) => {
        const acc = selectCard(a, b) === a.label ? a : b
        return acc
      })
      setSelectedCard(cheapest.label)
    }
  }, [crossmintFee, paperFee, winterFee])

  useEffect(() => {
    if (!disclosure.isOpen) return
  }, [tokenContract, tokenId, disclosure.isOpen])

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Buy with credit card')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col">
          <NftItemList
            bundle={pipe(
              bundle,
              NEA.map((a) => ({
                ...a,
                price: utils.formatUnits(price, decimals),
                currency,
              })),
            )}
          />
          <div className="mt-8 flex flex-col space-y-3">
            <FormLabelLike className="mr-auto">{t('Pay via')}</FormLabelLike>
            {paymentItems.map(({ label, icon: Icon, fee, showCard }, i) => {
              return showCard ? (
                <div
                  key={i}
                  onClick={() => {
                    if (fee !== null) setSelectedCard(label)
                  }}
                  className={clsx(
                    'flex h-14 cursor-pointer items-center gap-4 self-stretch rounded-lg border-2 border-gray-300 py-4 px-6 text-gray-700',
                    selectedCard == label &&
                      'border-2 border-gray-700 bg-gray-700 text-gray-100',
                    fee === null
                      ? 'opacity-50 hover:!cursor-not-allowed'
                      : 'hover:border-2 hover:border-gray-700 hover:bg-gray-700 hover:text-gray-100',
                  )}
                >
                  <div className="flex h-6 grow items-center">
                    <Icon className="h-6 w-6" />
                    <p className="ts-button-2 ml-3 h-4 lg:ts-button-1">
                      {label}
                    </p>
                  </div>
                  <div className="flex items-center justify-end gap-1 text-right">
                    {fee && (
                      <p className="ts-body-3 font-bold lg:ts-body-2">{fee}</p>
                    )}
                  </div>
                </div>
              ) : null
            })}
          </div>
          <hr className="my-6 border-gray-300 lg:my-8" />
          <Button
            className="h-12 gap-3 py-4 px-6"
            colorScheme="primary-1"
            onClick={() => {
              setShowModal(selectedCard)
            }}
            isDisabled={selectedCard === null}
          >
            <ShoppingBagLine className="h-4 w-4" />
            {t('Buy')}
          </Button>
          <CreditCard
            {...{
              bundle,
              showModal,
              setShowModal,
              selectedCard,
              setPaperFee,
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
