import { BigNumber, utils } from 'ethers'

export function toChecksumAddress(address: string): string {
  return utils.getAddress(address.toLowerCase())
}

export function isAddress(address: string): boolean {
  return utils.isAddress(address)
}

export function randomHex(length: number): string {
  return BigNumber.from(utils.randomBytes(length)).toHexString()
}
