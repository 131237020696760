import {
  BigNumber,
  BigNumberish,
  ContractTransaction,
  PayableOverrides,
  providers,
} from 'ethers'

import { RegisteredUser } from '../auth/types/user'
import { CartV2__factory } from '../contract'
import {
  CHECK_RESULT_APPROVE,
  CHECK_RESULT_BALANCE,
  checkBalance,
  MarketError,
} from '../market'
import * as Err from '../market/errors'
import { WETH_CONTRACT_MAPS, X2Y2_CART_CONTRACT_MAPS } from './contracts'
import {
  CartItem,
  ItemWithOrder,
  LooksrareOrderResult,
  SeaportOrderResult,
  X2Y2OrderResult,
} from './types'
import {
  calcX2Y2CallData,
  checkFiltered,
  checkReported,
  getLooksrareOrders,
  getSeaportOrders,
  signX2Y2Orders,
} from './utils'

const AMOUNT_0 = BigNumber.from(0)

const checkWethBalance = async (
  networkId: number,
  weth2Eth: BigNumber,
  signer: providers.JsonRpcSigner,
) => {
  const contract = WETH_CONTRACT_MAPS[networkId]
  const checkResult = await checkBalance(
    networkId,
    contract,
    weth2Eth,
    signer,
    '',
    true,
  )
  if (checkResult === CHECK_RESULT_APPROVE) {
    throw new MarketError(Err.ERR_0010)
  } else if (checkResult === CHECK_RESULT_BALANCE) {
    throw new MarketError(Err.ERR_0011)
  }
}

const buyNfts = async (
  networkId: number,
  signer: providers.JsonRpcSigner,
  items: ItemWithOrder[],
  amountToWEth: BigNumber,
  currentBlockTimestamp: number,
): Promise<ContractTransaction> => {
  const accountAddress = X2Y2_CART_CONTRACT_MAPS[networkId]
  const contract = WETH_CONTRACT_MAPS[networkId]

  const calcSeaportFulfillData = (await import('./seaport')).calcFulfillData
  const calcLooksrareFulfillData = (await import('./looksrare')).calcFulfillData
  const marketDatas = await Promise.all(
    items.map((item) => {
      if (item.tag === 'x2y2') {
        return calcX2Y2CallData(networkId, signer, item.order, item.value)
      } else if (item.tag === 'looksrare') {
        return calcLooksrareFulfillData(signer, item.order)
      } else {
        return calcSeaportFulfillData(
          networkId,
          signer,
          item.order,
          currentBlockTimestamp,
        )
      }
    }),
  )
  const weth = AMOUNT_0
  let value = AMOUNT_0
  const marketOrders = marketDatas.reduce((r, md) => {
    if (md) {
      value = value.add(md.value)
      r.push(md)
    }
    return r
  }, [] as { addr: string; value: BigNumber; data: string }[])
  if (marketOrders.length === 0) {
    throw new MarketError(Err.ERR_0005)
  }
  let weth2Eth = AMOUNT_0
  let eth2Weth = AMOUNT_0
  if (weth.gt(amountToWEth)) {
    const diff = weth.sub(amountToWEth)
    value = value.add(diff)
    eth2Weth = diff
  } else if (weth.lt(amountToWEth)) {
    const diff = amountToWEth.sub(weth)
    value = value.gt(diff) ? value.sub(diff) : AMOUNT_0
    weth2Eth = diff
  }

  // cartV2.buy
  const cartV2 = CartV2__factory.connect(accountAddress, signer)

  const moreDustTokens: string[] = [contract]
  const args = [
    weth2Eth,
    eth2Weth,
    [] as { token: string; target: string }[],
    [] as { amount: BigNumberish; token: string }[],
    marketOrders,
    items
      .filter(
        (item) => item.tag !== 'opensea' && item.tokenStandard === 'erc721',
      )
      .map((item) => ({ token: item.contract, tokenId: item.tokenId })),
    items
      .filter(
        (item) => item.tag !== 'opensea' && item.tokenStandard === 'erc1155',
      )
      .map((item) => ({
        token: item.contract,
        tokenId: item.tokenId,
        amount: 1,
      })),
    moreDustTokens,
  ] as const
  const options: PayableOverrides = { value }
  try {
    const gasLimit = await cartV2.estimateGas.buy(...args, options)
    options.gasLimit = gasLimit.mul(6).div(5)
  } catch (e) {
    console.log(e)
  }
  const tx = await cartV2.buy(...args, options)
  return tx
}

const buyOSNfts = async (
  networkId: number,
  signer: providers.JsonRpcSigner,
  items: ItemWithOrder[],
  amountToWEth: BigNumber,
  currentBlockTimestamp: number,
): Promise<ContractTransaction> => {
  const cartContract = X2Y2_CART_CONTRACT_MAPS[networkId]

  const calcFulfillData = (await import('./seaport')).calcFulfillData
  const marketDatas = await Promise.all(
    items.map((item) =>
      calcFulfillData(networkId, signer, item.order, currentBlockTimestamp),
    ),
  )
  let value: BigNumber = AMOUNT_0
  const marketOrders = marketDatas.reduce((r, md) => {
    if (md) {
      value = value.add(md.value)
      r.push(md)
    }
    return r
  }, [] as { addr: string; value: BigNumber; data: string }[])
  if (marketOrders.length === 0) {
    throw new MarketError(Err.ERR_0005)
  }
  value = value.gt(amountToWEth) ? value.sub(amountToWEth) : AMOUNT_0

  // cartV2.buyOS
  const cartV2 = CartV2__factory.connect(cartContract, signer)
  const args = [amountToWEth, marketOrders] as const
  const options: PayableOverrides = { value }
  try {
    const gasLimit = await cartV2.estimateGas.buyOS(...args, options)
    options.gasLimit = gasLimit.mul(6).div(5)
  } catch (e) {
    console.log(e)
  }
  const tx = await cartV2.buyOS(...args, options)
  return tx
}

export const checkout = async (
  user: RegisteredUser,
  items: ItemWithOrder[],
  _amountToEth: BigNumber,
  amountToWEth: BigNumber,
): Promise<ContractTransaction> => {
  const provider = user.web3Provider
  const signer = provider.getSigner()
  const networkId = provider.network.chainId

  const currentBlock = await provider.getBlock('latest')
  const timestamp = currentBlock.timestamp

  // check approve & balance
  await checkWethBalance(networkId, amountToWEth, signer)

  const x2y2Items = items.filter((item) => item.tag === 'x2y2')
  const looksrareItems = items.filter((item) => item.tag === 'looksrare')
  if (x2y2Items.length > 0 || looksrareItems.length > 0) {
    return buyNfts(networkId, signer, items, amountToWEth, timestamp)
  } else {
    return buyOSNfts(networkId, signer, items, amountToWEth, timestamp)
  }
}

export const checkCartItems = async (
  user: RegisteredUser,
  items: CartItem[],
  checkListingSupport: boolean,
): Promise<ItemWithOrder[]> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const signer = provider.getSigner()
  const buyer = await signer.getAddress()
  const accountAddress = X2Y2_CART_CONTRACT_MAPS[networkId]
  const looksrareItems: CartItem[] = []
  const seaportItems: CartItem[] = []
  const x2y2Items: CartItem[] = []
  items.forEach((item) => {
    if (item.tag === 'looksrare') {
      looksrareItems.push(item)
    } else if (item.tag === 'x2y2') {
      x2y2Items.push(item)
    } else if (item.tag === 'opensea') {
      seaportItems.push(item)
    }
  })
  const x2y2Orders: Record<number, X2Y2OrderResult> =
    x2y2Items.length > 0
      ? await signX2Y2Orders(user.token, accountAddress, x2y2Items)
      : {}
  const seaportOrders: Record<string, SeaportOrderResult> =
    seaportItems.length > 0
      ? await getSeaportOrders(buyer.toLowerCase(), seaportItems)
      : {}
  const looksrareOrders: Record<string, LooksrareOrderResult> =
    seaportItems.length > 0
      ? await getLooksrareOrders(buyer.toLowerCase(), looksrareItems)
      : {}
  const reportedResult =
    checkListingSupport && x2y2Items.length > 0
      ? await checkReported(x2y2Items)
      : {}
  const filteredResult =
    x2y2Items.length > 0 ? await checkFiltered(networkId, x2y2Items) : {}
  const result = items.map((item) => {
    const itemData = {
      tag: item.tag,
      contract: item.contract,
      tokenId: item.tokenId,
      tokenStandard: item.tokenStandard,
    }
    if (item.tag === 'looksrare') {
      const looksrareOrder: LooksrareOrderResult = looksrareOrders[item.orderId]
      if (looksrareOrder) {
        return {
          ...looksrareOrder,
          ...itemData,
          reported: false,
          priceChanged: !item.price.eq(looksrareOrder.value),
        } as ItemWithOrder
      }
    } else if (item.tag === 'x2y2') {
      if (filteredResult[item.orderId]) {
        return {
          ...itemData,
          order: undefined,
          value: AMOUNT_0,
          invalidReason: 'Not Supported',
          reported: reportedResult[item.orderId] ?? false,
          priceChanged: false,
        } as ItemWithOrder
      } else {
        const x2y2Order: X2Y2OrderResult = x2y2Orders[item.orderId]
        if (x2y2Order) {
          return {
            ...x2y2Order,
            ...itemData,
            reported: reportedResult[item.orderId] ?? false,
            priceChanged: !item.price.eq(x2y2Order.value),
          } as ItemWithOrder
        }
      }
    } else if (item.tag === 'opensea') {
      const key = `${item.contract.toLowerCase()}_${item.tokenId}`
      const seaportOrder: SeaportOrderResult = seaportOrders[key]
      if (seaportOrder) {
        return {
          ...seaportOrder,
          ...itemData,
          reported: false,
          priceChanged: !item.price.eq(seaportOrder.value),
        } as ItemWithOrder
      }
    }
    return {
      order: undefined,
      value: AMOUNT_0,
      ...itemData,
      reported:
        item.tag !== 'x2y2' ? false : reportedResult[item.orderId] ?? false,
      invalidReason: 'Order Cancelled',
      priceChanged: false,
    } as ItemWithOrder
  })
  return result
}
