import clsx from 'clsx'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
> & {
  isError?: boolean
}
export const FormHelperText: FC<Props> = ({
  className,
  isError = false,
  ...rest
}) => (
  <p
    className={clsx(
      className,
      'ts-caption-2 mt-1',
      isError ? 'text-primary-3' : 'text-gray-500',
    )}
    {...rest}
  />
)
