import { NETWORK_ETH, NETWORK_GOERLI } from '@/consts'
import { DEFAULT_NETWORK } from '@/utils/network'

type TokenMeta = {
  symbol: string
  icon: string
  decimals: number
}

export const tokenMeta: Record<string, TokenMeta> =
  DEFAULT_NETWORK === NETWORK_ETH
    ? {
        '0xdac17f958d2ee523a2206206994597c13d831ec7': {
          symbol: 'USDT',
          icon: '',
          decimals: 6,
        },
        '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': {
          symbol: 'USDC',
          icon: '',
          decimals: 6,
        },
        '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
          symbol: 'WETH',
          icon: '',
          decimals: 18,
        },
      }
    : DEFAULT_NETWORK === NETWORK_GOERLI
    ? {
        '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6': {
          symbol: 'WETH',
          icon: '',
          decimals: 18,
        },
        '0x0a103756315d52d8f85922fb746b6888ff105e29': {
          symbol: 'WETH(0a10)',
          icon: '',
          decimals: 18,
        },
        '0x02e474f9237871c36642b4d875bd1da43990b8e1': {
          symbol: 'WETH(02e4)',
          icon: '',
          decimals: 18,
        },
        '0xfacf2f6e7aea88fec90dac9f40668f71a7f9ab4e': {
          symbol: 'Invalid',
          icon: '',
          decimals: 18,
        },
        '0xc778417e063141139fce010982780140aa0cd5ab': {
          symbol: 'Invalid',
          icon: '',
          decimals: 18,
        },
      }
    : {}
