import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'

import useCurrency from '@/hooks/useCurrency'
import { InfoCircleFilled, TrashLine, VerifiedFilled } from '@/icons'
import { ReactComponent as OpenSea } from '@/images/OpenSea.svg'
import { InvalidReason } from '@/lib/x2y2'

import { IconButton } from '../form'
import { TokenIcon } from '../TokenIcon'
import Link from '../TwLink'

export type ItemCheckResult =
  | { tag: 'price-changed'; price: string }
  | { tag: 'not-valid'; reason: InvalidReason | null }
  | { tag: 'os-reported' }

type Props = {
  isOS?: boolean
  image: ReactNode
  contractName: string
  contractVerified: boolean
  name: string
  price: string
  symbol: string
  result: ItemCheckResult | null
  href: string
  onLinkClick: () => void
  onRemove: () => void
}
export const CartItem = ({
  isOS = false,
  image,
  contractName,
  contractVerified,
  name,
  price,
  symbol,
  result,
  href,
  onLinkClick,
  onRemove,
}: Props) => {
  const { t } = useTranslation()
  const { fmtCoinNumber } = useCurrency()
  const isInvalid = result?.tag === 'not-valid'
  const newPrice = result?.tag === 'price-changed' ? result.price : price
  const reasonTitles: Record<InvalidReason, string> = {
    'Order Cancelled': t('Cancelled listing'),
    'Your Own NFT': t('Your own listing'),
    'Not Supported(1155)': t('Unsupported ERC-1155 listing'),
    'Not Supported': t('Unsupported listing'),
  }
  return (
    <div
      className={clsx(
        'flex flex-col space-y-3 px-3',
        isInvalid && 'bg-gray-300 bg-opacity-50 py-3',
        result?.tag === 'os-reported' && 'bg-red-bg py-3',
      )}
    >
      <div className="flex items-center">
        <Link
          className={clsx(
            'flex flex-1 items-center',
            isInvalid && 'opacity-50 hover:opacity-25',
          )}
          hoverStyle="opacity"
          href={href}
          isExternal={isOS}
          onClick={onLinkClick}
        >
          {image}
          <div className="ml-3">
            <div className="flex items-center text-gray-500">
              <span className="ts-caption-2 text-ellipsis line-clamp-1">
                {contractName}
              </span>
              {contractVerified && (
                <VerifiedFilled className="ml-1 h-3 w-3 text-primary-1" />
              )}
            </div>
            <div className="ts-body-3 text-ellipsis font-bold line-clamp-1">
              {name}
            </div>
            <div className="mt-0.5 flex items-center space-x-1">
              {isOS && <OpenSea className="h-4 w-4" />}
              <TokenIcon className="h-3 w-3" symbol={symbol} />
              {result?.tag === 'price-changed' && (
                <span className="ts-caption-2 text-primary-3 line-through">
                  {price}
                </span>
              )}
              <span className="ts-caption-2 font-bold">{newPrice}</span>
              <span className="ts-caption-2 text-gray-500">
                ({fmtCoinNumber(parseFloat(newPrice), symbol)})
              </span>
            </div>
          </div>
        </Link>
        <IconButton
          className="ml-4 h-12 w-12 shrink-0 rounded-full"
          aria-label={t('Remove')}
          icon={<TrashLine className="h-6 w-6 text-gray-500" />}
          onClick={onRemove}
        />
      </div>
      {result?.tag === 'not-valid' ? (
        <div className="flex items-center space-x-2 opacity-50">
          <InfoCircleFilled className="h-5 w-5 text-gray-400" />
          <p className="ts-caption-2 text-gray-700">
            {result.reason ? reasonTitles[result.reason] : '-'}
          </p>
        </div>
      ) : result?.tag === 'os-reported' ? (
        <div className="flex items-center space-x-2">
          <InfoCircleFilled className="h-5 w-5 text-primary-3" />
          <p className="ts-caption-2 text-primary-3">
            {t('Reported on OpenSea')}
          </p>
        </div>
      ) : null}
    </div>
  )
}
