import clsx from 'clsx'
import { useTranslation } from 'next-i18next'

import { useColorModeValue } from '@/components/colorMode'
import { ReactComponent as EmptyDark } from '@/images/EmptyDark.svg'
import { ReactComponent as EmptyLight } from '@/images/EmptyLight.svg'

import { Button } from '../form'

type Props = {
  label?: string
  button?: {
    title: string
    onClick: () => void
  }
  hiddenIcon?: boolean
}
export const Empty = ({ label, button, hiddenIcon }: Props) => {
  const { t } = useTranslation()
  const EmptyImage = useColorModeValue(EmptyLight, EmptyDark)

  return (
    <div
      className={clsx(
        'flex flex-col items-center space-y-6',
        hiddenIcon ? 'pt-8 pb-4' : 'py-8',
      )}
    >
      {!hiddenIcon && <EmptyImage className="h-10 w-10" />}
      <p className="ts-body-3 text-gray-500">{label ?? t('No data yet...')}</p>
      {button && (
        <Button variant="outline" size="sm" onClick={button.onClick}>
          {button.title}
        </Button>
      )}
    </div>
  )
}
