import { TFunction } from 'next-i18next'

import { SearchPayload as ActivitiesSearchPayload } from '@/lib/es/activity'
import { SearchPayload as OrdersSearchPayload } from '@/lib/es/nft'

import { activitiesTabUrlQueryCodec } from './activities'
import { itemsTabUrlQueryCodec } from './items'

export * from './items'
export * from './activities'

export const ALL_TABS = ['items', 'activities'] as const
export type Tab = (typeof ALL_TABS)[number]
export const DEFAULT_TAB: Tab = 'items'
export const tabTitles = (t: TFunction, tab: Tab) => {
  switch (tab) {
    case 'items':
      return t('Items')
    case 'activities':
      return t('Activities')
  }
}

type PageUrlPayload =
  | { tab: 'items'; query: Pick<OrdersSearchPayload, 'sort' | 'filters'> }
  | { tab: 'activities'; query: Pick<ActivitiesSearchPayload, 'filters'> }

export const pageURL = (payload: PageUrlPayload) => {
  switch (payload.tab) {
    case 'items':
      return `/explore${itemsTabUrlQueryCodec.encode(payload.query)}`
    case 'activities':
      return `/activities${activitiesTabUrlQueryCodec.encode(payload.query)}`
  }
}
