import { flow, pipe } from 'fp-ts/function'

import { connectTypeDecoder } from '@/lib/auth/types/connectType'
import { D, E, IOE } from '@/lib/fp'
import { itemDecoder as shoppingCartItemDecoder } from '@/lib/shoppingCart'

import { connectOptionDecoder } from './auth/types/connectOption'
import { featurePromptOptionsDecoder } from './featurePromptDefaultOptions'
import { recentlySearchedCollectionItemDecoder } from './navbar'

/// Entry for JSON Serializable data in localStorage
export interface Entry<A> {
  get: IOE.IOEither<string, A>
  set: (a: A) => IOE.IOEither<string, void>
  clear: IOE.IOEither<string, void>
}
export const genEntry = <A>(
  key: string,
  decoder: D.Decoder<unknown, A>,
): Entry<A> => {
  const get = pipe(
    IOE.tryCatch(
      () => localStorage.getItem(key),
      () => 'localStorage unreachable',
    ),
    IOE.chainEitherK(
      flow(
        flow(
          D.string.decode,
          E.mapLeft(() => 'data bad format'),
        ),
        E.chain((a) =>
          E.tryCatch(
            () => JSON.parse(a),
            () => 'data bad format',
          ),
        ),
        E.chain(
          flow(
            decoder.decode,
            E.mapLeft(() => 'data bad format'),
          ),
        ),
      ),
    ),
  )
  const set = (value: A) =>
    IOE.tryCatch(
      () => localStorage.setItem(key, JSON.stringify(value)),
      () => 'unreachable',
    )
  const clear = IOE.tryCatch(
    () => localStorage.removeItem(key),
    () => 'unreachable',
  )
  return { get, set, clear }
}

export const credential = genEntry(
  'credential',
  D.struct({ refreshToken: D.string }),
)
export const prevWeb3ConnOption = genEntry(
  'prev-web3-conn-option',
  D.struct({ option: connectOptionDecoder }),
)
export const prevWeb3ConnType = genEntry(
  'prev-web3-conn-type',
  D.struct({ type: connectTypeDecoder }),
)
export const shoppingCartItems = genEntry(
  'shopping-cart-items',
  D.array(shoppingCartItemDecoder),
)
export const recentlySearchedCollections = genEntry(
  'recently-searched-collections',
  D.record(D.array(recentlySearchedCollectionItemDecoder)),
)
export const featurePromptOptions = genEntry(
  'feat-prompt-options',
  featurePromptOptionsDecoder,
)
export const stakingRewardData = genEntry(
  'staking-reward-data',
  D.struct({
    blockPerDay: D.number,
    expiredAt: D.number,
    tokenPerDay: D.number,
    totalAmount: D.number,
    wethPerDay: D.number,
  }),
)
