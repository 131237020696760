import { TFunction } from 'next-i18next'

import { NETWORK_ETH } from '@/consts'
import { switchNetwork } from '@/lib/auth'
import * as U from '@/lib/auth/types/user'
import toast from '@/lib/toast'

import { DEFAULT_NETWORK } from './'

type ErrorHandler = (e: unknown) => void

export const guardNetwork = async (
  user: U.Web3RegisteredUser | U.RegisteredUser,
  networkId: number,
  action: () => void,
  onError: ErrorHandler,
) => {
  if (user.web3Provider.network.chainId !== networkId) {
    try {
      await switchNetwork(networkId)
    } catch (e) {
      onError(e)
    }
    return
  }
  action()
}

export const defaultErrorHandler =
  (t: TFunction): ErrorHandler =>
  (e) => {
    console.error(e)
    toast({
      status: 'error',
      title: t('Please switch to {{network}} network to proceed.', {
        network:
          DEFAULT_NETWORK === NETWORK_ETH
            ? 'Ethereum'
            : 'Ethereum Goerli testnet',
      }),
    })
  }
