import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'

import { VerifiedFilled } from '@/icons'
import { ReactComponent as OpenSea } from '@/images/OpenSea.svg'

import { TokenIcon } from '../TokenIcon'

type Props = {
  isOS?: boolean
  image: ReactNode
  contractName: string
  contractVerified: boolean
  name: string
  price: string
  symbol: string
  success: boolean
}
export const ResultItem = ({
  isOS = false,
  image,
  contractName,
  contractVerified,
  name,
  price,
  symbol,
  success,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center space-x-3">
      {image}
      <div className="min-w-0 flex-1">
        <p className="flex items-center text-gray-500">
          <span className="ts-caption-2 truncate">{contractName}</span>
          {contractVerified && (
            <VerifiedFilled className="ml-1 h-3 w-3 text-primary-1" />
          )}
        </p>
        <p className="ts-body-3 truncate break-all font-bold">{name}</p>
      </div>
      <p className="flex items-center space-x-1">
        {isOS && <OpenSea className="h-4 w-4" />}
        {success ? (
          <>
            <TokenIcon className="h-4 w-4" symbol={symbol} />
            <span className="ts-body-2 font-bold">{price}</span>
          </>
        ) : (
          <span className="ts-body-2 font-bold text-primary-3">
            {t('Failed')}
          </span>
        )}
      </p>
    </div>
  )
}
