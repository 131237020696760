import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui'
import { renderPaperCheckoutLink } from '@paperxyz/js-client-sdk'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { useMemo } from 'react'
import useSWR from 'swr'
import WinterCheckout from 'winter-marketplace-checkout/lib/WinterCheckout'
import { z } from 'zod'

import { NETWORK_ETH } from '@/consts'
import useOptionalTask from '@/hooks/useOptionalTask'
import * as api from '@/lib/api'
import { getUserAddressN, useUser } from '@/lib/auth'
import { User } from '@/lib/auth/types/user'
import { enableBuyWithCrossmint } from '@/lib/features'
import { O, RD, TE } from '@/lib/fp'
import { Bundle } from '@/lib/nft/detail'
import { postQuery } from '@/lib/xy3'
import { DEFAULT_NETWORK } from '@/utils/network'

import { AllItem } from './BuyWithCreditCard'
import { CardType, Fee } from './BuyWithCreditCardModal'

const fmtWinterLanguage = (language: string) => {
  switch (language) {
    case 'FR':
      return 'french'
    case 'JA':
      return 'japanese'
    case 'ZH-HANS':
      return 'chinese'
    case 'ZH-HANT':
      return 'chineseT'
    case 'KO':
      return 'korean'
    default:
      return 'EN'
  }
}

const useUserEmail = (user: User) => {
  const initUserTask = useMemo(
    () =>
      user && user._tag === 'registered'
        ? O.some(
            pipe(
              api.addressUserMetaWithAuth({}, user.token),
              TE.map((a) => a.email),
            ),
          )
        : O.none,
    [user],
  )
  const [userEmail] = useOptionalTask(initUserTask)
  const getUserEmail = RD.toUndefined(userEmail) ?? undefined
  return getUserEmail
}

type Props = {
  showModal: CardType
  setShowModal: (key: CardType) => void
  selectedCard?: CardType
  setPaperFee?: (key: Fee) => void
  bundle?: Bundle
  allItems?: AllItem[]
}

export const CreditCard = ({
  showModal,
  setShowModal,
  selectedCard,
  setPaperFee,
  bundle,
  allItems,
}: Props) => {
  const user = useUser()
  const walletAddress = getUserAddressN(user) ?? undefined
  const userEmail = useUserEmail(user)
  const buyAllItemsInCart = allItems?.length
  useEffect(() => {
    const el = document.getElementById('crossmint-pay')
    if (showModal === 'Crossmint' && el && enableBuyWithCrossmint) {
      ;(el.firstChild as HTMLButtonElement)?.click()
      setShowModal(null)
    }
  }, [setShowModal, showModal])
  return (
    <>
      {buyAllItemsInCart && <Crossmint {...{ walletAddress, allItems }} />}
      {!buyAllItemsInCart && bundle && (
        <CreditCardWithSingleItem
          {...{
            showModal,
            setShowModal,
            selectedCard,
            setPaperFee,
            bundle,
            userEmail,
            walletAddress,
          }}
        />
      )}
    </>
  )
}

type CrossmintProps = Pick<Props, 'allItems'> & {
  walletAddress: string | undefined
  tokenContract?: string
  tokenId?: string
}

const Crossmint = ({
  walletAddress,
  allItems,
  tokenContract,
  tokenId,
}: CrossmintProps) => {
  const nfts = allItems
    ? allItems.map((a) => ({
        type: 'x2y2-secondary-eth',
        contractAddress: a.contractAddress,
        tokenId: a.tokenId,
      }))
    : {
        type: 'x2y2-secondary-eth',
        contractAddress: tokenContract,
        tokenId,
      }
  return (
    enableBuyWithCrossmint && (
      <div className="hidden" id="crossmint-pay">
        <CrossmintPayButton
          clientId={process.env.NEXT_PUBLIC_CROSSMINTCLIENTID ?? ''}
          environment={
            DEFAULT_NETWORK === NETWORK_ETH ? 'production' : 'staging'
          }
          mintConfig={nfts}
          mintTo={walletAddress}
        />
      </div>
    )
  )
}

type CreditCardWithSingleItemProps = Omit<Props, 'allItems'> & {
  walletAddress: string | undefined
  userEmail: string | undefined
  bundle: Bundle
}
const CreditCardWithSingleItem = ({
  showModal,
  setShowModal,
  selectedCard,
  setPaperFee,
  bundle,
  walletAddress,
  userEmail,
}: CreditCardWithSingleItemProps) => {
  const { i18n } = useTranslation()
  const { token: tokenContract, tokenId } = bundle[0].tokenPair
  const { networkId, name, asset } = bundle[0].info
  const { data: paperData, isValidating } = usePaperApi({
    title: name,
    walletAddress: walletAddress ?? '',
    tokenContractAddress: tokenContract,
    tokenId,
    imageUrl: asset.image ?? '',
  })

  useEffect(() => {
    if (!isValidating && selectedCard === null && setPaperFee) {
      const paperValue = paperData?.estimatedPrice?.value
      const paperFee =
        paperValue === null || isNaN(Number(paperValue))
          ? null
          : Number(paperValue)
      setPaperFee(paperFee)
    }
  }, [isValidating, paperData, selectedCard, setPaperFee])

  useEffect(() => {
    if (showModal === 'Paper') {
      renderPaperModal(showModal, paperData?.checkoutLinkIntentUrl)
      setShowModal(null)
    }
  }, [paperData, setShowModal, showModal])
  return (
    <>
      <Crossmint {...{ walletAddress, tokenContract, tokenId }} />
      <WinterCheckout
        showModal={showModal === 'Winter'}
        walletAddress={walletAddress}
        email={userEmail}
        contractAddress={tokenContract}
        tokenId={tokenId}
        production={networkId == NETWORK_ETH}
        orderSource={'x2y2.io'}
        fillSource={'x2y2.io'}
        language={fmtWinterLanguage(i18n.language.toUpperCase())}
        onClose={() => setShowModal(null)}
      />
    </>
  )
}

const extPaperZ = z.object({
  checkoutLinkIntentUrl: z.string(),
  estimatedPrice: z.object({
    value: z.string(),
  }),
})
type ParamsProps = {
  title: string
  walletAddress: string
  tokenContractAddress: string
  tokenId: string
  imageUrl: string
}
export const usePaperApi = ({
  title,
  walletAddress,
  tokenContractAddress,
  tokenId,
  imageUrl,
}: ParamsProps) => {
  const { data, isValidating } = useSWR(
    {
      url: '/ext/withpaper/checkout-link',
      query: {},
      params: {
        title,
        imageUrl,
        walletAddress,
        contractArgs: {
          tokenContractAddress,
          tokenId,
        },
        hidePayWithCrypto: true,
        hideNativeMint: true,
      },
    },
    async ({ url, query, params }) => {
      const r = await postQuery(url, query, params)
      if (r.status !== 200) throw Error('not ok')
      const d = extPaperZ.parse(r.data)
      return d
    },
  )
  return { data, isValidating }
}

export const renderPaperModal = async (
  selectedCard: CardType,
  paperCheckoutUrl: string | undefined | null,
) => {
  if (selectedCard === 'Paper' && typeof paperCheckoutUrl === 'string') {
    try {
      await renderPaperCheckoutLink({
        checkoutLinkUrl: paperCheckoutUrl,
      })
    } catch (e) {
      console.log('Render Paper checkout link error :', e)
    }
  }
}
