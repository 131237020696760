import { pipe } from 'fp-ts/lib/function'
import * as iot from 'io-ts'

import { D, E } from '@/lib/fp'

export const nullableDecoder = <A>(decoder: D.Decoder<unknown, A>) =>
  pipe(
    decoder,
    D.alt(() => ({
      decode: (): E.Either<never, A | null> => E.right(null),
    })),
  )

export const numberFromStringDecoder: D.Decoder<unknown, number> = pipe(
  D.string,
  D.parse((s) => {
    const n = parseFloat(s)
    if (isNaN(n)) return D.failure(s, 'numberFromString')
    return D.success(n)
  }),
)

export const intFromStringDecoder: D.Decoder<unknown, number> = pipe(
  D.string,
  D.parse((s) => {
    const n = parseFloat(s)
    if (isNaN(n)) return D.failure(s, 'intFromString')
    if (!iot.Int.is(n)) return D.failure(s, 'intFromString')
    return D.success(n)
  }),
)

export const decoderWithDefault = <E, A>(decoder: D.Decoder<E, A>, df: A) =>
  pipe(
    decoder,
    D.alt(() => ({
      decode: (): E.Either<never, A> => E.right(df),
    })),
  )

export const pageDecoder = decoderWithDefault(intFromStringDecoder, 1)
