import { toChecksumAddress } from '@/lib/web3'
import { networkIdToSlug } from '@/utils/network'

import { pageURL as collectionURL } from './collection'
import { pageURL as discoverURL } from './discover'
import { ContractInfoFragment } from './gql/types'
import { pageURL as loanURL } from './loan'
import { Tab as SettingsTab } from './settings'

export { collectionURL, discoverURL, loanURL }
export { pageURL as userURL, pageURLMatcher as userURLMatcher } from './user'

/**
 * Returns URI for a contract, could be a collection or a discovery search.
 * @param contract the NFT contract
 * @returns URI of the page
 */
export const contractURL = (
  contract: Pick<
    ContractInfoFragment,
    'id' | 'contract' | 'network_id' | 'slug'
  >,
) =>
  collectionURL({
    tab: 'items',
    slug: contract.slug ?? toChecksumAddress(contract.contract),
  })

export const topURL = '/'

type ListPrice = { price: string; symbol: string }
const listPriceParam = (a: ListPrice) => `list_price=${a.price}_${a.symbol}`

const toHash = (a: string | undefined) => (a ? '#' + a : '')

type DetailURLParams = {
  networkId: number
  tokenContract: string
  tokenId: string
  listPrice?: ListPrice
}
export const detailURL = ({
  networkId,
  tokenContract,
  tokenId,
  listPrice,
}: DetailURLParams) => {
  const network = networkIdToSlug(networkId)
  const query = listPrice ? '?' + listPriceParam(listPrice) : ''
  return `/${network}/${toChecksumAddress(tokenContract)}/${tokenId}${query}` // prettier-ignore
}

export type RankingTab = 'top' | 'x2y2' | 'opensea' | 'looksrare'
export const rankingURL = (tab: RankingTab) =>
  `/collections` + (tab === 'top' ? '' : `?tab=${tab}`)

export const settingsURL = (tab: SettingsTab) => `/settings/${tab}`

export const bulkListingURL = '/tools/bulk-listing'

type RewardsURLParams = {
  tab?: 'airdrop'
}
export const rewardsURL = ({ tab }: RewardsURLParams) =>
  `/rewards${toHash(tab)}`

// MARK: Misc

export const fullSiteURL = (uri: string) => {
  return process.env.NEXT_PUBLIC_BASE_URL + uri
}

export const isExternal = (url: string) => {
  return url.startsWith('https://') || url.startsWith('http://')
}

export const isDecentralizedURL = (url: string) => {
  return (
    url.startsWith('ipfs://') ||
    url.startsWith('https://pinata.buzz/') ||
    url.startsWith('https://ipfs.featured.market/ipfs/') ||
    url.startsWith('https://ipfs.io/') ||
    url.startsWith('https://gateway.pinata.cloud/ipfs/') ||
    url.startsWith('data:')
  )
}

export const addUTM = (
  url: string,
  options: {
    source?: string
    medium?: string
    campaign?: string
  } = {},
) => {
  const { source, medium, campaign } = options
  return (
    `${url + (url.indexOf('?') > -1 ? '&' : '?')}` +
    `utm_source=${source || 'x2y2.io'}&` +
    `utm_medium=${medium || 'web'}&` +
    `utm_campaign=${campaign || 'launchpad'}`
  )
}

export const tokenomicsURL =
  'https://mirror.xyz/x2y2.eth/HyyViU7Ir7fW5cch0DozBD8_Qkak5FiLEi0bmx1cmgA'
export const tokenomics2URL =
  'https://mirror.xyz/x2y2.eth/k2Bv3UMpNFJTJiYdf5m48sANHn-Ei3m6ohmkO29qeoU'
