import { BigNumber, constants, utils } from 'ethers'
import { pipe } from 'fp-ts/lib/function'
import { TFunction } from 'next-i18next'

import { Img } from '@/components/media'
import { metadataAssetsAsNftImageProps, NftImage } from '@/components/media'
import { useUFO } from '@/images/UFOImage'
import { BundleItem } from '@/lib/nft/detail'

import { resizedCdnUrl } from '../cdn'
import { ercTypeToTokenKind } from '../collection/utils'
import { fmtDuration } from '../formatter'
import { O } from '../fp'
import { TOKEN_721, TokenKind } from '../market'
import { itemDisplayName } from '../nft'
import { extractNftMetadataAssets } from '../nft/metadata'
import { contractURL, detailURL } from '../url'
import { isNonNullable } from '../utils'
import { ALL_EXPIRE_POINTS } from './constants'
import {
  LoanParams,
  OfferItem,
  OfferListQuery,
  OfferListQueryAction,
  OfferListQueryWithoutNftParams,
  OrderItem,
} from './types'

export const durationTitle = (t: TFunction, seconds: number): string => {
  switch (seconds) {
    case 0:
      return t('All')
    default:
      return fmtDuration(t, seconds)
  }
}

const truncateEthToStr = (eth: BigNumber, fraction = 4) => {
  const onePadZero = (10 ** (18 - fraction)).toString()
  const r = ((str) => Number(str[str.length - (18 - fraction)] || '0'))(
    eth.toString(),
  )
  let v = eth.sub(eth.mod(onePadZero))
  // NOTE: handle rounding
  if ((r >= 5 && v.gt(0)) || (r < 5 && v.lt(0))) v = v.add(onePadZero)
  return utils.formatEther(v)
}
export const fmtEthNumber = (eth: BigNumber, fraction = 4) =>
  utils.commify(truncateEthToStr(eth, fraction))
export const truncateEthToNumber = (eth: BigNumber, fraction = 4) =>
  parseFloat(truncateEthToStr(eth, fraction))

const DISPLAY_NAME_OF_TOKEN_NAME = 1
export const extractDisplayNameOfLoanItem = ({
  nft,
  collection,
}: Required<Pick<OrderItem, 'nft' | 'collection'>>) => {
  const tokenIdLabel = `#${nft.tokenId}`
  return collection.displayTypeOfTokenName === DISPLAY_NAME_OF_TOKEN_NAME
    ? nft?.meta?.name
      ? `${nft.meta.name}`
      : tokenIdLabel
    : tokenIdLabel
}
export const getLoanItemDetail = (
  { nft, collection }: Pick<OrderItem, 'nft' | 'collection'>,
  ufoImage: ReturnType<typeof useUFO>,
) => {
  const detail = {
    url: '#',
    title: '-',
    verified: false,
    image: <Img src={resizedCdnUrl(ufoImage, 128)} />,
    collection: null as OrderItem['collection'] | null,
  }

  if (nft) {
    if (!collection) {
      throw new Error('This should never happen')
    }

    detail.url = detailURL({
      networkId: nft.networkId,
      tokenContract: collection.contract,
      tokenId: nft.tokenId,
    })
    detail.title = extractDisplayNameOfLoanItem({ nft, collection })
    detail.verified = false
    detail.image = (
      <NftImage
        className="w-full"
        {...metadataAssetsAsNftImageProps(
          extractNftMetadataAssets(nft?.meta, collection.displayOptions),
        )}
        imageSizes={['64px']}
      />
    )
    detail.collection = collection
  } else if (collection) {
    detail.url = contractURL({
      id: collection.id,
      contract: collection.contract,
      network_id: collection.networkId,
      slug: collection.slug,
    })
    detail.title = collection.name
    detail.verified = collection.verified
    detail.image = (
      <Img
        className="rounded-lg"
        src={resizedCdnUrl(collection.iconUrl ?? ufoImage, 128)}
      />
    )
    detail.collection = null
  }

  return detail
}

export const getBundleFromOfferOrOrder = ({
  nft,
  collection,
}: Required<Pick<OfferItem | OrderItem, 'nft' | 'collection'>>): BundleItem => {
  const { networkId, tokenId, meta } = nft
  const { contract, name, displayOptions, verified, ercType } = collection
  const asset = extractNftMetadataAssets(meta, displayOptions)
  return {
    tokenPair: {
      token: contract,
      tokenId,
      amount: 1,
      kind: (ercTypeToTokenKind(ercType) ?? TOKEN_721) as TokenKind,
      mintData: constants.HashZero,
    },
    info: {
      asset,
      creator: collection.name,
      verified,
      name: itemDisplayName(meta, name, tokenId),
      networkId,
    },
  }
}

export const getContractFromOffer = ({
  collection,
}: Required<Pick<OfferItem, 'collection'>>) => {
  const { contract, networkId, verified, iconUrl } = collection
  return {
    contract,
    network_id: networkId,
    name: collection.name,
    verified,
    icon_url: iconUrl,
  }
}

export const extractExpirePoints = (
  loanExpireRange: LoanParams['expireRange'],
) => {
  const min = loanExpireRange[0]
  const max = loanExpireRange.at(-1)
  const isValid = min !== undefined && max !== undefined && max >= min

  return ALL_EXPIRE_POINTS.filter((a) => isValid && a >= min && a <= max)
}

export const offerListQueryReducer = <
  T extends OfferListQuery | OfferListQueryWithoutNftParams,
>(
  state: T,
  action: OfferListQueryAction,
): T => {
  const { type, payload } = action

  switch (type) {
    case 'SET_DURATION':
      return {
        ...state,
        page: 1,
        duration: payload,
      }
    case 'SET_PAGE':
      return { ...state, page: payload }
    case 'SET_ORDER_AND_SORT':
      return {
        ...state,
        order: payload.order,
        sort:
          state.order === payload.order && state.sort === 'desc'
            ? 'asc'
            : 'desc',
      }
    case 'SET_ISSUFFICIENT':
      return {
        ...state,
        page: 1,
        isSufficient: payload.isSufficient,
      }
  }
}

export const calcInterestRate = (
  repayment?: string | null,
  loanValue?: string | null,
) => {
  try {
    return pipe(
      O.guard(isNonNullable(repayment) && isNonNullable(loanValue)),
      O.map(() => ({
        repay: BigNumber.from(repayment),
        loan: BigNumber.from(loanValue),
      })),
      O.map(
        ({ repay, loan }): number =>
          repay.sub(loan).mul(1000000).div(loan).toNumber() / 1000000,
      ),
      O.getOrElseW(() => null),
    )
  } catch {
    return null
  }
}
