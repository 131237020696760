import clsx from 'clsx'
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactElement,
} from 'react'

import { Spinner } from '../Spinner'
import Link, { LinkProps } from '../TwLink'

type Variant = 'solid' | 'outline' | 'text'
type ColorScheme = 'gray' | 'primary-1' | 'primary-3'
type Size = 'sm-md' | 'sm' | 'md'

const baseStyles: Record<Variant, string> = {
  solid:
    /*tw:*/ 'relative inline-flex items-center justify-center truncate disabled:opacity-40 disabled:cursor-not-allowed focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    /*tw:*/ 'relative inline-flex items-center justify-center truncate border-2 disabled:opacity-40 disabled:cursor-not-allowed',
  text: /*tw:*/ 'relative inline-flex items-center justify-center truncate align-bottom ts-button-2 h-6 button-hover:opacity-50 disabled:opacity-40 disabled:cursor-not-allowed',
}

const variantStyles: Record<Variant, Partial<Record<ColorScheme, string>>> = {
  solid: {
    'primary-1':
      /*tw:*/ 'bg-primary-1 text-button-text button-hover:bg-primary-1-600',
    'primary-3':
      /*tw:*/ 'bg-primary-3 text-button-text button-hover:bg-primary-3-600',
  },
  outline: {
    gray: /*tw:*/ 'text-gray-700 hover:text-gray-100 hover:bg-gray-800 hover:border-gray-800 disabled:text-gray-100 disabled:bg-gray-800 disabled:border-gray-800',
    'primary-1':
      /*tw:*/ 'border-primary-1 text-primary-1 button-hover:border-primary-1-600 button-hover:bg-primary-1-600 button-hover:text-button-text',
    'primary-3':
      /*tw:*/ 'border-primary-3 text-primary-3 button-hover:border-primary-3-600 button-hover:bg-primary-3-600 button-hover:text-button-text',
  },
  text: {
    'primary-1': /*tw:*/ 'text-primary-1',
  },
}

const sizeStyles: Record<Size, string> = {
  'sm-md':
    /*tw:*/ 'ts-button-2 h-10 px-4 rounded-lg lg:ts-button-1 lg:h-12 lg:px-6',
  sm: /*tw:*/ 'ts-button-2 h-10 px-4 rounded-lg',
  md: /*tw:*/ 'ts-button-1 h-12 px-6 rounded-lg',
}

type ButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'disabled'
> & {
  variant?: Variant
  colorScheme?: ColorScheme
  size?: Size
  isLoading?: boolean
  isDisabled?: boolean
  loadingText?: string
  leftIcon?: ReactElement
  rightIcon?: ReactElement
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      variant = 'solid',
      colorScheme = 'gray',
      size = 'sm-md',
      type = 'button',
      isLoading,
      isDisabled,
      loadingText,
      leftIcon,
      rightIcon,
      ...props
    },
    ref,
  ) => {
    const content = (
      <>
        {leftIcon ? (
          <span className={clsx(variant === 'text' ? 'mr-1' : 'mr-3')}>
            {leftIcon}
          </span>
        ) : null}
        {children}
        {rightIcon ? (
          <span className={clsx(variant === 'text' ? 'ml-1' : 'ml-3')}>
            {rightIcon}
          </span>
        ) : null}
      </>
    )
    return (
      <button
        className={clsx(
          className,
          baseStyles[variant],
          variantStyles[variant][colorScheme],
          variant !== 'text' && sizeStyles[size],
        )}
        ref={ref}
        type={type}
        disabled={isDisabled || isLoading}
        {...props}
      >
        {isLoading && (
          <span
            className={clsx(
              'flex items-center justify-center',
              // TODO: consider use iconSpacing like chakra
              loadingText ? 'mr-3' : 'absolute inset-0',
            )}
          >
            {/* TODO: can use a custom one after all buttons migrated */}
            <Spinner className="h-[1em] w-[1em]" />
          </span>
        )}
        {isLoading
          ? loadingText ?? <span className="opacity-0">{content}</span>
          : content}
      </button>
    )
  },
)
Button.displayName = 'Button'

type ButtonLinkProps = LinkProps & {
  variant?: Variant
  colorScheme?: ColorScheme
  size?: Size
}
export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      className,
      children,
      variant = 'solid',
      colorScheme = 'gray',
      size = 'sm-md',
      hoverStyle: _,
      ...props
    },
    ref,
  ) => (
    <Link
      className={clsx(
        className,
        baseStyles[variant],
        variantStyles[variant][colorScheme],
        variant !== 'text' && sizeStyles[size],
      )}
      ref={ref}
      hoverStyle="none"
      {...props}
    >
      {children}
    </Link>
  ),
)
ButtonLink.displayName = 'ButtonLink'
