import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { Fragment } from 'react'

import { Tooltip } from '@/components/overlay'
import { InfoCircleLine } from '@/icons'
import { fmtNumber } from '@/lib/formatter'

// Use sumtype if this turns more complex...
type Percent = number | [number]
type Line = {
  label: string
  tip: string
  percent: Percent | null
  detail?: { label: string; percent: Percent }[]
}

const fmtPercent = (a: Percent) =>
  Array.isArray(a) ? `0%~${a[0]}%` : `${fmtNumber(a)}%`

type Props = {
  className?: string
  lines: Line[]
}
export const PriceBreakdown = ({ className, lines }: Props) => {
  const { t } = useTranslation()
  if (!lines) return null
  return (
    <div
      className={clsx(
        className,
        'space-y-1 rounded-xl bg-modal-section-bg px-6 py-5',
      )}
    >
      {lines.map((a, i) => (
        <div
          className="flex items-center justify-between space-x-3 text-gray-700"
          key={i}
        >
          <div className="flex items-center space-x-1">
            <p className="ts-caption-2 lg:ts-caption">{a.label}</p>
            <Tooltip label={a.tip} placement="top" hasArrow>
              <InfoCircleLine className="h-4 w-4 text-gray-500" />
            </Tooltip>
          </div>
          <div className="flex items-center space-x-1">
            {a.percent !== null ? (
              <p className="ts-caption-2 lg:ts-caption">
                {fmtPercent(a.percent)}
              </p>
            ) : a.detail ? (
              <>
                <p className="ts-caption-2 lg:ts-caption">
                  {t('By Collection')}
                </p>
                <Tooltip
                  label={a.detail.map((d, j) => (
                    <Fragment key={j}>
                      {j > 0 && <br />}
                      {d.label}: {fmtPercent(d.percent)}
                    </Fragment>
                  ))}
                  placement="top"
                  hasArrow
                >
                  <InfoCircleLine className="h-4 w-4 text-gray-500" />
                </Tooltip>
              </>
            ) : (
              <p className="ts-caption-2 lg:ts-caption">?</p>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
