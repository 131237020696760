import clsx from 'clsx'

type Props = {
  className?: string
}
export const Skeleton = ({ className }: Props) => (
  <div
    className={clsx(
      className,
      'inline-block w-full rounded bg-gray-300 bg-[length:200px_100%] bg-no-repeat',
      'animate-skeleton bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300',
    )}
  />
)
