import { BigNumber, utils } from 'ethers'

import { getProviderByNetworkId } from '@/utils/network'

const abi = [
  'event EvProfit(bytes32 itemHash, address currency, address to, uint256 amount)',
  'event EvFailure(uint256 index, bytes error)',
  'event Approval(address indexed owner, address indexed approved, uint256 indexed tokenId)',
  'event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)',
  'event Result(uint256 index, bool success)',
]
const iface = new utils.Interface(abi)

type ITEM_PRICE = {
  tag: 'x2y2' | 'opensea' | 'looksrare'
  price: string
}

type Log = { topics: Array<string>; data: string }

const parseLog = (
  log: Log,
  targetEvent: string,
): utils.LogDescription | undefined => {
  try {
    // skip event EvInventory
    if (
      log.topics &&
      log.topics[0] !==
        '0x3cbb63f144840e5b1b0a38a7c19211d2e89de4d7c5faf8b2d3c1776c302d1d33'
    ) {
      const event = iface.parseLog(log)
      if (event && event.name === targetEvent) {
        return event
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      // other events
      console.log((e as Error).message)
    }
  }
  return undefined
}

const calcResult = (
  prices: ITEM_PRICE[],
  failedItems: number[],
  totalAmounts: { amountToEth: BigNumber; amountToWeth: BigNumber },
  gasRebate: BigNumber,
) => {
  let totalCount = 0
  let salesCount = 0
  let salesVol: BigNumber = BigNumber.from('0')
  let amountToEth = totalAmounts.amountToEth
  prices.forEach((itemPrice, index) => {
    if (failedItems.includes(index)) {
      amountToEth = amountToEth.sub(utils.parseEther(itemPrice.price))
    } else {
      totalCount++
      if (itemPrice.tag === 'x2y2') {
        salesCount++
        salesVol = salesVol.add(utils.parseEther(itemPrice.price))
      }
    }
  })
  // salesVol * 0.05
  const maxGasRebate = salesVol.div(20)
  const txGasRebate =
    totalCount > 0 ? gasRebate.mul(salesCount).div(totalCount) : gasRebate
  return {
    gasRebate: maxGasRebate.lt(txGasRebate) ? maxGasRebate : txGasRebate,
    failed: failedItems,
    total: {
      amountToEth,
      amountToWeth: totalAmounts.amountToWeth,
      amountTotal: amountToEth.add(totalAmounts.amountToWeth),
    },
  }
}

export const checkTxResult = async (
  networkId: number,
  txHash: string,
  prices: ITEM_PRICE[],
  totalAmounts: { amountToEth: BigNumber; amountToWeth: BigNumber },
  isCart: boolean,
): Promise<{
  failed: number[]
  total: {
    amountToEth: BigNumber
    amountToWeth: BigNumber
    amountTotal: BigNumber
  }
  gasRebate: BigNumber
}> => {
  if (isCart) {
    return checkCartTxReuslt(networkId, txHash, prices, totalAmounts)
  }
  const failedItems: number[] = []
  const provider = getProviderByNetworkId(networkId)
  const txReceipt = await provider.getTransactionReceipt(txHash)
  const gasRebate = txReceipt.gasUsed.mul(50).mul(10 ** 9)
  txReceipt.logs.forEach((log: Log) => {
    const event = parseLog(log, 'EvFailure')
    if (event) {
      failedItems.push(
        BigNumber.from(event.args['index'].toString()).toNumber(),
      )
    }
  })
  return calcResult(prices, failedItems, totalAmounts, gasRebate)
}

const checkCartTxReuslt = async (
  networkId: number,
  txHash: string,
  prices: ITEM_PRICE[],
  totalAmounts: { amountToEth: BigNumber; amountToWeth: BigNumber },
): Promise<{
  failed: number[]
  total: {
    amountToEth: BigNumber
    amountToWeth: BigNumber
    amountTotal: BigNumber
  }
  gasRebate: BigNumber
}> => {
  const failedItems: number[] = []
  const provider = getProviderByNetworkId(networkId)
  const txReceipt = await provider.getTransactionReceipt(txHash)
  const gasRebate = txReceipt.gasUsed.mul(50).mul(10 ** 9)
  txReceipt.logs.forEach((log: Log) => {
    const event = parseLog(log, 'Result')
    if (event) {
      const success: boolean = event.args['success'] as boolean
      if (!success) {
        failedItems.push(
          BigNumber.from(event.args['index'].toString()).toNumber(),
        )
      }
    }
  })
  return calcResult(prices, failedItems, totalAmounts, gasRebate)
}
