import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes } from 'react'

type TextareaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => (
    <textarea
      className={clsx(
        className,
        'form-input ts-input-1 rounded-lg border-2 border-gray-300 bg-transparent px-4 placeholder:text-gray-400 hover:border-gray-400 focus:border-gray-500 focus:ring-0',
      )}
      ref={ref}
      {...props}
    />
  ),
)
