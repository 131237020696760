import { Dialog } from '@headlessui/react'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { createContext, ReactNode, RefObject, useContext } from 'react'

import {
  fadeConfig,
  scaleFadeConfig,
  slideConfig,
} from '@/components/transition'
import { CloseLine } from '@/icons'

type MotionPreset = 'slide' | 'scale' | 'none'
const ModalCtx = createContext({
  onClose: (): void => undefined,
  motionPreset: 'none' as MotionPreset,
})
export const useModalCtx = () => useContext(ModalCtx)

export const transitions = {
  slide: {
    ...slideConfig,
    custom: { direction: 'right' },
  },
  scale: {
    ...scaleFadeConfig,
    custom: { initialScale: 0.95, reverse: true },
  },
  none: {},
}

type ModalProps = {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  initialFocus?: RefObject<HTMLElement | null>
  motionPreset?: MotionPreset
}
export const Modal = ({
  children,
  isOpen,
  onClose,
  initialFocus,
  motionPreset = 'scale',
}: ModalProps) => (
  <ModalCtx.Provider value={{ onClose, motionPreset }}>
    <AnimatePresence>
      {isOpen && (
        <Dialog
          static
          open={isOpen}
          onClose={onClose}
          initialFocus={initialFocus}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  </ModalCtx.Provider>
)

type ModalOverlayProps = {
  blur?: boolean
}
export const ModalOverlay = ({ blur = false }: ModalOverlayProps) => (
  <motion.div
    className={clsx(
      'fixed inset-0 z-[1400] bg-black/50',
      blur && 'backdrop-blur-sm',
    )}
    {...fadeConfig}
  />
)

type ModalContentProps = {
  className?: string
  children: ReactNode
  size?: 'md' | 'full'
}
export const ModalContent = ({
  className,
  children,
  size = 'md',
}: ModalContentProps) => {
  const { motionPreset } = useModalCtx()
  return (
    <div className="fixed inset-0 z-[1400] overflow-y-auto">
      <div
        className={clsx(
          'grid min-h-full place-items-center',
          size === 'md' && 'px-6 py-[60px] lg:px-8',
        )}
      >
        <Dialog.Panel
          as={motion.section}
          className={clsx(
            className,
            'relative mx-auto flex w-full flex-col bg-modal-bg',
            size === 'md' ? 'max-w-md rounded-20' : 'h-full',
          )}
          {...transitions[motionPreset]}
        >
          {children}
        </Dialog.Panel>
      </div>
    </div>
  )
}

type ModalHeaderProps = {
  className?: string
  children: ReactNode
}
export const ModalHeader = ({ className, children }: ModalHeaderProps) => (
  <Dialog.Title
    as="header"
    className={clsx(
      className,
      'ts-headline-6 mx-6 mt-6 py-2 lg:mx-8 lg:mt-8 lg:ts-headline-4 lg:py-0',
    )}
  >
    {children}
  </Dialog.Title>
)

type ModalFooterProps = {
  className?: string
  children: ReactNode
}
export const ModalFooter = ({ className, children }: ModalFooterProps) => (
  <footer
    className={clsx(
      className,
      'sticky bottom-0 flex bg-modal-bg px-6 py-4 lg:px-8',
    )}
  >
    {children}
  </footer>
)

// For easier migration, may be removed later
type ModalBodyProps = {
  className?: string
  children: ReactNode
}
export const ModalBody = ({ className, children }: ModalBodyProps) => (
  <div className={clsx(className, 'flex-1 p-6 lg:p-8')}>{children}</div>
)

type ModalCloseButtonProps = {
  className?: string
  children?: ReactNode
  title?: string
}
export const ModalCloseButton = ({
  className,
  children,
  title,
}: ModalCloseButtonProps) => {
  const { t } = useTranslation()
  const { onClose } = useModalCtx()
  return (
    <button
      className={clsx(
        className,
        'absolute top-6 right-6 grid place-items-center rounded-full p-2 lg:top-8 lg:right-8',
      )}
      aria-label={title ?? t('Close')}
      type="button"
      onClick={(e) => {
        e.stopPropagation()
        onClose()
      }}
    >
      {children ?? <CloseLine className="h-6 w-6" />}
    </button>
  )
}
