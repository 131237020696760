import { BigNumber, utils } from 'ethers'

import { getTokenMeta } from '@/lib/token'

export const parseBigNum = (
  networkId: number,
  a: {
    price: string
    currency: string
  },
) => utils.parseUnits(a.price, getTokenMeta(networkId, a.currency).decimals)

export const min = (a: BigNumber, b: BigNumber) => (a.lt(b) ? a : b)
