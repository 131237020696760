import { pipe } from 'fp-ts/lib/function'
import { TFunction } from 'next-i18next'
import { ParsedUrlQuery } from 'querystring'

import { fmtEthAddr } from '@/lib/formatter'
import { A, D, E, O } from '@/lib/fp'
import { Maybe } from '@/lib/gql/types'
import { kvToUrlQuery } from '@/lib/search/utils'
import { intFromStringDecoder } from '@/utils/codec'

export const ItemsSortT = D.union(D.literal('recently'))
export type ItemsSort = D.TypeOf<typeof ItemsSortT>

export type Contract = {
  network_id: number
  address: string
  name: string | null
  icon_url: string | null
  count: number
  verified: boolean
  stats?: Maybe<{
    floor_price?: unknown
  }>
}

const statusDecoder = D.union(
  D.literal('all'),
  D.literal('on-sale'),
  D.literal('not-on-sale'),
  D.literal('hidden'),
)
export const ALL_STATUS = ['all', 'on-sale', 'not-on-sale', 'hidden'] as const
export type Status = (typeof ALL_STATUS)[number]
export const DEFAULT_STATUS: Status = 'all'
export const statusTitle = (
  t: TFunction,
  s: Status,
  amount?: {
    total: number
    hidden: number
    onSale: number
    notOnSale: number
  },
) => {
  switch (s) {
    case 'all':
      return t('All') + (amount?.total ? ` (${amount.total})` : '')
    case 'on-sale':
      return t('On Sale') + (amount?.onSale ? ` (${amount.onSale})` : '')
    case 'not-on-sale':
      return (
        t('Not On Sale') + (amount?.notOnSale ? ` (${amount.notOnSale})` : '')
      )
    case 'hidden':
      return t('Hidden') + (amount?.hidden ? ` (${amount.hidden})` : '')
  }
}
export const sortDecoder = D.union(D.literal('latest'), D.literal('token-id'))
export type Sort = D.TypeOf<typeof sortDecoder>
export const sortTitle = (t: TFunction, s: Sort) => {
  switch (s) {
    case 'latest':
      return t('Recent_In Wallet')
    case 'token-id':
      return t('By Token Id')
  }
}
export const DEFAULT_SORT: Sort = 'latest'
export const ALL_SORTS: Sort[] = ['latest', 'token-id']

export type Filters = Partial<{
  status: Status
  contract: {
    network_id: number
    address: string
  }
  network: number
}>

export type Query = {
  filters?: Filters
  sort: Sort
  page: number
}

export const DEFAULT_QUERY: Query = {
  sort: DEFAULT_SORT,
  page: 1,
}

export const urlQueryCodec = {
  encode: (query: Query): string => {
    return pipe(
      [
        pipe(
          O.fromNullable(query.filters?.status),
          O.map((v) => ({ k: 'status', v })),
        ),
        pipe(
          O.fromNullable(query.filters?.contract),
          O.map((v) => ({
            k: 'contract',
            v: `${v.network_id}_${v.address.toLowerCase()}`,
          })),
        ),
        pipe(
          O.fromNullable(query.filters?.network),
          O.filter((a) => !!a),
          O.map((v) => ({ k: 'network', v: `${v}` })),
        ),
        pipe(
          O.some(query.sort),
          O.filter((a) => a !== DEFAULT_SORT),
          O.map((v) => ({ k: 'sort', v })),
        ),
        pipe(
          O.some(query.page),
          O.filter((a) => a > 1),
          O.map((v) => ({ k: 'page', v: v.toString() })),
        ),
      ],
      A.compact,
      kvToUrlQuery,
    )
  },
  decode: (q: ParsedUrlQuery): Query => {
    return {
      filters: {
        status: pipe(
          statusDecoder.decode(q['status']),
          O.fromEither,
          O.toUndefined,
        ),
        contract: (() => {
          const raw = D.string.decode(q['contract'])
          if (E.isLeft(raw)) return undefined
          const parts = raw.right.split('_')
          if (parts.length !== 2) return undefined
          return pipe(
            O.Do,
            O.bind('network_id', () =>
              pipe(intFromStringDecoder.decode(parts[0]), O.fromEither),
            ),
            O.bind('address', () => O.some(parts[1])),
            O.toUndefined,
          )
        })(),
        network: pipe(
          intFromStringDecoder.decode(q['network']),
          O.fromEither,
          O.toUndefined,
        ),
      },
      sort: pipe(
        sortDecoder.decode(q['sort']),
        O.fromEither,
        O.getOrElse((): Sort => DEFAULT_SORT),
      ),
      page: pipe(
        intFromStringDecoder.decode(q['page']),
        O.fromEither,
        O.filter((a) => a > 1),
        O.getOrElse(() => 1),
      ),
    }
  },
}

export const filterLabelsVMGen = ({
  filters,
  setFilters,
  contracts,
}: {
  filters: Filters
  setFilters: (a: Filters) => void
  contracts: Contract[]
}) => {
  return {
    options: A.compact([
      pipe(
        O.fromNullable(filters?.contract),
        O.map((src) => ({
          title: pipe(
            contracts,
            A.findFirst(
              (a) =>
                a.network_id === src.network_id && a.address === src.address,
            ),
            O.chainNullableK((a) => a.name),
            O.getOrElse(() => fmtEthAddr(src.address)),
          ),
          onDel: () => {
            setFilters({ ...filters, contract: undefined })
          },
        })),
      ),
    ]),
    onClear: () => setFilters({}),
  }
}
