import dayjs from 'dayjs'
import { BigNumber, constants, utils } from 'ethers'
import { Trans, useTranslation } from 'next-i18next'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm, UseFormWatch } from 'react-hook-form'

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  FormLabelLike,
  Input,
  PopoverSelect,
} from '@/components/form'
import { metadataAssetsAsNftImageProps, NftImage } from '@/components/media'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tooltip,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { UseDisclosureReturn, useLoading, UseModalReturn } from '@/hooks'
import { useExpireOption, usePaymentInfo } from '@/hooks/nft'
import { ShoppingTagLine } from '@/icons'
import { RegisteredUser } from '@/lib/auth/types/user'
import { fmtPercentage } from '@/lib/formatter'
import { validateBigNumber } from '@/lib/formik'
import {
  checkTransfer,
  getErrorMessage,
  INTENT_SELL,
  sell,
  TOKEN_1155,
} from '@/lib/market'
import { Bundle, OrderInfo } from '@/lib/nft/detail'
import toast from '@/lib/toast'
import { getTokenMeta } from '@/lib/token'

import {
  FiatPricePreview,
  FloorPriceDesc,
  getMinFloorPrice,
  PriceBreakdown,
} from '../shared'
import { ApproveNftProps } from './ApproveNftModal'
import { FloorPrice } from './FloorWarningModal'
import {
  calcChangePercentage,
  ListFloorWarningProps,
} from './ListFloorWarningModal'

export const getFeePercent = (feeRate: number) => feeRate / 10000

type Inputs = {
  price: string
  taker: string
}

type FeePreviewProps = {
  orderInfo: OrderInfo | undefined
  watch: UseFormWatch<Inputs>
}
const FeePreview = ({ orderInfo, watch }: FeePreviewProps) => {
  const { t } = useTranslation()
  const taker = watch('taker')
  const isPrivate = utils.isAddress(taker)
  const marketFeePercent = orderInfo
    ? getFeePercent(isPrivate ? 0 : orderInfo.market_fee_rate)
    : null
  const creatorFeePercent = orderInfo
    ? getFeePercent(isPrivate ? 0 : orderInfo.royalty_fee_rate)
    : null
  return (
    <div className="space-y-3">
      <FormLabelLike>{t('Fees')}</FormLabelLike>
      <p className="ts-caption-2 text-gray-500 lg:ts-caption">
        {t('Listing is free. Once sold, the following fees will be deducted.')}
      </p>
      <PriceBreakdown
        lines={[
          {
            label: t('Market Fee'),
            tip: t('Fee to X2Y2'),
            percent: marketFeePercent,
          },
          {
            label: t('Tips for Creator'),
            tip: t('Tips go to the creator'),
            percent: creatorFeePercent,
          },
        ]}
      />
    </div>
  )
}

type ListPreviewProps = PropsWithChildren<{
  symbol: string
  decimals: number
  orderInfo: OrderInfo | undefined
  watch: UseFormWatch<Inputs>
}>
const ListPreview = ({
  symbol,
  decimals,
  orderInfo,
  watch,
  children,
}: ListPreviewProps) => {
  const { t } = useTranslation()
  const price = watch('price')
  const taker = watch('taker')
  const isPrivate = utils.isAddress(taker)
  const marketFeeRate = orderInfo && !isPrivate ? orderInfo.market_fee_rate : 0
  // TODO: share logic in previews and more?
  const creatorFeeRate =
    orderInfo && !isPrivate ? orderInfo.royalty_fee_rate : 0
  const previewPrice = (price: string) => {
    try {
      const bn = utils.parseUnits(price, decimals)
      return utils.formatUnits(bn, decimals)
    } catch {
      return '0.0'
    }
  }
  const previewReceive = (price: string) => {
    try {
      const bn = utils.parseUnits(price, decimals)
      return utils.formatUnits(
        bn.mul(1000000 - marketFeeRate - creatorFeeRate).div(1000000),
        decimals,
      )
    } catch {
      return '0.0'
    }
  }
  return (
    <footer className="hidden space-x-6 rounded-b-20 bg-modal-bg p-8 lg:flex">
      <p className="ts-body-2 ml-auto self-center text-gray-500">
        {t('List the item at {{price}}, receive {{receive}} when sold', {
          price: `${previewPrice(price)} ${symbol}`,
          receive: `${previewReceive(price)} ${symbol}`,
        })}
      </p>
      {children}
    </footer>
  )
}

export type ListProps = {
  user: RegisteredUser
  bundle: Bundle
  initialPrice?: BigNumber
  floorPrice: FloorPrice | null
  osFloorPrice: number
  approveNftModal: UseModalReturn<ApproveNftProps>
  floorWarningModal: UseModalReturn<ListFloorWarningProps>
  onDone?: () => void
}
type Props = ListProps & {
  disclosure: UseDisclosureReturn
}
export const ListModal = ({
  disclosure,
  user,
  bundle,
  initialPrice = constants.Zero,
  floorPrice,
  osFloorPrice,
  approveNftModal,
  floorWarningModal,
  onDone,
}: Props) => {
  const { t } = useTranslation()

  const divider = <hr className="hidden h-auto w-px border-l lg:block" />

  const [currency, setCurrency] = useState<string | null>(null)
  const expire = useExpireOption()

  const isBundle = bundle.length > 1
  const { kind: tokenKind } = bundle[0].tokenPair
  const { networkId } = bundle[0].info
  const { symbol, decimals } = getTokenMeta(networkId, currency ?? '')

  const [isFocused, setFocused] = useState(false)

  const minFloorPrice = floorPrice
    ? getMinFloorPrice(floorPrice, osFloorPrice)
    : null

  const { isLoading, setLoading, guardLoading, reloadPage } = useLoading()
  const onSign =
    (price: BigNumber, taker: string) =>
    async (setLoading: (a: boolean) => void, approveModalCb: () => void) => {
      if (!currency) return
      try {
        setLoading(true)
        const tokens = bundle.map((a) => a.tokenPair)
        const deadline = expire.value
        const royalty = orderInfo ? orderInfo.royalty_fee_rate : 0
        await sell({
          networkId,
          user,
          deadline,
          currency,
          items: [{ price, tokens, royalty }],
          isBundle,
          bundleName: '',
          bundleDesc: '',
          isPrivate: !!taker,
          taker,
        })
        onDone ? onDone() : await reloadPage()
        approveModalCb() // Show done in approve modal
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
        const error = getErrorMessage(t, e)
        const nft = bundle[0].tokenPair
        toast({
          status: 'error',
          title: (
            <ReportToast
              user={user.web3Provider.getSigner()}
              networkId={networkId}
              tokenContract={nft.token}
              tokenId={nft.tokenId}
              error={error}
            />
          ),
        })
      }
    }

  const nft = bundle[0]
  const tokenContract = nft.tokenPair.token
  const contracts = useMemo(() => [tokenContract], [tokenContract])
  const paymentInfo = usePaymentInfo({
    isActive: disclosure.isOpen,
    user,
    networkId,
    contracts,
  }).paymentInfo
  const orderInfo = paymentInfo?.[tokenContract]

  const currencies = useMemo(
    () => orderInfo?.supported_tokens[INTENT_SELL] ?? [],
    [orderInfo],
  )
  useEffect(() => {
    if (currencies.length === 0) return
    setCurrency(currencies[0])
  }, [currencies])

  const submitButton = (
    <Button
      className="GA-list"
      colorScheme="primary-1"
      isLoading={isLoading}
      type="submit"
    >
      {<ShoppingTagLine className="mr-3 h-4 w-4" />}
      {t('List')}
    </Button>
  )

  const defaultValues = useMemo(
    () => ({
      price: !initialPrice.isZero()
        ? utils.formatUnits(initialPrice, decimals)
        : '',
      taker: '',
    }),
    [initialPrice, decimals],
  )
  const {
    register,
    watch,
    handleSubmit,
    setError,
    formState: { touchedFields, errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues,
  })

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    const minExpire = dayjs().add(15, 'minutes')
    if (dayjs(expire.value * 1000).isBefore(minExpire)) {
      toast({
        status: 'error',
        title: t('The expiration date has to be 15 minutes later.'),
      })
      return
    }
    const price = utils.parseUnits(values.price, decimals)
    const taker = values.taker
    if (taker && !utils.isAddress(taker)) {
      setError('taker', { message: t('Invalid address') })
      return
    }
    setLoading(true)
    try {
      const isOk = await checkTransfer(
        networkId,
        nft.tokenPair.token,
        tokenKind,
        bundle.map((a) => a.tokenPair.tokenId),
        user.meta.address,
      )
      if (!isOk) {
        toast({
          status: 'error',
          title: t('Trading cancelled. An issue is found in the NFT contract.'),
        })
        return
      }
      const onContinue = () => {
        if (!currency) return // TODO: review
        disclosure.onClose()
        approveNftModal.onOpen({
          provider: user.web3Provider,
          networkId,
          tokenKind,
          contracts: [{ contract: tokenContract, name: nft.info.creator }],
          item: {
            ...bundle[0],
            price: utils.formatUnits(price, decimals),
            currency,
          },
          title: t('List item(s) for sale', { count: 1 }),
          subtitle: t('Complete listing'),
          desc: t('Accept the signing request with your selling info.'),
          loadingText: t('Waiting for signature…'),
          actionText: t('Sign'),
          doneText: t('Your item(s) is ready', { count: 1 }),
          onAction: onSign(price, taker),
        })
      }
      if (
        minFloorPrice &&
        parseFloat(values.price) < minFloorPrice.value * 0.75
      ) {
        floorWarningModal.onOpen({
          price,
          floorPrice: minFloorPrice,
          onContinue,
        })
      } else {
        onContinue()
      }
    } finally {
      setLoading(false)
    }
  }

  const price = watch('price')
  const err =
    errors.price?.message ||
    ((touchedFields.price || isFocused) &&
    minFloorPrice &&
    parseFloat(price) < minFloorPrice.value
      ? t('{{percentage}} lower than floor price', {
          percentage: fmtPercentage(
            calcChangePercentage(minFloorPrice.value, parseFloat(price)),
          ),
        })
      : null)

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
    >
      <ModalOverlay />
      {/* Make some room for date picker */}
      <ModalContent
        className="sm:max-w-[30em] lg:mb-[60px] lg:mt-[120px] lg:h-auto lg:max-w-[1120px] lg:rounded-20"
        size="full"
      >
        <ModalBody className="!p-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <header className="ts-headline-6 sticky top-0 z-10 rounded-t-20 bg-modal-bg p-6 lg:static lg:ts-headline-4 lg:p-8">
              <p className="py-2 lg:py-0">{t('List your NFT')}</p>
              <ModalCloseButton />
              <div className="mt-6 -mb-6 flex flex-col border-b pb-6 lg:hidden">
                {submitButton}
              </div>
            </header>
            <div className="flex flex-col space-y-6 p-6 lg:flex-row lg:space-x-6 lg:space-y-0 lg:px-8 lg:py-0">
              <div className="flex flex-row space-x-3 lg:max-w-[256px] lg:flex-col lg:space-x-0 lg:space-y-2">
                <NftImage
                  className="h-20 w-20 lg:h-[256px] lg:w-[256px]"
                  {...metadataAssetsAsNftImageProps(nft.info.asset)}
                />
                <div>
                  <p className="ts-caption-2 truncate text-gray-500">
                    {nft.info.creator}
                  </p>
                  <p className="ts-body-2 truncate font-bold">
                    {nft.info.name}
                  </p>
                </div>
              </div>
              {divider}
              <div className="flex min-w-0 flex-1 flex-col space-y-6 lg:space-y-8">
                <FormControl>
                  <FormLabel htmlFor="price">{t('Price')}</FormLabel>
                  <div className="flex space-x-4">
                    <Tooltip
                      isOpen={!!err}
                      label={err}
                      variant="error"
                      placement="top"
                      hasArrow
                    >
                      <Input
                        id="price"
                        placeholder="0.0"
                        {...register('price', {
                          validate: validateBigNumber(t, decimals),
                        })}
                        isInvalid={!!(errors.price && touchedFields.price)}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        isDisabled={currencies.length === 0}
                        autoComplete="off"
                      />
                    </Tooltip>
                    <PopoverSelect
                      className="h-12 max-w-[100px]"
                      items={currencies.map((a) => ({
                        key: a,
                        title: getTokenMeta(networkId, a).symbol,
                        value: a,
                      }))}
                      value={currency ?? ''}
                      onChange={setCurrency}
                    />
                  </div>
                  <FiatPricePreview {...{ price, symbol }} />
                  {floorPrice && (
                    <FloorPriceDesc
                      className="mt-1"
                      floorPrice={floorPrice}
                      osFloorPrice={osFloorPrice}
                    />
                  )}
                </FormControl>
                {expire.control}
              </div>
              {divider}
              <div className="flex min-w-0 flex-1 flex-col space-y-6 lg:space-y-8">
                <FormControl>
                  <FormLabel htmlFor="taker">{t('Private Sale')}</FormLabel>
                  <Tooltip
                    label={tokenKind === TOKEN_1155 ? t('Coming soon…') : null}
                    placement="top"
                    hasArrow
                    shouldWrapChildren
                  >
                    <Input
                      id="taker"
                      spellCheck={false}
                      placeholder="0x..."
                      {...register('taker')}
                      isDisabled={tokenKind === TOKEN_1155}
                      isInvalid={!!(errors.taker && touchedFields.taker)}
                    />
                  </Tooltip>
                  {errors.taker && (
                    <FormHelperText isError>
                      {errors.taker?.message}
                    </FormHelperText>
                  )}
                  <FormHelperText>
                    <Trans t={t}>
                      Only the specified address can buy your item. There’s no
                      fee or royalty for private sales.
                    </Trans>
                  </FormHelperText>
                </FormControl>
                <FeePreview {...{ orderInfo, watch }} />
              </div>
            </div>
            <ListPreview {...{ symbol, decimals, orderInfo, watch }}>
              {submitButton}
            </ListPreview>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
