import { BigNumber, utils } from 'ethers'
import { Trans, useTranslation } from 'next-i18next'

import { UseDisclosureReturn } from '@/hooks'
import { fmtPercentage } from '@/lib/formatter'

import { FloorPrice, FloorWarningModal } from './FloorWarningModal'

export const calcChangePercentage = (base: number, value: number) =>
  ((base - value) * 100) / base

export type ListFloorWarningProps = {
  price: BigNumber
  floorPrice: FloorPrice
  onContinue: () => void
}
type Props = ListFloorWarningProps & {
  disclosure: UseDisclosureReturn
}
export const ListFloorWarningModal = ({
  disclosure,
  price,
  floorPrice,
  onContinue,
}: Props) => {
  const { t } = useTranslation()
  return (
    <FloorWarningModal
      disclosure={disclosure}
      title={t('Low Price Warning')}
      desc={
        <Trans t={t}>
          Are you sure to list at a price{' '}
          <span className="text-primary-3">
            {{
              percentage: fmtPercentage(
                calcChangePercentage(
                  floorPrice.value,
                  parseFloat(utils.formatEther(price)),
                ),
              ),
            }}{' '}
            lower
          </span>{' '}
          than the floor price of the collection?
        </Trans>
      }
      action={t('Continue Listing')}
      onContinue={onContinue}
    />
  )
}
