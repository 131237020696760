import { BigNumber, utils } from 'ethers'
import { pipe } from 'fp-ts/lib/function'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { Button } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { useCart } from '@/components/shoppingCart/CartProvider'
import { ContractMeta } from '@/components/shoppingCart/SuccessModal'
import { UseDisclosureReturn, useLoading, UseModalReturn } from '@/hooks'
import { usePayWithBoth } from '@/hooks/nft'
import { ShoppingBagLine } from '@/icons'
import { ReactComponent as BendDAO } from '@/images/BendDAO.svg'
import { isSameAddress, RegisteredUser } from '@/lib/auth/types/user'
import { fmtNumber } from '@/lib/formatter'
import { NEA } from '@/lib/fp'
import { http } from '@/lib/http'
import { buyNow, getErrorMessage } from '@/lib/market'
import { Bundle } from '@/lib/nft/detail'
import { getListingSupport } from '@/lib/opensea'
import toast from '@/lib/toast'
import { getTokenMeta } from '@/lib/token'
import { OpenSeaMetadata } from '@/lib/unimeta'
import { WETH_CONTRACT_MAPS } from '@/lib/x2y2'
import { parseKindToTokenStandard } from '@/lib/x2y2/utils'
import { DEFAULT_NETWORK } from '@/utils/network'

import { NftItemList, PayWithBoth, WarningLabel } from '../shared'
import { ApproveTokenProps } from './ApproveTokenModal'

const wethContract = WETH_CONTRACT_MAPS[DEFAULT_NETWORK]

type BendDAOItem = {
  symbol: string
  underlyingAsset: string
}

export type BundleWithMeta = NEA.NonEmptyArray<
  Bundle[0] & { meta: OpenSeaMetadata }
>

export type BuyProps = {
  user: RegisteredUser
  bundle: BundleWithMeta
  orderId: number
  price: BigNumber
  currency: string
  royaltyFee: number
  isPrivate: boolean
  contractMeta: ContractMeta
  approveTokenModal: UseModalReturn<ApproveTokenProps>
}
type Props = BuyProps & {
  disclosure: UseDisclosureReturn
}
export const BuyModal = ({
  disclosure,
  user,
  bundle,
  orderId,
  price,
  currency,
  royaltyFee,
  isPrivate,
  contractMeta,
  approveTokenModal,
}: Props) => {
  const { t } = useTranslation()

  const { token: tokenContract, tokenId } = bundle[0].tokenPair
  const { creator, verified, networkId } = bundle[0].info
  const { decimals } = getTokenMeta(networkId, currency)
  const { isLoading, setLoading, guardLoading } = useLoading()

  const totalPrice = price
  const {
    balanceEth,
    balanceWeth,
    payWithEth,
    setPayWithEth,
    payWithWeth,
    setPayWithWeth,
    amountToEth,
    amountToWeth,
    amountTotal,
    amountInsufficient,
  } = usePayWithBoth({ isActive: disclosure.isOpen, totalPrice })
  const isInsufficientBalance = amountInsufficient.gt(0)
  const { openSuccessModal } = useCart()

  const { data } = useSWR('/uniapi/bendao/nfts', (url) =>
    http.get<{ data: { nfts: BendDAOItem[] } }>(url, {
      baseURL: process.env.NEXT_PUBLIC_X2Y2_API_BASE_URL,
    }),
  )
  const bendDAOItems = data?.data.data.nfts ?? []
  const isBendDAOItem = bendDAOItems.some((a) =>
    isSameAddress(a.underlyingAsset, tokenContract),
  )

  const [isOsSupported, setOsSupported] = useState(true)
  useEffect(() => {
    if (!disclosure.isOpen) return
    const action = async () => {
      const ls = await getListingSupport(tokenContract, tokenId)
      setOsSupported(ls)
    }
    action()
  }, [tokenContract, tokenId, disclosure.isOpen])
  const showWarning = bundle.length === 1 && !isOsSupported

  const calcFee = (fee: number) => (!isPrivate ? fee : 0)

  const onBuy = async (
    setLoading: (a: boolean) => void,
    approveModalCb?: () => void,
  ) => {
    setLoading(true)
    let toastId = undefined
    try {
      const tx = await buyNow({
        networkId,
        user,
        amountToEth,
        amountToWeth,
        items: [
          {
            orderId,
            contract: tokenContract,
            tokenId,
            currency,
            price,
            royalty: calcFee(royaltyFee),
          },
        ],
        handleWarning: (error: unknown) => {
          console.warn(`Something's wrong`, error)
          toast({
            status: 'warning',
            title: t(`Something's wrong`),
          })
        },
      })
      setLoading(false)
      approveModalCb?.() // Close approve modal
      disclosure.onClose()
      toastId = toast({
        status: 'loading',
        title: t('Waiting for transaction…'),
      })
      await tx.wait()
      // const txResult = await checkTxResult(
      //   DEFAULT_NETWORK,
      //   tx.hash,
      //   [{ tag: 'x2y2', price: utils.formatEther(price) }],
      //   { amountToEth, amountToWeth },
      //   false,
      // )
      const onView = () =>
        openSuccessModal({
          items: [
            {
              id: orderId,
              price: utils.formatEther(price),
              currency,
              contract: tokenContract,
              contractName: creator,
              contractVerified: verified ?? false,
              tokenId,
              tokenStandard: parseKindToTokenStandard(bundle[0].tokenPair.kind),
              meta: bundle[0].meta,
              success: true,
            },
          ],
          osItems: [],
          total: {
            amountToEth,
            amountToWeth,
            amountTotal,
          },
          txHash: tx.hash,
          contractMeta,
        })
      toast({
        status: 'success',
        title: (current) => (
          <div className="flex items-center space-x-3">
            <p>{t("You've successfully bought the NFT.")}</p>
            <Button
              className="!h-8"
              colorScheme="primary-1"
              size="sm"
              onClick={() => {
                toast.close(current.id)
                onView()
              }}
            >
              {t('View')}
            </Button>
          </div>
        ),
      })
    } catch (e) {
      console.error(e)
      setLoading(false)
      const error = getErrorMessage(t, e)
      toast({
        status: 'error',
        title: (
          <ReportToast
            user={user.web3Provider.getSigner()}
            networkId={networkId}
            tokenContract={tokenContract}
            tokenId={tokenId}
            error={error}
          />
        ),
      })
    } finally {
      toastId && toast.close(toastId)
    }
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Buy')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col">
          <NftItemList
            bundle={pipe(
              bundle,
              NEA.map((a) => ({
                ...a,
                price: utils.formatUnits(price, decimals),
                currency,
              })),
            )}
          />
          {showWarning && (
            <div className="mt-2 flex">
              <WarningLabel />
            </div>
          )}
          <PayWithBoth
            className="mt-6 lg:mt-8"
            style="buy-modal"
            {...{
              balanceEth,
              balanceWeth,
              payWithEth,
              payWithWeth,
              setPayWithEth,
              setPayWithWeth,
              amountToEth,
              amountToWeth,
              amountTotal,
            }}
          />
          <Button
            className="mt-6 lg:mt-8"
            colorScheme="primary-1"
            leftIcon={<ShoppingBagLine className="h-4 w-4" />}
            isLoading={isLoading}
            isDisabled={isInsufficientBalance}
            onClick={
              amountToWeth.isZero()
                ? () => onBuy(setLoading)
                : () => {
                    disclosure.onClose()
                    approveTokenModal.onOpen({
                      provider: user.web3Provider,
                      networkId,
                      currency: wethContract,
                      amount: amountToWeth,
                      ethAmount: amountToEth,
                      title: t('Buy'),
                      subtitle: t('Complete payment'),
                      desc: t('Click the Pay button to finish your payment.'),
                      loadingText: t('Waiting for transaction…'),
                      stayWhenDone: false,
                      actionText: t('Pay'),
                      onAction: onBuy,
                    })
                  }
            }
          >
            {isInsufficientBalance
              ? t('Insufficient funds: {{value}}', {
                  value: `${fmtNumber(
                    parseFloat(utils.formatEther(amountInsufficient)),
                    { max: 4 },
                  )} ETH`,
                })
              : t('Buy')}
          </Button>
          {isBendDAOItem && (
            <>
              <div className="flex items-center space-x-4 py-4">
                <hr className="flex-1" />
                <p className="ts-hairline-3 text-gray-400">Or</p>
                <hr className="flex-1" />
              </div>
              <Button
                variant="outline"
                colorScheme="primary-1"
                onClick={() => {
                  const url = `https://www.benddao.xyz/app/liquidity/buy/down-payment/x2y2/${tokenContract}/${tokenId}`
                  window.open(url, '_blank')
                  disclosure.onClose()
                }}
              >
                <Trans t={t}>
                  Down Payment via{' '}
                  <BendDAO className="ml-2 h-4" aria-label="BendDAO" />
                </Trans>
              </Button>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
