import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  Side,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import {
  Children,
  cloneElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react'
import mergeRefs from 'react-merge-refs'

const noScale: Variants = {
  exit: {
    opacity: 0,
    transition: {
      opacity: { duration: 0.15, easings: 'easeInOut' },
    },
  },
  enter: {
    opacity: 1,
    transition: {
      opacity: { duration: 0.2, easings: 'easeOut' },
    },
  },
}

type Variant = 'invert' | 'error'

export const baseStyles: Record<Variant, string> = {
  invert: /*tw:*/ 'bg-gray-900 text-gray-400',
  error: /*tw:*/ 'bg-primary-3 text-button-text',
}

type Props = PropsWithChildren<{
  className?: string
  variant?: Variant
  /**
   * The React component to use as the trigger for the tooltip.
   */
  children: ReactNode
  /**
   * The label of the tooltip.
   */
  label?: ReactNode
  /**
   * The placement of the popper relative to its reference.
   * @default "top"
   */
  placement?: Placement
  /**
   * If `true`, the tooltip will wrap its children
   * in a `<span/>` with `tabIndex=0`.
   */
  shouldWrapChildren?: boolean
  /**
   * If `true`, the tooltip will show an arrow tip
   */
  hasArrow?: boolean
  /**
   * The distance or margin between the reference and popper.
   * It is used internally to create an `offset` modifier.
   */
  gutter?: number
  /** If `true`, the tooltip will be kept open */
  isOpen?: boolean
}>
export const Tooltip = ({
  className,
  children,
  variant = 'invert',
  label,
  placement = 'top',
  shouldWrapChildren = false,
  hasArrow = false,
  gutter = 8,
  isOpen,
}: Props) => {
  const arrowRef = useRef<HTMLSpanElement>(null)
  const [open, setOpen] = useState(false)

  const {
    x,
    y,
    placement: finalPlacement,
    reference,
    floating,
    strategy,
    context,
    middlewareData,
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(gutter),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef, padding: 8 }),
    ],
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ])

  // Preserve the consumer's ref
  const ref = useMemo(
    () => mergeRefs([reference, (children as any).ref]),
    [reference, children],
  )

  const shouldWrap = typeof children === 'string' || shouldWrapChildren
  let trigger: ReactElement
  if (shouldWrap) {
    trigger = (
      <span tabIndex={0} {...getReferenceProps({ ref })}>
        {children}
      </span>
    )
  } else {
    // Ensure tooltip has only one child node
    const child = Children.only(children) as React.ReactElement
    trigger = cloneElement(child, getReferenceProps({ ref, ...child.props }))
  }

  // If the `label` is empty, there's no point showing the tooltip.
  // Let's simply return the children
  if (!label) {
    return <>{children}</>
  }

  const arrowX = middlewareData.arrow?.x
  const arrowY = middlewareData.arrow?.y
  const staticSideOfFloating = finalPlacement.split('-')[0] as Side
  const staticSideOfArrowMap: Record<Side, Side> = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }
  const staticSideOfArrow = staticSideOfArrowMap[staticSideOfFloating]

  return (
    <>
      {trigger}
      <FloatingPortal>
        <AnimatePresence>
          {(open || isOpen) && (
            <motion.div
              className={clsx(
                className,
                'ts-caption z-[1800] max-w-[352px] rounded-lg px-4 py-2 opacity-50 shadow-lg',
                baseStyles[variant],
              )}
              variants={noScale}
              initial="exit"
              animate="enter"
              exit="exit"
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
              })}
            >
              {hasArrow && (
                <span
                  className={clsx(
                    'absolute h-2 w-2 rotate-45',
                    baseStyles[variant],
                  )}
                  ref={arrowRef}
                  style={{
                    left: arrowX ? `${arrowX}px` : undefined,
                    top: arrowY ? `${arrowY}px` : undefined,
                    [staticSideOfArrow]: '-4px',
                  }}
                />
              )}
              {label}
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}
