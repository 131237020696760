import { pipe } from 'fp-ts/lib/function'
import { ParsedUrlQuery } from 'querystring'

import { SearchPayload as ActivitiesSearchPayload } from '@/lib/es/activity'
import { EsMapping } from '@/lib/es/mapping'
import { SearchPayload as OrdersSearchPayload } from '@/lib/es/nft'
import { A } from '@/lib/fp'
import { basicURLQueryDecode, basicURLQueryKVGen } from '@/lib/search/nft'
import {
  activityCategoriesURLQueryCodec,
  kvToUrlQuery,
} from '@/lib/search/utils'

export const itemsTabUrlQueryCodec = {
  decode: (
    q: ParsedUrlQuery,
    esMapping: EsMapping,
  ): Pick<OrdersSearchPayload, 'sort' | 'filters'> => {
    const query = basicURLQueryDecode(q, esMapping)
    if (!query.sort) query.sort = 'price_asc'
    return query
  },
  encode: (query: Pick<OrdersSearchPayload, 'sort' | 'filters'>) =>
    pipe(basicURLQueryKVGen(query, 'price_asc'), kvToUrlQuery),
}

export const activitiesTabUrlQueryCodec = {
  decode: (q: ParsedUrlQuery): Pick<ActivitiesSearchPayload, 'filters'> => {
    return {
      filters: {
        categories: activityCategoriesURLQueryCodec.decode(q),
      },
    }
  },
  encode: (query: ActivitiesSearchPayload) => {
    return pipe(
      [pipe(activityCategoriesURLQueryCodec.encode(query.filters?.categories))],
      A.compact,
      kvToUrlQuery,
    )
  },
}

export type PageUrlPayload = (
  | {
      tab: 'items'
      query?: Pick<OrdersSearchPayload, 'sort' | 'filters'>
    }
  | {
      tab: 'activities'
      query?: Pick<ActivitiesSearchPayload, 'filters'>
    }
  | { tab: 'data' }
  | { tab: 'holders' }
  | { tab: 'edit' }
) & { slug: string }

export const pageURL = (payload: PageUrlPayload) => {
  const query = (() => {
    switch (payload.tab) {
      case 'items':
        return itemsTabUrlQueryCodec.encode(payload.query ?? {})
      case 'activities':
        return activitiesTabUrlQueryCodec.encode(payload.query ?? {})
      case 'data':
      case 'holders':
      case 'edit':
        return ''
    }
  })()
  return `/collection/${payload.slug}/${payload.tab}${query}`
}
