import { ColorMode } from './types'

export const STORAGE_KEY = 'chakra-ui-color-mode'

type MaybeColorMode = ColorMode | undefined

export interface StorageManager {
  type: 'localStorage'
  ssr?: boolean
  get(init?: ColorMode): MaybeColorMode
  set(value: ColorMode | 'system'): void
}

export function createLocalStorageManager(key: string): StorageManager {
  return {
    ssr: false,
    type: 'localStorage',
    get(init?) {
      if (!globalThis?.document) return init
      let value: any
      try {
        value = localStorage.getItem(key) || init
      } catch (ignored) {}

      return value || init
    },
    set(value) {
      try {
        localStorage.setItem(key, value)
      } catch (ignored) {}
    },
  }
}

export const localStorageManager = createLocalStorageManager(STORAGE_KEY)
