import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'

export const useLoading = () => {
  const router = useRouter()
  const [isLoading, setLoading] = useState(false)

  const guardLoading = useCallback(
    (action: () => void) => () => {
      if (isLoading) return
      action()
    },
    [isLoading],
  )

  const reloadPage = useCallback(
    () => router.replace(router.asPath, undefined, { scroll: false }),
    [router],
  )

  return { isLoading, setLoading, guardLoading, reloadPage }
}
