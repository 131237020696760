import clsx from 'clsx'
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactElement,
} from 'react'

import { Spinner } from '../Spinner'

type Variant = 'solid' | 'outline' | 'ghost' | 'toolbar'
type ColorScheme = 'gray' | 'primary-1' | 'primary-3'

const baseStyles: Record<Variant, string> = {
  solid:
    /*tw:*/ 'inline-flex relative justify-center items-center rounded-lg disabled:opacity-40 disabled:cursor-not-allowed focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    /*tw:*/ 'inline-flex relative justify-center items-center border-2 disabled:opacity-40 disabled:cursor-not-allowed',
  ghost:
    /*tw:*/ 'inline-flex relative justify-center items-center rounded-full disabled:cursor-not-allowed',
  toolbar:
    /*tw:*/ 'inline-flex relative justify-center items-center rounded-full disabled:cursor-not-allowed',
}

const variantStyles: Record<Variant, Partial<Record<ColorScheme, string>>> = {
  solid: {
    'primary-1':
      /*tw:*/ 'bg-primary-1 text-button-text hover:enabled:bg-primary-1-600',
  },
  outline: {
    gray: /*tw*/ 'text-gray-700 hover:text-gray-100 hover:bg-gray-800 hover:border-gray-800 disabled:text-gray-100 disabled:bg-gray-800 disabled:border-gray-800',
  },
  ghost: {
    gray: /*tw:*/ 'hover:enabled:opacity-50 disabled:text-gray-500',
    'primary-1': /*tw:*/ 'text-primary-1 hover:enabled:opacity-50',
    'primary-3': /*tw:*/ 'text-primary-3 hover:enabled:opacity-50',
  },
  toolbar: {
    gray: /*tw:*/ 'hover:enabled:bg-modal-section-bg disabled:text-gray-500',
  },
}

type IconButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'disabled' | 'aria-label'
> & {
  variant?: Variant
  colorScheme?: ColorScheme
  icon: ReactElement
  'aria-label': string
  isLoading?: boolean
  isDisabled?: boolean
}
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      variant = 'ghost',
      colorScheme = 'gray',
      icon,
      type = 'button',
      isLoading,
      isDisabled,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        className={clsx(
          className,
          baseStyles[variant],
          variantStyles[variant][colorScheme],
        )}
        ref={ref}
        type={type}
        disabled={isDisabled || isLoading}
        {...props}
      >
        {isLoading && (
          <span className="absolute inset-0 flex items-center justify-center">
            {/* TODO: can use a custom one after all buttons migrated */}
            <Spinner className="h-[1em] w-[1em]" />
          </span>
        )}
        {isLoading ? <span className="opacity-0">{icon}</span> : icon}
      </button>
    )
  },
)
IconButton.displayName = 'IconButton'
