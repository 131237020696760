import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'next-i18next'

import { Empty, Loading } from '@/components/feedback'
import { O, RD, TE } from '@/lib/fp'

import useOptionalTask, { Options } from './useOptionalTask'

export const useRemoteDataView = <E, A>(
  task: O.Option<TE.TaskEither<E, A>>,
  render: (a: A, reload: () => void) => JSX.Element | null,
  options: Options & { loadingView?: JSX.Element } = {},
) => {
  const { t } = useTranslation()
  const [data, reload] = useOptionalTask(task, options)
  return pipe(
    data,
    RD.fold(
      () => null,
      () => {
        if (options.loadingView) return options.loadingView
        return <Loading />
      },
      () => (
        <Empty
          label={t('An error has occurred') + ' (ERR-002)'}
          button={{
            title: t('Reload'),
            onClick: () => reload(),
          }}
        />
      ),
      (data) => render(data, reload),
    ),
  )
}
