import * as ethers from 'ethers'
import { constVoid, pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'next-i18next'
import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import { useDisclosure } from '@/hooks'
import * as api from '@/lib/api'
import { useAuth } from '@/lib/auth'
import { RegisteredUser, Web3RegisteredUser } from '@/lib/auth/types/user'
import { E } from '@/lib/fp'
import toast from '@/lib/toast'

import { Button } from '../form'

type State =
  | {
      tag: 'noAsk'
    }
  | {
      tag: 'init'
      user: Web3RegisteredUser
    }
  | {
      tag: 'pending'
    }
  | {
      tag: 'failure'
    }

export type OnSuccess = (u: RegisteredUser) => void

export type Handler = {
  show: (user: Web3RegisteredUser, onSuccess?: OnSuccess) => void
}

const UserSignModal: ForwardRefRenderFunction<Handler, unknown> = (_, ref) => {
  const disclosure = useDisclosure()
  const { serverSignIn } = useAuth()
  const [state, setState] = useState<State>({ tag: 'noAsk' })

  const { t } = useTranslation()

  const onSuccess = useRef<OnSuccess>(constVoid)

  const onConfirmed = async (user: Web3RegisteredUser) => {
    setState({ tag: 'pending' })
    const address = user.meta.address
    const addressMsgToSignResp = await api.addressMsgToSign({ address })()
    if (E.isLeft(addressMsgToSignResp)) throw new Error('Server bad response')
    const { id, msg } = addressMsgToSignResp.right
    const isEOA = (await user.web3Provider.getCode(user.meta.address)) === '0x'
    const signedMsg: string = await user.web3Provider.send('personal_sign', [
      ethers.utils.hexlify(ethers.utils.toUtf8Bytes(msg)),
      address,
    ])
    const signInResp = await api.signIn({
      id,
      address: address,
      signedMsg,
      isEOA,
    })()
    pipe(
      signInResp,
      E.fold(
        () => {
          toast({
            title: t('Signing Failed'),
            status: 'warning',
          })
        },
        ({ refreshToken }) => {
          serverSignIn({
            refreshToken,
            web3RegisteredUser: user,
          })
            .then((a) => {
              if (a.success) {
                onSuccess.current(a.user)
                onSuccess.current = constVoid
              }
            })
            .catch((e) => console.error(e))
        },
      ),
    )
  }
  const startBtnRef = useRef<HTMLButtonElement>(null)

  useImperativeHandle(ref, () => ({
    show: (user, newOnSuccess) => {
      setState({ tag: 'init', user })
      onSuccess.current = newOnSuccess ?? constVoid
      disclosure.onOpen()
    },
  }))

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      initialFocus={startBtnRef}
    >
      <ModalOverlay />
      <ModalContent className="!max-w-sm">
        <ModalHeader>{t('Verify Address')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {state.tag === 'init' && (
            <div className="flex flex-col gap-6">
              <p className="ts-body-2">
                {t(
                  'You will be asked to sign a message in your wallet to verify you as the owner of the address.',
                )}
              </p>
              <div className="flex items-center gap-3">
                <Button
                  className="flex-1"
                  colorScheme="primary-1"
                  size="sm"
                  ref={startBtnRef}
                  onClick={() =>
                    onConfirmed(state.user)
                      .then(() => {
                        disclosure.onClose()
                      })
                      .catch((e) => {
                        setState({ tag: 'failure' })
                        console.error(e)
                      })
                  }
                >
                  {t('OK')}
                </Button>
                <Button
                  className="flex-1"
                  variant="outline"
                  size="sm"
                  onClick={() => disclosure.onClose()}
                >
                  {t('Cancel')}
                </Button>
              </div>
            </div>
          )}
          {state.tag === 'pending' && (
            <div className="flex flex-col gap-6">
              <p className="ts-body-2">{t('Please continue in the wallet')}</p>
              <Button colorScheme="primary-1" size="sm" isLoading isDisabled>
                {t('OK')}
              </Button>
            </div>
          )}
          {state.tag === 'failure' && (
            <div className="flex flex-col gap-6">
              <p className="ts-body-2">
                {t('Account verification failed, please retry later')}
              </p>
              <Button
                colorScheme="primary-1"
                size="sm"
                onClick={() => disclosure.onClose()}
              >
                {t('OK')}
              </Button>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(UserSignModal)
