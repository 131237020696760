import { BigNumber, constants, providers, utils } from 'ethers'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'

import {
  AccordionStep,
  AccordionStepItem,
  AccordionStepPanel,
} from '@/components/disclosure'
import { Button } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { UseDisclosureReturn, useLoading } from '@/hooks'
import { CheckFilled, LockFilled } from '@/icons'
import { O } from '@/lib/fp'
import {
  approveErc20,
  ApproveTarget,
  getErrorMessage,
  isApprovedErc20,
} from '@/lib/market'
import toast from '@/lib/toast'
import { getTokenMeta } from '@/lib/token'

export type ApproveTokenProps = {
  provider: providers.Web3Provider
  networkId: number
  currency: string
  amount: BigNumber
  approveAmount?: BigNumber
  ethAmount?: BigNumber
  isCart?: boolean
  target?: ApproveTarget
  title: string
  subtitle: string
  desc: string
  loadingText: string
  actionText: string
  onAction: (setLoading: (a: boolean) => void, onDone: () => void) => void
} & (
  | {
      stayWhenDone: true
      doneText: string
    }
  | {
      stayWhenDone: false
      doneText?: never
    }
)
type Props = ApproveTokenProps & {
  disclosure: UseDisclosureReturn
}
export const ApproveTokenModal = ({
  disclosure,
  provider,
  networkId,
  currency,
  amount,
  approveAmount = amount,
  ethAmount = constants.Zero,
  isCart = false,
  target = 'x2y2',
  title,
  subtitle,
  desc,
  loadingText,
  actionText,
  stayWhenDone,
  doneText,
  onAction,
}: Props) => {
  const { t } = useTranslation()

  const [step, setStep] = useState<0 | 1 | 2>(0)
  const { symbol, decimals } = getTokenMeta(networkId, currency)
  const { isLoading: isLoading0, setLoading, guardLoading } = useLoading()
  const [isApproved, setApproved] = useState<O.Option<boolean>>(O.none)
  const isLoading = O.isNone(isApproved) || isLoading0
  const approveBtnRef = useRef<HTMLButtonElement>(null)
  const actionBtnRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (isLoading) return
    // Wait until refs available
    setTimeout(() => {
      const btnRef = step === 0 ? approveBtnRef : actionBtnRef
      btnRef.current?.focus()
    }, 0)
  }, [step, disclosure.isOpen, isLoading])

  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if (checked) return
    const action = async () => {
      try {
        const approved = await isApprovedErc20(
          networkId,
          currency,
          approveAmount,
          provider.getSigner(),
          isCart,
          target,
        )
        setApproved(O.some(approved))
        setStep(approved ? 1 : 0)
        setChecked(true)
      } catch (e) {
        console.error(e)
        const error = getErrorMessage(t, e)
        toast({
          status: 'error',
          title: (
            <ReportToast
              user={provider.getSigner()}
              networkId={networkId}
              tokenContract={currency}
              tokenId="-"
              error={error}
            />
          ),
        })
      }
    }
    action()
  }, [
    t,
    provider,
    networkId,
    currency,
    amount,
    checked,
    isCart,
    target,
    approveAmount,
  ])

  const onApprove = async () => {
    setLoading(true)
    try {
      await approveErc20(
        networkId,
        currency,
        provider.getSigner(),
        isCart,
        target,
      )
      setLoading(false)
      setApproved(O.some(true))
      setStep(1)
    } catch (e) {
      console.error(e)
      setLoading(false)
      const error = getErrorMessage(t, e)
      toast({
        status: 'error',
        title: (
          <ReportToast
            user={provider.getSigner()}
            networkId={networkId}
            tokenContract={currency}
            tokenId="-"
            error={error}
          />
        ),
      })
    }
  }

  const onDone = () => setStep(2)

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
      initialFocus={approveBtnRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AccordionStep index={Math.min(step, 1)}>
            <AccordionStepItem
              title={t('Approve {{symbol}}', { symbol })}
              step={step}
              index={0}
            >
              <AccordionStepPanel className="space-y-6">
                <p>
                  <Trans t={t}>
                    We’ll ask your approval for the marketplace to access your{' '}
                    {{ symbol }} token. This is a one-time only operation.
                  </Trans>
                </p>
                <Button
                  ref={approveBtnRef}
                  variant="outline"
                  leftIcon={<LockFilled className="h-4 w-4" />}
                  isLoading={isLoading}
                  loadingText={
                    O.isNone(isApproved)
                      ? undefined
                      : t('Waiting for transaction…')
                  }
                  onClick={onApprove}
                >
                  {t('Approve')}
                </Button>
              </AccordionStepPanel>
            </AccordionStepItem>
            <AccordionStepItem title={subtitle} step={step} index={1}>
              <AccordionStepPanel className="space-y-6">
                <p>{desc}</p>
                {!(amount.isZero() && ethAmount.isZero()) && (
                  <div className="flex items-center justify-between space-x-3">
                    <p className="ts-hairline-3 text-gray-600">{t('Price')}</p>
                    <p className="ts-headline-5 break-all text-gray-800">
                      {!ethAmount.isZero()
                        ? utils.formatEther(ethAmount) + ' ETH + '
                        : ''}
                      {utils.formatUnits(amount, decimals)} {symbol}
                    </p>
                  </div>
                )}
                <Button
                  ref={actionBtnRef}
                  colorScheme="primary-1"
                  isLoading={isLoading}
                  loadingText={loadingText}
                  leftIcon={
                    step < 2 ? undefined : <CheckFilled className="h-4 w-4" />
                  }
                  onClick={
                    step === 2
                      ? disclosure.onClose
                      : stayWhenDone
                      ? () => onAction(setLoading, onDone)
                      : () => onAction(setLoading, disclosure.onClose)
                  }
                >
                  {stayWhenDone && step === 2 ? doneText : actionText}
                </Button>
              </AccordionStepPanel>
            </AccordionStepItem>
          </AccordionStep>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
