import { pipe } from 'fp-ts/lib/function'
import http from 'http'

import { O, R } from '@/lib/fp'
import { HttpRequestConfig } from '@/lib/http'

export const requestConfig = (
  headers?: http.IncomingHttpHeaders,
): HttpRequestConfig => {
  const config: HttpRequestConfig = {
    baseURL: process.env.MARKET_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }
  if (headers && config.headers) {
    config.headers['Authorization'] = headers['authorization'] ?? ''
  }
  return config
}

export const errorMessage = (e: unknown): string => {
  const errorMessage = e instanceof Error ? `: ${(e as Error).message}` : ''
  return `bad response${errorMessage}`
}

export const filterForwardedHeaders = (
  headers: http.IncomingHttpHeaders,
): Record<string, string> => {
  return pipe(
    headers,
    R.filterMapWithIndex((k, v) => {
      if (k.startsWith('x-forwarded-') && v) {
        if (typeof v === 'string') {
          return O.some(v)
        } else {
          return O.some(v.join(','))
        }
      }
      return O.none
    }),
  )
}

export const requestConfigWithForwardedHeaders = (
  headers: http.IncomingHttpHeaders,
): HttpRequestConfig => {
  const config = requestConfig(headers)
  const forwardedHeaders = filterForwardedHeaders(headers)
  Object.keys(forwardedHeaders).forEach((key) => {
    if (key.toLowerCase().startsWith('x-forwarded-')) {
      if (config.headers) {
        config.headers[key] = forwardedHeaders[key]
      } else {
        config.headers = { [key]: forwardedHeaders[key] }
      }
    }
  })
  return config
}
