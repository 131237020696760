export const NOT_FOUND_NFTS = [
  '0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/81397',
  '0xa310425046661c523d98344f7e9d66b32195365d/12381',
  '0xa310425046661c523d98344f7e9d66b32195365d/12097',
  '0x38f81cf87b7f7a8f22d1c7cb25b6373dca4a14a8/1',
  '0x9201a886740d193e315f1f1b2b193321d6701d07/2523',
  '0x9201a886740d193e315f1f1b2b193321d6701d07/2524',
  '0x185345270d3618914a52d078f428e191a26de836/77728195044139938857631693763679457245199716189186543639318585005937923194881',
  '0x8c5acf6dbd24c66e6fd44d4a4c3d7a2d955aaad2/39202092635371949456337972989165311386884879793347389106737191925426489951041',
  '0x569718d9e7562312cebf9316c31d000c80d542e3/5',
  '0x6bb72b487239ca47fd20c4c3b647ff7806cc238a/20',
  '0xa310425046661c523d98344f7e9d66b32195365d/19876',
  '0xa310425046661c523d98344f7e9d66b32195365d/23786',
  '0xabefbc9fd2f806065b4f3c237d4b59d9a97bcac7/18402',
  '0xabefbc9fd2f806065b4f3c237d4b59d9a97bcac7/31142',
  '0xabefbc9fd2f806065b4f3c237d4b59d9a97bcac7/8834',
  '0xd55e9a096400036d5965cd4bc6fb580555957647/2065',
  '0xd07dc4262bcdbf85190c01c996b4c06a461d2430/438',
  '0xd07dc4262bcdbf85190c01c996b4c06a461d2430/64272',
  '0xd07dc4262bcdbf85190c01c996b4c06a461d2430/36917',
  '0xd07dc4262bcdbf85190c01c996b4c06a461d2430/426108',
  '0x1d963688fe2209a98db35c67a041524822cf04ff/28686',
  '0x1d963688fe2209a98db35c67a041524822cf04ff/59981',
  '0x1d963688fe2209a98db35c67a041524822cf04ff/52807',
  '0x7a53e6fe1298089cf13bc0247a96ecb418d0b4ba/9',
  '0x7a53e6fe1298089cf13bc0247a96ecb418d0b4ba/8',
  '0x7a53e6fe1298089cf13bc0247a96ecb418d0b4ba/6',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/3012',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/2028',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/2666',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/2944',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/1113',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/1108',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/1326',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/836',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/774',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/330',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/317',
  '0x2cb51714abdd9d1d4ea14d4f1fb25561fdc9c49f/358',
  '0x2cc8342d7c8bff5a213eb2cde39de9a59b3461a7/31010',
  '0xf38d6bf300d52ba7880b43cddb3f94ee3c6c4ea6/8923',
  '0xfc0d0430cc11effb068007306d6efd9b2ac7a460/44030042453791041515191968450395731750384911073592268585510350597161105528494',
  '0x3f669aeff1607f8a0233cb2de7508f5a9a114685/44030042453791041515191968450395731750384911073592268585510350597161105528494',
  '0x4eb3c41962d5a6f2b892ba7210b880edb78df54a/11',
  '0x4eb3c41962d5a6f2b892ba7210b880edb78df54a/19',
  '0x0b50cbcba7c5e310fcf7118930119c9794e3e70e/3',
  '0x1d963688fe2209a98db35c67a041524822cf04ff/35999',
  '0x1d963688fe2209a98db35c67a041524822cf04ff/53673',
  '0x4d67dcc8ab25f604b2dee43f0a12d04f923dd1e3/36',
  '0x4d67dcc8ab25f604b2dee43f0a12d04f923dd1e3/76',
  '0x4d67dcc8ab25f604b2dee43f0a12d04f923dd1e3/84',
  '0x4d67dcc8ab25f604b2dee43f0a12d04f923dd1e3/87',
  '0x4d67dcc8ab25f604b2dee43f0a12d04f923dd1e3/119',
  '0x4eb3c41962d5a6f2b892ba7210b880edb78df54a/5',
  '0x58298b78e26f15be20aa2e8b001112ea59e8b909/305',
  '0x45704edabe7d2e038c35876dd3b6789511e452cf/3464',
  '0xf924fed62a15c879213e677dada6cf7db5174620/6200010001',
  '0x515370a7aeb834866333c56d81045c7bd70fcd8c/16',
  '0x736bae7626934763b1dcd2714867ca7462694e60/3',
  '0xc23d925684919c1619f13427817d3fee6c24debb/74620085835807141967812726533520779474928452219843785439454944286167493784214',
  '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/85901290689739209526054068013181057136954807491910597262216024875721184993947',
  '0x92e5c08d485d264a7001fcb36d3b52cbb9d26e04/23',
  '0x92e5c08d485d264a7001fcb36d3b52cbb9d26e04/24',
  '0x8c5acf6dbd24c66e6fd44d4a4c3d7a2d955aaad2/109254087002531779810739207224565024596814919765536100184311522630796516219283',
  '0xb66a603f4cfe17e3d27b87a8bfcad319856518b8/89145210338494231454134465898800368695053729180537948106083398537886734221315',
  '0xb66a603f4cfe17e3d27b87a8bfcad319856518b8/89145210338494231454134465898800368695053729180537948106083398537886734221316',
  '0x60f80121c31a0d46b5279700f9df786054aa5ee5/735620',
  '0x92e5c08d485d264a7001fcb36d3b52cbb9d26e04/12',
  '0x92e5c08d485d264a7001fcb36d3b52cbb9d26e04/27',
  '0x1d963688fe2209a98db35c67a041524822cf04ff/20061',
  '0x1d963688fe2209a98db35c67a041524822cf04ff/35745',
  '0x837f60e4f4d010943fdf93e5f3c3de8b6ef71006/245',
  '0x92e5c08d485d264a7001fcb36d3b52cbb9d26e04/18',
  '0x00ef627672e4ca7f28e697071669c1d4cf54a6f8/7',
  '0x01bba896c86c2f2739ec814900c12adeff6d5a11/849',
  '0x043b6fbcfe98e04381af5da8de6c74570fad4c64/7',
  '0x054a09c01e96b752785d1f0fa38eb9179de8e527/7',
  '0x0bc12490d4f5909168432ac6440c21db436ecd65/7',
  '0x11a778a736c8176f159d7f9413cfa047fd0a0b0b/7',
  '0x16c668ce9c2347e0937ea09760398aeb3e36a684/7',
  '0x3703bcc051e0fd26d9431296a9bf0376e4e398f2/7',
  '0x39d9455642726e8e9f283d8fff724d460fe8a6b6/7',
  '0x3bd06dafa8365403c794e0766fe944d26c8894c5/7',
  '0x46aea2d3181ac48751f57ce3919c9453bc217e5a/7',
  '0x60479ac44f8c9ad540b0fcd799564d570f02daa0/7',
  '0x643a0e022651680370718b6ff6616938e52bffd1/7',
  '0x663c9335c7bbf9fc4317e3274a567ffeb530146b/7',
  '0x6836ba66ab1c35ea55fdf07d1637f9959f7143ab/7',
  '0x6a0d6b43de4f8a696d220fc1df97dfa91e654a7f/7',
  '0x75fa387b96dd0bdff12a1f06b0cd4aab763064c2/7',
  '0x79b6522e65b8ebb356fee3f6b9b964f7a35865b8/7',
  '0x79e9d0d1c49dabbb9bb7e0110a8a014afa09a4d1/7',
  '0x7c2c4c512c5bd22688d72fb823e8edd31bd0624f/7',
  '0x8721e447a855f954b7669e769b0837cf4dc3eeb2/7',
  '0x877e8dbc33c9205acfbebba387d71c2c4ec8f94c/7',
  '0x8a473a870915723adf8242db61b0ce94b620689f/7',
  '0x91d34d7688de99df63c659de53962d10e1310561/7',
  '0x920fa6901dd90a023b8d09a024d3833702f0ad50/7',
  '0x93685b450bdec4ebe56d66f915d0039c37f0fadc/7',
  '0x970362290741b6d16a9a7f752739130a141190d6/7',
  '0x9a5473292b382b2bc522c1ff63913a1051c270c3/7',
  '0xa34e023bab4dd58a043eeffb9b9319afe59e483a/7',
  '0xb8774c6a3491d9b8dded419477638c26cfe8b0a6/7',
  '0xba83bf331e478294e17c46e56a446250aad0b84c/4',
  '0xba83bf331e478294e17c46e56a446250aad0b84c/5',
  '0xbf5aff552353ce314ee095990c8c96aa7b1cf8d6/7',
  '0xc91a243270f77d57910580e7d2233f95d894cdbd/7',
  '0xca6802a4d6f0a2e801056591bf5a38af458822df/7',
  '0xd1fb268a281e0ca3e9acac6d186126f945ca2c2c/7',
  '0xdff9ad427443e71d0c6d305b4624e01673d49dec/7',
  '0xe01cc0951143fe4ab7f4d686a91e78f5eef5d84d/7',
  '0xe0b35f4da6d1ef8b6965f46d51c0b4d985dc2761/7',
  '0xe7bd0e53ee71ff7cc1ec837ae73dcfe61537ffbe/7',
  '0xe92b5aecb187c79028b7c0ffc24f3b01e7850b8b/7',
  '0xf01d9e4e6dcf6e2e5abe4d74c970e7d2ed444799/7',
  '0xf41408a152aabf1f443f074a5171d5cba076c94e/7',
  '0xf7fa3407e75f628b78cd3785ff564de98fa52f80/7',
  '0xfa00ac74df3b209cf80c0ea8f3f8f764115cce32/7',
  '0xfb58bb34b20c757558ff762a03d1e7ae871f1ab1/7',
  '0x2A187453064356c898cAe034EAed119E1663ACb8/44030042453791041515191968450395731750384911073592268585510350597161105528494',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/15529',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/27107',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/31988',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/32692',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/35923',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/36383',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/39374',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/43471',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/45084',
  '0x2Cc8342d7c8BFf5A213eb2cdE39DE9a59b3461A7/62823',
  '0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405/29792',
  '0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405/35756',
  '0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405/36270',
  '0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405/51704',
  '0x4af84535625fE40990bfB35019B944a9933f7593/44030042453791041515191968450395731750384911073592268585510350597161105528494',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/48248556949783308865793797927330725178064718367390204081070207480036476974763',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/97679013350013698045270453475308417577798017762065958465519722874363940687298',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/102057744645030159741156941469531471525899887286890557687081945869529672060431',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/8997516350349919167276656162365093483852642151953223156396535060863864550937',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/1836985881988667364145511271263576937798603006272547545358243456802052948843',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/10337234423653876573789216192187815317957446908480503679569953873511998673631',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/11897225565450307176543930117434127985639388805032095750827784894706036321430',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/16352636129704269265576159054570017211069072702648726978848819076661636240426',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/28735966365141423695587875178428285677425736138917655500575445059895696310718',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/34159332826637097234586459724378324510668892799654898967890689322940648092427',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/38239678308735817402011085437195079859211359930422838351542671388745485042445',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/40337082651995477213355613927514419797371575534215847085191270501690402964014',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/40929632009620661147303811467972153536243004779365763491671431486780668665256',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/41595175132619843215793975137805296525573218295893334175739650310668178744382',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/41938716907951656232304280752778146146385076826517271971110806045432870905751',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/44555492639182861005433515441304647878861183342012353315291895364630947074033',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/45009138013978563627703050128601731778721156386568661019548966506072399133062',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/54401458723205453287351458226050308359922626644505567763859993691115167280598',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/56359344174498632104759416961865022928751403558171984400450883138069567484394',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/57919484222967040638036514148586496912700776841491007175325210142044627991830',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/58591856311112168578772079730032641741458209081677814499582401366005599685133',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/59761040209577333748621522598978930178654043869228792812742862171246654531131',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/60295885984653632885536472749541518653931873344656296791924213835758805362528',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/64612337007009950777159415076645563386929076650920556082242117423715250169404',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/67404924439207968636668905091877025143043571357327546775691282489971871195071',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/73035446815094674908950610494580935202337761281915614154937091195841762816605',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/73528768293051770555713782777547206359346052659858439045890669787748005934284',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/74309031582038255111532474316776398401360067368060263871357885062368114234905',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/75065683887860767870467130403878805472977308042200142719360865074332391550429',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/75311087909795284417348557731845691485886852197631319517662594073216141700808',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/79848547558625886491544232264040412373141089659235724021341404511044115758146',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/85124698224506405970300132409714072120322875583201376114443255683454947200486',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/89016960500317339366158617869644726300756010656391678840035161928400790064402',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/94987461332694813531060432644659617815553337269316142245887687444980834988282',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/95144866652250855544535083409538277095979878768474166655616790692092000034422',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/100084257988031108764531284699055302032122934893702238300752265992816861183761',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/105422425421556652390217935170431905871560333343790870957398948687884416205679',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/108436557895213559682729021360876903242468769888928692764832957786814001952677',
  '0x6317C4CAB501655D7B85128A5868E98a094C0082/1370',
  '0x6317C4CAB501655D7B85128A5868E98a094C0082/6797',
  '0x6317C4CAB501655D7B85128A5868E98a094C0082/8003',
  '0x7d256d82b32d8003d1cA1a1526ED211E6e0dA9e2/11629',
  '0x7d256d82b32d8003d1cA1a1526ED211E6e0dA9e2/11632',
  '0xaA20f900e24cA7Ed897C44D92012158f436ef791/4087',
  '0xb932a70A57673d89f4acfFBE830E8ed7f75Fb9e0/34394',
  '0xBA9BBEa08241845013b40a061E4A77c9345e4562/996',
  '0xBCA47158eCd586Ca4CddDAA5bD5e38d4F1e677B2/2858',
  '0xC53Dc593b69c67A209Cf70d4172078c9125449bB/21048',
  '0xc9EEf4c46ABcb11002c9bB8A47445C96CDBcAffb/659',
  '0xc9EEf4c46ABcb11002c9bB8A47445C96CDBcAffb/2198',
  '0xcC7187dDBe8F099D31bAc88d8D67F793001D718E/2248',
  '0xD1E5b0FF1287aA9f9A268759062E4Ab08b9Dacbe/82374137455220321477296423691135728670031519403284958366113837110170059472326',
  '0xD4416b13d2b3a9aBae7AcD5D6C2BbDBE25686401/29974248406000476091875597976656301139429989579191052532800415773821281424023',
  '0xD4416b13d2b3a9aBae7AcD5D6C2BbDBE25686401/46820519989567727502892495553593734144329561953351349676158612257300287907153',
  '0xD4416b13d2b3a9aBae7AcD5D6C2BbDBE25686401/58553223553873135661551054550093008426400963512755683168251969014795521549768',
  '0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/5942',
  '0x6e9E3ABaE12a4e1B1fa1847e583a8f81806960D2/361',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/112855081658351770716382428752099496780983516678898011049867489119262437636019',
  '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85/70718340935623537200571818916097070877455139676864610371331938880244130632136',
].map((k) => k.toLowerCase())
