import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  // TODO: Add more config if needed
  thickness?: string
  trackColor?: 'transparent' | 'gray'
  label?: string
}
export const Spinner = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      style,
      thickness = '2px',
      trackColor = 'transparent',
      label,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation()
    return (
      <div
        ref={ref}
        className={clsx(
          className,
          'inline-block animate-loading rounded-full border-b-current border-l-current',
          trackColor === 'transparent'
            ? 'border-transparent'
            : 'border-gray-300',
        )}
        style={{ borderWidth: thickness, ...style }}
        {...rest}
      >
        <span className="sr-only">{label ?? t('Loading')}</span>
      </div>
    )
  },
)

Spinner.displayName = 'Spinner'
