import { pipe } from 'fp-ts/lib/function'

import { NETWORK_ETH } from '@/consts'
import { D, E, TE } from '@/lib/fp'
import { DEFAULT_NETWORK } from '@/utils/network'

import { http } from './http'

const BASE_URL =
  DEFAULT_NETWORK === NETWORK_ETH
    ? 'https://api.opensea.io/api/v1'
    : 'https://testnets-api.opensea.io/api/v1'

export const SKIP_CONTRACTS = [
  '0x1821363abc9E33f1bfbE3f96F7C68ECa7f6Af2BD', // degod
  '0x2EE6AF0dFf3A1CE3F7E3414C52c48fd50d73691e', // RR/REDACTED
].map((a) => a.toLowerCase())

export const MOONBIRDS_CONTRACT = '0x23581767a106ae21c074b2276d25e5c3e136a68b'
const SKIP_MOONBIRDS_TOKEN_IDS = ['5799', '1969']

const floorPriceCache: Record<string, number> = {}
export const getFloorPrice = async (collection: string): Promise<number> => {
  const cached = floorPriceCache[collection]
  if (cached) return cached
  const r = await fetch(
    `https://api.reservoir.tools/stats/v2?collection=${collection}`,
    { headers: { 'x-api-key': '263a5b43-a7f0-5b70-ac84-6c12f75408f4' } },
  )
  const d = await r.json()
  const floorPrice = d?.stats?.market?.floorAsk?.price?.amount?.native
  if (!Number.isFinite(floorPrice)) throw Error('invalid data', floorPrice)
  floorPriceCache[collection] = floorPrice
  return floorPrice
}

export const canSkipCheck = (token: string, tokenId: string): boolean => {
  if (SKIP_CONTRACTS.includes(token.toLowerCase())) {
    return true
  }
  if (
    MOONBIRDS_CONTRACT === token.toLowerCase() &&
    SKIP_MOONBIRDS_TOKEN_IDS.includes(tokenId)
  ) {
    return true
  }
  return false
}

export const getListingSupport = async (token: string, tokenId: string) => {
  if (canSkipCheck(token, tokenId)) {
    return true
  }
  try {
    const { data } = await http.get(
      `/asset/${token}/${tokenId}?include_orders=false`,
      { baseURL: BASE_URL, timeout: 10_000 },
    )
    return data.supports_wyvern
  } catch {
    return true
  }
}
