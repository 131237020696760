import { Svg } from '@/components/media'
import {
  NETWORK_ARBITRUM,
  NETWORK_ARBITRUM_TESTNET,
  NETWORK_AVAX,
  NETWORK_AVAX_TESTNET,
  NETWORK_BSC,
  NETWORK_BSC_TESTNET,
  NETWORK_ETH,
  NETWORK_FANTOM,
  NETWORK_FANTOM_TESTNET,
  NETWORK_GOERLI,
  NETWORK_OPTIMISM,
  NETWORK_OPTIMISM_TESTNET,
  NETWORK_POLYGON,
  NETWORK_POLYGON_TESTNET,
} from '@/consts'
import {
  Arbitrum,
  Avalanche,
  Bsc,
  BscTestnet,
  Ethereum,
  Fantom,
  Goerli,
  Optimism,
  Polygon,
  PolygonTestnet,
  QuestionCircleLine,
} from '@/icons'

export const networkTitles = (
  network: number,
): {
  short: string
  long: string
} => {
  switch (network) {
    case NETWORK_BSC:
      return {
        short: 'BNB Chain',
        long: 'BNB Chain',
      }
    case NETWORK_BSC_TESTNET:
      return {
        short: 'Testnet',
        long: 'BSC Testnet',
      }
    case NETWORK_ETH:
      return {
        short: 'Ethereum',
        long: 'Ethereum',
      }
    case NETWORK_GOERLI:
      return {
        short: 'Goerli',
        long: 'Ethereum Goerli',
      }
    case NETWORK_POLYGON:
      return {
        short: 'Polygon',
        long: 'Polygon',
      }
    case NETWORK_POLYGON_TESTNET:
      return {
        short: 'Mumbai',
        long: 'Polygon Mumbai',
      }
    case NETWORK_AVAX:
      return {
        short: 'Avalanche',
        long: 'Avalanche',
      }
    case NETWORK_AVAX_TESTNET:
      return {
        short: 'Fuji',
        long: 'Avalanche Fuji',
      }
    case NETWORK_FANTOM:
      return {
        short: 'Fantom',
        long: 'Fantom',
      }
    case NETWORK_FANTOM_TESTNET:
      return {
        short: 'Testnet',
        long: 'Fantom Testnet',
      }
    case NETWORK_ARBITRUM:
      return {
        short: 'Arbitrum',
        long: 'Arbitrum',
      }
    case NETWORK_ARBITRUM_TESTNET:
      return {
        short: 'Testnet',
        long: 'Arbitrum Testnet',
      }
    case NETWORK_OPTIMISM:
      return {
        short: 'Optimism',
        long: 'Optimism',
      }
    case NETWORK_OPTIMISM_TESTNET:
      return {
        short: 'Kovan',
        long: 'Optimism Kovan',
      }
    default:
      return {
        short: 'N/A',
        long: 'Not Available',
      }
  }
}

export const networkIcon = (network: number): Svg => {
  switch (network) {
    case NETWORK_BSC:
      return Bsc
    case NETWORK_BSC_TESTNET:
      return BscTestnet
    case NETWORK_ETH:
      return Ethereum
    case NETWORK_GOERLI:
      return Goerli
    case NETWORK_POLYGON:
      return Polygon
    case NETWORK_POLYGON_TESTNET:
      return PolygonTestnet
    case NETWORK_AVAX:
    case NETWORK_AVAX_TESTNET:
      return Avalanche
    case NETWORK_FANTOM:
    case NETWORK_FANTOM_TESTNET:
      return Fantom
    case NETWORK_ARBITRUM:
    case NETWORK_ARBITRUM_TESTNET:
      return Arbitrum
    case NETWORK_OPTIMISM:
    case NETWORK_OPTIMISM_TESTNET:
      return Optimism
    default:
      return QuestionCircleLine
  }
}
