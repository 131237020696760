import clsx from 'clsx'
import { createContext, ReactNode, useContext } from 'react'

import { Collapse } from '@/components/transition'
import { CheckFilled } from '@/icons'

const AccordionStepCtx = createContext({ index: 0 })

type Props = {
  children: ReactNode
  index: number
}
export const AccordionStep = ({ children, index }: Props) => (
  <AccordionStepCtx.Provider value={{ index }}>
    <div className="flex flex-col">{children}</div>
  </AccordionStepCtx.Provider>
)

type ItemProps = {
  children: ReactNode
  title: string
  step: number
  index: number
}
export const AccordionStepItem = ({
  children,
  title,
  step,
  index,
}: ItemProps) => {
  const isDisabled = index > step
  const { index: openedIndex } = useContext(AccordionStepCtx)
  const isOpen = index === openedIndex
  return (
    <section className="flex flex-col [&:last-child_.accordion-step-panel]:border-b-0">
      <header
        className={clsx(
          'flex items-center border-b py-4',
          isDisabled && 'text-gray-400',
        )}
      >
        <span className="ts-hairline-2 mr-2.5 flex-1">
          {index + 1}. {title}
        </span>
        <CheckFilled
          className={clsx(
            'h-5 w-5',
            isDisabled
              ? 'text-gray-400'
              : index === step
              ? 'text-gray-500'
              : 'text-primary-4',
          )}
        />
      </header>
      <Collapse in={isOpen}>{children}</Collapse>
    </section>
  )
}

type PanelProps = {
  className?: string
  children: ReactNode
}
export const AccordionStepPanel = ({ className, children }: PanelProps) => (
  <div
    className={clsx(
      className,
      'accordion-step-panel ts-body-3 flex flex-col border-b py-6 text-gray-500 lg:ts-body-2',
    )}
  >
    {children}
  </div>
)
