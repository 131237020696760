import clsx from 'clsx'
import globalDayjs, { Dayjs, UnitType } from 'dayjs'
import flatpickr from 'flatpickr'
import minMaxTimePlugin from 'flatpickr/dist/plugins/minMaxTimePlugin'
import { Instance } from 'flatpickr/dist/types/instance'
import { useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'

import { IconButton, PopoverSelect } from '@/components/form'
import { useDayjs } from '@/hooks'
import { CalendarLine, CloseLine } from '@/icons'

const hourSeconds = 3600
const daySeconds = 24 * hourSeconds

const durationToSeconds = (a: ExpireOption) => {
  switch (a) {
    case '1h':
      return hourSeconds
    case '3h':
      return hourSeconds * 3
    case '6h':
      return hourSeconds * 6
    case '1d':
      return daySeconds
    case '3d':
      return daySeconds * 3
    case '7d':
      return daySeconds * 7
    case '14d':
      return daySeconds * 14
    case '30d':
      return daySeconds * 30
    case '90d':
      return daySeconds * 90
    case '180d':
      return daySeconds * 180
  }
}

// NOTE: This is an IO, call it right before deadline is needed
export const getDeadline = (a: ExpireOption) => {
  const duration = durationToSeconds(a)
  if (duration === 0) return 0
  return Math.trunc(new Date().valueOf() / 1000) + duration
}

type DurationPickerProps = {
  className?: string
  onDateUpdate: (timestamp: number) => void
  initialDate?: Date
}

type DurationOption = { value: string; label: ExpireOption | 'custom' }

// Simple helper to simulate a duration options
const getCustomDateOptions = (date: Date): DurationOption => ({
  value: date.toISOString(),
  label: 'custom',
})

// TODO: Reduce boilerplate
const expireOptions = [
  '1h',
  '3h',
  '6h',
  '1d',
  '3d',
  '7d',
  '14d',
  '30d',
  '90d',
  '180d',
] as const
type ExpireOption = (typeof expireOptions)[number]

export const defaultExpireOption: ExpireOption = '30d'

export const getDefaultDurationOption = (): DurationOption => ({
  value: new Date(getDeadline(defaultExpireOption) * 1000).toISOString(),
  label: defaultExpireOption,
})

// Ref: https://github.com/iamkun/dayjs/issues/1619#issuecomment-983022487
const ceil = (a: Dayjs, amount: number, unit: UnitType) =>
  a.add(amount - (a.get(unit) % amount), unit).startOf(unit)

export const DurationPicker = ({
  className,
  onDateUpdate,
  initialDate,
}: DurationPickerProps) => {
  const { t } = useTranslation()
  const dayjs = useDayjs()
  const [endTime, setEndTime] = useState(
    initialDate
      ? getCustomDateOptions(initialDate)
      : getDefaultDurationOption(),
  )
  const nodeRef = useRef<HTMLDivElement>(null)
  const flatpickrInstance = useRef<Instance>()
  const hasPickedCustomDatetime = endTime.label === 'custom'

  const handleSelectPresetEndTime = (label: ExpireOption | 'custom') => {
    if (label === 'custom') return // should not happend
    const date = getDeadline(label) * 1000
    const newOption = { value: new Date(date).toISOString(), label }
    setEndTime(newOption)
    onDateUpdate(date)
  }

  useEffect(() => {
    if (nodeRef.current) {
      const min0 = globalDayjs().add(15, 'minutes')
      const min = ceil(min0, 5, 'minutes') // Round up to the next 5
      const minDate = min.format('YYYY-MM-DD')
      const minTime = min.format('HH:mm')

      flatpickrInstance.current = flatpickr(nodeRef.current, {
        enableTime: true,
        wrap: true,
        static: true,
        disableMobile: true, // Fix auto open on mobile
        time_24hr: true,
        defaultHour: min.hour(),
        defaultMinute: min.minute(),
        onChange: ([date]) => {
          const newOption = date
            ? getCustomDateOptions(date)
            : getDefaultDurationOption()
          setEndTime(newOption)
          onDateUpdate(new Date(newOption.value).getTime())
        },
        plugins: [
          minMaxTimePlugin({
            table: {
              [minDate]: {
                minTime,
                maxTime: '23:59',
              },
            },
          }),
        ],
        minDate,
      })
    }

    return () => {
      if (flatpickrInstance.current) {
        flatpickrInstance.current.destroy()
      }
    }
  }, [nodeRef, flatpickrInstance, setEndTime, onDateUpdate])

  const renderOption = (a: ExpireOption) => {
    switch (a) {
      case '1h':
        return t('{{count}} hours', { count: 1 })
      case '3h':
        return t('{{count}} hours', { count: 3 })
      case '6h':
        return t('{{count}} hours', { count: 6 })
      case '1d':
        return t('{{count}} days', { count: 1 })
      case '3d':
        return t('{{count}} days', { count: 3 })
      case '7d':
        return t('{{count}} days', { count: 7 })
      case '14d':
        return t('{{count}} days', { count: 14 })
      case '30d':
        return t('{{count}} days', { count: 30 })
      case '90d':
        return t('{{count}} days', { count: 90 })
      case '180d':
        return t('{{count}} days', { count: 180 })
    }
  }

  return (
    <div className={clsx(className, 'relative flex items-center space-x-2')}>
      <div className="min-w-0 flex-1">
        {hasPickedCustomDatetime ? (
          <div className="h-12 truncate rounded-lg border-2 px-4 py-2.5 font-bold">
            {dayjs(endTime.value).format('lll')}
          </div>
        ) : (
          <PopoverSelect
            className="h-12"
            items={expireOptions.map((a) => ({
              key: a,
              title: renderOption(a),
              value: a,
            }))}
            value={endTime.label}
            onChange={handleSelectPresetEndTime}
          />
        )}
      </div>
      <div className="flatpickr modal relative h-12 w-12" ref={nodeRef}>
        <input
          className="pointer-events-none absolute -z-10 w-0 opacity-0"
          data-input
        />
        <IconButton
          className={clsx(
            '!absolute inset-0 rounded-lg',
            hasPickedCustomDatetime ? 'visible' : 'invisible',
          )}
          variant="outline"
          icon={<CloseLine className="h-6 w-6 text-gray-500" />}
          aria-label={t('Clear date')}
          data-clear
        />
        <IconButton
          className={clsx(
            '!absolute inset-0 rounded-lg',
            hasPickedCustomDatetime ? 'invisible' : 'visible',
          )}
          variant="outline"
          icon={<CalendarLine className="h-6 w-6 text-gray-500" />}
          aria-label={t('Custom date')}
          data-toggle
        />
      </div>
    </div>
  )
}
