// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const isNodeProd = process.env.NODE_ENV === 'production'
const isProd = process.env.NEXT_PUBLIC_ENV === 'prod'
if (isNodeProd) {
  if (isProd) {
    Sentry.init({
      release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
      dsn:
        SENTRY_DSN ||
        'https://02ca7f13150b4f84ab9f804c3fc20968@o914006.ingest.sentry.io/6164175',
      // Adjust this value in production, or use tracesSampler for greater control
      sampleRate: 0.1,
      tracesSampleRate: 0,
    })
  } else {
    Sentry.init({
      dsn: 'https://436ac93aa2aa4b1c8697aad3ffc478f8@o914006.ingest.sentry.io/4504002930802688',
      sampleRate: 0.1,
      tracesSampleRate: 0,
    })
  }
}
