import { constVoid } from 'fp-ts/lib/function'
import { useTranslation } from 'next-i18next'
import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react'

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@/components/overlay'
import { useDisclosure } from '@/hooks'
import {
  BitKeep,
  BraveWallet,
  CloseLine,
  CoinbaseWallet,
  Fortmatic,
  HyperPay,
  ImToken,
  MathWallet,
  MetaMask,
  OkxWallet,
  OneKeyWallet,
  Phantom,
  SafePal,
  TokenPocket,
  TrustWallet,
  WalletConnect,
} from '@/icons'
import { useAuth } from '@/lib/auth'
import {
  ALL_CONNECT_OPTIONS,
  ConnectOption,
} from '@/lib/auth/types/connectOption'
import { ConnectType } from '@/lib/auth/types/connectType'
import { Web3RegisteredUser } from '@/lib/auth/types/user'
import { enableOkxWallet } from '@/lib/features'

import { IconButton } from '../form'

const optionIcons = {
  MetaMask,
  WalletConnect,
  'Coinbase Wallet': CoinbaseWallet,
  'OneKey Wallet': OneKeyWallet,
  Fortmatic,
  BitKeep,
  TokenPocket,
  'Trust Wallet': TrustWallet,
  imToken: ImToken,
  SafePal,
  MathWallet,
  HyperPay,
  'Brave Wallet': BraveWallet,
  'OKX Wallet': OkxWallet,
  Phantom,
}

const optionConnectType = (opt: ConnectOption): ConnectType => {
  switch (opt) {
    case 'MetaMask':
    case 'OneKey Wallet':
    case 'BitKeep':
    case 'TokenPocket':
    case 'Trust Wallet':
    case 'MathWallet':
    case 'imToken':
    case 'SafePal':
    case 'HyperPay':
    case 'Brave Wallet':
    case 'OKX Wallet':
    case 'Phantom':
      return 'injected'
    case 'WalletConnect':
      return 'wallet-connect'
    case 'Coinbase Wallet':
      return 'wallet-link'
    case 'Fortmatic':
      return 'fortmatic'
  }
}

export type OnSuccess = (u: Web3RegisteredUser) => void

export type Handler = {
  show: (onSuccess?: OnSuccess) => void
}

const ConnectWalletModal: ForwardRefRenderFunction<Handler, unknown> = (
  _,
  ref,
) => {
  const { t } = useTranslation()
  const disclosure = useDisclosure()
  const { web3SignIn } = useAuth()

  const onSuccess = useRef<OnSuccess>(constVoid)
  useImperativeHandle(
    ref,
    () => ({
      show: (newOnSuccess) => {
        onSuccess.current = newOnSuccess ?? constVoid
        disclosure.onOpen()
      },
    }),
    [disclosure],
  )

  return (
    <Modal {...disclosure}>
      <ModalOverlay />
      <ModalContent className="lg:max-w-[660px]">
        <ModalBody className="flex flex-col gap-6 p-8">
          <div className="flex items-center justify-between">
            <p className="ts-headline-6 md:ts-headline-4">
              {t('Connect a wallet')}
            </p>
            <IconButton
              className="h-10 w-10 rounded-full"
              icon={<CloseLine className="h-6 w-6 text-gray-500" />}
              aria-label={t('Close')}
              onClick={disclosure.onClose}
            />
          </div>
          <div className="grid grid-cols-1 gap-y-2 md:grid-cols-2 md:gap-y-4">
            {ALL_CONNECT_OPTIONS.map((opt) => {
              if (!enableOkxWallet && opt === 'OKX Wallet') return null
              const OptIcon = optionIcons[opt]
              return (
                <button
                  key={opt}
                  className="flex items-center gap-4 rounded-full px-3 py-3 hover:bg-black/10 dark:hover:bg-white/[0.07] md:px-4"
                  onClick={() => {
                    disclosure.onClose()
                    web3SignIn({
                      option: opt,
                      type: optionConnectType(opt),
                      triggeredByUser: true,
                    }).then((a) => {
                      if (a.success) {
                        onSuccess.current(a.user)
                        onSuccess.current = constVoid
                      }
                    })
                  }}
                >
                  <OptIcon className="h-6 w-6 lg:h-10 lg:w-10" />
                  <p className="ts-body-2 font-bold text-gray-700">{opt}</p>
                </button>
              )
            })}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ConnectWalletModal)
