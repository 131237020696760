import dayjs from 'dayjs'
import { BigNumber, constants, utils } from 'ethers'
import { pipe } from 'fp-ts/function'
import { useTranslation } from 'next-i18next'
import { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { getFeePercent } from '@/components/detail/modals'
import {
  Button,
  FormControl,
  FormLabel,
  FormLabelLike,
  Input,
} from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { TokenIcon } from '@/components/TokenIcon'
import { UseDisclosureReturn, useLoading, UseModalReturn } from '@/hooks'
import { useExpireOption, usePaymentInfo } from '@/hooks/nft'
import { RegisteredUser } from '@/lib/auth/types/user'
import { fmtNumber, fmtPercentage } from '@/lib/formatter'
import { validateBigNumber } from '@/lib/formik'
import { NEA } from '@/lib/fp'
import { changePrice, getErrorMessage } from '@/lib/market'
import { Bundle } from '@/lib/nft/detail'
import toast from '@/lib/toast'
import { getTokenMeta } from '@/lib/token'

import { FiatPricePreview, NftItemList } from '../shared'
import { FloorPrice } from './FloorWarningModal'
import {
  calcChangePercentage,
  ListFloorWarningProps,
} from './ListFloorWarningModal'
import { RelistProps } from './RelistModal'

type Inputs = {
  price: string
}

export type ChangePriceProps = {
  user: RegisteredUser
  bundle: Bundle
  orderId: number
  price: string
  currency: string
  isPrivate: boolean
  taker: string | null
  initialPrice?: BigNumber
  deadline: number
  floorPrice: FloorPrice | null
  royaltyFee: number | null
  relistModal: UseModalReturn<RelistProps>
  floorWarningModal: UseModalReturn<ListFloorWarningProps>
  onDone?: () => void
}
type Props = ChangePriceProps & {
  disclosure: UseDisclosureReturn
}
export const ChangePriceModal = ({
  disclosure,
  user,
  bundle,
  orderId,
  price: orderPrice0,
  currency,
  isPrivate,
  taker,
  initialPrice = constants.Zero,
  deadline: orderDeadline,
  floorPrice,
  royaltyFee: orderCreatorFee,
  relistModal,
  floorWarningModal,
  onDone,
}: Props) => {
  const { t } = useTranslation()

  const { token: tokenContract, tokenId } = bundle[0].tokenPair
  const { networkId } = bundle[0].info
  const { symbol, decimals } = getTokenMeta(networkId, currency)
  const orderPrice = utils.parseUnits(orderPrice0, decimals)
  const { isLoading, setLoading, guardLoading, reloadPage } = useLoading()

  const [isFocused, setFocused] = useState(false)

  const expire = useExpireOption({ deadline: orderDeadline })

  const contracts = useMemo(() => [tokenContract], [tokenContract])
  const orderInfo = usePaymentInfo({
    isActive: disclosure.isOpen,
    user,
    networkId,
    contracts,
  }).paymentInfo?.[tokenContract]

  const onUpdate = async (price: BigNumber, deadline: number) => {
    setLoading(true)
    try {
      const tokens = bundle.map((a) => a.tokenPair)
      await changePrice({
        networkId,
        user,
        deadline,
        currency,
        items: [{ orderId, tokens, price }],
        isBundle: bundle.length > 1,
        isPrivate,
        taker: taker ?? '',
      })
      onDone ? onDone() : await reloadPage()
      setLoading(false)
      disclosure.onClose()
      toast({
        title: t('The price is changed.'),
        status: 'success',
      })
    } catch (e) {
      console.error(e)
      setLoading(false)
      const error = getErrorMessage(t, e)
      toast({
        status: 'error',
        title: (
          <ReportToast
            user={user.web3Provider.getSigner()}
            networkId={networkId}
            tokenContract={tokenContract}
            tokenId={tokenId}
            error={error}
          />
        ),
      })
    }
  }

  const defaultValues = useMemo(
    () => ({
      price: !initialPrice.isZero()
        ? utils.formatUnits(initialPrice, decimals)
        : '',
    }),
    [initialPrice, decimals],
  )
  const {
    register,
    watch,
    handleSubmit,
    formState: { touchedFields, errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues,
  })
  const onSubmit: SubmitHandler<Inputs> = (values) => {
    if (!orderInfo) return
    // TODO: DRY if more
    const minExpire = dayjs().add(15, 'minutes')
    if (dayjs(expire.value * 1000).isBefore(minExpire)) {
      toast({
        status: 'error',
        title: t('The expiration date has to be 15 minutes later.'),
      })
      return
    }
    const price = values.price
      ? utils.parseUnits(values.price, decimals)
      : orderPrice
    const onContinue = () => {
      const deadline = expire.value
      if (price.gt(orderPrice)) {
        disclosure.onClose()
        relistModal.onOpen({
          user,
          orderId,
          item: {
            ...bundle[0],
            price: orderPrice0,
            currency,
          },
          price,
          deadline,
          isPrivate,
          taker,
          orderInfo: {
            ...orderInfo,
            royalty_fee_rate: orderCreatorFee ?? orderInfo.royalty_fee_rate,
          },
          onDone: onDone ? onDone : reloadPage,
        })
      } else {
        onUpdate(price, deadline)
      }
    }
    if (floorPrice && parseFloat(values.price) < floorPrice.value * 0.75) {
      floorWarningModal.onOpen({ price, floorPrice, onContinue })
    } else {
      onContinue()
    }
  }

  const price = watch('price')
  const newPrice = (() => {
    try {
      return utils.parseUnits(price, decimals)
    } catch {
      return orderPrice
    }
  })()
  const err =
    errors.price?.message ||
    ((touchedFields.price || isFocused) &&
    floorPrice &&
    parseFloat(price) < floorPrice.value
      ? t('{{percentage}} lower than floor price', {
          percentage: fmtPercentage(
            calcChangePercentage(floorPrice.value, parseFloat(price)),
          ),
        })
      : null)

  const creatorFeePercent = orderInfo
    ? getFeePercent(isPrivate ? 0 : orderInfo.royalty_fee_rate)
    : null
  const orderFeePercent =
    orderCreatorFee != null ? getFeePercent(orderCreatorFee) : null

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Change price')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="flex flex-col space-y-6 lg:space-y-8">
            <NftItemList
              bundle={pipe(
                bundle,
                NEA.map((a) => ({ ...a, price: orderPrice0, currency })),
              )}
            />
            <div>
              {floorPrice && (
                <div className="flex items-center space-x-3">
                  <FormLabelLike className="flex-1">
                    {t('Floor Price')}
                  </FormLabelLike>
                  <div className="flex items-center space-x-1">
                    <TokenIcon className="h-4 w-4" symbol={floorPrice.symbol} />
                    <p className="ts-body-2 font-bold">
                      {fmtNumber(floorPrice.value)}
                    </p>
                  </div>
                </div>
              )}
              {orderFeePercent !== null &&
                creatorFeePercent !== null &&
                orderFeePercent !== creatorFeePercent && (
                  <div className="hidden items-center space-x-3">
                    <p className="ts-hairline-3 flex-1 text-primary-3">
                      {t('Change Royalty (Tips)⚠️')}
                    </p>
                    <p className="ts-body-2 font-bold">
                      {fmtNumber(orderFeePercent)}% →{' '}
                      {fmtNumber(creatorFeePercent)}%
                    </p>
                  </div>
                )}
            </div>
            <FormControl>
              <FormLabel htmlFor="price">
                {t('New Price ({{symbol}})', { symbol })}
              </FormLabel>
              <Tooltip
                isOpen={!!err}
                label={err}
                variant="error"
                placement="top"
                hasArrow
              >
                <Input
                  id="price"
                  placeholder="0.0"
                  {...register('price', {
                    validate: (a) =>
                      !a ? undefined : validateBigNumber(t, decimals)(a),
                  })}
                  isInvalid={!!(errors.price && touchedFields.price)}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  autoComplete="off"
                />
              </Tooltip>
              <FiatPricePreview {...{ price, symbol }} />
            </FormControl>
            {expire.control}
            <Button
              colorScheme="primary-1"
              isLoading={isLoading || !orderInfo}
              isDisabled={
                newPrice.eq(orderPrice) && expire.value === orderDeadline
              }
              type="submit"
            >
              {t('Change price')}
            </Button>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  )
}
