import { Disclosure } from '@headlessui/react'
import { useTranslation } from 'next-i18next'

import { FormLabelLike } from '@/components/form'
import { ArrowUpSimpleLine } from '@/icons'
import { NEA } from '@/lib/fp'
import { Bundle } from '@/lib/nft/detail'

import { NftItem } from './NftItem'

type BundleWithPrice = NEA.NonEmptyArray<
  Bundle[0] & { price: string | null; currency: string | null }
>

type Props = { bundle: BundleWithPrice }
export const NftItemList = ({ bundle }: Props) => {
  const { t } = useTranslation()
  return (
    <Disclosure as="div" className="flex flex-col" defaultOpen>
      <Disclosure.Button className="flex items-center">
        <FormLabelLike className="mr-auto">
          {/* NOTE: No plural here */}
          {t('Items')} ({bundle.length})
        </FormLabelLike>
        <ArrowUpSimpleLine className="ml-4 h-6 w-6 text-gray-500 ui-open:rotate-180 motion-safe:duration-200" />
      </Disclosure.Button>
      <Disclosure.Panel className="mt-6 flex flex-col space-y-3">
        {bundle.map((a, i) => (
          <NftItem item={a} price={a.price} currency={a.currency} key={i} />
        ))}
      </Disclosure.Panel>
    </Disclosure>
  )
}
