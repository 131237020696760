import { BigNumber } from 'ethers'
import { getAddress } from 'ethers/lib/utils'
import { z } from 'zod'

export const isNonNullable = <T>(a: T): a is NonNullable<T> =>
  a !== null && a !== undefined

export function safeParseBigNumber(
  s: string,
): { success: true; data: BigNumber } | { success: false; error: unknown } {
  try {
    return { success: true, data: BigNumber.from(s) }
  } catch (e) {
    return { success: false, error: e }
  }
}

export const bigNumberZ = z.preprocess((x) => {
  if (isNonNullable(x)) {
    const r = safeParseBigNumber(`${x}`)
    if (r.success) return r.data
    else return r.error
  } else {
    return x
  }
}, z.instanceof(BigNumber))

export const addressZ = z
  .string()
  .trim()
  .regex(/^0x[0-9A-Fa-f]{40}$/)
  .transform((x) => getAddress(x))
