import clsx from 'clsx'
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  LabelHTMLAttributes,
} from 'react'

type Props = DetailedHTMLProps<
  LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>
export const FormLabel: FC<Props> = ({ className, ...rest }) => (
  <label
    className={clsx(className, 'ts-hairline-2 mb-3 block text-gray-500')}
    {...rest}
  />
)

// TODO: better naming?
export const FormLabelLike: FC<
  DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>
> = ({ className, ...rest }) => (
  <p className={clsx(className, 'ts-hairline-2 text-gray-500')} {...rest} />
)

export const RequiredIndicator = () => (
  <span className="ml-1 text-primary-3" role="presentation" aria-hidden>
    *
  </span>
)
