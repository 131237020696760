import { Dispatch, useMemo } from 'react'
import { useStorageState } from 'react-storage-hooks'
import { z } from 'zod'

const dummyStorage: Storage = {
  length: 0,
  key: () => null,
  getItem: () => null,
  setItem: () => undefined,
  removeItem: () => undefined,
  clear: () => undefined,
}

const getStorage = (): Storage => {
  try {
    return typeof window === 'undefined' ? dummyStorage : localStorage
  } catch {
    console.log('getStorage(): localStorage is not available')
    return dummyStorage
  }
}

export const usePersistentState = <A, D, O = A>(
  key: string,
  codec: z.Schema<A, D, O>,
) => {
  const [rawState, setState] = useStorageState(getStorage(), key)
  const state = useMemo(() => {
    const r = codec.safeParse(rawState)
    return r.success ? r.data : undefined
  }, [codec, rawState])
  return [state, setState as Dispatch<A>] as const
}
