import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  size,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { useState } from 'react'

type Props = {
  placement?: Placement
  offset?: Parameters<typeof offset>[0]
  width?: 'match-reference' | number
}
export const usePopover = ({
  placement = 'bottom',
  offset: offsetValue = 16,
  width,
}: Props) => {
  const [isOpen, setOpen] = useState(false)
  const { x, y, reference, floating, strategy } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setOpen,
    middleware: [
      offset(offsetValue),
      flip(),
      shift({ padding: 16 }),
      size({
        padding: 8,
        apply({ availableWidth, availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
          })
          if (width) {
            Object.assign(elements.floating.style, {
              width:
                width === 'match-reference'
                  ? `${rects.reference.width}px`
                  : `${width}px`,
            })
          }
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const { getReferenceProps, getFloatingProps } = useInteractions()
  const referenceProps = {
    ref: reference,
    ...getReferenceProps(),
  }
  const floatingProps = getFloatingProps({
    ref: floating,
    style: {
      position: strategy,
      top: y ?? 0,
      left: x ?? 0,
    },
  })
  return { isOpen, reference, referenceProps, floatingProps }
}
