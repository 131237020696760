import clsx from 'clsx'
import { Children, CSSProperties, forwardRef, ReactChild } from 'react'

type Props = {
  className?: string
  style?: CSSProperties
  children: ReactChild
  ratio: number
}
export const AspectRatio = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, style, children, ratio } = props
  const child = Children.only(children)
  return (
    <div
      ref={ref}
      className={clsx(className, 'aspect-ratio relative')}
      style={style}
    >
      <div
        className="aspect-ratio-placeholder"
        style={{ paddingBottom: `${(1 / ratio) * 100}%` }}
      />
      {child}
    </div>
  )
})

AspectRatio.displayName = 'AspectRatio'
