import { increment, pipe } from 'fp-ts/function'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'

import * as api from '@/lib/api'
import { isSameAddress } from '@/lib/auth/types/user'
import { A, O, TE } from '@/lib/fp'
import { extractNftMetadata } from '@/lib/nft/metadata'
import { Item } from '@/lib/shoppingCart'
import * as store from '@/lib/store'
import toast from '@/lib/toast'
import { parseErcTypeToTokenStandard } from '@/lib/x2y2/utils'

const LIMIT = 20

const upsertItem = (items: Item[], nextItems: Item[], item: Item) => {
  const i = items.findIndex(
    (a) =>
      isSameAddress(a.contract, item.contract) && a.tokenId === item.tokenId,
  )
  if (i !== -1) {
    nextItems[i] = item
  } else {
    nextItems.push(item)
  }
}

type Props = {
  isLoading: boolean
}
export const useX2Y2Cart = ({ isLoading }: Props) => {
  const { t } = useTranslation()
  const [items, _setItems] = useState<Item[]>([])
  const itemsIds = useMemo(() => new Set(items.map((a) => a.id)), [items])
  const setItems = useCallback((xs: Item[]) => {
    _setItems(xs)
    store.shoppingCartItems.set(xs)()
  }, [])

  // Inherit shopping cart from local storage
  const [refreshToken, setRefreshToken] = useState(0)
  useEffect(() => {
    pipe(
      store.shoppingCartItems.get(),
      TE.fromEither,
      TE.chain((prevItems): TE.TaskEither<string, Item[]> => {
        if (prevItems.length === 0) return TE.right([])
        return pipe(
          api.ordersByIds(prevItems.map((a) => a.id)),
          TE.map((newItems) => {
            return pipe(
              prevItems,
              A.filterMap((prevItem) => {
                const newItem = newItems.find((a) => a.id === prevItem.id)
                if (!newItem) return O.none
                if (!newItem.nft) return O.none
                return O.some({
                  id: newItem.id,
                  price: newItem.price,
                  currency: newItem.currency,
                  contract: newItem.nft_contract.contract,
                  contractName: newItem.nft_contract.name,
                  contractVerified: newItem.nft_contract.verified,
                  tokenId: newItem.nft.token_id,
                  tokenStandard: parseErcTypeToTokenStandard(
                    newItem.nft_contract.erc_type,
                  ),
                  meta: extractNftMetadata(newItem.nft.metadata?.meta),
                })
              }),
            )
          }),
        )
      }),
      TE.map((xs) => {
        setItems(xs)
        return null
      }),
      (task) => task(),
    )
  }, [setItems, refreshToken])

  const addItem = useCallback(
    (item: Item) => {
      if (isLoading) return
      if (items.length >= LIMIT) {
        toast({
          status: 'warning',
          title: t('You can add maximum of 20 items into shopping cart'),
        })
        return
      }
      const nextItems = [...items]
      upsertItem(items, nextItems, item)
      setItems(nextItems)
    },
    [items, setItems, isLoading, t],
  )
  const addItems = useCallback(
    (newItems: Item[]) => {
      if (isLoading) return false
      if (items.length + newItems.length > LIMIT) {
        toast({
          status: 'warning',
          title: t('You can add maximum of 20 items into shopping cart'),
        })
        return false
      }
      const nextItems = [...items]
      for (const item of newItems) {
        upsertItem(items, nextItems, item)
      }
      setItems(nextItems)
      return true
    },
    [isLoading, items, setItems, t],
  )

  const removeItem = useCallback(
    (id: number) => {
      if (isLoading) return
      setItems(items.filter((a) => a.id !== id))
    },
    [items, setItems, isLoading],
  )

  const itemInCart = useCallback((id: number) => itemsIds.has(id), [itemsIds])
  const clear = useCallback(() => setItems([]), [setItems])
  const reload = useCallback(() => setRefreshToken(increment), [])
  const remove = useCallback(
    (ids: number[]) => setItems(items.filter((a) => !ids.includes(a.id))),
    [items, setItems],
  )
  const applyChanges = useCallback(
    (changed: { contract: string; tokenId: string; price: string }[]) => {
      setItems(
        items.map((a) => {
          const r = changed.find(
            (c) =>
              isSameAddress(a.contract, c.contract) && a.tokenId === c.tokenId,
          )
          return r ? { ...a, price: r.price } : a
        }),
      )
    },
    [items, setItems],
  )

  return {
    items,
    addItem,
    addItems,
    removeItem,
    itemInCart,
    clear,
    reload,
    remove,
    applyChanges,
  }
}
