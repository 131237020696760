import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'

import { Button } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import { UseDisclosureReturn } from '@/hooks'

export type FloorPrice = { currency: string; symbol: string; value: number }

export type FloorWarningProps = {
  title: string
  desc: ReactNode
  action: string
  onContinue: () => void
}
type Props = FloorWarningProps & {
  disclosure: UseDisclosureReturn
}
export const FloorWarningModal = ({
  disclosure,
  title,
  desc,
  action,
  onContinue,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      {/* Place on top of tooltip in change price modal */}
      <ModalContent className="z-[1800]">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-6 lg:space-y-8">
          <p className="ts-body-3 lg:ts-body-2">{desc}</p>
          <div className="flex space-x-3 lg:space-x-4">
            <Button
              className="flex-1"
              variant="outline"
              onClick={disclosure.onClose}
            >
              {t('Cancel')}
            </Button>
            <Button
              className="flex-1"
              colorScheme="primary-3"
              onClick={() => {
                disclosure.onClose()
                onContinue()
              }}
            >
              {action}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
