import crypto from 'crypto'
import { BigNumber, ethers, providers } from 'ethers'

import { http, HttpRequestConfig, HttpResponse } from '@/lib/http'
import { definitions } from '@/lib/openapi/uniapiV2'

import { LOAN_CONTRACT_MAPS } from './loan'
import { OfferSignature, XY3Offer } from './types'

const API_KEY = '8923f924-1f00-4a6a-b484-088e6ffed7f3'
const KVV = 2

type MethodType = 'get' | 'post'

const generateSignatureString = (
  method: MethodType,
  nonce: number,
  path: string,
  query: Record<string, string>,
  version: number,
) => {
  if (version === 2) {
    return generateSignatureStringV2(method, nonce, path, query)
  }
  const keys = Object.keys(query)
  const queryList = keys
    .filter((key: string) => query[key] !== undefined)
    .map((key: string) => `${encodeURI(key)}=${encodeURI(query[key])}`)
    .sort((a: string, b: string): number => (a > b ? -1 : 1))
  const sep = `;${queryList.length % 2 === 0 ? '#-' : '–#'};`
  const ts = Math.floor(nonce / 10) * 10 + queryList.length
  const qs = queryList.join('&')
  const sah256 = crypto.createHash('sha256')
  sah256.update(Buffer.from(`${API_KEY}${sep}${method}${sep}${ts}${sep}${qs}`))
  return sah256.digest('hex')
}

const generateSignatureStringV2 = (
  method: MethodType,
  nonce: number,
  path: string,
  query: Record<string, string>,
) => {
  const keys = Object.keys(query)
  const queryList = keys
    .filter((k) => query[k] !== undefined)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
    .sort((a, b) => (a > b ? -1 : 1))
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  let sepFlag = true
  if (queryList.length % 2 !== 0) {
    sepFlag = !sepFlag
  }
  if (path.length % 2 === 0) {
    sepFlag = !sepFlag
  }
  const sep = `;${sepFlag ? '#-' : '–#'};`
  const ts = Math.floor(nonce / 10) * 10 + queryList.length
  const qs = queryList.join('&')
  const sah256 = crypto.createHash('sha256')
  const bufferString = `${API_KEY}${sep}${method}${sep}${ts}${sep}${path};${qs}`
  sah256.update(Buffer.from(bufferString))
  return sah256.digest('hex')
}

const xy3RequestConfig = (
  nonce: number,
  sig: string | null,
  token: string | null,
): HttpRequestConfig => {
  const config: HttpRequestConfig = {
    baseURL: process.env.NEXT_PUBLIC_UNIAPI_ENDPOINT,
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
  }
  if (config.headers) {
    if (sig) {
      config.headers['X-API-KEY'] = API_KEY
      config.headers['X-KVV'] = `${KVV}`
      config.headers['X-NONCE'] = `${nonce}`
      config.headers['X-SIG'] = sig
    }
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
  }
  return config
}

const queryParams = (query: Record<string, string>): string => {
  const keys = Object.keys(query)
  return keys
    .filter((key: string) => query[key] !== undefined)
    .map(
      (key: string) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
    )
    .join('&')
}

export const get = async <T = any>(
  url: string,
  query: Record<string, any>,
  token: string | null,
): Promise<HttpResponse<T>> => {
  const nonce = Math.floor(Date.now() / 1000)
  const sig = generateSignatureString('get', nonce, url, query, KVV)
  const requestConfig: HttpRequestConfig = xy3RequestConfig(nonce, sig, token)
  const params = queryParams(query)
  return await http.get<T>(`${url}${params ? '?' + params : ''}`, requestConfig)
}

export const post = async <T = any>(
  url: string,
  params: Record<string, any>,
  token: string | null,
): Promise<HttpResponse<T>> => {
  const nonce = Math.floor(Date.now() / 1000)
  const requestConfig: HttpRequestConfig = xy3RequestConfig(nonce, null, token)
  return await http.post<T>(url, params, requestConfig)
}

export const postQuery = async <T = any>(
  url: string,
  query: Record<string, any>,
  data: any,
): Promise<HttpResponse<T>> => {
  const nonce = Math.floor(Date.now() / 1000)
  const sig = generateSignatureString('post', nonce, url, query, KVV)
  const requestConfig: HttpRequestConfig = xy3RequestConfig(nonce, sig, null)
  const params = queryParams(query)
  const postUrl = `${url}${params ? '?' + params : ''}`
  return await http.post<T>(postUrl, data, requestConfig)
}

export const calcNonce = () => {
  const randomHex = '0x' + crypto.randomBytes(32).toString('hex')
  return BigNumber.from(randomHex).toString()
}

export const encodeMessageV2 = (
  chainId: number,
  userAddress: string,
  params: definitions['MakeOffer2Params'],
  extra: string,
) => {
  const offer: XY3Offer = {
    borrowAsset: params.erc20Address,
    borrowAmount: params.amount,
    repayAmount: params.repayment,
    nftAsset: params.contractAddress,
    borrowDuration: params.duration,
    timestamp: params.createTime,
    extra,
  }
  const offerPack = ethers.utils.solidityPack(
    ['address', 'uint256', 'uint256', 'address', 'uint32', 'uint256', 'bytes'],
    [
      offer.borrowAsset,
      offer.borrowAmount,
      offer.repayAmount,
      offer.nftAsset,
      offer.borrowDuration,
      offer.timestamp,
      offer.extra,
    ],
  )
  const lenderSignature: OfferSignature = {
    nonce: params.nonce,
    expiry: params.expiresTime,
    signer: userAddress,
    signature: '',
  }
  return encodeOffer(
    offerPack,
    params.tokenId,
    lenderSignature,
    LOAN_CONTRACT_MAPS[chainId],
    chainId,
  )
}

export const encodeOffer = (
  offerPack: string,
  tokenId: string | undefined,
  signature: OfferSignature,
  loanContract: string,
  chainId: number,
) => {
  const signaturePack = ethers.utils.solidityPack(
    ['address', 'uint256', 'uint256'],
    [signature.signer, signature.nonce, signature.expiry], // signer(lender), nonce, expiry
  )
  return tokenId
    ? ethers.utils.solidityKeccak256(
        ['bytes', 'uint256', 'bytes', 'address', 'uint256'],
        [offerPack, tokenId, signaturePack, loanContract, chainId],
      )
    : ethers.utils.solidityKeccak256(
        ['bytes', 'bytes', 'address', 'uint256'],
        [offerPack, signaturePack, loanContract, chainId],
      )
}

export const sign = async (
  web3Provider: providers.Web3Provider,
  message: string,
  userAddress: string,
) => {
  const orderSig = (await web3Provider.send('personal_sign', [
    message,
    userAddress,
  ])) as string
  const v = parseInt(orderSig.slice(130, 132), 16)
  return `${orderSig.substring(0, 130)}${(v < 27 ? v + 27 : v).toString(16)}`
}
