import {
  LOAN_GET_TERMS_LIST_URL,
  LOAN_OFFER_LIST_URL,
  LOAN_ORDERS_LIST_URL,
  LOAN_USER_CURRENT_LEND_ORDER_LIST_URL,
  LOAN_USER_OFFER_LIST_URL,
} from '@/lib/loan/apiPaths'
import { WithRequiredProperty } from '@/types/utils'

import { definitions, paths } from '../openapi/uniapiV2'
import { LoanOfferType, LoanType, StatusType } from './url'

type OfferListExchange = paths[typeof LOAN_OFFER_LIST_URL]['get']

type RawOfferListQuery = OfferListExchange['parameters']['query']
export type OfferListQuery = Omit<
  Required<RawOfferListQuery>,
  'userAddress' | 'isSufficient'
> & {
  userAddress?: RawOfferListQuery['userAddress']
  isSufficient?: RawOfferListQuery['isSufficient']
}
export type OfferListQueryWithoutNftParams = Omit<
  OfferListQuery,
  'contractAddress' | 'tokenId'
>

export type OfferListResp =
  OfferListExchange['responses']['200']['schema']['data']

export type Duration = OfferListQuery['duration']

export type OfferItem = definitions['OfferItem']

export type OfferListQueryAction =
  | {
      type: 'SET_DURATION'
      payload: OfferListQuery['duration']
    }
  | {
      type: 'SET_PAGE'
      payload: OfferListQuery['page']
    }
  | {
      type: 'SET_ORDER_AND_SORT'
      payload: {
        order: OfferListQuery['order']
        sort?: OfferListQuery['sort']
      }
    }
  | {
      type: 'SET_ISSUFFICIENT'
      payload: {
        isSufficient?: OfferListQuery['isSufficient']
      }
    }

type UserCurrentLendOrderListExchange =
  paths[typeof LOAN_USER_CURRENT_LEND_ORDER_LIST_URL]['get']

export type UserOrderListQuery = Required<
  UserCurrentLendOrderListExchange['parameters']['query']
> & {
  loanType: LoanType
  statusType: StatusType
}

export type UserOrderListResp =
  UserCurrentLendOrderListExchange['responses']['200']['schema']['data']

export type UserOrderListQueryAction =
  | {
      type: 'SET_LOAN_TYPE'
      payload: UserOrderListQuery['loanType']
    }
  | {
      type: 'SET_STATUS_TYPE'
      payload: UserOrderListQuery['statusType']
    }
  | {
      type: 'SET_PAGE'
      payload: UserOrderListQuery['page']
    }
  | {
      type: 'RESET'
      payload: Partial<UserOrderListQuery>
    }

export type OrderItem = definitions['OrderItem']

type UserOfferListExchange = paths[typeof LOAN_USER_OFFER_LIST_URL]['get']

export type UserOfferListQuery = Required<
  UserOfferListExchange['parameters']['query']
> & {
  offerType: LoanOfferType
}

export type UserOfferListResp =
  UserOfferListExchange['responses']['200']['schema']['data']

export type UserOfferListQueryAction =
  | {
      type: 'SET_PAGE'
      payload: UserOfferListQuery['page']
    }
  | {
      type: 'SET_OFFER_TYPE'
      payload: UserOfferListQuery['offerType']
    }
  | {
      type: 'RESET'
      payload: Partial<UserOfferListQuery>
    }

export type LoanParams = definitions['LoanParams']

export type Collection = definitions['Xy3Collection']

export type SupportedTokenInfo = definitions['SupportedTokenInfo']

export type OfferQuoteItem = definitions['xy3.OfferQuoteItem']

export type TermItem = WithRequiredProperty<
  definitions['xy3.TermItem'],
  'collectionAddress' | 'tokenId'
>

type TermsListExchange = paths[typeof LOAN_GET_TERMS_LIST_URL]['get']
export type TermsListResp =
  TermsListExchange['responses']['200']['schema']['data']

type OrderListExchange = paths[typeof LOAN_ORDERS_LIST_URL]['get']
export type OrderListResp =
  OrderListExchange['responses']['200']['schema']['data']

export enum LoanOfferStatus {
  Pending = 10,
  Confirming = 20,
  Completed = 30,
  Cancelled = 40,
}

export enum LoanOrderStatus {
  Escrow = 30,
  Repaid = 40,
  Defaulted = 50,
  Claimed = 60,
}
