import { constants } from 'ethers'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

import { ApiRes } from '@/lib/api/types'
import { isNonAnynomousUser, User } from '@/lib/auth/types/user'
import { http } from '@/lib/http'
import { OrderInfo } from '@/lib/nft/detail'
import toast from '@/lib/toast'
import { x2y2RequestConfig } from '@/lib/x2y2/utils'

export type PaymentInfo = Record<string, OrderInfo>

type Props = {
  isActive: boolean
  user: User
  networkId: number
  contracts: string[]
}
export const usePaymentInfo = ({
  isActive,
  user,
  networkId,
  contracts,
}: Props) => {
  const { t } = useTranslation()

  const [isFetching, setIsFetching] = useState(true)
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>()

  useEffect(() => {
    // Prevent fetch during bulk select
    if (!isActive) {
      setIsFetching(false)
    } else {
      const action = async () => {
        setIsFetching(true)
        try {
          const { data } = await http.post<ApiRes<PaymentInfo>>(
            '/api/contracts/payment_info',
            {
              address: isNonAnynomousUser(user)
                ? user.meta.address
                : constants.AddressZero,
              contracts,
              network_id: networkId,
            },
            x2y2RequestConfig(user._tag === 'registered' ? user.token : null),
          )
          if (!data.success) {
            throw Error('not success')
          }
          setPaymentInfo(data.data)
        } catch (e) {
          setPaymentInfo(null)
          console.error(e)
          toast({ status: 'error', title: t("Something's wrong") })
        } finally {
          setIsFetching(false)
        }
      }
      action()
    }
  }, [t, contracts, networkId, isActive, user])

  return { isPaymentInfoFetching: isFetching, paymentInfo }
}
