import { ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
}
/**
 * Just for easier migration path from chakra, and maybe future extension
 */
export const FormControl = ({ className, children }: Props) => (
  <div className={className} role="group">
    {children}
  </div>
)
