import { BigNumber, providers, utils } from 'ethers'

import { ERC20__factory, WETH__factory } from '@/lib/contract'
import {
  MARKET_CONTRACT_MAPS,
  WETH_CONTRACT_MAPS,
  X2Y2_CART_CONTRACT_MAPS,
} from '@/lib/x2y2'
import {
  approveErc20 as approve,
  isApprovedErc20 as isApproved,
} from '@/lib/xy3'
import { getProviderByNetworkId } from '@/utils/network'

import { ApproveTarget } from './types'

export const CHECK_RESULT_BALANCE = -1
export const CHECK_RESULT_APPROVE = 0
export const CHECK_RESULT_OK = 1

export const isApprovedErc20 = async (
  networkId: number,
  contract: string,
  amount: BigNumber,
  user: providers.JsonRpcSigner,
  isCart: boolean,
  target: ApproveTarget,
) => {
  const token = ERC20__factory.connect(contract, user)
  const owner = await user.getAddress()
  if (target === 'xy3') {
    return isApproved(networkId, token, owner, amount)
  }
  const operator = isCart
    ? X2Y2_CART_CONTRACT_MAPS[networkId]
    : MARKET_CONTRACT_MAPS[networkId]
  const allowance = await token.allowance(owner, operator)
  return allowance.gte(amount)
}

export const approveErc20 = async (
  networkId: number,
  contract: string,
  user: providers.JsonRpcSigner,
  isCart: boolean,
  target: ApproveTarget,
) => {
  const token = ERC20__factory.connect(contract, user)
  // TODO: Review amount
  const amount = utils.parseEther('100000000')
  if (target === 'xy3') {
    await approve(networkId, token, amount)
  } else {
    const operator = isCart
      ? X2Y2_CART_CONTRACT_MAPS[networkId]
      : MARKET_CONTRACT_MAPS[networkId]
    const tx = await token.approve(operator, amount)
    await tx.wait()
  }
}

export const checkBalance = async (
  networkId: number,
  contract: string,
  amount: BigNumber,
  user: providers.JsonRpcSigner,
  maker: string,
  isCart: boolean,
): Promise<number> => {
  const token = ERC20__factory.connect(contract, user)
  const address = maker ? maker : await user.getAddress()
  const balance = await token.balanceOf(address)
  if (balance.lt(amount)) {
    return CHECK_RESULT_BALANCE
  }
  if (maker) {
    const operator = isCart
      ? X2Y2_CART_CONTRACT_MAPS[networkId]
      : MARKET_CONTRACT_MAPS[networkId]
    const allowance = await token.allowance(maker, operator)
    if (allowance.lt(amount)) {
      return CHECK_RESULT_APPROVE
    }
  }
  return CHECK_RESULT_OK
}

export const swap = async (param: {
  networkId: number
  user: providers.JsonRpcSigner
  amountToEth: BigNumber
  amountToWeth: BigNumber
}) => {
  const AMOUNT_0 = BigNumber.from(0)
  const weth = WETH_CONTRACT_MAPS[param.networkId]
  const contract = WETH__factory.connect(weth, param.user)
  if (param.amountToEth.gt(AMOUNT_0)) {
    const tx = await contract.withdraw(param.amountToEth)
    await tx.wait()
  } else if (param.amountToWeth.gt(AMOUNT_0)) {
    const tx = await contract.deposit({ value: param.amountToWeth })
    await tx.wait()
  }
}

export const checkEthTransfer = async (networkId: number, to: string) => {
  const provider = getProviderByNetworkId(networkId)
  const from = WETH_CONTRACT_MAPS[networkId]
  try {
    await provider.estimateGas({
      from,
      to,
      value: utils.parseEther('0.01').toHexString(),
    })
  } catch (e) {
    console.error(e)
    return false
  }
  return true
}
