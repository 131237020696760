import { Trans, useTranslation } from 'next-i18next'

import { Button } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import Link from '@/components/TwLink'
import { UseDisclosureReturn } from '@/hooks'
import { EtherscanFilled } from '@/icons'
import { fmtEthAddr } from '@/lib/formatter'
import { scannerContractURL } from '@/utils/network'

export type VerifyProps = {
  networkId: number
  tokenContract: string
  contractName: string
  action: () => void
}
type Props = VerifyProps & {
  disclosure: UseDisclosureReturn
}
export const VerifyModal = ({
  disclosure,
  networkId,
  tokenContract,
  contractName,
  action,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Unreviewed')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-6 lg:space-y-8">
          <div className="space-y-3">
            <div className="flex items-center space-x-3 rounded-2xl bg-modal-section-bg p-4">
              <div className="min-w-0 flex-1">
                <p className="ts-headline-6 truncate">{contractName}</p>
                <p className="ts-caption text-gray-500">
                  {fmtEthAddr(tokenContract)}
                </p>
              </div>
              <Link
                hoverStyle="opacity"
                aria-label={contractName}
                href={scannerContractURL(tokenContract, networkId)}
                isExternal
              >
                <EtherscanFilled className="h-5 w-5 text-gray-500" />
              </Link>
            </div>
            <p className="ts-body-3 lg:ts-body-2">
              <Trans t={t}>
                The contract is{' '}
                <strong className="text-primary-3">not reviewed by X2Y2</strong>
                . Please make sure it is the correct one for{' '}
                <strong>{{ contractName }}</strong> that you want to buy.
              </Trans>
            </p>
          </div>
          <div className="flex space-x-3 lg:space-x-4">
            <Button
              className="flex-1"
              variant="outline"
              onClick={disclosure.onClose}
            >
              {t('Cancel')}
            </Button>
            <Button
              className="flex-1"
              colorScheme="primary-1"
              onClick={() => {
                disclosure.onClose()
                action()
              }}
            >
              {t('Continue')}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
