import clsx from 'clsx'

type Props<T extends string> = {
  options: T[]
  selected: T
  onSelect: (a: T) => void
  className?: string
  size?: 'sm' | 'md'
}

export const ScopeSelect = <T extends string>({
  options,
  selected,
  onSelect,
  className,
  size = 'md',
}: Props<T>) => {
  return (
    <ul
      className={clsx(
        'flex items-center overflow-hidden rounded-lg border-2 border-gray-300',
        size === 'md' ? 'px-2.5' : 'px-1.5',
        className,
      )}
    >
      {options.map((option) => (
        <li key={option}>
          <button
            onClick={() => {
              if (option !== selected) {
                onSelect(option)
              }
            }}
            className={clsx(
              'ts-input-2 transition-all hover:text-gray-700',
              option === selected ? 'text-gray-700' : 'text-gray-400',
              size === 'md' ? 'py-2 px-1.5' : 'py-1 px-1',
            )}
          >
            {option.replace('d', 'D')}
          </button>
        </li>
      ))}
    </ul>
  )
}
