import clsx from 'clsx'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { AnchorHTMLAttributes, forwardRef, PropsWithChildren } from 'react'

export type LinkProps = PropsWithChildren<
  NextLinkProps &
    AnchorHTMLAttributes<HTMLAnchorElement> & {
      hoverStyle?: 'underline' | 'opacity' | 'none'
      isExternal?: boolean
    }
>

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      href,
      as,
      replace,
      scroll = true,
      shallow,
      prefetch,
      locale,
      children,
      hoverStyle = 'underline',
      isExternal = false,
      className,
      onClick,
      ...rest
    },
    ref,
  ): JSX.Element => {
    return (
      <NextLink
        passHref={true}
        href={href}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        prefetch={prefetch}
        locale={locale}
      >
        <a
          ref={ref}
          className={clsx(
            className,
            hoverStyle === 'underline'
              ? 'hover:underline'
              : hoverStyle === 'opacity'
              ? 'hover:opacity-50'
              : undefined,
          )}
          target={isExternal ? '_blank' : undefined}
          rel={isExternal ? 'noopener noreferrer' : undefined}
          onClick={onClick}
          {...rest}
        >
          {children}
        </a>
      </NextLink>
    )
  },
)

Link.displayName = 'Link'

export default Link
