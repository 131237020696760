import { RegisteredUser } from '@/lib/auth/types/user'
import {
  Collection,
  LoanOfferType,
  LoanType,
  OfferItem,
  OfferListResp,
  OfferQuoteItem,
  OrderItem,
  OrderListResp,
  StatusType,
  SupportedTokenInfo,
  TermsListResp,
  UserOrderListResp,
} from '@/lib/loan'
import { definitions, paths } from '@/lib/openapi/uniapiV2'

import {
  LOAN_BATCH_LOAN_GET_TERMS_INFO_URL,
  LOAN_CANCEL_ALL_OFFER_URL,
  LOAN_CANCEL_OFFER_URL,
  LOAN_CANCEL_TERMS_URL,
  LOAN_COLLECTION_STATUS_URL,
  LOAN_COLLECTION_SUPPORT_URL,
  LOAN_GET_TERMS_INFO_URL,
  LOAN_GET_TERMS_LIST_URL,
  LOAN_MAKE_OFFER2_URL,
  LOAN_NFT_STATUS_URL,
  LOAN_OFFER_LIST_URL,
  LOAN_OFFER_QUOTE_URL,
  LOAN_ORDERS_LATEST_URL,
  LOAN_ORDERS_LIST_URL,
  LOAN_PARAMS_URL,
  LOAN_SERVER_SIGN_URL,
  LOAN_SET_TERMS_URL,
  LOAN_STATS_URL,
  LOAN_UNLOCK_OFFER_URL,
  LOAN_USER_COLLECTION_OFFER_LIST_URL,
  LOAN_USER_CURRENT_BORROW_ORDER_LIST_URL,
  LOAN_USER_CURRENT_LEND_ORDER_LIST_URL,
  LOAN_USER_HISTORY_BORROW_ORDER_LIST_URL,
  LOAN_USER_HISTORY_LEND_ORDER_LIST_URL,
  LOAN_USER_OFFER_LIST_URL,
  LOAN_USER_TOKENS_SUPPORT_URL,
  LOAN_WHITELIST_URL,
} from '../loan/apiPaths'
import { RunInput } from '../market'
import { ERROR_0001, LoanError } from './errors'
import * as loan from './loan'
import { calcNonce, encodeMessageV2, get, post, postQuery, sign } from './utils'

type PathType = keyof paths

export const getSysStats = async (): Promise<
  definitions['LoanStats'] | undefined
> => {
  try {
    const url: PathType = LOAN_STATS_URL
    type Res = paths[typeof url]['get']['responses'][200]['schema']
    const resp = await get<Res>(url, {}, null)
    if (resp.status === 200 && resp.data.code === 0) {
      return resp.data.data
    }
  } catch (ignored) {}
  return undefined
}

export const getSysParam = async (): Promise<
  definitions['LoanParams'] | undefined
> => {
  try {
    const url: PathType = LOAN_PARAMS_URL
    type Res = paths[typeof url]['get']['responses'][200]['schema']
    const resp = await get<Res>(url, {}, null)
    if (resp.status === 200 && resp.data.code === 0) {
      return resp.data.data
    }
  } catch (ignored) {}
  return undefined
}

export const getWhitelist = async (): Promise<string[]> => {
  try {
    const url: PathType = LOAN_WHITELIST_URL
    type Res = paths[typeof url]['get']['responses'][200]['schema']
    const resp = await get<Res>(url, {}, null)
    if (resp.status === 200 && resp.data.code === 0) {
      return resp.data.data ?? []
    }
  } catch (ignored) {}
  return []
}

export const getCollectionsSupport = async (): Promise<Collection[]> => {
  const url: PathType = LOAN_COLLECTION_SUPPORT_URL
  type Res = paths[typeof url]['get']['responses'][200]['schema']
  const resp = await get<Res>(url, {}, null)
  if (resp.status !== 200 || resp.data.code !== 0 || !resp.data.data) {
    throw Error('not ok')
  }
  return resp.data.data
}

export const getCollectionStatus = async (
  address: string,
): Promise<definitions['CollectionStats'] | undefined> => {
  try {
    const url: PathType = LOAN_COLLECTION_STATUS_URL
    type Res = paths[typeof url]['get']['responses'][200]['schema']
    const resp = await get<Res>(url.replace(/{address}/, address), {}, null)
    if (resp.status === 200 && resp.data.code === 0) {
      return resp.data.data
    }
  } catch (ignored) {}
  return undefined
}

export const getNftStatus = async (
  address: string,
  tokenId: string,
): Promise<definitions['xy3.NFTResponseBodyProperty'] | undefined> => {
  try {
    const url: PathType = LOAN_NFT_STATUS_URL
    type Res = paths[typeof url]['get']['responses'][200]['schema']
    const resp = await get<Res>(
      url.replace(/{address}/, address).replace(/{tokenId}/, tokenId),
      {},
      null,
    )
    if (resp.status === 200 && resp.data.code === 0) {
      return resp.data.data
    }
  } catch (ignored) {}
  return undefined
}

const offerListUrl: PathType = LOAN_OFFER_LIST_URL
export type OfferListQuery =
  paths[typeof offerListUrl]['get']['parameters']['query']

export const getOfferList = async (
  query: OfferListQuery,
): Promise<OfferListResp> => {
  type Res = paths[typeof offerListUrl]['get']['responses'][200]['schema']
  const resp = await get<Res>(offerListUrl, query, null)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export type UserOfferListQuery = {
  offerType: LoanOfferType
  page?: number
  pageSize?: number
}

export const getUserOfferList = async (
  address: string,
  query: UserOfferListQuery,
): Promise<OfferListResp> => {
  const offerType = query.offerType
  const url: PathType =
    offerType === 'offer'
      ? LOAN_USER_OFFER_LIST_URL
      : LOAN_USER_COLLECTION_OFFER_LIST_URL
  type Res = paths[typeof url]['get']['responses'][200]['schema']
  const resp = await get<Res>(
    url.replace(/{address}/, address),
    { page: query.page, pageSize: query.pageSize },
    null,
  )
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const getOrdersList = async (query: {
  collection?: string
  page?: number
  pageSize?: number
}): Promise<OrderListResp> => {
  const url: PathType = LOAN_ORDERS_LIST_URL
  type Res = paths[typeof url]['get']['responses'][200]['schema']
  const resp = await get<Res>(url, query, null)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export type UserOrderListQuery = {
  loanType: LoanType
  statusType: StatusType
  page?: number
  pageSize?: number
}

export const getUserOrderList = async (
  address: string,
  query: UserOrderListQuery,
): Promise<UserOrderListResp> => {
  const { loanType, statusType } = query
  const url: PathType =
    loanType === 'lend'
      ? statusType === 'current'
        ? LOAN_USER_CURRENT_LEND_ORDER_LIST_URL
        : LOAN_USER_HISTORY_LEND_ORDER_LIST_URL
      : statusType === 'current'
      ? LOAN_USER_CURRENT_BORROW_ORDER_LIST_URL
      : LOAN_USER_HISTORY_BORROW_ORDER_LIST_URL
  type Res = paths[typeof url]['get']['responses'][200]['schema']
  const resp = await get<Res>(
    url.replace(/{address}/, address),
    { page: query.page, pageSize: query.pageSize },
    null,
  )
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const getUserSupportedTokens = async (
  address: string,
): Promise<SupportedTokenInfo[]> => {
  const url: PathType = LOAN_USER_TOKENS_SUPPORT_URL
  type Res = paths[typeof url]['get']['responses'][200]['schema']
  const resp = await get<Res>(url.replace(/{address}/, address), {}, null)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data ?? []
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const getLatestOrder = async (
  params: definitions['xy3.BaseNftParams'],
): Promise<definitions['OrderItem']> => {
  const url: PathType = LOAN_ORDERS_LATEST_URL
  type Res = paths[typeof url]['get']['responses'][200]['schema']
  const resp = await get<Res>(url, params, null)
  if (resp.status !== 200 || resp.data.code !== 0 || !resp.data.data) {
    throw Error('not ok')
  }
  return resp.data.data
}

export const makeOffer = async (
  user: RegisteredUser,
  params: {
    adminFee?: number | undefined
  } & MakeOfferV2Params,
): Promise<definitions['MakeOfferResponse'] | undefined> => {
  if (!params.createTime) {
    params.createTime = Math.floor(Date.now() / 1000)
  }
  params.expiresTime =
    params.expiresTime +
    (params.expiresTime < params.createTime ? params.createTime : 0)
  params.nonce = calcNonce()
  return await makeOfferV2(user, params)
}

const makeOfferV2Url: PathType = LOAN_MAKE_OFFER2_URL
type MakeOfferV2Params =
  paths[typeof makeOfferV2Url]['post']['parameters']['body']['params']

export const makeOfferV2 = async (
  user: RegisteredUser,
  params: MakeOfferV2Params,
): Promise<definitions['MakeOfferResponse'] | undefined> => {
  type Res = paths[typeof makeOfferV2Url]['post']['responses'][200]['schema']
  const provider = user.web3Provider
  const chainId = provider.network.chainId
  const signer = provider.getSigner()
  const userAddress = await signer.getAddress()
  const extra = params.extra ?? '0x00'
  params.extra = extra
  const message = encodeMessageV2(chainId, userAddress, params, extra)
  params.signature = await sign(provider, message, userAddress)
  const resp = await post<Res>(makeOfferV2Url, params, user.token)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

const cancelOfferUrl: PathType = LOAN_CANCEL_OFFER_URL
export type CancelOfferParams =
  paths[typeof cancelOfferUrl]['post']['parameters']['body']['params']

export const cancelOffer = async (
  networkId: number,
  user: RegisteredUser,
  params: CancelOfferParams,
): Promise<void> => {
  const { web3Provider, token } = user
  const signer = web3Provider.getSigner()
  const tx = await loan.cancel(signer, networkId, params.nonce)
  try {
    type Res = paths[typeof cancelOfferUrl]['post']['responses'][200]['schema']
    const resp = await post<Res>(cancelOfferUrl, params, token)
    if (resp.status !== 200 || resp.data.code !== 0) {
      console.warn(
        'cancel offer api error',
        resp.data.code?.toString() ?? resp.data.msg,
      )
    }
  } catch (e) {
    console.warn('cancel offer api error', e)
  }
  await tx.wait()
}

const cancelAllOfferUrl: PathType = LOAN_CANCEL_ALL_OFFER_URL
export type CancelAllOfferParams =
  paths[typeof cancelAllOfferUrl]['post']['parameters']['body']['params']

export const cancelAllOffer = async (
  networkId: number,
  user: RegisteredUser,
  params: CancelAllOfferParams,
): Promise<void> => {
  const { web3Provider, token } = user
  const signer = web3Provider.getSigner()
  // todo timestamp from api?
  const blockNumber: number = await web3Provider.getBlockNumber()
  const timestamp = (await web3Provider.getBlock(blockNumber)).timestamp - 1
  const tx = await loan.cancelAll(signer, networkId, timestamp)
  try {
    type Res =
      paths[typeof cancelAllOfferUrl]['post']['responses'][200]['schema']
    const resp = await post<Res>(cancelAllOfferUrl, params, token)
    if (resp.status !== 200 || resp.data.code !== 0) {
      console.warn(
        'cancel all offer api error',
        resp.data.code?.toString() ?? resp.data.msg,
      )
    }
  } catch (e) {
    console.warn('cancel all offer api error', e)
  }
  await tx.wait()
}

const serverSignUrl: PathType = LOAN_SERVER_SIGN_URL
export type ServerSignParams =
  paths[typeof serverSignUrl]['post']['parameters']['body']['params']

const signServerData = async (
  token: string,
  offerItem: definitions['OfferItem'],
  params: ServerSignParams,
) => {
  if (!offerItem.amount || !offerItem.repayment) {
    throw new LoanError(ERROR_0001)
  }
  type Res = paths[typeof serverSignUrl]['post']['responses'][200]['schema']
  const resp = await post<Res>(
    serverSignUrl,
    { ...params, contract: offerItem.contractAddress },
    token,
  )
  if (resp.status === 200 && resp.data.code === 0 && resp.data.data) {
    const signMsg = resp.data.data
    return {
      brokerSignature: {
        nonce: signMsg.nonce,
        expiry: signMsg.expireTime,
        signer: signMsg.address,
        signature: signMsg.signature,
      },
      lenderSignature: {
        nonce: offerItem.nonce,
        expiry: offerItem.expireTime,
        signer: offerItem.lender,
        signature: offerItem.signature,
      },
      offer: {
        borrowAmount: offerItem.amount ?? '0',
        repayAmount: offerItem.repayment ?? '0',
        nftAsset: offerItem.contractAddress,
        borrowDuration: offerItem.duration,
        borrowAsset: offerItem.erc20Address,
        adminShare: offerItem.adminFee, // only for v1
        timestamp: offerItem.createdAt,
        extra: offerItem.extra, // only for v2
      },
    }
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const acceptOffer = async (
  networkId: number,
  user: RegisteredUser,
  offerItem: definitions['OfferItem'],
  params: ServerSignParams,
) => {
  const { web3Provider, token } = user
  const signer = web3Provider.getSigner()
  const data = await signServerData(token, offerItem, params)
  const isCollectionOffer = !offerItem.tokenId
  const tokenId = offerItem.tokenId ?? params.tokenId
  return await loan.acceptV2(
    signer,
    networkId,
    data.offer,
    tokenId,
    isCollectionOffer,
    data.lenderSignature,
    data.brokerSignature,
    undefined,
  )
}

export const refinance = async (
  networkId: number,
  user: RegisteredUser,
  offerItem: definitions['OfferItem'],
  params: ServerSignParams,
  order: OrderItem,
) => {
  const isCollectionOffer = !offerItem.tokenId
  const tokenId = offerItem.tokenId ?? params.tokenId
  if (
    order.contractAddress.toLowerCase() !==
      offerItem.contractAddress.toLowerCase() ||
    order.tokenId !== tokenId
  ) {
    throw new LoanError(ERROR_0001)
  }
  const { web3Provider, token } = user
  const signer = web3Provider.getSigner()
  const data = await signServerData(token, offerItem, params)
  return await loan.acceptV2(
    signer,
    networkId,
    data.offer,
    tokenId,
    isCollectionOffer,
    data.lenderSignature,
    data.brokerSignature,
    order,
  )
}

export const downPay = async (
  networkId: number,
  user: RegisteredUser,
  loanOffer: OfferItem,
  params: ServerSignParams,
  x2y2OrderRunInput: RunInput,
) => {
  const isCollectionOffer = !loanOffer.tokenId
  const tokenId = loanOffer.tokenId ?? params.tokenId
  const { web3Provider, token } = user
  const signer = web3Provider.getSigner()
  const data = await signServerData(token, loanOffer, params)
  return await loan.borrowToBuy(
    signer,
    networkId,
    data.offer,
    tokenId,
    isCollectionOffer,
    data.lenderSignature,
    data.brokerSignature,
    x2y2OrderRunInput,
  )
}

export const queryOfferQuote = async (
  params: definitions['xy3.BaseNftParams'][],
): Promise<OfferQuoteItem[]> => {
  const url: PathType = LOAN_OFFER_QUOTE_URL
  type Res = paths[typeof url]['post']['responses'][200]['schema']
  const resp = await postQuery<Res>(url, {}, params)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data ?? []
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const batchGetTermInfo = async (
  params: definitions['xy3.BatchTermInfoParams'],
): Promise<definitions['xy3.TermItem'][]> => {
  const url: PathType = LOAN_BATCH_LOAN_GET_TERMS_INFO_URL
  type Res = paths[typeof url]['post']['responses'][200]['schema']
  const resp = await postQuery<Res>(url, {}, params)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data ?? []
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const setTerms = async (
  user: RegisteredUser,
  params: definitions['SetTermParams'],
): Promise<definitions['SetTermResponse'] | undefined> => {
  const url: PathType = LOAN_SET_TERMS_URL
  type Res = paths[typeof url]['post']['responses'][200]['schema']
  const resp = await post<Res>(url, params, user.token)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const cancelTerms = async (
  user: RegisteredUser,
  params: definitions['xy3.BaseNftParams'],
): Promise<void> => {
  const url: PathType = LOAN_CANCEL_TERMS_URL
  type Res = paths[typeof url]['post']['responses'][200]['schema']
  const resp = await post<Res>(url, params, user.token)
  if (resp.status !== 200 || resp.data.code !== 0) {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const getTermInfo = async (
  query: definitions['xy3.BaseNftParams'],
): Promise<definitions['xy3.TermItem'] | undefined> => {
  const url: PathType = LOAN_GET_TERMS_INFO_URL
  type Res = paths[typeof url]['get']['responses'][200]['schema']
  const resp = await get<Res>(url, query, null)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data
  } else if (resp.status === 400 && resp.data.code === 1040400) {
    return undefined
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const getTermList = async (
  query: definitions['xy3.GetTermsParams'],
): Promise<TermsListResp> => {
  const url: PathType = LOAN_GET_TERMS_LIST_URL
  type Res = paths[typeof url]['get']['responses'][200]['schema']
  const resp = await get<Res>(url, query, null)
  if (resp.status === 200 && resp.data.code === 0) {
    return resp.data.data
  } else {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}

export const unlockOffer = async (
  user: RegisteredUser,
  params: definitions['xy3.OfferUnlockParams'],
): Promise<void> => {
  const url: PathType = LOAN_UNLOCK_OFFER_URL
  type Res = paths[typeof url]['post']['responses'][200]['schema']
  const resp = await post<Res>(url, params, user.token)
  if (resp.status !== 200 || resp.data.code !== 0) {
    throw new LoanError(resp.data.code?.toString() ?? ERROR_0001)
  }
}
