import { BigNumber, utils } from 'ethers'
import { TFunction } from 'next-i18next'

export const validateNumber = (t: TFunction) => (value: string) =>
  Number.isFinite(parseFloat(value)) ? undefined : t('The number is invalid.')

export const validateBigNumber =
  (t: TFunction, decimals: number) => (value: string) => {
    try {
      const bn = utils.parseUnits(value, decimals)
      if (bn.lte(0)) return t('The number is invalid.')
      const max = utils.parseUnits('10000000000', decimals)
      if (bn.gt(max)) return t('The number is too large.')
    } catch (e) {
      return t('The number is invalid.')
    }
  }

// NOTE: Must validateBigNumber before using this!
export const validateMinValue =
  (t: TFunction, decimals: number, minPrice: BigNumber) => (value: string) => {
    const bn = utils.parseUnits(value, decimals)
    if (bn.lt(minPrice)) return t('The number is invalid.')
  }

export const validateMaxValue =
  (t: TFunction, decimals: number, maxPrice: BigNumber) => (value: string) => {
    const bn = utils.parseUnits(value, decimals)
    if (bn.gt(maxPrice)) return t('The number is invalid.')
  }

export const validateMaxNeqValue =
  (t: TFunction, decimals: number, maxPrice: BigNumber) => (value: string) => {
    const bn = utils.parseUnits(value, decimals)
    if (bn.gte(maxPrice)) return t('The number is invalid.')
  }
