import { pipe } from 'fp-ts/lib/function'
import { TFunction, useTranslation } from 'next-i18next'

import { O } from '@/lib/fp'

import { Currency, currencyDigits } from './currency'
import { toChecksumAddress } from './web3'

const truncEthAddr = (a: string, compact: boolean) =>
  compact ? a.slice(0, 6) : `${a.slice(0, 6)}…${a.slice(-4)}`

export const fmtEthAddr = (addr: string, compact = false) => {
  const len = addr.length
  if (len <= 10) return addr
  try {
    const a = toChecksumAddress(addr)
    return truncEthAddr(a, compact)
  } catch {
    return truncEthAddr(addr, compact)
  }
}

export const fmtLargeNumber = (num: number) => {
  const units = ['', 'K', 'M', 'B']
  let unitsIdx = 0
  while (num >= 1000 && unitsIdx < units.length - 1) {
    num /= 1000
    unitsIdx += 1
  }
  return (
    num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }) + units[unitsIdx]
  )
}

export const fmtNumber = (
  num: number,
  fraction?: { min?: number; max?: number },
): string => {
  if (num !== 0 && !num) return '' // TODO: Remove
  if (num >= 10_000) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: fraction?.min ?? 0,
      maximumFractionDigits: fraction?.max ?? 0,
    })
  } else if (num >= 100) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: fraction?.min ?? 0,
      maximumFractionDigits: fraction?.max ?? 2,
    })
  } else if (num >= 1) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: fraction?.min ?? 0,
      maximumFractionDigits: fraction?.max ?? 4,
    })
  } else if (num > 0) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: fraction?.min ?? 0,
      maximumFractionDigits: fraction?.max ?? 8,
    })
  } else if (num === 0) {
    return '0'
  } else {
    return '-' + fmtNumber(-num, fraction)
  }
}

// TODO: remove this
export const fmtNumber_Temp = (
  num: number,
  fraction?: { min?: number; max?: number },
): string => {
  if (num !== 0 && !num) return '' // TODO: Remove
  if (num >= 1_0000) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: fraction?.min ?? 0,
      maximumFractionDigits: fraction?.max ?? 0,
    })
  } else if (num >= 100) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: fraction?.min ?? 0,
      maximumFractionDigits: fraction?.max ?? 2,
    })
  } else if (num >= 1) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: fraction?.min ?? 0,
      maximumFractionDigits: fraction?.max ?? 4,
    })
  } else if (num > 0) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: fraction?.min ?? 0,
      maximumFractionDigits: fraction?.max ?? 8,
    })
  } else if (num === 0) {
    return '0'
  } else {
    return '-' + fmtNumber(-num, fraction)
  }
}

export const fmtCurrencyNumber = (num: number, currency: Currency): string =>
  num.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: currencyDigits(currency),
  })

export const fmtOffsetNumber = (num: number): string =>
  num >= 0 ? `+${fmtNumber(num)}` : `${fmtNumber(num)}`

export const fmtPercentage = (x: number, fractionNumbers?: number) => {
  if (x > 0 && x < 0.1) return '< 0.1%'
  return x.toFixed(fractionNumbers ?? 1) + '%'
}
export const fmtChangePercent = (x: number, fractionNumbers?: number) =>
  (x > 0 ? '+' : '') + x.toFixed(fractionNumbers ?? 1) + '%'

export const fmtOptionalPercentage = (
  x: number | null | undefined,
  fractionNumbers?: number,
) =>
  pipe(
    O.fromNullable(x),
    O.map((a) => fmtPercentage(a, fractionNumbers)),
    O.getOrElse(() => '-'),
  )

export const fmtPoolLiquidityRatio = (num: number | null) =>
  num && num < 0.01 ? '< 0.01%' : fmtOptionalPercentage(num, 2)

export const fmtAlertPrice = (price: number) =>
  price < 1 ? fmtNumber(1 / price) + ':1' : '1:' + fmtNumber(price)

export const useFormatter = () => {
  const { t } = useTranslation()
  const fmtDuration = (duration: number): string => {
    if (duration <= 0) {
      return ''
    }
    const h = duration / 1000 / 3600
    if (h < 0.05) {
      return ''
    } else if (h < 24) {
      return t('{{hours}} hour', { count: h, hours: h.toFixed(1) })
    }
    const d = h / 24
    return t('{{days}} day', { count: d, days: d.toFixed(1) })
  }
  return { fmtDuration }
}

export const fmtCountdown = (seconds: number) => {
  if (seconds < 0) return '-'
  const pad = (n: number) => (n < 10 ? '0' + n : n)
  const h = Math.floor(seconds / 3600)
  const m = pad(Math.floor(seconds / 60) % 60)
  const s = pad(Math.floor(seconds % 60))
  return `${h}:${m}:${s}`
}

export const fmtDuration = (t: TFunction, seconds: number) =>
  seconds < 3600
    ? t('{{count}} mins', { count: Math.floor(seconds / 60) })
    : seconds < 24 * 3600
    ? t('{{count}} hours', { count: Math.floor(seconds / 3600) })
    : t('{{count}} days', { count: Math.floor(seconds / 3600 / 24) })

export const fmtDurationScope = (seconds: number) =>
  seconds < 3600
    ? `${Math.floor(seconds / 60)}m`
    : seconds < 24 * 3600
    ? `${Math.floor(seconds / 3600)}h`
    : `${Math.floor(seconds / 3600 / 24)}D`
