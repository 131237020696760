import clsx from 'clsx'
import { BigNumber, utils } from 'ethers'
import { pipe } from 'fp-ts/function'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { MouseEventHandler, useState } from 'react'

import { Button } from '@/components/form'
import { Tooltip } from '@/components/overlay'
import { useModal } from '@/hooks'
import useCurrency from '@/hooks/useCurrency'
import { CreditCard3Line } from '@/icons'
import { enableBuyWithCrossmint } from '@/lib/features'
import { NEA } from '@/lib/fp'
import { Bundle } from '@/lib/nft/detail'
import { getTokenMeta } from '@/lib/token'
import { OpenSeaMetadata } from '@/lib/unimeta'
import { DEFAULT_NETWORK } from '@/utils/network'

import { BuyWithCreditCardModal, CardType } from './BuyWithCreditCardModal'
import { CreditCard } from './CreditCard'

const MotionButton = motion(Button)
const MotionCreditCard3Line = motion(CreditCard3Line)

export type AllItem = {
  contractAddress: string
  tokenId: string
  price: string
}

type Props = {
  price: BigNumber
  currency: string
  bundle?: Bundle
  meta?: OpenSeaMetadata
  isHover: boolean
  setHover: (hover: boolean) => void
  allItems?: AllItem[]
}
export const BuyWithCreditCard = ({
  price,
  currency,
  bundle,
  meta,
  isHover,
  setHover,
  allItems,
}: Props) => {
  const { t } = useTranslation()
  const { getUSDNumber } = useCurrency()
  const [showModal, setShowModal] = useState<CardType>(null)
  const token = getTokenMeta(DEFAULT_NETWORK, currency)
  const priceNumber = price
    ? getUSDNumber(
        parseFloat(utils.formatUnits(price, token.decimals)),
        token.symbol,
      )
    : 0
  const limitAmount = {
    winter: 10000,
    crossmint: 10000,
    paper: 4620, // 3ETH
  }

  const buyAllItemsInCart = allItems?.length
  const showBuyWithWinterCard =
    priceNumber > 0 && priceNumber < limitAmount.winter && !buyAllItemsInCart
  const showBuyWithCrossmintCard =
    priceNumber > 0 &&
    priceNumber < limitAmount.crossmint &&
    enableBuyWithCrossmint
  const showBuyWithPaperCard =
    priceNumber > 0 && priceNumber < limitAmount.paper && !buyAllItemsInCart
  const showBuyWithCreditCard =
    showBuyWithWinterCard || showBuyWithCrossmintCard || showBuyWithPaperCard
  const showBuyWithCreditModal =
    Number(showBuyWithWinterCard) +
      Number(showBuyWithCrossmintCard) +
      Number(showBuyWithPaperCard) >
    1
  const buyWithCreditCardModal = useModal(BuyWithCreditCardModal)
  const service = showBuyWithWinterCard
    ? 'Winter'
    : showBuyWithCrossmintCard
    ? 'Crossmint'
    : 'Paper'

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.currentTarget.blur()
    showBuyWithCreditModal && bundle
      ? buyWithCreditCardModal.onOpen({
          bundle: pipe(
            bundle,
            NEA.map((a) => ({ ...a, meta: meta ?? {} })),
          ),
          price,
          currency,
          display: {
            showBuyWithWinterCard,
            showBuyWithCrossmintCard,
            showBuyWithPaperCard,
          },
        })
      : setShowModal(service)
  }

  return showBuyWithCreditCard ? (
    <div className="flex items-center">
      <Tooltip
        label={
          !showBuyWithCreditModal && t('Provided by {{service}}', { service })
        }
        placement="top"
        hasArrow
      >
        <MotionButton
          className={clsx(
            '!rounded-none',
            isHover ? '!absolute inset-0 !px-[22px]' : 'ml-px',
          )}
          colorScheme="primary-1"
          onClick={onClick}
          layout
          onHoverStart={() => setHover(true)}
          onHoverEnd={() => setHover(false)}
        >
          <MotionCreditCard3Line layout className="h-4 w-4" />
          {isHover && (
            <span className="ml-3 truncate">{t('Buy with credit card')}</span>
          )}
        </MotionButton>
      </Tooltip>
      {buyWithCreditCardModal.component}
      {!showBuyWithCreditModal && (
        <CreditCard
          {...{
            bundle,
            showModal,
            setShowModal,
            allItems,
          }}
        />
      )}
    </div>
  ) : null
}
