import { BigNumber, constants, utils } from 'ethers'
import { pipe } from 'fp-ts/function'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Button, FormControl, Input } from '@/components/form'
import { FormLabel, PopoverSelect } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { UseDisclosureReturn } from '@/hooks'
import { useLoading, UseModalReturn } from '@/hooks'
import { useExpireOption, usePaymentInfo } from '@/hooks/nft'
import { MegaphoneLine } from '@/icons'
import { RegisteredUser } from '@/lib/auth/types/user'
import { validateBigNumber } from '@/lib/formik'
import { NEA } from '@/lib/fp'
import { buyOffer, getErrorMessage, INTENT_BUY } from '@/lib/market'
import { Bundle } from '@/lib/nft/detail'
import toast from '@/lib/toast'
import { getTokenMeta } from '@/lib/token'

import { FloorPriceDesc, NftItemList } from '../shared'
import { ApproveTokenProps } from './ApproveTokenModal'
import { FloorPrice } from './FloorWarningModal'

type Inputs = {
  price: string
}
const defaultValues: Inputs = { price: '' }

export type OfferProps = {
  user: RegisteredUser
  bundle: Bundle
  sellOrder: { id: number; currency: string } | null
  floorPrice: FloorPrice | null
  osFloorPrice: number
  approveTokenModal: UseModalReturn<ApproveTokenProps>
}
type Props = OfferProps & {
  disclosure: UseDisclosureReturn
}
export const OfferModal = ({
  disclosure,
  user,
  bundle,
  sellOrder,
  floorPrice,
  osFloorPrice,
  approveTokenModal,
}: Props) => {
  const { t } = useTranslation()

  const [currency, setCurrency] = useState<string | null>(null)

  const { token: tokenContract, tokenId } = bundle[0].tokenPair
  const { networkId } = bundle[0].info
  const { decimals } = getTokenMeta(networkId, currency ?? '')
  const { isLoading, setLoading, guardLoading, reloadPage } = useLoading()

  const contracts = useMemo(() => [tokenContract], [tokenContract])
  const orderInfo = usePaymentInfo({
    isActive: disclosure.isOpen,
    user,
    networkId,
    contracts,
  }).paymentInfo?.[tokenContract]

  const currencies = useMemo(
    () => orderInfo?.supported_tokens[INTENT_BUY] ?? [],
    [orderInfo],
  )
  useEffect(() => {
    if (currencies.length === 0) return
    setCurrency(currencies[0] ?? null)
  }, [currencies])

  const expireOption = useExpireOption()

  const onOffer =
    (price: BigNumber) =>
    async (setLoading: (a: boolean) => void, approveModalCb?: () => void) => {
      if (!currency) return
      setLoading(true)
      try {
        const tokens = bundle.map((a) => a.tokenPair)
        const deadline = expireOption.value
        await buyOffer({
          networkId,
          user,
          items: [{ tokens, price }],
          isCollection: false,
          isBundle: bundle.length > 1,
          orderId: sellOrder?.id ?? 0,
          currency,
          deadline,
        })
        await reloadPage()
        setLoading(false)
        approveModalCb?.() // Close approve modal
        disclosure.onClose()
        toast({
          title: t('An offer is created.'),
          status: 'success',
        })
      } catch (e) {
        console.error(e)
        setLoading(false)
        const error = getErrorMessage(t, e)
        toast({
          status: 'error',
          title: (
            <ReportToast
              user={user.web3Provider.getSigner()}
              networkId={networkId}
              tokenContract={tokenContract}
              tokenId={tokenId}
              error={error}
            />
          ),
        })
      }
    }

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues,
  })

  const onSubmit: SubmitHandler<Inputs> = (values) => {
    if (!currency) return
    const price = utils.parseUnits(values.price, decimals)
    if (currency === constants.AddressZero) {
      onOffer(price)(setLoading)
    } else {
      disclosure.onClose()
      approveTokenModal.onOpen({
        provider: user.web3Provider,
        networkId,
        currency,
        amount: price,
        title: t('Make Offer'),
        subtitle: t('Complete signing'),
        desc: t('Click the Sign button to create an offer.'),
        loadingText: t('Waiting for signature…'),
        stayWhenDone: false,
        actionText: t('Sign'),
        onAction: onOffer(price),
      })
    }
  }

  const title = t('Make Offer')

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            className="flex flex-col space-y-6 lg:space-y-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <NftItemList
              bundle={pipe(
                bundle,
                NEA.map((a) => ({
                  ...a,
                  // TODO: Use price from sell order?
                  price: null,
                  currency: null,
                })),
              )}
            />
            <FormControl>
              <FormLabel htmlFor="price">{t('Price')}</FormLabel>
              <div className="flex space-x-4">
                <Input
                  id="price"
                  placeholder="0.0"
                  {...register('price', {
                    validate: validateBigNumber(t, decimals),
                  })}
                  isInvalid={!!(errors.price && touchedFields.price)}
                  isDisabled={currencies.length === 0}
                  autoComplete="off"
                />
                <PopoverSelect
                  className="h-12 max-w-[100px]"
                  items={currencies.map((a) => ({
                    key: a,
                    title: getTokenMeta(networkId, a).symbol,
                    value: a,
                  }))}
                  value={currency ?? ''}
                  onChange={setCurrency}
                />
              </div>
              {errors.price && (
                <p className="ts-caption-2 mt-1 text-primary-3">
                  {errors.price?.message}
                </p>
              )}
              {floorPrice && (
                <FloorPriceDesc
                  className="mt-1"
                  floorPrice={floorPrice}
                  osFloorPrice={osFloorPrice}
                />
              )}
            </FormControl>
            {expireOption.control}
            <Button
              colorScheme="primary-1"
              leftIcon={<MegaphoneLine className="h-4 w-4" />}
              isLoading={isLoading}
              type="submit"
            >
              {title}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
