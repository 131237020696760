import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

import { FormLabel } from '@/components/form'
import {
  defaultExpireOption,
  DurationPicker,
  getDeadline,
} from '@/components/nft/detail/DurationPicker'

type Props = { deadline?: number }
export const useExpireOption = ({ deadline: initialDeadline }: Props = {}) => {
  const { t } = useTranslation()
  const [deadline, setDeadline] = useState(
    initialDeadline ?? getDeadline(defaultExpireOption),
  )
  const onDateUpdate = useCallback((a) => setDeadline(Math.round(a / 1000)), [])
  const control = (
    <div>
      <FormLabel htmlFor="expire">{t('Expires After')}</FormLabel>
      <DurationPicker
        onDateUpdate={onDateUpdate}
        initialDate={
          initialDeadline ? new Date(initialDeadline * 1000) : undefined
        }
      />
    </div>
  )

  return { value: deadline, control }
}
