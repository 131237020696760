import { ColorMode } from './types'

// NOTE: Use the same classes as in tailwind
export const classNames = {
  light: 'light',
  dark: 'dark',
}

export function getColorModeUtils() {
  const utils = {
    setDataset: (value: ColorMode) => {
      document.documentElement.dataset.theme = value
      document.documentElement.style.colorScheme = value
    },
    setClassName(dark: boolean) {
      document.body.classList.add(dark ? classNames.dark : classNames.light)
      document.body.classList.remove(dark ? classNames.light : classNames.dark)
    },
    query() {
      return window.matchMedia('(prefers-color-scheme: dark)')
    },
    getSystemTheme(fallback?: ColorMode) {
      const dark = utils.query().matches ?? fallback === 'dark'
      return dark ? 'dark' : 'light'
    },
    addListener(fn: (cm: ColorMode) => unknown) {
      const mql = utils.query()
      const listener = (e: MediaQueryListEvent) => {
        fn(e.matches ? 'dark' : 'light')
      }

      if (typeof mql.addListener === 'function') mql.addListener(listener)
      else mql.addEventListener('change', listener)

      return () => {
        if (typeof mql.removeListener === 'function')
          mql.removeListener(listener)
        else mql.removeEventListener('change', listener)
      }
    },
  }

  return utils
}
