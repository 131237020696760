import { ComponentType, useState } from 'react'

import { useDisclosure, UseDisclosureReturn } from '@/hooks'

export type UseModalReturn<OpenProps> = {
  // NOTE: Prevent inserting multiple component into DOM
  // component: JSX.Element | null
  onOpen: (props: OpenProps) => void
}
export const useModal = <OpenProps,>(
  Modal: ComponentType<{ disclosure: UseDisclosureReturn } & OpenProps>,
) => {
  const disclosure = useDisclosure()
  const [props, setProps] = useState<OpenProps | null>(null)
  const onOpen = (props: OpenProps) => {
    setProps(props)
    disclosure.onOpen()
  }
  const onClose = () => {
    disclosure.onClose()
    setProps(null)
  }
  const component = props && (
    <Modal disclosure={{ ...disclosure, onClose }} {...props} />
  )
  return { component, onOpen, isOpen: disclosure.isOpen }
}
