import { constVoid, pipe } from 'fp-ts/lib/function'
import { createContext, FC, useContext, useEffect, useState } from 'react'

import { D, E } from '@/lib/fp'
import * as store from '@/lib/store'

// MARK: Utils

// NOTE: Use partial here to prevent data loss when adding new entry
export const browserPreferenceDecoder = D.partial({
  gridStyle: D.union(D.literal('normal'), D.literal('compact')),
  showSidePanel: D.boolean,
  clickToCart: D.boolean,
  rankingOrderBy: D.string,
})
type BrowserPreference = Required<D.TypeOf<typeof browserPreferenceDecoder>>
const DEFAULT_BROWSER_PREFERENCE: BrowserPreference = {
  gridStyle: 'normal',
  showSidePanel: false,
  clickToCart: false,
  rankingOrderBy: '',
}

export const browserPreferenceStore = store.genEntry(
  'browser-preference',
  browserPreferenceDecoder,
)

// MARK: Ctx

type BrowserPreferenceCtxState = {
  value: BrowserPreference
  set: (payload: Partial<BrowserPreference>) => void
}

const DEFAULT_BROWSER_PREFERENCE_CTX_STATE: BrowserPreferenceCtxState = {
  value: DEFAULT_BROWSER_PREFERENCE,
  set: constVoid,
}

const BrowserPreferenceContext = createContext<BrowserPreferenceCtxState>(
  DEFAULT_BROWSER_PREFERENCE_CTX_STATE,
)
export const useBrowserPreference = () => useContext(BrowserPreferenceContext)

export const BrowserPreferenceProvider: FC = ({ children }) => {
  const [value, setValue] = useState(DEFAULT_BROWSER_PREFERENCE)
  useEffect(() => {
    pipe(
      browserPreferenceStore.get(),
      E.map((a) => setValue({ ...DEFAULT_BROWSER_PREFERENCE, ...a })),
    )
  }, [])
  const set = (payload: Partial<BrowserPreference>) => {
    setValue((prev) => {
      const newValue = { ...prev, ...payload }
      browserPreferenceStore.set(newValue)()
      return newValue
    })
  }
  return (
    <BrowserPreferenceContext.Provider value={{ value, set }}>
      {children}
    </BrowserPreferenceContext.Provider>
  )
}
