import { D } from './fp'
import { openseaMetadataDecoder } from './unimeta'

export const itemDecoder = D.struct({
  id: D.number,
  price: D.string,
  currency: D.string,

  contract: D.string,
  contractName: D.string,
  contractVerified: D.boolean,
  tokenId: D.string,
  tokenStandard: D.union(D.literal('erc721'), D.literal('erc1155')),
  meta: openseaMetadataDecoder,
})
export type Item = D.TypeOf<typeof itemDecoder>
