import { TFunction } from 'next-i18next'

export const ERROR_0000 = '0000' // Wrong Contract
export const ERROR_0001 = '0001' // API Error
export const ERROR_0002 = '0002' // Insufficient WETH balance
export const ERROR_1090403 = '1090403' // Server maintenance

export class LoanError extends Error {
  public constructor(errorMsg: string) {
    super()
    this.name = this.constructor.name
    this.message = errorMsg
  }
}

export const loanErrorMessages = (t: TFunction): Record<string, string> => ({
  [ERROR_0000]: t('Unknown error occurred'),
  [ERROR_0001]: t('Unknown error occurred'),
  [ERROR_0002]: t('Insufficient WETH balance'),
  [ERROR_1090403]: t('Server maintenance'), // server maintenance 服务器维护
  // '1003': t('remote error'), // todo
  '1004': t('Invalid parameters'),
  '1013': t('Invalid API Key'), // ApiKey
  '1014': t('You are making requests too frequently'), // Signature
  '1018': t('You are creating too many offers'),
  '1019': t('You are making too many server sign requests'),
  '1010401': t('The repayment must be no less than loan value'), // 归还金额小于出借金额
  '1010402': t('The amount is either too small or too large'), // amount 支持最小值为：xxx, 目前为：xxx
  '1010403': t('The APR is either too small or too large'), // APR允许范围[xxx],当前为：xxx
  '1010404': t('The admin fee is not correct'), // adminFee 必须是xxx
  '1010405': t('The currency is not supported'), // erc20 仅支持[xxx],当前是:xxx
  '1010406': t('The duration is not supported'), // duration 仅支持[xxx], 当前是：xxx
  '1010407': t('The NFT is not supported as collateral'), // 不支持的NFT交易: xxx
  '1010408': t('The signature is invalid'), // 签名验证失败
  '1010409': t('The nonce is invalid'), // nonce不能重复
  '1010410': t('The expire date is invalid'), // 订单有效期允许范围[xxx],当前是：xxx
  '1010411': t('Invalid parameter'), // Invalid parameter
  '1010412': t('Not allowed Collection offer'), // Collection offer is unsupported for the collection
  '1020404': t('The order does not exist'), // 订单信息未找到
  '1030100': t('The offer does not exist'), // 订单不存在
  '1030101': t('The offer is locked'), // 订单被锁
  '1030102': t('The number of offers locked exceeds the limit'), // 超过限制
  '1030110': t('The order is expired already'), // 订单已过期
  '1030200': t('The NFT is forbidden'), // 黑点不可下单
  '1030201': t(
    'Failed to check whether the NFT is marked as suspicious or not',
  ), // Failed to check whether the NFT is marked as suspicious or not
  '1030300': t('Signature error'), // 签名信息出错
  '1030400': t('Fail to obtain the signature'), // 请求签名服出错
  '1030410': t('Fail to obtain the signature'), // 签名服出错
  '1040400': t('Unsupported NFT collection'), // 未支持的合约
  '1040401': t('Failed to get default ERC20 addresses'), // 无法获取默认Erc20
  '1040402': t('ERC20 address not supported'), // Erc20地址不对
  '1040403': t('Failed to cancel the term'), // 取消term失败
  '1040404': t('No corresponding data'), // 没有对应的数据
  '1040501': t('Failed to update the term'), // 更新term失败
  '1040502': t('Failed to create an term'), // 创建term失败
  '1090100': t('System error'), // 系统配置错误
  '1091501': t('System error'), // adminFee 没有配置
  '1091502': t('System error'), // minimumAmount 没有配置
  '1091503': t('System error'), // 没有配置
  '1091504': t('System error'), // tokenContract 没有配置
  '1091505': t('System error'), // duration period 没有配置
  '1091506': t('System error'), // offerExpireRange 没有配置
})
