import { OpenSeaMetadata } from '../unimeta'
import { NOT_FOUND_NFTS } from './constants'

export const convertIpfsUrl = (url: string) => {
  let res = url
  const target = 'https://img.x2y2.io/ipfs/'
  for (const pt of ['https://ipfs.io/ipfs/', 'ipfs://']) {
    res = res.replace(pt, target)
  }
  return res
}

export const itemDisplayName = (
  meta: Pick<OpenSeaMetadata, 'name'>,
  contract: string,
  tokenId: string,
) => meta.name || contract + ' #' + tokenId

export const shouldHide = (contract: string, tokenId: string): boolean => {
  const nft = `${contract.toLowerCase()}/${tokenId.toLowerCase()}`
  return NOT_FOUND_NFTS.includes(nft)
}
