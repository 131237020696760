import clsx from 'clsx'

const valueToPercent = (value: number, min: number, max: number) =>
  ((value - min) * 100) / (max - min)

type CircularProgressProps = {
  className: string
  radius?: number
  strokWidth?: number
  color?: 'primary-1' | 'primary-3' | 'secondary-3'
  trackColor?: 'gray' | 'primary-3' | 'yellow-bg'
  value: number
  min?: number
  max?: number
}
export const CircularProgress = ({
  className,
  radius = 42,
  strokWidth = 18,
  color = 'primary-1',
  trackColor = 'gray',
  value,
  min = 0,
  max = 100,
}: CircularProgressProps) => {
  const fillStyle = {
    'primary-1': /*tw:*/ 'text-primary-1',
    'primary-3': /*tw:*/ 'text-primary-3',
    'secondary-3': /*tw:*/ 'text-secondary-3',
  }
  const trackStyle = {
    gray: /*tw:*/ 'text-gray-300',
    'primary-3': /*tw:*/ 'text-primary-3-100',
    'yellow-bg': /*tw:*/ 'text-yellow-bg',
  }
  // Building a Progress Ring: https://css-tricks.com/building-progress-ring-quickly/
  const circumference = radius * 2 * Math.PI
  const percent = valueToPercent(value, min, max)
  return (
    <svg className={className} viewBox="0 0 100 100">
      <circle
        className={trackStyle[trackColor]}
        strokeWidth={strokWidth}
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx="50"
        cy="50"
      />
      <circle
        className={clsx('origin-center -rotate-90', fillStyle[color])}
        strokeWidth={strokWidth}
        strokeDasharray={circumference}
        strokeDashoffset={circumference - (percent / 100) * circumference}
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx="50"
        cy="50"
      />
    </svg>
  )
}
