import { Client } from '@elastic/elasticsearch'
import { pipe } from 'fp-ts/lib/function'
import { TFunction } from 'next-i18next'

import { ON_PROD } from '@/lib/env'
import { A, D, NEA, O } from '@/lib/fp'

import * as utils from './utils'

const INDEX_NAME = ON_PROD ? 'x2y2-avtivity' : 'x2y2-avtivity-stag'

export const categoryDecoder = D.union(
  D.literal('listing'),
  D.literal('sale'),
  D.literal('offer'),
  D.literal('transfer'),
)
export type Category = D.TypeOf<typeof categoryDecoder>
export const ALL_CATEGORIES: Category[] = [
  'listing',
  'sale',
  'offer',
  'transfer',
]
export const DEFAULT_CATEGORIES: Category[] = ['sale']
export const categoryTitle = (t: TFunction, tp: Category): string => {
  switch (tp) {
    case 'listing':
      return t('Listing')
    case 'sale':
      return t('Sale')
    case 'offer':
      return t('Offer')
    case 'transfer':
      return t('Transfer')
  }
}
export const categoryToType = (category: Category) => {
  switch (category) {
    case 'listing':
      return 'offer_listing'
    case 'sale':
      return 'new_sale'
    case 'offer':
      return 'new_buy_offer'
    case 'transfer':
      return 'transfer'
  }
}

export const searchPayloadDecoder = D.partial({
  filters: D.partial({
    network: D.number,
    user: D.string,
    categories: D.array(categoryDecoder),
    contracts: D.array(D.number),
    nft: D.number,
    bundle: D.number,
  }),
  limit: D.number,
  offset: D.number,
})
export type SearchPayload = D.TypeOf<typeof searchPayloadDecoder>

export const search = (client: Client, payload: SearchPayload) => {
  const query = {
    bool: {
      must: A.compact<Record<string, unknown>>([
        pipe(
          O.fromNullable(payload.filters?.network),
          O.map((network_id) => ({ term: { network_id } })),
        ),
        pipe(
          O.fromNullable(payload.filters?.user),
          O.map((address) => ({
            bool: {
              should: [
                { term: { from_address: address } },
                { term: { to_address: address } },
              ],
            },
          })),
        ),
        pipe(
          O.fromNullable(payload.filters?.categories ?? ALL_CATEGORIES),
          O.chain(NEA.fromArray),
          O.map((xs) => ({
            bool: {
              should: xs.map((category) => {
                return {
                  term: { type: categoryToType(category) },
                }
              }),
            },
          })),
        ),
        pipe(
          O.fromNullable(payload.filters?.contracts),
          O.chain(NEA.fromArray),
          O.map((xs) => ({
            bool: {
              should: xs.map((contract_id) => ({ term: { contract_id } })),
            },
          })),
        ),
        pipe(
          O.fromNullable(payload.filters?.nft),
          O.map((nft_id) => ({ term: { nft_id } })),
        ),
        pipe(
          O.fromNullable(payload.filters?.bundle),
          O.map((bundle_id) => ({ term: { bundle_id } })),
        ),
      ]),
    },
  }
  const filters = payload.filters
  if (filters) {
    if (
      !filters.bundle &&
      !(filters.contracts && filters.contracts.length > 0) &&
      !filters.nft &&
      !filters.user
    ) {
      const day = Math.round(new Date().getTime() / 1000) - 86400
      query.bool.must.push({
        bool: { filter: { range: { created_at: { gt: day } } } },
      })
    }
  }
  return utils.search(
    client,
    {
      index: INDEX_NAME,
      offset: payload.offset,
      limit: payload.limit,
      body: {
        _source: [],
        query,
        sort: [{ created_at: 'desc' }],
      },
    },
    D.struct({}),
  )
}
