import { BigNumber, utils } from 'ethers'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Nft_Order } from '@/lib/gql/types'
import { getTokenMeta } from '@/lib/token'

type ListPrice = {
  price: BigNumber
  symbol: string
}

type Props = {
  networkId: number
  sellOrder: Pick<Nft_Order, 'type' | 'price' | 'currency'> | null
  isOwner: boolean
}
export const useListPriceChanged = ({
  networkId,
  sellOrder,
  isOwner,
}: Props) => {
  const router = useRouter()
  const sellPrice = sellOrder
    ? {
        // Don't bother decimals here since it cannot be inferred from symbol
        price: utils.parseEther(sellOrder.price),
        symbol: getTokenMeta(networkId, sellOrder.currency).symbol,
      }
    : null

  const [listPrice, setListPrice] = useState<ListPrice | null>(null)
  useEffect(() => {
    const url = new URL(window.location.href)
    const listPriceParam = url.searchParams.get('list_price')
    if (!listPriceParam) return
    const [price, symbol] = listPriceParam.split('_')
    setListPrice({ price: utils.parseEther(price), symbol })
    url.searchParams.delete('list_price')
    router.replace(url, undefined, { shallow: true })
  }, [router])

  const listPriceChanged =
    sellPrice && listPrice && sellOrder?.type === 'sell'
      ? !sellPrice.price.eq(listPrice.price) ||
        sellPrice.symbol !== listPrice.symbol
      : false
  return listPriceChanged && !isOwner ? sellPrice : null
}
