import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

import { Button, FormLabelLike } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import { TokenIcon } from '@/components/TokenIcon'
import { UseDisclosureReturn } from '@/hooks'
import { usePaymentInfo } from '@/hooks/nft'
import useCurrency from '@/hooks/useCurrency'
import { CheckFilled } from '@/icons'
import { RegisteredUser } from '@/lib/auth/types/user'
import { enableAcceptOfferRoyalty } from '@/lib/features'
import { fmtNumber } from '@/lib/formatter'
import { Bundle } from '@/lib/nft/detail'
import { getTokenMeta } from '@/lib/token'

import { NftItem, PriceBreakdown } from '../shared'
import { getFeePercent } from './ListModal'

export type AcceptOfferProps = {
  user: RegisteredUser
  bundle: Bundle
  price: string
  currency: string
  royaltyFee: number
  onAction: (royalty: number) => void
}

type Props = AcceptOfferProps & {
  disclosure: UseDisclosureReturn
}
export const AcceptOfferModal = ({
  disclosure,
  user,
  bundle,
  price,
  currency,
  royaltyFee,
  onAction,
}: Props) => {
  const { t } = useTranslation()
  const { fmtCoinNumber } = useCurrency()

  const { token: tokenContract } = bundle[0].tokenPair
  const { networkId } = bundle[0].info
  const { symbol } = getTokenMeta(networkId, currency)

  const contracts = useMemo(() => [tokenContract], [tokenContract])
  const orderInfo = usePaymentInfo({
    isActive: disclosure.isOpen,
    user,
    networkId,
    contracts,
  }).paymentInfo?.[tokenContract]

  const marketFeePercent = orderInfo
    ? getFeePercent(orderInfo.market_fee_rate)
    : null
  const creatorFeePercent = orderInfo
    ? getFeePercent(
        enableAcceptOfferRoyalty ? royaltyFee : orderInfo.royalty_fee_rate,
      )
    : null

  const receive =
    marketFeePercent !== null && creatorFeePercent !== null
      ? parseFloat(price) *
        (1 - marketFeePercent / 100 - creatorFeePercent / 100)
      : null

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Accept Offer')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-8">
          <NftItem item={bundle[0]} price={price} currency={currency} />
          <div>
            <FormLabelLike className="mb-3">{t('Fees')}</FormLabelLike>
            <PriceBreakdown
              lines={[
                {
                  label: t('Market Fee'),
                  tip: t('Fee to X2Y2'),
                  percent: marketFeePercent,
                },
                {
                  label: t('Tip the creator'),
                  tip: t('Fee to the creator of the NFT'),
                  percent: creatorFeePercent,
                },
              ]}
            />
          </div>
          <hr />
          <div>
            <div className="flex items-center space-x-3">
              <p className="ts-hairline-1 mr-auto text-gray-500">
                {t('You receive')}
              </p>
              {receive ? (
                <div className="flex min-w-0 items-center space-x-1">
                  <TokenIcon className="h-6 w-6 shrink-0" symbol={symbol} />
                  <p className="ts-headline-5 truncate">{fmtNumber(receive)}</p>
                </div>
              ) : (
                <p className="ts-headline-5 truncate">-</p>
              )}
            </div>
            <p className="ts-caption text-right text-gray-500">
              ({receive ? fmtCoinNumber(receive, symbol) : '-'})
            </p>
          </div>
          <Button
            colorScheme="primary-1"
            leftIcon={<CheckFilled className="h-4 w-4" />}
            isDisabled={!orderInfo}
            onClick={() => {
              if (!orderInfo) return
              disclosure.onClose()
              onAction(
                enableAcceptOfferRoyalty
                  ? royaltyFee
                  : orderInfo.royalty_fee_rate,
              )
            }}
          >
            {t('Accept')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
