export const openShare = (
  baseUrl: string,
  keys: {
    url?: string
    text?: string
  },
  options?: {
    [key: string]: string
  },
) => {
  const url = new URL(baseUrl)
  if (keys.text) url.searchParams.append(keys.text, document.title)
  if (keys.url) url.searchParams.append(keys.url, window.location.href)
  if (options) {
    Object.entries(options).forEach(([k, v]) => {
      url.searchParams.append(k, v)
    })
  }
  window.open(url.toString())
}
