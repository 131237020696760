import {
  BigNumber,
  BigNumberish,
  ContractTransaction,
  ethers,
  PayableOverrides,
  providers,
} from 'ethers'

import { NETWORK_ETH, NETWORK_GOERLI } from '@/consts'
import {
  ERC20,
  ERC20__factory,
  ERC721,
  ERC721__factory,
  ERC1155,
  XY3__factory,
  XY3ServiceFee__factory,
  XY3V1__factory,
  XY3V3__factory,
} from '@/lib/contract'

import { OrderItem } from '../loan'
import { RunInput, runInputParamType } from '../market'
import { ERROR_0000, ERROR_0002, LoanError } from './errors'
import { CallData, OfferSignature, XY3Offer } from './types'

export const LOAN_V1_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xc28f7ee92cd6619e8eec6a70923079fbafb86196',
  [NETWORK_GOERLI]: '0xc28f7ee92cd6619e8eec6a70923079fbafb86196',
}
export const DELEGATE_V1_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xef887e8b1c06209f59e8ae55d0e625c937344376',
  [NETWORK_GOERLI]: '0xef887e8b1c06209f59e8ae55d0e625c937344376',
}
export const LOAN_TICKET_V1_CONTRACT_MAP: Record<number, string> = {
  [NETWORK_ETH]: '0x0e258c84df0f8728ae4a6426ea5fd163eb6b9d1b',
  [NETWORK_GOERLI]: '0x0e258c84df0f8728ae4a6426ea5fd163eb6b9d1b',
}
export const LOAN_V1_EXCHANGE_CONTRACT_MAP: Record<number, string> = {
  [NETWORK_ETH]: '0x83e1c2b35262f1ba55afba61bc7b8682aab2848f',
  [NETWORK_GOERLI]: '0x30182390d0Dc71f7c3FCd27FCAfC8e1B84b1506d',
}

export const LOAN_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xfa4d5258804d7723eb6a934c11b1bd423bc31623',
  [NETWORK_GOERLI]: '0x6e74f222e1ed3cf4ae91f4f9b2b990e9cad3df17',
}
export const DELEGATE_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xef887e8b1c06209f59e8ae55d0e625c937344376',
  [NETWORK_GOERLI]: '0xef887e8b1c06209f59e8ae55d0e625c937344376',
}
export const LOAN_TICKET_CONTRACT_MAP: Record<number, string> = {
  [NETWORK_ETH]: '0x0e258c84df0f8728ae4a6426ea5fd163eb6b9d1b',
  [NETWORK_GOERLI]: '0x0e258c84df0f8728ae4a6426ea5fd163eb6b9d1b',
}
export const LOAN_X2Y2_EXCHANGE_CONTRACT_MAP: Record<number, string> = {
  [NETWORK_ETH]: '0x8488f9E5900553A77B7B92384c66b2E4BE7601e7',
  [NETWORK_GOERLI]: '0x8488f9E5900553A77B7B92384c66b2E4BE7601e7',
}
export const LOAN_SERVICE_FEE_CONTRACT_MAP: Record<number, string> = {
  [NETWORK_ETH]: '0xb858E4a6f81173892AD263584aa5b78F2407EE72',
  [NETWORK_GOERLI]: '0x579bad828fb247305bd6dabfc437227aeac2bbb0',
}

export const LOAN_V3_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xb81965ddfdda3923f292a47a1be83ba3a36b5133',
  [NETWORK_GOERLI]: '0x21051aac466415b4db44cce4e7b376fdb36ef495',
}
export const LOAN_TICKET_V2_CONTRACT_MAP: Record<number, string> = {
  [NETWORK_ETH]: '0xbd85bf4c970b91984e6a2b8ba9c577a58a8c20f9',
  [NETWORK_GOERLI]: '0x4d27a78e7eb271e66772d626ea6dd845e400cb05',
}
export const LOAN_TICKET_V3_CONTRACT_MAP: Record<number, string> = {
  [NETWORK_ETH]: '0xdc45492c27dc311800823a6379436138510f6953',
  [NETWORK_GOERLI]: '0x83a3fec7345887bd5e7b755c741b50cad0767309',
}

export const getDelegateContract = (networkId: number): string => {
  const delegateContract = DELEGATE_CONTRACT_MAPS[networkId]
  if (!delegateContract) {
    throw new LoanError(ERROR_0000)
  }
  return delegateContract
}

export const getLoanContract = (networkId: number): string => {
  const loanContract = LOAN_V3_CONTRACT_MAPS[networkId]
  if (!loanContract) {
    throw new LoanError(ERROR_0000)
  }
  return loanContract
}

export const isLoanContract = (networkId: number, address: string): boolean => {
  try {
    const loanContracts = [
      LOAN_V3_CONTRACT_MAPS[networkId].toLowerCase(),
      LOAN_CONTRACT_MAPS[networkId].toLowerCase(),
      LOAN_V1_CONTRACT_MAPS[networkId].toLowerCase(),
    ]
    return loanContracts.includes(address.toLowerCase())
  } catch (ignored) {
    throw new LoanError(ERROR_0000)
  }
}

// TODO: rename when loanId in V2 is ready
export const isLoanBorrowerTicketV3Token = (
  networkId: number,
  address: string,
): boolean => {
  try {
    const promissoryNoteToken = [
      // TODO: add when loanId in V2 is ready
      // LOAN_TICKET_V2_CONTRACT_MAP[networkId].toLowerCase(),
      LOAN_TICKET_V3_CONTRACT_MAP[networkId].toLowerCase(),
    ]
    return promissoryNoteToken.includes(address.toLowerCase())
  } catch (ignored) {
    throw new LoanError(ERROR_0000)
  }
}

export const getLoanRepayContractByBorrowerTicketToken = (
  networkId: number,
  address: string,
): string | null => {
  try {
    if (
      address.toLowerCase() ===
      LOAN_TICKET_V2_CONTRACT_MAP[networkId].toLowerCase()
    ) {
      return LOAN_CONTRACT_MAPS[networkId]
    }
    if (
      address.toLowerCase() ===
      LOAN_TICKET_V3_CONTRACT_MAP[networkId].toLowerCase()
    ) {
      return LOAN_V3_CONTRACT_MAPS[networkId]
    }
    return null
  } catch (ignored) {
    throw new LoanError(ERROR_0000)
  }
}

export const isApprovedNft721 = async (
  networkId: number,
  nft: ERC721,
  owner: string,
) => {
  const operator = getDelegateContract(networkId)
  return await nft.isApprovedForAll(owner, operator)
}

export const approveNft721 = async (networkId: number, nft: ERC721) => {
  const operator = getDelegateContract(networkId)
  const tx = await nft.setApprovalForAll(operator, true)
  await tx.wait()
}

export const isApprovedNft1155 = async (
  networkId: number,
  nft: ERC1155,
  owner: string,
) => {
  const operator = getDelegateContract(networkId)
  return await nft.isApprovedForAll(owner, operator)
}

export const approveNft1155 = async (networkId: number, nft: ERC1155) => {
  const operator = getDelegateContract(networkId)
  const tx = await nft.setApprovalForAll(operator, true)
  await tx.wait()
}

export const isApprovedErc20 = async (
  networkId: number,
  token: ERC20,
  owner: string,
  amount: BigNumber,
) => {
  const operator = getDelegateContract(networkId)
  const allowance = await token.allowance(owner, operator)
  return allowance.gte(amount)
}

export const approveErc20 = async (
  networkId: number,
  token: ERC20,
  amount: BigNumber,
) => {
  const operator = getDelegateContract(networkId)
  const tx = await token.approve(operator, amount)
  await tx.wait()
}

export const checkBalance = async (
  user: providers.JsonRpcSigner,
  contract: string,
  amount: BigNumber,
) => {
  const token = ERC20__factory.connect(contract, user)
  const address = await user.getAddress()
  const balance = await token.balanceOf(address)
  return balance.gte(amount)
}

export const cancel = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  offerNonce: string,
): Promise<ContractTransaction> => {
  const loanContract = getLoanContract(networkId)
  const xy3 = XY3__factory.connect(loanContract, signer)
  return xy3.cancelByNonce(offerNonce)
}

export const cancelAll = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  timestamp: number,
): Promise<ContractTransaction> => {
  const loanContract = getLoanContract(networkId)
  const xy3 = XY3__factory.connect(loanContract, signer)
  return xy3.cancelByTimestamp(timestamp)
}

const calcCallDataByOrder = async (
  contract: string,
  v1Exchange: string,
  order: OrderItem | undefined,
): Promise<CallData> => {
  if (!order) {
    const target = ethers.constants.AddressZero
    return { target, selector: '0x00000000', data: '0x', referral: 0 }
  }
  const data = ethers.utils.defaultAbiCoder.encode([`uint32`], [order.loanId])
  if (contract.toLowerCase() === order.loanContract.toLowerCase()) {
    // const iface = new ethers.utils.Interface([
    //   'function repay(address _sender, bytes calldata _param)',
    // ])
    // const functionData = iface.encodeFunctionData('repay', [
    //   order.borrower,
    //   data,
    // ])
    const selector = '0x320907b8' // repay(address,bytes)
    return { target: contract, selector, data, referral: 2 }
  } else {
    // const iface = new ethers.utils.Interface([
    //   'function exchange(address sender, bytes memory params)',
    // ])
    // const functionData = iface.encodeFunctionData('exchange', [
    //   order.borrower,
    //   data,
    // ])
    const selector = '0x881e142e' // exchange(address,bytes)
    return { target: v1Exchange, selector, data, referral: 1 }
  }
}

export const getServiceFee = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  order: OrderItem,
) => {
  const v1Exchange = LOAN_V1_EXCHANGE_CONTRACT_MAP[networkId]
  const loanContract = LOAN_CONTRACT_MAPS[networkId]
  const serviceFeeContract = LOAN_SERVICE_FEE_CONTRACT_MAP[networkId]
  const xy3ServiceFee = XY3ServiceFee__factory.connect(
    serviceFeeContract,
    signer,
  )
  return xy3ServiceFee.getServiceFee(
    loanContract.toLowerCase() === order.loanContract.toLowerCase()
      ? loanContract
      : v1Exchange,
    await signer.getAddress(),
    order.contractAddress,
  )
}

const calcX2y2RunCallData = async (
  exchange: string,
  orderRunInput: RunInput,
): Promise<CallData> => {
  const data = ethers.utils.defaultAbiCoder.encode(
    [runInputParamType],
    [orderRunInput],
  )
  return {
    target: exchange,
    selector: '0x881e142e', // exchange(address,bytes)
    data,
    referral: 6666,
  }
}

export const acceptV2 = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  offer: XY3Offer,
  nftId: BigNumberish,
  isCollectionOffer: boolean,
  lenderSignature: OfferSignature,
  brokerSignature: OfferSignature,
  order: OrderItem | undefined,
): Promise<ContractTransaction> => {
  const contract = LOAN_CONTRACT_MAPS[networkId]
  const v1Exchange = LOAN_V1_EXCHANGE_CONTRACT_MAP[networkId]
  const xy3 = XY3__factory.connect(contract, signer)
  const callData = await calcCallDataByOrder(contract, v1Exchange, order)
  return xy3.borrow(
    offer,
    nftId,
    isCollectionOffer,
    lenderSignature,
    brokerSignature,
    callData,
  )
}

export const checkBorrowToBuyBalance = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  currency: string,
  balanceAmount: BigNumber,
  payAmount: BigNumber,
): Promise<boolean> => {
  const delegateContract = DELEGATE_CONTRACT_MAPS[networkId]
  const checkResult = await checkLoanBalance(
    signer,
    currency,
    balanceAmount,
    delegateContract,
    payAmount,
  )
  if (!checkResult) {
    throw new LoanError(ERROR_0002)
  }
  return true
}

export const borrowToBuy = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  offer: XY3Offer,
  nftId: BigNumberish,
  isCollectionOffer: boolean,
  lenderSignature: OfferSignature,
  brokerSignature: OfferSignature,
  x2y2OrderRunInput: RunInput,
): Promise<ContractTransaction> => {
  const contract = LOAN_CONTRACT_MAPS[networkId]
  const exchange = LOAN_X2Y2_EXCHANGE_CONTRACT_MAP[networkId]
  const xy3 = XY3__factory.connect(contract, signer)
  const callData = await calcX2y2RunCallData(exchange, x2y2OrderRunInput)
  const borrowParams = [
    offer,
    nftId,
    isCollectionOffer,
    lenderSignature,
    brokerSignature,
    callData,
  ] as const
  const options: PayableOverrides = {}
  try {
    const gasLimit = await xy3.estimateGas.borrow(...borrowParams)
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {}
  return xy3.borrow(...borrowParams, options)
}

const getLoanDetail = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  loanId: number,
  isV2: boolean,
) => {
  if (isV2) {
    const contract = LOAN_CONTRACT_MAPS[networkId]
    const xy3 = XY3__factory.connect(contract, signer)
    const loanState = await xy3.getLoanState(loanId)
    const isOpen = loanState.status === 1 // StatusType.NEW
    if (!isOpen) return undefined
    const loan = await xy3.loanDetails(loanId)
    return { loan, loanState }
  } else {
    const contractV1 = LOAN_V1_CONTRACT_MAPS[networkId]
    const xy3V1 = XY3V1__factory.connect(contractV1, signer)
    const loanState = await xy3V1.getLoanState(loanId)
    const isOpen = loanState.status === 1 // StatusType.NEW
    if (!isOpen) return undefined
    const loan = await xy3V1.loanDetails(loanId)
    return { loan, loanState }
  }
}

export const checkLoanBalance = async (
  signer: providers.JsonRpcSigner,
  borrowAsset: string,
  balanceAmount: BigNumber,
  operator: string,
  payAmount: BigNumber = balanceAmount,
): Promise<boolean> => {
  const balanceResult = await checkBalance(signer, borrowAsset, payAmount)
  if (!balanceResult) return false
  const token = ERC20__factory.connect(borrowAsset, signer)
  const user = await signer.getAddress()
  const allowance = await token.allowance(user, operator)
  return allowance.gte(balanceAmount)
}

export const checkRepay = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  loanContract: string,
  loanId: number,
  payAmount?: BigNumber,
  fee?: BigNumber,
): Promise<boolean> => {
  const contract = LOAN_CONTRACT_MAPS[networkId]
  const isV2 = loanContract.toLowerCase() === contract.toLowerCase()
  const loanDetail = await getLoanDetail(signer, networkId, loanId, isV2)
  if (loanDetail) {
    const loan = loanDetail.loan
    const now = Math.floor(Date.now() / 1000)
    const isExpired = now > loan.loanStart.toNumber() + loan.loanDuration
    if (isExpired) return false
    const delegateContract = isV2
      ? DELEGATE_CONTRACT_MAPS[networkId]
      : DELEGATE_V1_CONTRACT_MAPS[networkId]
    const checkResult = await checkLoanBalance(
      signer,
      loan.borrowAsset,
      fee ? loan.repayAmount.add(fee) : loan.repayAmount,
      delegateContract,
      payAmount,
    )
    if (!checkResult) {
      throw new LoanError(ERROR_0002)
    }
    return true
  }
  return false
}

export const repay = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  loanContract: string,
  loanId: number,
): Promise<ContractTransaction> => {
  if (
    loanContract.toLowerCase() ===
    LOAN_V3_CONTRACT_MAPS[networkId].toLowerCase()
  ) {
    const xy3V3 = XY3V3__factory.connect(loanContract, signer)
    return xy3V3.repay(loanId)
  } else if (
    loanContract.toLowerCase() === LOAN_CONTRACT_MAPS[networkId].toLowerCase()
  ) {
    const xy3 = XY3__factory.connect(loanContract, signer)
    return xy3['repay(uint32)'](loanId)
  } else {
    const contractV1 = LOAN_V1_CONTRACT_MAPS[networkId]
    const xy3V1 = XY3V1__factory.connect(contractV1, signer)
    return xy3V1.repay(loanId)
  }
}

const getLender = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  isV2: boolean,
  xy3NftId: BigNumber,
) => {
  try {
    const promissoryNoteToken = isV2
      ? LOAN_TICKET_CONTRACT_MAP[networkId]
      : LOAN_TICKET_V1_CONTRACT_MAP[networkId]
    const erc721 = ERC721__factory.connect(promissoryNoteToken, signer)
    return await erc721.ownerOf(xy3NftId)
  } catch (ignored) {}
  return ''
}

export const checkRedempt = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  loanContract: string,
  loanId: number,
): Promise<boolean> => {
  const contract = LOAN_CONTRACT_MAPS[networkId]
  const isV2 = loanContract.toLowerCase() === contract.toLowerCase()
  const loanDetail = await getLoanDetail(signer, networkId, loanId, isV2)
  if (loanDetail) {
    const loan = loanDetail.loan
    const loanState = loanDetail.loanState
    const now = Math.floor(Date.now() / 1000)
    const isExpired = now > loan.loanStart.toNumber() + loan.loanDuration
    if (!isExpired) return false
    const userAddress = await signer.getAddress()
    const lender = await getLender(signer, networkId, isV2, loanState.xy3NftId)
    return lender.toLowerCase() === userAddress.toLowerCase()
  }
  return false
}

export const redempt = async (
  signer: providers.JsonRpcSigner,
  networkId: number,
  loanContract: string,
  loanId: number,
): Promise<ContractTransaction> => {
  const contract = LOAN_CONTRACT_MAPS[networkId]
  const isV2 = loanContract.toLowerCase() === contract.toLowerCase()
  if (isV2) {
    const xy3 = XY3__factory.connect(loanContract, signer)
    return xy3.liquidate(loanId)
  } else {
    const contractV1 = LOAN_V1_CONTRACT_MAPS[networkId]
    const xy3V1 = XY3V1__factory.connect(contractV1, signer)
    return xy3V1.liquidate(loanId)
  }
}
