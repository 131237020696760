import { pipe } from 'fp-ts/lib/function'

import { A, D, O } from '@/lib/fp'
import { decoderWithDefault } from '@/utils/codec'

export const rarityKVDecoder = pipe(
  D.struct({
    attributes: D.array(
      D.struct({
        trait_type: D.string,
        value: decoderWithDefault(D.string, null),
      }),
    ),
  }),
  D.map(({ attributes }) => {
    return pipe(
      attributes,
      A.filterMap((src) => {
        if (!src.value) return O.none
        return O.some({ k: src.trait_type, v: src.value })
      }),
    )
  }),
)
