import { flow, pipe } from 'fp-ts/lib/function'
import { NumberFromString } from 'io-ts-types'
import { ParsedUrlQuery } from 'querystring'

import {
  Category as ActivityCategory,
  categoryDecoder as activityCategoryDecoder,
  DEFAULT_CATEGORIES,
} from '@/lib/es/activity'
import {
  attrKeywordsFilterDecoder,
  AttrMinMaxFilter,
  attrMinMaxFilterDecoder,
} from '@/lib/es/types'
import { A, D, E, NEA, O, Ord, S, TE } from '@/lib/fp'
import { nullableDecoder, numberFromStringDecoder } from '@/utils/codec'

export const kvToUrlQuery = flow(
  (
    xs: {
      k: string
      v: string
    }[],
  ) => {
    type Item = (typeof xs)[0]
    const ord = Ord.contramap((a: Item) => a.k)(S.Ord)
    return A.sort(ord)(xs)
  },
  NEA.fromArray,
  O.fold(
    () => '',
    (xs) => '?' + xs.map(({ k, v }) => `${k}=${v}`).join('&'),
  ),
)

export const contractsURLQueryCodec = {
  decode: (q: ParsedUrlQuery) =>
    pipe(
      D.string.decode(q['contracts']),
      O.fromEither,
      O.map((value) => {
        const items = value.split(',').map((a) => decodeURIComponent(a))
        return pipe(
          items,
          A.filterMap((item) =>
            pipe(NumberFromString.decode(item), O.fromEither),
          ),
        )
      }),
      O.getOrElse((): number[] => []),
    ),
  encode: (contracts: number[]) =>
    pipe(
      NEA.fromArray(contracts),
      O.map((v) => ({
        k: 'contracts',
        v: v
          .sort()
          .map((a) => a.toString())
          .join(','),
      })),
    ),
}

export const activityCategoriesURLQueryCodec = {
  decode: (q: ParsedUrlQuery) =>
    pipe(
      D.string.decode(q['categories']),
      O.fromEither,
      O.map((value) => {
        const items = value.split(',')
        return pipe(
          items,
          A.filterMap((item) =>
            pipe(activityCategoryDecoder.decode(item), O.fromEither),
          ),
        )
      }),
      O.getOrElse(() => DEFAULT_CATEGORIES),
    ),
  encode: (categories?: ActivityCategory[]) =>
    pipe(
      NEA.fromArray(categories ?? []),
      O.map((v) => ({
        k: 'categories',
        v: v.sort().join(','),
      })),
    ),
}

export const minmaxURLQueryCodec = {
  decode: (src: string) => {
    const parts = src.split(',')
    if (parts.length !== 2) return O.none
    return pipe(
      D.tuple(
        nullableDecoder(numberFromStringDecoder),
        nullableDecoder(numberFromStringDecoder),
      ).decode(parts),
      O.fromEither,
    )
  },
  encode: ([min, max]: AttrMinMaxFilter) => {
    if (min === null && max === null) return O.none
    return O.some(`${min ?? ''},${max ?? ''}`)
  },
}

export const keywordsURLQueryCodec = {
  decode: (src: string) => {
    const items = src.split(',')
    return pipe(
      attrKeywordsFilterDecoder.decode(items),
      E.getOrElse((): string[] => []),
      NEA.fromArray,
    )
  },
  encode: (src: string[]) => {
    return pipe(
      src,
      NEA.fromArray,
      O.map((xs) =>
        xs
          .map((a) => encodeURIComponent(a))
          .sort()
          .join(','),
      ),
    )
  },
}

export const attrSelectedCount = (src: unknown) => {
  const minmax = attrMinMaxFilterDecoder.decode(src)
  if (E.isRight(minmax)) {
    if (minmax.right[0] !== null || minmax.right[1] !== null) {
      return 1
    } else {
      return 0
    }
  }
  const keywords = attrKeywordsFilterDecoder.decode(src)
  if (E.isRight(keywords)) return keywords.right.length
  return 0
}

export const getEntitiesFromDBByIds = <DBEntity extends { id: number }>(
  ids: number[],
  fetch: (ids: number[]) => TE.TaskEither<string, DBEntity[]>,
) => {
  return pipe(
    fetch(ids),
    TE.map((resp) => {
      const map: Record<number, DBEntity> = {}
      for (const entity of resp) {
        map[entity.id] = entity
      }
      const data: DBEntity[] = []
      for (const id of ids) {
        const item = map[id]
        if (item) data.push(item)
      }
      return data
    }),
  )
}

export const getEntitiesFromDBByESEntities = <
  ESEntity extends { id: string },
  DBEntity extends { id: number },
>(
  src: { data: ESEntity[]; total: number },
  fetch: (ids: number[]) => TE.TaskEither<string, DBEntity[]>,
) => {
  const ids = src.data.map((a) => parseInt(a.id))
  return pipe(
    getEntitiesFromDBByIds(ids, fetch),
    TE.map((data) => ({ data, total: src.total })),
  )
}
