import { Web3ReactProvider } from '@web3-react/core'
import 'core-js/features/object/from-entries'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import { ethers } from 'ethers'
import 'focus-visible/dist/focus-visible'
import { appWithTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import Analytics from '@/components/Analytics'
import { BrowserPreferenceProvider } from '@/components/BrowserPreferenceCtx'
import { ColorModeProvider } from '@/components/colorMode'
import { useDefaultDesc } from '@/components/Seo'
import { CartProvider } from '@/components/shoppingCart/CartProvider'
import { Toaster } from '@/components/Toast'
import { PortfolioProvider } from '@/components/user/PortfolioCtx'
import meta from '@/data/meta'
import { useRouterLoading } from '@/hooks'
import { CurrencyProvider } from '@/hooks/useCurrency'
import { shared } from '@/images/cdnImages'
import { AuthProvider } from '@/lib/auth'
import { resizedCdnUrl } from '@/lib/cdn'
import { NotificationContextProvider } from '@/lib/user/NotificationProvider'
import '@/styles/globals.css'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(utc)

const getWeb3Provider = (provider: ethers.providers.ExternalProvider) =>
  new ethers.providers.Web3Provider(provider)

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const routerLoading = useRouterLoading()
  // NOTE: Prevent extra render caused by routerLoading changed during navigation
  const pageComponent = useMemo(
    () => <Component {...pageProps} />,
    [Component, pageProps],
  )

  const canonical = meta.siteUrl + router.asPath
  const desc = useDefaultDesc()
  return (
    <>
      <Analytics />
      <DefaultSeo
        title="X2Y2 Marketplace"
        titleTemplate={'%s - x2y2.io'}
        canonical={canonical}
        description={desc}
        openGraph={{
          type: 'website',
          url: canonical,
          images: [{ url: resizedCdnUrl(shared.og, 1440) }],
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: meta.siteUrl + '/favicon.ico',
          },
          {
            rel: 'icon',
            href: meta.siteUrl + '/favicon.svg',
            type: 'image/svg+xml',
          },
          {
            rel: 'apple-touch-icon',
            href: meta.siteUrl + '/icon.png',
            sizes: '512x512',
          },
        ]}
        twitter={{ cardType: 'summary_large_image' }}
      />
      <Web3ReactProvider getLibrary={getWeb3Provider}>
        <ColorModeProvider>
          <CurrencyProvider>
            <AuthProvider>
              <CartProvider>
                <PortfolioProvider>
                  <NotificationContextProvider>
                    <BrowserPreferenceProvider>
                      {routerLoading && (
                        <div className="fixed inset-x-0 top-0 z-[999999] h-1">
                          <div className="absolute h-full min-w-[50%] animate-progress bg-gradient-to-r from-transparent via-primary-1 to-transparent" />
                        </div>
                      )}
                      {pageComponent}
                      <Toaster />
                    </BrowserPreferenceProvider>
                  </NotificationContextProvider>
                </PortfolioProvider>
              </CartProvider>
            </AuthProvider>
          </CurrencyProvider>
        </ColorModeProvider>
      </Web3ReactProvider>
    </>
  )
}

export default appWithTranslation(App)
