import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import { ON_SERVER } from '@/lib/env'

import { ColorModeContext } from './context'
import { localStorageManager } from './manager'
import { ColorMode, ColorModeOptions } from './types'
import { getColorModeUtils } from './utils'

const useSafeLayoutEffect = ON_SERVER ? useEffect : useLayoutEffect

export interface ColorModeProviderProps {
  children?: React.ReactNode
  options?: ColorModeOptions
}

function getTheme(fallback?: ColorMode) {
  return fallback
}

export function ColorModeProvider(props: ColorModeProviderProps) {
  const { children, options: { useSystemColorMode, initialColorMode } = {} } =
    props

  const defaultColorMode = initialColorMode === 'dark' ? 'dark' : 'light'
  const colorModeManager = localStorageManager

  const [colorMode, rawSetColorMode] = useState(() =>
    getTheme(defaultColorMode),
  )
  const [resolvedColorMode, setResolvedColorMode] = useState(() => getTheme())

  const { getSystemTheme, setClassName, setDataset, addListener } = useMemo(
    () => getColorModeUtils(),
    [],
  )

  const resolvedValue =
    initialColorMode === 'system' && !colorMode ? resolvedColorMode : colorMode

  const setColorMode = useCallback(
    (value: ColorMode | 'system') => {
      //
      const resolved = value === 'system' ? getSystemTheme() : value
      rawSetColorMode(resolved)

      setClassName(resolved === 'dark')
      setDataset(resolved)

      colorModeManager.set(resolved)
    },
    [colorModeManager, getSystemTheme, setClassName, setDataset],
  )

  useSafeLayoutEffect(() => {
    if (initialColorMode === 'system') {
      setResolvedColorMode(getSystemTheme())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const managerValue = colorModeManager.get()

    if (managerValue) {
      setColorMode(managerValue)
      return
    }

    if (initialColorMode === 'system') {
      setColorMode('system')
      return
    }

    setColorMode(defaultColorMode)
  }, [colorModeManager, defaultColorMode, initialColorMode, setColorMode])

  const toggleColorMode = useCallback(() => {
    setColorMode(resolvedValue === 'dark' ? 'light' : 'dark')
  }, [resolvedValue, setColorMode])

  useEffect(() => {
    if (!useSystemColorMode) return
    return addListener(setColorMode)
  }, [useSystemColorMode, addListener, setColorMode])

  const context = useMemo(
    () => ({
      colorMode: resolvedValue as ColorMode,
      toggleColorMode,
      setColorMode,
      forced: false,
    }),
    [resolvedValue, toggleColorMode, setColorMode],
  )

  return (
    <ColorModeContext.Provider value={context}>
      {children}
    </ColorModeContext.Provider>
  )
}

ColorModeProvider.displayName = 'ColorModeProvider'
