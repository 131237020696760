import { useTranslation } from 'next-i18next'

import { ForbiddenByOpenseaFilled } from '@/icons'

export const WarningLabel = () => {
  const { t } = useTranslation()
  return (
    <p className="ts-caption-2 flex items-center space-x-1 rounded-lg bg-primary-3 px-3 py-1 font-bold text-button-text">
      <ForbiddenByOpenseaFilled className="h-4 w-4" />
      <span>{t('Marked on OpenSea')}</span>
    </p>
  )
}
