import { Client } from '@elastic/elasticsearch'
import { SearchResponse } from '@elastic/elasticsearch/api/types'
import { flow, pipe } from 'fp-ts/lib/function'
import { TFunction } from 'next-i18next'

import { ON_PROD } from '@/lib/env'
import { A, D, E, O } from '@/lib/fp'
import { nullableDecoder } from '@/utils/codec'

import { isNonNullable } from '../utils'
import { EsMapping } from './mapping'
import {
  attrKeywordsFilterDecoder,
  attrMinMaxFilterDecoder,
  attrsFilterDecoder,
  AttrsSearchOptions,
} from './types'
import * as utils from './utils'

const INDEX_NAME = ON_PROD ? 'x2y2-nft' : 'x2y2-nft-stag'

export const sortDecoder = D.union(
  D.literal('latest'),
  D.literal('price_asc'),
  D.literal('price_desc'),
  D.literal('ending_soon'),
  D.literal('rarity_rank'),
)
export type Sort = D.TypeOf<typeof sortDecoder>
export const getAllSorts = ({
  enableRarities = false,
}: {
  enableRarities?: boolean
}): Sort[] => {
  if (enableRarities) {
    return ['latest', 'price_asc', 'price_desc', 'ending_soon', 'rarity_rank']
  } else {
    return ['latest', 'price_asc', 'price_desc', 'ending_soon']
  }
}
export const DEFAULT_SORT: Sort = 'latest'
export const sortTitle = (t: TFunction, sort: Sort): string => {
  switch (sort) {
    case 'latest':
      return t('Recently Listed')
    case 'price_asc':
      return `${t('Price')}: ${t('Low to High')}`
    case 'price_desc':
      return `${t('Price')}: ${t('High to Low')}`
    case 'ending_soon':
      return t('Ending Soon')
    case 'rarity_rank':
      return `${t('Rarity')}: ${t('High to Low')}`
  }
}

export const searchPayloadFiltersDecoder = D.partial({
  downPayMode: D.boolean,
  buyNow: D.boolean,
  network: D.number,
  contracts: D.array(D.number),
  priceMin: D.number,
  priceMax: D.number,
  currency: D.string,
  // NOTE: Set query would discard buyNow
  query: D.string,
  attributes: attrsFilterDecoder,
})
export const searchPayloadDecoder = D.partial({
  filters: searchPayloadFiltersDecoder,
  sort: sortDecoder,
  limit: D.number,
  offset: D.number,
})
export type SearchPayloadFilters = D.TypeOf<typeof searchPayloadFiltersDecoder>
export type SearchPayload = D.TypeOf<typeof searchPayloadDecoder>

const filtersToEsQuery = ({ filters = {} }: Pick<SearchPayload, 'filters'>) => {
  const {
    buyNow = false,
    network,
    contracts = [],
    priceMin,
    priceMax,
    currency,
    query,
    attributes = {},
  } = filters
  const isBuyNow = query !== undefined ? false : buyNow
  return {
    bool: {
      must: [
        isBuyNow ? { term: { has_open_order: true } } : null,
        network !== undefined ? { term: { network_id: network } } : null,
        contracts.length > 0
          ? {
              bool: {
                should: contracts.map((id) => ({ term: { contract_id: id } })),
              },
            }
          : null,
        priceMin !== undefined ? { range: { price: { gte: priceMin } } } : null,
        priceMax !== undefined ? { range: { price: { lte: priceMax } } } : null,
        currency ? { term: { currency } } : null,
        query
          ? {
              bool: {
                should: [
                  { term: { token_id: query } },
                  { match: { name: { query } } },
                ],
              },
            }
          : null,
        ...pipe(
          Object.entries(attributes),
          A.chain(([k, v]) =>
            pipe(
              attrKeywordsFilterDecoder,
              D.map((keywords) => {
                if (keywords.length === 0) return []
                return [
                  {
                    bool: {
                      should: keywords.map((a) => ({ term: { [k]: a } })),
                    },
                  },
                ]
              }),
              D.alt(() =>
                pipe(
                  attrMinMaxFilterDecoder,
                  D.map(([min, max]) => {
                    const qs: Record<string, unknown>[] = []
                    if (min) qs.push({ range: { [k]: { gte: min } } })
                    if (max) qs.push({ range: { [k]: { lte: max } } })
                    return qs
                  }),
                ),
              ),
              ({ decode }) => decode(v),
              E.getOrElse((): Record<string, unknown>[] => []),
            ),
          ),
        ),
        isBuyNow ? { range: { end_at: { gte: 'now' } } } : null,
      ].filter(isNonNullable),
    },
  }
}

export const search = (client: Client, payload: SearchPayload) => {
  const query = filtersToEsQuery(payload)
  const sort = (() => {
    switch (payload.sort ?? DEFAULT_SORT) {
      case 'latest':
        return [{ created_at: { order: 'desc', missing: '_last' } }]
      case 'price_asc':
        return [{ price: { order: 'asc', missing: '_last' } }]
      case 'price_desc':
        return [{ price: { order: 'desc', missing: '_last' } }]
      case 'ending_soon':
        return [{ end_at: { order: 'asc', missing: '_last' } }]
      case 'rarity_rank':
        return [{ rank: { order: 'asc', missing: '_last' } }, { nft_id: 'asc' }]
    }
  })()
  return utils.search(
    client,
    {
      index: INDEX_NAME,
      offset: payload.offset,
      limit: payload.limit,
      body: {
        _source: [],
        query,
        sort,
      },
      track_total_hits: true,
    },
    D.struct({
      has_royalty: nullableDecoder(D.boolean),
      has_open_order: nullableDecoder(D.boolean),
      order_id: nullableDecoder(D.number),
    }),
  )
}

export const searchKeyword = (client: Client, keyword: string) => {
  const query = {
    bool: {
      must: [{ match: { name: { query: keyword } } }],
    },
  }
  return utils.search(
    client,
    {
      index: INDEX_NAME,
      limit: 50,
      body: {
        _source: [],
        query,
        sort: ['_score'],
      },
      track_total_hits: true,
    },
    D.struct({
      has_royalty: nullableDecoder(D.boolean),
      has_open_order: nullableDecoder(D.boolean),
      order_id: nullableDecoder(D.number),
    }),
  )
}

export const attrsOptions = async (
  client: Client,
  contract_id: number,
  esMapping: EsMapping,
): Promise<AttrsSearchOptions> => {
  const aggs: Record<string, unknown> = {}
  for (const { es_key, type } of esMapping) {
    if (type === 'text') {
      aggs[es_key] = {
        terms: { field: es_key, size: 500, order: { _count: 'desc' } },
      }
    }
  }
  const body = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              contract_id,
            },
          },
        ],
      },
    },
    aggs,
  }
  const resp = await client.search<SearchResponse<unknown>>({
    index: INDEX_NAME,
    body,
  })
  const res: AttrsSearchOptions = {}
  for (const { es_key, type } of esMapping) {
    if (type === 'text') {
      pipe(
        O.fromNullable(resp.body.aggregations),
        O.map((a) => a[es_key]),
        O.chain(
          flow(
            D.struct({
              buckets: D.array(
                D.struct({
                  key: D.string,
                  doc_count: D.number,
                }),
              ),
            }).decode,
            O.fromEither,
          ),
        ),
        O.map((a) => {
          res[es_key] = { type: 'text', options: a.buckets }
        }),
      )
    }
  }
  return res
}
