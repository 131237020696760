import { createContext, FC, useContext } from 'react'

import { Loading } from '@/components/feedback'

import { useAuth } from './authProvider'
import * as U from './types/user'

const UserContext = createContext<
  U.AnonymousUser | U.Web3RegisteredUser | U.RegisteredUser
>({
  _tag: 'anonymous',
})
export const useUser = ():
  | U.AnonymousUser
  | U.Web3RegisteredUser
  | U.RegisteredUser => useContext(UserContext)
export const UserProvider: FC = ({ children }) => {
  const { user } = useAuth()
  switch (user._tag) {
    case 'pending':
      return <Loading />
    case 'anonymous':
    case 'web3-registered':
    case 'registered':
      return (
        <UserContext.Provider value={user}>{children}</UserContext.Provider>
      )
  }
}
