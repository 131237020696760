import { D, NEA } from '@/lib/fp'
import { TokenPair } from '@/lib/market'
import { networkSlugDecoder } from '@/utils/network'

import { NftMetadataAssets } from './metadata'

export type NftInfo = {
  asset: NftMetadataAssets
  creator: string
  verified: boolean
  name: string
  networkId: number
}
export type BundleItem = {
  tokenPair: TokenPair
  info: NftInfo
}
export type Bundle = NEA.NonEmptyArray<BundleItem>

export type Contract = { contract: string; name: string }
export const getBundleContracts = (bundle: Bundle) =>
  bundle.reduce<Contract[]>(
    (cs, a) =>
      cs.some((c) => c.contract === a.tokenPair.token)
        ? cs
        : cs.concat({
            contract: a.tokenPair.token,
            name: a.info.creator,
          }),
    [],
  )

export const queryDecoder = D.struct({
  network: networkSlugDecoder,
  tokenContract: D.string,
  tokenId: D.string,
})

export type Query = D.TypeOf<typeof queryDecoder>

const orderInfoDecoder = D.struct({
  custom_royalty: D.boolean,
  market_fee_rate: D.number,
  royalty_fee_rate: D.number,
  supported_tokens: D.record(D.array(D.string)),
})

export type OrderInfo = D.TypeOf<typeof orderInfoDecoder>
