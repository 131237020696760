import { BigNumber } from 'ethers'

import { D } from '@/lib/fp'

import { RunInput } from '../market'

export type MARKET_TAG = 'x2y2' | 'opensea' | 'looksrare'
export type TokenStandard = 'erc721' | 'erc1155'

export type InvalidReason =
  | 'Order Cancelled'
  | 'Your Own NFT'
  | 'Not Supported(1155)'
  | 'Not Supported'

export type CartItem = {
  tag: MARKET_TAG
  orderId: number
  currency: string
  contract: string
  tokenId: string
  tokenStandard: TokenStandard
  price: BigNumber
}

export type EmptyInput = Record<string, unknown>

export type X2Y2OrderResult = {
  order: RunInput | EmptyInput | undefined
  value: BigNumber
  invalidReason: InvalidReason | null
}

export type SeaportOrderResult = {
  order: any | undefined
  value: BigNumber
  invalidReason: InvalidReason | null
}

export type LooksrareOrderResult = {
  order: any | undefined
  value: BigNumber
  invalidReason: InvalidReason | null
}

export type ItemWithOrder = {
  tag: MARKET_TAG
  contract: string
  tokenId: string
  tokenStandard: TokenStandard
  order: any
  value: BigNumber
  reported: boolean
  invalidReason: InvalidReason | null
  priceChanged: boolean
}

export const getNftOrdersPayloadDecoder = D.array(
  D.struct({
    contractAddr: D.string,
    tokenId: D.string,
    network: D.number,
  }),
)
export type getNftOrdersPayload = D.TypeOf<typeof getNftOrdersPayloadDecoder>

export const getOrdersPayloadDecoder = D.array(D.number)

export type GetOrdersPayload = D.TypeOf<typeof getOrdersPayloadDecoder>

export type GetOrdersResp = {
  success: boolean
  data?: {
    id: number
    price: string
    nft: {
      token: string
      token_id: string
    }
  }[]
}
