import hotToast, { Renderable, Toast, ValueOrFunction } from 'react-hot-toast'

import { warningIcon } from '@/components/Toast'

type Props = {
  status: 'warning' | 'success' | 'error' | 'loading'
  title: ValueOrFunction<Renderable, Toast>
  duration?: number
}
const toast = ({ status, title, duration = 5000 }: Props): string => {
  const opts = { duration }
  switch (status) {
    case 'warning':
      return hotToast(title, {
        ...opts,
        icon: warningIcon,
      })
    case 'success':
      return hotToast.success(title, opts)
    case 'error':
      return hotToast.error(title, opts)
    case 'loading':
      return hotToast.loading(title, {
        ...opts,
        duration: Number.POSITIVE_INFINITY,
      })
  }
}

toast.close = (toastId: string) => hotToast.dismiss(toastId)

toast.closeAll = () => hotToast.dismiss()

export default toast
