import { providers, utils } from 'ethers'

import { randomHex } from '@/lib/web3'

import * as Err from './errors'
import { TOKEN_1155 } from './order'
import { CancelInput, NFTToken, OfferItem, Order, RunInput } from './types'

const orderItemParamType = `tuple(uint256 price, bytes data)`
const feeParamType = `tuple(uint256 percentage, address to)`
const settleDetailParamType = `tuple(uint8 op, uint256 orderIdx, uint256 itemIdx, uint256 price, bytes32 itemHash, address executionDelegate, bytes dataReplacement, uint256 bidIncentivePct, uint256 aucMinIncrementPct, uint256 aucIncDurationSecs, ${feeParamType}[] fees)`
const settleSharedParamType = `tuple(uint256 salt, uint256 deadline, uint256 amountToEth, uint256 amountToWeth, address user, bool canFail)`
const orderParamType = `tuple(uint256 salt, address user, uint256 network, uint256 intent, uint256 delegateType, uint256 deadline, address currency, bytes dataMask, ${orderItemParamType}[] items, bytes32 r, bytes32 s, uint8 v, uint8 signVersion)`
const cancelInputParamType = `tuple(bytes32[] itemHashes, uint256 deadline, uint8 v, bytes32 r, bytes32 s)`
export const runInputParamType = `tuple(${orderParamType}[] orders, ${settleDetailParamType}[] details, ${settleSharedParamType} shared, bytes32 r, bytes32 s, uint8 v)`
const orderParamTypes = [
  `uint256`,
  `address`,
  `uint256`,
  `uint256`,
  `uint256`,
  `uint256`,
  `address`,
  `bytes`,
  `uint256`,
  `${orderItemParamType}[]`,
]
const itemHashParamTypes = [
  `uint256`,
  `address`,
  `uint256`,
  `uint256`,
  `uint256`,
  `uint256`,
  `address`,
  `bytes`,
  orderItemParamType,
]
const data721ParamType = `tuple(address token, uint256 tokenId)[]`
const data1155ParamType = `tuple(address token, uint256 tokenId, uint256 amount)[]`

export const is1155ItemData = (data: NFTToken[]): boolean => {
  let has721 = false
  let has1155 = false
  data.forEach((token: NFTToken) => {
    if (token.kind === TOKEN_1155) {
      has1155 = true
    } else {
      has721 = true
    }
  })
  if ((has721 && has1155) || data.length == 0) {
    throw new Err.MarketError(Err.ERR_3000)
  }
  return has1155
}

export const is1155Order = (items: OfferItem[]): boolean => {
  let has721 = false
  let has1155 = false
  items.forEach((item: OfferItem) => {
    item.tokens.forEach((token: NFTToken) => {
      if (token.kind === TOKEN_1155) {
        has1155 = true
      } else {
        has721 = true
      }
    })
  })
  if (has721 && has1155) {
    throw new Err.MarketError(Err.ERR_3000)
  }
  return has1155
}

export const calcItemHash = (order: Order, itemIdx: number) => {
  const itemHashData = utils.defaultAbiCoder.encode(itemHashParamTypes, [
    order.salt,
    order.user,
    order.network,
    order.intent,
    order.delegateType,
    order.deadline,
    order.currency,
    order.dataMask,
    order.items[itemIdx],
  ])
  return utils.keccak256(itemHashData)
}

export const encodeItemData = (data: NFTToken[]) => {
  if (is1155ItemData(data)) {
    return utils.defaultAbiCoder.encode([data1155ParamType], [data])
  } else {
    return utils.defaultAbiCoder.encode([data721ParamType], [data])
  }
}

export const encodeOrder = (order: Order): string => {
  return utils.defaultAbiCoder.encode([orderParamType], [order])
}

export const decodeCancelInput = (data: string): CancelInput | undefined => {
  try {
    const result = utils.defaultAbiCoder.decode([cancelInputParamType], data)
    return result[0] as CancelInput
  } catch (ignored) {
    console.log('decodeCancelInput error', ignored)
  }
  return undefined
}

export const decodeRunInput = (data: string): RunInput | undefined => {
  try {
    const result = utils.defaultAbiCoder.decode([runInputParamType], data)
    return result[0] as RunInput
  } catch (ignored) {
    console.log('decodeRunInput error', ignored)
  }
  return undefined
}

export const signOrderData = async (
  web3Provider: providers.Web3Provider,
  order: Order,
) => {
  const orderData = utils.defaultAbiCoder.encode(orderParamTypes, [
    order.salt,
    order.user,
    order.network,
    order.intent,
    order.delegateType,
    order.deadline,
    order.currency,
    order.dataMask,
    order.items.length,
    order.items,
  ])
  // console.log('orderData: ', orderData)
  // const re = utils.defaultAbiCoder.decode(orderParamTypes, orderData)
  // console.log(re)
  const orderHash = utils.keccak256(orderData)
  // console.log('orderHash: ', orderHash)
  const orderSig = (await web3Provider.send('personal_sign', [
    orderHash,
    order.user,
  ])) as string
  // console.log('orderSig: ', orderSig)
  order.r = `0x${orderSig.slice(2, 66)}`
  order.s = `0x${orderSig.slice(66, 130)}`
  order.v = parseInt(orderSig.slice(130, 132), 16)
  fixSignature(order)
}

export const fixDeadline = (deadline: number): number => {
  return deadline > 0 ? deadline : 1e10
}

export const fixSignature = (data: CancelInput | Order | RunInput) => {
  // in geth its always 27/28, in ganache its 0/1. Change to 27/28 to prevent
  // signature malleability if version is 0/1
  // see https://github.com/ethereum/go-ethereum/blob/v1.8.23/internal/ethapi/api.go#L465
  if (data.v < 27) {
    data.v = data.v + 27
  }
}

export const randomSalt = () => {
  return utils.hexZeroPad(randomHex(16), 64)
}
