export const NETWORK_ETH = 1
export const NETWORK_RINKEBY = 4
export const NETWORK_GOERLI = 5

// Support Traverse
export const NETWORK_BSC = 56
export const NETWORK_BSC_TESTNET = 97
export const NETWORK_POLYGON = 137
export const NETWORK_POLYGON_TESTNET = 80001 // Mumbai Testnet
export const NETWORK_AVAX = 43114 // avalanche
export const NETWORK_AVAX_TESTNET = 43113 // fuji
export const NETWORK_FANTOM = 250
export const NETWORK_FANTOM_TESTNET = 4002
export const NETWORK_ARBITRUM = 42161
export const NETWORK_ARBITRUM_TESTNET = 421611 // (arb)rinkeby
export const NETWORK_OPTIMISM = 10
export const NETWORK_OPTIMISM_TESTNET = 69

export const IF165 = '0x01ffc9a7'
export const IF721 = '0x80ac58cd'
export const IF721Enum = '0x780e9d63'
export const IF721Meta = '0x5b5e139f'
export const IF1155 = '0xd9b67a26'
export const IF1155Meta = '0x0e89341c'

// https://github.com/safe-global/safe-contracts/blob/main/CHANGELOG.md#handler-contracts-1
export const FALLBACK_HANDLER = '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4'
// https://github.com/safe-global/safe-contracts/blob/main/contracts/interfaces/ISignatureValidator.sol#LL6C19-L6C19
export const EIP1271_MAGIC_VALUE = '0x20c13b0b'
