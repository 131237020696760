import { useCallback, useEffect, useMemo, useRef } from 'react'

export const useRaf = (
  cb: (v: { time: number; delta: number }) => void,
  framerate: number,
) => {
  const frame = useRef<number>()
  const last = useRef(performance.now())
  const init = useRef(performance.now())
  const stats = useRef<any>(null)
  const expectDelta = useMemo(() => 1000 / framerate, [framerate])

  // useEffect(() => {
  //   const Stats = require('stats.js')
  //   stats.current = new Stats()
  //   document.body.appendChild(stats.current.dom)
  // }, [])

  const animate = useCallback(() => {
    const now = performance.now()
    const time = now - init.current
    const delta = now - last.current

    if (delta >= expectDelta) {
      stats.current?.begin()

      cb({ time, delta })
      last.current = now

      stats.current?.end()
    }
    frame.current = requestAnimationFrame(animate)
  }, [cb, expectDelta])

  useEffect(() => {
    frame.current = requestAnimationFrame(animate)
    return () =>
      frame.current ? cancelAnimationFrame(frame.current) : undefined
  }, [animate]) // Make sure to change it if the deps change
}
