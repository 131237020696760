import clsx from 'clsx'
import { constants } from 'ethers'
import { useTranslation } from 'next-i18next'

import { Tooltip } from '@/components/overlay'
import { TokenIcon } from '@/components/TokenIcon'
import { ReactComponent as OpenSea } from '@/images/OpenSea.svg'
import { ReactComponent as X2Y2 } from '@/images/X2Y2.svg'
import { fmtNumber } from '@/lib/formatter'

import { FloorPrice } from '../modals'

type FloorPriceTooltipProps = {
  floorPrice: FloorPrice & { symbol: string }
  osFloorPrice: number
}
export const FloorPriceTooltip = ({
  floorPrice,
  osFloorPrice,
}: FloorPriceTooltipProps) => {
  const { t } = useTranslation()
  return (
    <div className="ts-caption-2 min-w-[211px]">
      <p className="flex items-center space-x-2">
        {/* <OpenSea className="h-4 w-4" /> */}
        <span className="flex-1">{t('Global Floor')}</span>
        <span>
          <strong>{osFloorPrice ? fmtNumber(osFloorPrice) : t('N/A')}</strong>{' '}
          ETH
        </span>
      </p>
      <p className="flex items-center space-x-2">
        <X2Y2 className="h-4 w-4" />
        <span className="flex-1">
          {t('Floor on {{platform}}', { platform: 'X2Y2' })}
        </span>
        <span>
          <strong>{fmtNumber(floorPrice.value)}</strong> {floorPrice.symbol}
        </span>
      </p>
    </div>
  )
}

export const getMinFloorPrice = (
  floorPrice: FloorPrice,
  osFloorPrice: number,
) =>
  floorPrice.symbol === 'ETH' &&
  osFloorPrice > 0 &&
  osFloorPrice < floorPrice.value
    ? { value: osFloorPrice, currency: constants.AddressZero, symbol: 'ETH' }
    : floorPrice

type Props = {
  className?: string
  floorPrice: FloorPrice | null
  osFloorPrice: number
}
export const FloorPriceDesc = ({
  className,
  floorPrice,
  osFloorPrice,
}: Props) => {
  const { t } = useTranslation()
  const minFloorPrice = floorPrice
    ? getMinFloorPrice(floorPrice, osFloorPrice)
    : null
  return (
    <p className={clsx(className, 'ts-caption-2 text-gray-500')}>
      {t('Floor:')}{' '}
      <Tooltip
        label={
          floorPrice && (
            <FloorPriceTooltip
              floorPrice={floorPrice}
              osFloorPrice={osFloorPrice}
            />
          )
        }
        placement="top"
        hasArrow
      >
        {minFloorPrice ? (
          <span className="ts-caption-2 font-bold">
            <TokenIcon
              className="mb-0.5 inline h-3 w-3"
              symbol={minFloorPrice.symbol}
              disableTooltip
            />
            <strong className="text-gray-800">
              {fmtNumber(minFloorPrice.value)}
            </strong>
          </span>
        ) : (
          '-'
        )}
      </Tooltip>
    </p>
  )
}
