// DO NOT EDIT THIS FILE
// This file is generated by /next/scripts/upload_images.ts
export const loanHeaderFi = {
  path: 'frontend/vF/DSLiopdKORGUki',
  width: 480,
  height: 480,
  format: 'png',
}
export const ajin = {
  path: 'frontend/3g/lGDBJEENVRjueK',
  width: 512,
  height: 512,
  format: 'png',
}
export const ayorLogo = {
  path: 'frontend/Do/kXsrB50tMxmIZe',
  width: 2000,
  height: 2000,
  format: 'png',
}
export const extScreenshot = {
  path: 'frontend/Et/8P6lV4eHQCHywH',
  width: 1460,
  height: 960,
  format: 'png',
}
export const fiBanner = {
  path: 'frontend/Bo/s59P0ZSEzQVNrB',
  width: 2400,
  height: 258,
  format: 'jpeg',
}
export const fiBannerMobile = {
  path: 'frontend/jw/s1a36FkEl7iYHq',
  width: 1290,
  height: 468,
  format: 'jpeg',
}
export const royaltyBg = {
  path: 'frontend/Pn/I1bNQ_an8U3I0i',
  width: 1376,
  height: 1046,
  format: 'png',
}
export const tgBind = {
  path: 'frontend/Ar/MOSAsCbxYUHkXX',
  width: 558,
  height: 296,
  format: 'png',
}
export const x2y2Console = {
  path: 'frontend/gf/4lHuPSg8C2E4Pl',
  width: 512,
  height: 92,
  format: 'png',
}
export const partners = {
  dark: {
    '10RaritysniperLogo': {
      path: 'frontend/_2/hEKNMoN9v1Kgo6',
      width: 200,
      height: 80,
      format: 'png',
    },
    '11NansenLogo': {
      path: 'frontend/h4/YRaO5_yx19LU3y',
      width: 200,
      height: 70,
      format: 'png',
    },
    '12Go+Logo': {
      path: 'frontend/dk/Iq17gfcl5uBLEp',
      width: 200,
      height: 44,
      format: 'png',
    },
    '13NftgoLogo': {
      path: 'frontend/qT/y8EnaBOcONvrjW',
      width: 200,
      height: 52,
      format: 'png',
    },
    '14SlashpaymentLogo': {
      path: 'frontend/-q/dzD0nt6k_Fkxth',
      width: 200,
      height: 32,
      format: 'png',
    },
    '15NfteyeLogo': {
      path: 'frontend/QY/VduWyZZfLrntzE',
      width: 200,
      height: 52,
      format: 'png',
    },
    '16B4fomoNFTLogo': {
      path: 'frontend/Hq/NpEXX0XHNhHnAn',
      width: 200,
      height: 44,
      format: 'png',
    },
    '17DefinedLogo': {
      path: 'frontend/ow/E2yFKguz9bDGl6',
      width: 200,
      height: 40,
      format: 'png',
    },
    '18NFTscoringLogo': {
      path: 'frontend/un/N4wXgyVvUuwkLV',
      width: 200,
      height: 36,
      format: 'png',
    },
    '19TokunLogo': {
      path: 'frontend/l3/2z_pAsVo4SHeKM',
      width: 200,
      height: 76,
      format: 'png',
    },
    '1OpenseaLogo': {
      path: 'frontend/pL/5KIjK9mZC1P7ov',
      width: 200,
      height: 56,
      format: 'png',
    },
    '20AtlasLogo': {
      path: 'frontend/iG/6Gqa0cEZYQKZU7',
      width: 200,
      height: 80,
      format: 'png',
    },
    '2UniswapLogo': {
      path: 'frontend/p_/jDnRjstf8g8H8Q',
      width: 200,
      height: 72,
      format: 'png',
    },
    '3GemLogo': {
      path: 'frontend/UO/PF3MPfslJafDIO',
      width: 200,
      height: 84,
      format: 'png',
    },
    '4NFTsenseiLogo': {
      path: 'frontend/K-/Z5kiFieBSmCeFT',
      width: 200,
      height: 28,
      format: 'png',
    },
    '5NfttrackLogo': {
      path: 'frontend/BY/zBuNOyTe51FHk7',
      width: 200,
      height: 64,
      format: 'png',
    },
    '6TraitsniperLogo': {
      path: 'frontend/kU/7QckOp3WbKQs8M',
      width: 200,
      height: 72,
      format: 'png',
    },
    '7NftscanLogo': {
      path: 'frontend/eS/BruoVAZ6UGL2JD',
      width: 200,
      height: 38,
      format: 'png',
    },
    '8NftnerdsLogo': {
      path: 'frontend/o_/toOY0juY-9mmeZ',
      width: 200,
      height: 68,
      format: 'png',
    },
    '9TinyastroLogo': {
      path: 'frontend/KZ/OCH5NAHob3Ubee',
      width: 200,
      height: 36,
      format: 'png',
    },
    fusionxyzLogo: {
      path: 'frontend/PR/t7YzfY_xHeB3hZ',
      width: 200,
      height: 80,
      format: 'png',
    },
    nftflipaiLogo: {
      path: 'frontend/sG/fj0S6oRnOWzEM0',
      width: 200,
      height: 56,
      format: 'png',
    },
  },
  'dark-active': {
    '10RaritysniperLogo': {
      path: 'frontend/WE/1e0JHiXPWwn2DQ',
      width: 200,
      height: 80,
      format: 'png',
    },
    '11NansenLogo': {
      path: 'frontend/8a/5yQzzIoX5X7V4f',
      width: 200,
      height: 70,
      format: 'png',
    },
    '12Go+Logo': {
      path: 'frontend/nk/TgBbB53qOhuwTS',
      width: 200,
      height: 44,
      format: 'png',
    },
    '13NftgoLogo': {
      path: 'frontend/SW/ZCkUKVegu-PJAA',
      width: 200,
      height: 52,
      format: 'png',
    },
    '14SlashpaymentLogo': {
      path: 'frontend/p9/xK372aEIw3WqqR',
      width: 200,
      height: 32,
      format: 'png',
    },
    '15NfteyeLogo': {
      path: 'frontend/dd/SdcE8XvNbiWTw5',
      width: 200,
      height: 52,
      format: 'png',
    },
    '16B4fomoNFTLogo': {
      path: 'frontend/18/J46cZDI9UyOTW7',
      width: 200,
      height: 44,
      format: 'png',
    },
    '17DefinedLogo': {
      path: 'frontend/XY/44-BLFEsfXUYTh',
      width: 200,
      height: 40,
      format: 'png',
    },
    '18NFTscoringLogo': {
      path: 'frontend/am/32ys0oBa92o4HO',
      width: 200,
      height: 36,
      format: 'png',
    },
    '19TokunLogo': {
      path: 'frontend/P_/sDQzqQMevylWam',
      width: 200,
      height: 76,
      format: 'png',
    },
    '1OpenseaLogo': {
      path: 'frontend/yS/Y2lsv1h8V1_WUF',
      width: 200,
      height: 56,
      format: 'png',
    },
    '20AtlasLogo': {
      path: 'frontend/fy/EITVjiF54Pi02c',
      width: 200,
      height: 80,
      format: 'png',
    },
    '2UniswapLogo': {
      path: 'frontend/wS/nvsGPnYm5xcciw',
      width: 200,
      height: 72,
      format: 'png',
    },
    '3GemLogo': {
      path: 'frontend/n-/oxENypxeri-JHo',
      width: 200,
      height: 84,
      format: 'png',
    },
    '4NFTsenseiLogo': {
      path: 'frontend/YH/U0neTWHtz0FPyo',
      width: 200,
      height: 28,
      format: 'png',
    },
    '5NfttrackLogo': {
      path: 'frontend/oF/_2TleOhRMpBKJl',
      width: 200,
      height: 64,
      format: 'png',
    },
    '6TraitsniperLogo': {
      path: 'frontend/uW/08WHnu17ZFtsKA',
      width: 200,
      height: 72,
      format: 'png',
    },
    '7NftscanLogo': {
      path: 'frontend/7x/HgAdR_z94aRAiG',
      width: 200,
      height: 38,
      format: 'png',
    },
    '8NftnerdsLogo': {
      path: 'frontend/KT/5NYIkJPDbx7OLZ',
      width: 200,
      height: 68,
      format: 'png',
    },
    '9TinyastroLogo': {
      path: 'frontend/2j/9HGIYw9Zkkhr9s',
      width: 200,
      height: 36,
      format: 'png',
    },
    fusionxyzLogo: {
      path: 'frontend/Mn/zedCxBZlNd6STm',
      width: 200,
      height: 80,
      format: 'png',
    },
    nftflipaiLogo: {
      path: 'frontend/Mb/JLVX1vpCi2GARL',
      width: 200,
      height: 56,
      format: 'png',
    },
  },
  light: {
    '10RaritysniperLogo': {
      path: 'frontend/0Z/tero12bnkX16JV',
      width: 200,
      height: 80,
      format: 'png',
    },
    '11NansenLogo': {
      path: 'frontend/bf/_PJpdCDJ6l_cN9',
      width: 200,
      height: 70,
      format: 'png',
    },
    '12Go+Logo': {
      path: 'frontend/uX/1zrRhgVPh-LPYB',
      width: 200,
      height: 44,
      format: 'png',
    },
    '13NftgoLogo': {
      path: 'frontend/Hp/83t_bvYo78roSA',
      width: 200,
      height: 52,
      format: 'png',
    },
    '14SlashpaymentLogo': {
      path: 'frontend/MH/XPF_z0HC2JCcsm',
      width: 200,
      height: 32,
      format: 'png',
    },
    '15NfteyeLogo': {
      path: 'frontend/IN/10vb67ZSJbIGGA',
      width: 200,
      height: 52,
      format: 'png',
    },
    '16B4fomoNFTLogo': {
      path: 'frontend/l9/VTVlWGp2Qdtl9d',
      width: 200,
      height: 44,
      format: 'png',
    },
    '17DefinedLogo': {
      path: 'frontend/ci/XXh_91Ue-iR84q',
      width: 200,
      height: 40,
      format: 'png',
    },
    '18NFTscoringLogo': {
      path: 'frontend/Wf/XteFirpagIV2V0',
      width: 200,
      height: 36,
      format: 'png',
    },
    '19TokunLogo': {
      path: 'frontend/tu/eQ3UJly1U3kqcd',
      width: 200,
      height: 76,
      format: 'png',
    },
    '1OpenseaLogo': {
      path: 'frontend/as/e-NA2Gaue6thx9',
      width: 200,
      height: 56,
      format: 'png',
    },
    '20AtlasLogo': {
      path: 'frontend/8f/rntxZL6K1t6TQa',
      width: 200,
      height: 80,
      format: 'png',
    },
    '2UniswapLogo': {
      path: 'frontend/43/jR4pfzbX0ncmaq',
      width: 200,
      height: 72,
      format: 'png',
    },
    '3GemLogo': {
      path: 'frontend/pb/XpB1eFeeRfARIn',
      width: 200,
      height: 84,
      format: 'png',
    },
    '4NFTsenseiLogo': {
      path: 'frontend/9e/NbNd7NMzAngDkp',
      width: 200,
      height: 28,
      format: 'png',
    },
    '5NfttrackLogo': {
      path: 'frontend/cO/O8FOZJBiGwkd6Q',
      width: 200,
      height: 64,
      format: 'png',
    },
    '6TraitsniperLogo': {
      path: 'frontend/Mf/v_wtvpvMiZ2kZ9',
      width: 200,
      height: 72,
      format: 'png',
    },
    '7NftscanLogo': {
      path: 'frontend/mm/gAMtw4YEYGi51T',
      width: 200,
      height: 38,
      format: 'png',
    },
    '8NftnerdsLogo': {
      path: 'frontend/Sb/c2rWMzrVxbTXnw',
      width: 200,
      height: 68,
      format: 'png',
    },
    '9TinyastroLogo': {
      path: 'frontend/er/TP1xM7-Pr36lDx',
      width: 200,
      height: 36,
      format: 'png',
    },
    fusionxyzLogo: {
      path: 'frontend/4V/U76pQZpCDqKo6M',
      width: 200,
      height: 80,
      format: 'png',
    },
    nftflipaiLogo: {
      path: 'frontend/Ao/Si4_Iftl7cYrug',
      width: 200,
      height: 56,
      format: 'png',
    },
  },
  'light-active': {
    '10RaritysniperLogo': {
      path: 'frontend/Lb/uctFXCTSb93eKd',
      width: 200,
      height: 80,
      format: 'png',
    },
    '11NansenLogo': {
      path: 'frontend/mA/wff5OOTjKdT37K',
      width: 200,
      height: 70,
      format: 'png',
    },
    '12Go+Logo': {
      path: 'frontend/KD/3HmS-qvEIcz6iQ',
      width: 200,
      height: 44,
      format: 'png',
    },
    '13NftgoLogo': {
      path: 'frontend/c0/veVtMUxuBwUZar',
      width: 200,
      height: 52,
      format: 'png',
    },
    '14SlashpaymentLogo': {
      path: 'frontend/p9/xK372aEIw3WqqR',
      width: 200,
      height: 32,
      format: 'png',
    },
    '15NfteyeLogo': {
      path: 'frontend/Bk/mXS3bi21FAdKE-',
      width: 200,
      height: 52,
      format: 'png',
    },
    '16B4fomoNFTLogo': {
      path: 'frontend/tu/W0p6UOhmucmmYm',
      width: 200,
      height: 44,
      format: 'png',
    },
    '17DefinedLogo': {
      path: 'frontend/3Q/YlgUcJRsd9wyYs',
      width: 200,
      height: 40,
      format: 'png',
    },
    '18NFTscoringLogo': {
      path: 'frontend/N6/gtr8Oaa3jyaQdG',
      width: 200,
      height: 36,
      format: 'png',
    },
    '19TokunLogo': {
      path: 'frontend/z-/LGc12lK8n9kx8u',
      width: 200,
      height: 76,
      format: 'png',
    },
    '1OpenseaLogo': {
      path: 'frontend/6U/5FEYqepIukMzkz',
      width: 200,
      height: 56,
      format: 'png',
    },
    '20AtlasLogo': {
      path: 'frontend/4J/mt0A0UwMfPFISu',
      width: 200,
      height: 80,
      format: 'png',
    },
    '2UniswapLogo': {
      path: 'frontend/wS/nvsGPnYm5xcciw',
      width: 200,
      height: 72,
      format: 'png',
    },
    '3GemLogo': {
      path: 'frontend/vs/Sn7g2JuHzFLwGu',
      width: 200,
      height: 84,
      format: 'png',
    },
    '4NFTsenseiLogo': {
      path: 'frontend/Y4/WX61bkjOy8d3Oq',
      width: 200,
      height: 28,
      format: 'png',
    },
    '5NfttrackLogo': {
      path: 'frontend/ZA/8LKl-dVnDH6Bw6',
      width: 200,
      height: 64,
      format: 'png',
    },
    '6TraitsniperLogo': {
      path: 'frontend/mu/KlWFAw51FmJIJW',
      width: 200,
      height: 72,
      format: 'png',
    },
    '7NftscanLogo': {
      path: 'frontend/ys/qzfU5bYjYZ19PG',
      width: 200,
      height: 38,
      format: 'png',
    },
    '8NftnerdsLogo': {
      path: 'frontend/8G/ARj75RUvN7IHVk',
      width: 200,
      height: 68,
      format: 'png',
    },
    '9TinyastroLogo': {
      path: 'frontend/JU/uj5Zhtzik04qOp',
      width: 200,
      height: 36,
      format: 'png',
    },
    fusionxyzLogo: {
      path: 'frontend/88/L5XkP07tw87nfb',
      width: 200,
      height: 80,
      format: 'png',
    },
    nftflipaiLogo: {
      path: 'frontend/5h/XRSeLVg4Af5imN',
      width: 200,
      height: 56,
      format: 'png',
    },
  },
}
export const shared = {
  404: {
    path: 'frontend/lx/ZUigJZOVUbtGIK',
    width: 1394,
    height: 534,
    format: 'png',
  },
  500: {
    path: 'frontend/iC/bRqxYGAB5GvWtt',
    width: 1472,
    height: 536,
    format: 'png',
  },
  clientError: {
    path: 'frontend/Bn/1VqTNfk2U-0cqL',
    width: 1360,
    height: 492,
    format: 'png',
  },
  defaultDark: {
    path: 'frontend/-8/sslb16hF6L30-T',
    width: 1080,
    height: 1080,
    format: 'png',
  },
  defaultLight: {
    path: 'frontend/xZ/fHpX8VqL4JmdJf',
    width: 1080,
    height: 1080,
    format: 'png',
  },
  og: {
    path: 'frontend/j8/qDuhtdblwLAP0-',
    width: 1200,
    height: 630,
    format: 'jpeg',
  },
  zeroFee: {
    path: 'frontend/Dp/sIqTZmtCOLzlHT',
    width: 637,
    height: 394,
    format: 'png',
  },
}
