import { STORAGE_KEY } from './manager'
import { classNames } from './utils'

export type ColorModeScriptProps = {
  initialColorMode?: 'light' | 'dark' | 'system'
  nonce?: string
}

const VALID_VALUES = new Set(['dark', 'light', 'system'])

/**
 * runtime safe-guard against invalid color mode values
 */
function normalize(initialColorMode: 'light' | 'dark' | 'system') {
  let value = initialColorMode
  if (!VALID_VALUES.has(value)) value = 'light'
  return value
}

export function getScriptSrc(props: ColorModeScriptProps = {}) {
  const { initialColorMode = 'light' } = props

  // runtime safe-guard against invalid color mode values
  const init = normalize(initialColorMode)

  const fn = `(function(){try{var a=function(c){var v="(prefers-color-scheme: dark)",h=window.matchMedia(v).matches?"dark":"light",r=c==="system"?h:c,o=document.documentElement,s=document.body,l="${classNames.light}",d="${classNames.dark}",i=r==="dark";return s.classList.add(i?d:l),s.classList.remove(i?l:d),o.style.colorScheme=r,o.dataset.theme=r,r},n=a,m="${init}",e="${STORAGE_KEY}",t=localStorage.getItem(e);t?a(t):localStorage.setItem(e,a(m))}catch(a){}})();`

  return `!${fn}`.trim()
}

export function ColorModeScript(props: ColorModeScriptProps = {}) {
  const { nonce } = props

  return (
    <script
      id="color-mode-script"
      nonce={nonce}
      dangerouslySetInnerHTML={{ __html: getScriptSrc(props) }}
    />
  )
}
