export type ShoppingCartAsset = {
  contractAddr: string
  tokenId: string
  price: string
  contractName: string
  name: string
  image: string
  testnet?: boolean
}

type ShoppingCartExt = {
  check: () => void
  getItems: () => Promise<ShoppingCartAsset[]>
  removeItem: (asset: ShoppingCartAsset) => Promise<true>
  removeItems: (assets: ShoppingCartAsset[]) => Promise<ShoppingCartAsset[]>
  editItems: (assets: ShoppingCartAsset[]) => Promise<ShoppingCartAsset[]>
  clear: () => Promise<true>
  setOnChangeHandler: (handler: (v: ShoppingCartAsset[]) => void) => void
}

export const checkExtInstalled = () => {
  if (!window) return false
  if (!(window as any).__shoppingCartExt) return false
  const shoppingCartExt: ShoppingCartExt = (window as any).__shoppingCartExt
  try {
    shoppingCartExt.check()
  } catch {
    return false
  }
  return true
}

export const getExt = () => {
  if (checkExtInstalled()) {
    return (window as any).__shoppingCartExt as ShoppingCartExt
  }
  throw 'opensea shopping cart extension is not installed'
}
