import dayjs from 'dayjs'
import { constants } from 'ethers'
import { pipe } from 'fp-ts/lib/function'
import { IntFromString } from 'io-ts-types'
import { TFunction } from 'next-i18next'
import { ParsedUrlQuery } from 'querystring'

import { EsMapping } from '@/lib/es/mapping'
import { SearchPayload } from '@/lib/es/nft'
import {
  attrKeywordsFilterDecoder,
  attrMinMaxFilterDecoder,
} from '@/lib/es/types'
import { fmtNumber } from '@/lib/formatter'
import { A, E, O } from '@/lib/fp'
import { ContractInfoFragment } from '@/lib/gql/types'
import { basicURLQueryDecode, basicURLQueryKVGen } from '@/lib/search/nft'
import { contractsURLQueryCodec, kvToUrlQuery } from '@/lib/search/utils'
import { DEFAULT_NETWORK } from '@/utils/network'

import { getTokenMeta } from '../token'

export const itemsTabUrlQueryCodec = {
  decode: (
    q: ParsedUrlQuery,
    esMapping: EsMapping,
  ): Pick<SearchPayload, 'sort' | 'filters'> => {
    const basicQuery = basicURLQueryDecode(q, esMapping)
    return {
      ...basicQuery,
      filters: {
        ...basicQuery.filters,
        network: pipe(
          IntFromString.decode(q['network']),
          O.fromEither,
          O.getOrElse(() => DEFAULT_NETWORK),
        ),
        contracts: contractsURLQueryCodec.decode(q),
      },
    }
  },
  encode: (query: Pick<SearchPayload, 'sort' | 'filters'>) =>
    pipe(
      [
        pipe(
          O.fromNullable(query.filters?.network),
          O.filter((a) => a !== DEFAULT_NETWORK),
          O.map((v) => ({ k: 'network', v: v.toString() })),
        ),
        contractsURLQueryCodec.encode(query.filters?.contracts ?? []),
      ],
      A.compact,
      A.concat(basicURLQueryKVGen(query)),
      kvToUrlQuery,
    ),
}

export const itemsTabFilterLabelsVMGen = ({
  filters,
  setFilters,
  esMapping,
  contracts,
  t,
}: {
  filters: SearchPayload['filters']
  setFilters: (a: SearchPayload['filters']) => void
  esMapping: EsMapping
  contracts: Pick<
    ContractInfoFragment,
    'id' | 'network_id' | 'contract' | 'name' | 'icon_url'
  >[]
  t: TFunction
}) => {
  return {
    options: A.compact([
      pipe(
        O.fromNullable(filters?.query),
        O.map((query) => {
          return {
            title: query,
            onDel: () => {
              setFilters({
                ...filters,
                query: undefined,
              })
            },
          }
        }),
      ),
      (() => {
        const priceMin = O.fromNullable(filters?.priceMin)
        const priceMax = O.fromNullable(filters?.priceMax)
        const currency = filters?.currency
          ? getTokenMeta(
              filters?.network ?? contracts[0]?.network_id ?? DEFAULT_NETWORK,
              filters?.currency ?? constants.AddressZero,
            ).symbol
          : ''
        return pipe(
          (() => {
            if (O.isSome(priceMin) && O.isSome(priceMax)) {
              if (priceMin.value === priceMax.value)
                return O.some(`${priceMin.value} ${currency}`)
              return O.some(
                t('{{min}} ~ {{max}} {{unit}}', {
                  min: fmtNumber(priceMin.value),
                  max: fmtNumber(priceMax.value),
                  unit: currency,
                }),
              )
            }
            if (O.isSome(priceMin)) {
              return O.some(
                t('>= {{min}} {{unit}}', {
                  min: fmtNumber(priceMin.value),
                  unit: currency,
                }),
              )
            }
            if (O.isSome(priceMax)) {
              return O.some(
                t(`<= {{max}} {{unit}}`, {
                  max: fmtNumber(priceMax.value),
                  unit: currency,
                }),
              )
            }
            return O.none
          })(),
          O.map((value) => ({
            title: `${t('Price')}: ${value}`,
            onDel: () =>
              setFilters({
                ...filters,
                priceMin: undefined,
                priceMax: undefined,
              }),
          })),
        )
      })(),
      ...(() => {
        const contractsInQuery = filters?.contracts ?? []
        const isSingleSelect = contractsInQuery.length === 1
        return pipe(
          contractsInQuery,
          A.map((a) => {
            const c = contracts.find((b) => b.id === a)
            if (!c) return O.none
            return O.some({
              title: c.name,
              onDel: () => {
                setFilters({
                  ...filters,
                  contracts: contractsInQuery.filter((a) => a !== c.id),
                  attributes: isSingleSelect ? {} : filters?.attributes,
                })
              },
            })
          }),
        )
      })(),
      ...pipe(
        esMapping,
        A.chain((m) => {
          const keywordsFilter = attrKeywordsFilterDecoder.decode(
            filters?.attributes?.[m.es_key],
          )
          if (E.isRight(keywordsFilter)) {
            return keywordsFilter.right.map((a) => ({
              title: `${m.name}: ${a}`,
              onDel: () => {
                setFilters({
                  ...filters,
                  attributes: {
                    ...filters?.attributes,
                    [m.es_key]: pipe(
                      keywordsFilter.right.filter((b) => b !== a),
                    ),
                  },
                })
              },
            }))
          }
          const minmaxFilter = attrMinMaxFilterDecoder.decode(
            filters?.attributes?.[m.es_key],
          )
          if (E.isRight(minmaxFilter)) {
            const [min, max] = minmaxFilter.right
            const fmt =
              m.type === 'date'
                ? (value: number) => dayjs(value * 1000).format('YYYY-MM-DD')
                : fmtNumber
            return pipe(
              (() => {
                if (min !== null && max !== null) {
                  if (min === max) return O.some(fmt(min))
                  return O.some(
                    t('{{min}} ~ {{max}}', {
                      min: fmt(min),
                      max: fmt(max),
                    }),
                  )
                }
                if (min !== null) {
                  return O.some(
                    t('>= {{min}}', {
                      min: fmt(min),
                    }),
                  )
                }
                if (max !== null) {
                  return O.some(
                    t(`<= {{max}}`, {
                      max: fmt(max),
                    }),
                  )
                }
                return O.none
              })(),
              O.map((value) => ({
                title: `${m.name}: ${value}`,
                onDel: () =>
                  setFilters({
                    ...filters,
                    attributes: {
                      ...filters?.attributes,
                      [m.es_key]: [null, null],
                    },
                  }),
              })),
              O.map((a) => [a]),
              O.getOrElse((): { title: string; onDel: () => void }[] => []),
            )
          }
          return []
        }),
        A.map(O.some),
      ),
    ]),
    onClear: () =>
      setFilters({
        // keep some filters from clear
        buyNow: filters?.buyNow,
      }),
  }
}
