import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react'

type CheckboxProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'disabled'
> & {
  isChecked?: boolean
  isIndeterminate?: boolean
  isDisabled?: boolean
  gaLabel?: string
}
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      children,
      isChecked,
      isIndeterminate,
      isDisabled,
      gaLabel,
      ...props
    },
    ref,
  ) => {
    return (
      <label
        className={clsx(
          className,
          'flex min-w-0 cursor-pointer items-center space-x-3',
        )}
      >
        <span className="relative flex">
          <input
            className={clsx(
              'form-checkbox h-6 w-6 cursor-pointer rounded border-2 border-input-checkbox-border bg-input-checkbox text-primary-1 transition-all',
              'hover:border-gray-400 hover:checked:bg-primary-1-600',
              'focus:ring-0 focus:ring-offset-0 focus-visible:ring-2',
              gaLabel,
            )}
            type="checkbox"
            ref={ref}
            checked={isIndeterminate ? false : isChecked}
            disabled={isDisabled}
            {...props}
          />
          {isIndeterminate && (
            <svg
              className="absolute inset-0 text-primary-1 hover:text-primary-1-600"
              viewBox="0 0 24 24"
            >
              <rect width="24" height="24" rx="4" fill="currentColor" />
              <path
                d="M6 12H18"
                stroke="#F7F8F8"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          )}
        </span>
        <span className="truncate">{children}</span>
      </label>
    )
  },
)

Checkbox.displayName = 'Checkbox'
