import { FortmaticConnector } from '@web3-react/fortmatic-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { pipe } from 'fp-ts/lib/function'

import { A } from '@/lib/fp'
import {
  ALL_MAINNETS,
  ALL_TESTNETS,
  getProviderURLByNetworkId,
} from '@/utils/network'

import { ON_PROD } from '../env'
import { WalletConnectV2Connector } from './WalletConnectV2Connector'

const COMMON_NETWORKS = [
  56, 97, 1, 137, 80001, 3, 4, 5, 43114, 43113, 250, 4002, 42161, 421611, 336,
  81, 128, 256, 66, 65, 10, 69, 1666600000, 1285, 1313161554, 1284, 122, 288,
  8217, 40, 1088, 42220, 25, 42262, 82, 592,
]

export const injected = new InjectedConnector({
  supportedChainIds: COMMON_NETWORKS,
})

export const walletConnect = new WalletConnectV2Connector({
  projectId: 'e0bfdc9191470736af9ca79ddff0cfe6',
  showQrModal: true,
  chains: ON_PROD ? ALL_MAINNETS : ALL_TESTNETS,
  rpcMap: pipe(
    [...ALL_MAINNETS, ...ALL_TESTNETS],
    A.reduce<number, Record<number, string>>({}, (dict, networkId) => {
      const providerURL = getProviderURLByNetworkId(networkId)
      if (providerURL) dict[networkId] = providerURL
      return dict
    }),
  ),
})

export const walletLink = new WalletLinkConnector({
  url: 'https://x2y2.io',
  appName: 'X2Y2',
  appLogoUrl: 'https://x2y2.io/icon.png',
  darkMode: false,
})

export const fortmatic = new FortmaticConnector({
  apiKey: 'pk_live_E79931B41FC052F5',
  chainId: 1,
})
