import clsx from 'clsx'
import { BigNumber, utils } from 'ethers'
import { useTranslation } from 'next-i18next'

import { FormLabelLike } from '@/components/form'
import { TokenIcon } from '@/components/TokenIcon'
import useCurrency from '@/hooks/useCurrency'
import { fmtNumber } from '@/lib/formatter'

const fmtNumberMaxDecimals = 4

type Props = {
  className?: string
  isBuyModal: boolean
  isSuccess?: boolean
  amountToEth: BigNumber
  amountToWeth: BigNumber
  amountTotal: BigNumber
}
export const TotalDisplay = ({
  className,
  isBuyModal,
  isSuccess = false,
  amountToEth,
  amountToWeth,
  amountTotal,
}: Props) => {
  const { t } = useTranslation()
  const { fmtCoinNumber } = useCurrency()

  const totalText = utils.formatEther(amountTotal)
  const showAmountEth = !amountToEth.isZero()
  const showAmountWeth = !amountToWeth.isZero()

  return (
    <div className={className}>
      <div className="flex items-center space-x-3">
        <FormLabelLike className="flex-1">
          {isSuccess ? t('You paid') : t('You pay')}
        </FormLabelLike>
        <div className="flex min-w-0 items-center space-x-3">
          {showAmountEth && (
            <div className="flex min-w-0 items-center space-x-1">
              <TokenIcon className="h-6 w-6 shrink-0" symbol="ETH" />
              <p
                className={clsx(
                  'truncate',
                  isBuyModal ? 'ts-headline-5' : 'ts-headline-6',
                )}
              >
                {fmtNumber(parseFloat(utils.formatEther(amountToEth)), {
                  max: fmtNumberMaxDecimals,
                })}
              </p>
            </div>
          )}
          {showAmountEth && showAmountWeth && <p className="ts-button-1">+</p>}
          {showAmountWeth && (
            <div className="flex min-w-0 items-center space-x-1">
              <TokenIcon className="h-6 w-6 shrink-0" symbol="WETH" />
              <p
                className={clsx(
                  'truncate',
                  isBuyModal ? 'ts-headline-5' : 'ts-headline-6',
                )}
              >
                {fmtNumber(parseFloat(utils.formatEther(amountToWeth)), {
                  max: fmtNumberMaxDecimals,
                })}
              </p>
            </div>
          )}
          {!showAmountEth && !showAmountWeth && (
            <p
              className={clsx(
                'truncate',
                isBuyModal ? 'ts-headline-5' : 'ts-headline-6',
              )}
            >
              -
            </p>
          )}
        </div>
      </div>
      <p className="ts-caption text-right text-gray-500">
        ({fmtCoinNumber(parseFloat(totalText), 'ETH')})
      </p>
    </div>
  )
}
