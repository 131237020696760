import { ethers } from 'ethers'

import { Maybe } from '@/lib/gql/types'

import { ConnectType } from './connectType'

type NarrowTaggedUnion<A, T> = A extends { _tag: T } ? A : never

export type User =
  | {
      _tag: 'pending'
    }
  | {
      _tag: 'anonymous'
    }
  | {
      _tag: 'web3-registered'
      web3ConnType: ConnectType
      web3Provider: ethers.providers.Web3Provider
      meta: {
        id: number
        address: string
        avatar_nft?: Maybe<{
          nft_contract: string
          token_id: string
          metadata?: Maybe<{ meta?: Maybe<unknown> }>
          contract: {
            display_options?: Maybe<unknown>
          }
        }>
        nickname?: Maybe<string>
      }
    }
  | {
      _tag: 'registered'
      web3ConnType: ConnectType
      web3Provider: ethers.providers.Web3Provider
      meta: {
        id: number
        address: string
        avatar_nft?: Maybe<{
          nft_contract: string
          token_id: string
          metadata?: Maybe<{ meta?: Maybe<unknown> }>
          contract: {
            display_options?: Maybe<unknown>
          }
        }>
        nickname?: Maybe<string>
      }
      token: string
      refreshToken: string
    }

export type PendingUser = NarrowTaggedUnion<User, 'pending'>
export type AnonymousUser = NarrowTaggedUnion<User, 'anonymous'>
export type Web3RegisteredUser = NarrowTaggedUnion<User, 'web3-registered'>
export type RegisteredUser = NarrowTaggedUnion<User, 'registered'>
export type NonAnonymousUser = Web3RegisteredUser | RegisteredUser

export const pending: PendingUser = { _tag: 'pending' }
export const anonymous: AnonymousUser = { _tag: 'anonymous' }
export const web3Registered = (
  a: Omit<Web3RegisteredUser, '_tag'>,
): Web3RegisteredUser => ({
  ...a,
  _tag: 'web3-registered',
})
export const registered = (
  a: Omit<RegisteredUser, '_tag'>,
): RegisteredUser => ({
  ...a,
  _tag: 'registered',
})

export const isNonAnynomousUser = (user: User): user is NonAnonymousUser => {
  switch (user._tag) {
    case 'web3-registered':
    case 'registered':
      return true
    default:
      return false
  }
}

export const isSameAddress = (addr1: string, addr2: string) => {
  return addr1.toLowerCase() === addr2.toLowerCase()
}
