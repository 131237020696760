import { Disclosure } from '@headlessui/react'
import { BigNumber } from 'ethers'
import { useTranslation } from 'next-i18next'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import { UseDisclosureReturn } from '@/hooks'
import { ArrowUpSimpleLine, OuterLinkLine, ShareSquareLine } from '@/icons'
import { fmtEthAddr } from '@/lib/formatter'
import { itemDisplayName } from '@/lib/nft'
import { extractNftMetadataAssets } from '@/lib/nft/metadata'
import { ShoppingCartAsset } from '@/lib/openseaShoppingCart'
import { Item } from '@/lib/shoppingCart'
import { getTokenMeta } from '@/lib/token'
import { DEFAULT_NETWORK, scannerTxURL } from '@/utils/network'
import { openShare } from '@/utils/openShare'

import { TotalDisplay } from '../detail/shared'
import { Button } from '../form'
import { FormLabelLike } from '../form'
import { Img, metadataAssetsAsNftImageProps, NftImage } from '../media'
import Link from '../TwLink'
import { ResultItem } from './ResultItem'

export type ContractMeta = { twitter: string | null }

export type SuccessProps = {
  items: (Item & { success: boolean })[]
  osItems: (ShoppingCartAsset & { success: boolean })[]
  total: {
    amountToEth: BigNumber
    amountToWeth: BigNumber
    amountTotal: BigNumber
  }
  txHash: string
  contractMeta?: ContractMeta
}
type Props = SuccessProps & {
  disclosure: UseDisclosureReturn
}
export const SuccessModal = ({
  disclosure,
  items,
  osItems,
  total,
  txHash,
  contractMeta,
}: Props) => {
  const { t } = useTranslation()
  const count = items.length + osItems.length
  const boughtCount =
    items.filter((a) => a.success).length +
    osItems.filter((a) => a.success).length
  const contractName = items[0]?.contractName ?? ''

  return (
    <Modal {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Done!')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-6">
          <div className="flex items-center space-x-6">
            <Icon1 width={64} height={64} />
            <p className="ts-body-2 text-gray-500">
              {t('{{count}} NFTs have been sent to your wallet.', {
                count: boughtCount,
              })}
            </p>
          </div>
          <Disclosure as="div" className="flex flex-col" defaultOpen>
            <Disclosure.Button className="flex items-center">
              <FormLabelLike className="mr-auto">
                {t('Items')} ({count})
              </FormLabelLike>
              <ArrowUpSimpleLine className="ml-4 h-6 w-6 text-gray-500 ui-open:rotate-180 motion-safe:duration-200" />
            </Disclosure.Button>
            <Disclosure.Panel className="mt-6 flex flex-col space-y-3">
              {items.map((item) => (
                <ResultItem
                  image={
                    <NftImage
                      className="h-12 w-12 shrink-0"
                      {...metadataAssetsAsNftImageProps(
                        extractNftMetadataAssets(item.meta),
                      )}
                      imageSizes={['128px']}
                    />
                  }
                  contractName={item.contractName}
                  contractVerified={item.contractVerified}
                  name={itemDisplayName(
                    item.meta,
                    item.contractName,
                    item.tokenId,
                  )}
                  price={item.price}
                  symbol={getTokenMeta(DEFAULT_NETWORK, item.currency).symbol}
                  success={item.success}
                  key={item.id}
                />
              ))}
              {osItems.map((item, i) => (
                <ResultItem
                  isOS
                  image={
                    <Img className="h-12 w-12 shrink-0" src={item.image} />
                  }
                  contractName={item.contractName}
                  contractVerified={false}
                  name={item.name}
                  price={item.price}
                  symbol="ETH"
                  success={item.success}
                  key={i}
                />
              ))}
            </Disclosure.Panel>
          </Disclosure>
          <hr />
          <div className="flex flex-col space-y-3">
            <TotalDisplay isBuyModal isSuccess {...total} />
            <div className="mt-2 flex items-center space-x-2">
              <FormLabelLike className="mr-auto truncate">
                {t('TX Hash')}
              </FormLabelLike>
              <Link
                className="flex items-center space-x-1"
                hoverStyle="opacity"
                href={scannerTxURL(txHash, DEFAULT_NETWORK)}
                isExternal
              >
                <span className="ts-body-2 font-bold text-gray-700">
                  {fmtEthAddr(txHash)}
                </span>
                <OuterLinkLine className="h-4 w-4 text-gray-500" />
              </Link>
            </div>
          </div>
          <Button
            colorScheme="primary-1"
            leftIcon={<ShareSquareLine className="h-4 w-4" />}
            onClick={(e) => {
              e.preventDefault()
              openShare(
                'https://twitter.com/intent/tweet',
                {},
                {
                  text: contractMeta
                    ? t(
                        'Just bought an {{nameOrTwitter}} on https://x2y2.io 🎉 #nfts #NFTCommmunity #{{nameHashTag}} #X2Y2',
                        {
                          nameOrTwitter: contractMeta.twitter
                            ? `@${contractMeta.twitter}`
                            : contractName,
                          nameHashTag: contractName.replaceAll(' ', ''),
                        },
                      )
                    : t(
                        'Just bought {{count}} NFTs on #X2Y2 in one click. #bulkbuy on https://x2y2.io',
                        { count: boughtCount },
                      ),
                },
              )
            }}
          >
            {t('Share')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

// MARK: Assets

const Icon1 = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0"
  >
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#FFCC4D"
    />
    <path
      d="M14.2223 15.0738C13.8498 15.0738 13.5032 14.8382 13.3787 14.4658C13.1983 13.9333 12.4738 12.4071 11.5556 12.4071C10.6116 12.4071 9.87739 14.0311 9.7325 14.4658C9.57694 14.9306 9.0765 15.184 8.60805 15.0275C8.14228 14.8729 7.89072 14.3689 8.04628 13.9031C8.15739 13.568 9.20539 10.6284 11.5565 10.6284C13.9076 10.6284 14.9547 13.568 15.0667 13.9031C15.2223 14.3689 14.9707 14.8729 14.5049 15.0275C14.4107 15.0595 14.3156 15.0738 14.2223 15.0738ZM23.1112 15.0738C22.7387 15.0738 22.3921 14.8382 22.2685 14.4658C22.0881 13.9333 21.3627 12.4071 20.4445 12.4071C19.5005 12.4071 18.7654 14.0311 18.6205 14.4658C18.4649 14.9306 17.9663 15.184 17.4961 15.0275C17.0312 14.8729 16.7787 14.3689 16.9343 13.9031C17.0454 13.568 18.0934 10.6284 20.4436 10.6284C22.7938 10.6284 23.8418 13.568 23.9529 13.9031C24.1085 14.3689 23.8569 14.8729 23.3912 15.0275C23.2987 15.0595 23.2045 15.0738 23.1112 15.0738ZM24.2978 18.392C24.1396 18.2489 23.9032 18.24 23.7334 18.3662C23.6987 18.392 20.2472 20.944 16.0001 20.944C11.7636 20.944 8.3005 18.392 8.26672 18.3662C8.09694 18.24 7.8605 18.2506 7.70228 18.392C7.54494 18.5342 7.50939 18.7689 7.61872 18.9502C7.73339 19.1413 10.4783 23.6106 16.0001 23.6106C21.5218 23.6106 24.2676 19.1413 24.3814 18.9502C24.4907 18.768 24.4561 18.5351 24.2978 18.392Z"
      fill="#664500"
    />
    <path
      d="M18.8702 3.50843C18.8702 3.72177 18.8969 3.92799 18.9431 4.1271C19.3209 6.47643 21.9333 8.95466 23.8053 9.63554C25.2853 9.09777 27.224 7.43466 28.1591 5.60088C26.3022 3.43199 23.8755 1.76621 21.1111 0.834656C19.8382 1.0631 18.8702 2.16977 18.8702 3.50843ZM12.216 25.8471C12.2666 25.632 12.296 25.408 12.296 25.1769C12.296 23.5475 10.9751 22.2267 9.34576 22.2267C8.35642 22.2267 7.48442 22.7147 6.94842 23.4613C6.41331 22.7155 5.54042 22.2267 4.55109 22.2267C3.44798 22.2267 2.49687 22.8391 1.99109 23.736C3.61242 26.6649 6.12442 29.0311 9.16264 30.4684C10.6178 29.2675 11.9458 27.5289 12.216 25.8471ZM30.4898 22.7929C30.2391 21.9484 29.4658 21.3289 28.5395 21.3289C27.8542 21.3289 27.2498 21.6667 26.8791 22.184C26.5084 21.6667 25.904 21.3289 25.2186 21.3289C24.0898 21.3289 23.1751 22.2435 23.1751 23.3724C23.1751 23.5324 23.1955 23.6871 23.2302 23.8364C23.5031 25.5298 25.3173 27.2995 26.7049 27.8907C28.2835 26.4684 29.5769 24.7378 30.4898 22.7929Z"
      fill="#FFAC33"
    />
    <path
      d="M29.8889 2.90577C29.8889 1.40177 28.6694 0.183105 27.1663 0.183105C26.2534 0.183105 25.448 0.633772 24.9538 1.32266C24.4596 0.633772 23.6543 0.183105 22.7414 0.183105C21.2374 0.183105 20.0187 1.40177 20.0187 2.90577C20.0187 3.11911 20.0454 3.32533 20.0916 3.52444C20.4703 5.87377 23.0818 8.35199 24.9538 9.03288C26.8258 8.35199 29.4374 5.87377 29.8152 3.52533C29.8623 3.32533 29.8889 3.11911 29.8889 2.90577ZM10.8516 25.2516C10.8516 23.6222 9.53072 22.3013 7.90138 22.3013C6.91205 22.3013 6.04005 22.7893 5.50405 23.536C4.96894 22.7893 4.09605 22.3013 3.10672 22.3013C1.47738 22.3013 0.156494 23.6222 0.156494 25.2516C0.156494 25.4827 0.185827 25.7058 0.235605 25.9218C0.645383 28.4667 3.47472 31.152 5.50316 31.8898C7.5316 31.1529 10.3609 28.4675 10.7707 25.9227C10.8223 25.7058 10.8516 25.4818 10.8516 25.2516ZM31.9538 23.224C31.9538 22.0951 31.0392 21.1804 29.9103 21.1804C29.2249 21.1804 28.6205 21.5182 28.2498 22.0355C27.8792 21.5182 27.2747 21.1804 26.5894 21.1804C25.4605 21.1804 24.5458 22.0951 24.5458 23.224C24.5458 23.384 24.5663 23.5387 24.6009 23.688C24.8845 25.4507 26.8445 27.3111 28.2498 27.8213C29.6543 27.3111 31.6152 25.4507 31.8978 23.688C31.9334 23.5387 31.9538 23.384 31.9538 23.224Z"
      fill="#DD2E44"
    />
  </svg>
)
