import { pipe } from 'fp-ts/lib/function'
import { TFunction } from 'next-i18next'
import { ParsedUrlQuery } from 'querystring'

import { A, D, E, O } from '@/lib/fp'
import { kvToUrlQuery } from '@/lib/search/utils'

export const typeDecoder = D.union(
  D.literal('offered'),
  D.literal('received'),
  D.literal('private-sale'),
)
export type Type = D.TypeOf<typeof typeDecoder>
export const ALL_TYPES: Type[] = ['offered', 'received', 'private-sale']
export const DEFAULT_TYPE: Type = 'offered'
export const typeTitle = (t: TFunction, type: Type): string => {
  switch (type) {
    case 'offered':
      return t('Offered')
    case 'received':
      return t('Received')
    case 'private-sale':
      return t('Private Sale')
  }
}

export const sortDecoder = D.union(
  D.literal('created_at_desc'),
  D.literal('fok_at_desc'),
)
export type Sort = D.TypeOf<typeof sortDecoder>
export const DEFAULT_SORT: Sort = 'created_at_desc'

export type Query = {
  type: Type
  sort: Sort
}

export const urlQueryCodec = {
  decode: (q: ParsedUrlQuery): Query => {
    return {
      type: pipe(
        typeDecoder.decode(q['type']),
        E.getOrElse((): Type => DEFAULT_TYPE),
      ),
      sort: pipe(
        sortDecoder.decode(q['sort']),
        E.getOrElse((): Sort => DEFAULT_SORT),
      ),
    }
  },
  encode: ({ page, query }: { page?: number; query?: Query }) => {
    return pipe(
      [
        pipe(
          O.fromNullable(page),
          O.filter((a) => a > 1),
          O.map((v) => ({ k: 'page', v: v.toString() })),
        ),
        pipe(
          O.fromNullable(query?.type),
          O.filter((a) => a !== DEFAULT_TYPE),
          O.map((v) => ({ k: 'type', v })),
        ),
        pipe(
          O.fromNullable(query?.sort),
          O.filter((a) => a !== DEFAULT_SORT),
          O.map((v) => ({ k: 'sort', v })),
        ),
      ],
      A.compact,
      kvToUrlQuery,
    )
  },
}
