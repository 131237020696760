import { useTranslation } from 'next-i18next'
import { useObservable } from 'rxjs-hooks'
import * as RxO from 'rxjs/operators'

import { Button } from '@/components/form'

import { useAuth } from './authProvider'
import { NonAnonymousUser, RegisteredUser } from './types/user'
import { userKey } from './utils'

type Props<T> = {
  children: (user: T) => JSX.Element
  connectWalletBtnText?: string
}
export const WithRegisteredUser = ({
  children,
  connectWalletBtnText,
}: Props<RegisteredUser>) => {
  const { user, tryWeb3SignIn, tryServerSignIn } = useAuth()
  useObservable(
    (_, $inputs) =>
      $inputs.pipe(
        RxO.filter(([u]) => u._tag !== 'pending'),
        RxO.distinctUntilChanged(([u1], [u2]) => userKey(u1) === userKey(u2)),
        RxO.map(([u, tw, ts]) => {
          switch (u._tag) {
            case 'anonymous':
              tw()
              break
            case 'web3-registered':
              ts()
              break
          }
          return null
        }),
      ),
    null,
    [user, tryWeb3SignIn, tryServerSignIn],
  )
  if (user._tag === 'pending') return null
  if (user._tag === 'registered') return children(user)
  return (
    <ConnectWalletBtn
      btnText={connectWalletBtnText}
      onClick={() => {
        switch (user._tag) {
          case 'anonymous':
            tryWeb3SignIn()
            break
          case 'web3-registered':
            tryServerSignIn()
            break
        }
      }}
    />
  )
}

export const WithNonAnonymousUser = ({ children }: Props<NonAnonymousUser>) => {
  const { user, tryWeb3SignIn } = useAuth()
  useObservable(
    (_, $inputs) =>
      $inputs.pipe(
        RxO.filter(([u]) => u._tag !== 'pending'),
        RxO.distinctUntilChanged(([u1], [u2]) => userKey(u1) === userKey(u2)),
        RxO.map(([u, tw]) => {
          switch (u._tag) {
            case 'anonymous':
              tw()
              break
            default:
              break
          }
          return null
        }),
      ),
    null,
    [user, tryWeb3SignIn],
  )
  if (user._tag === 'pending') return null
  if (user._tag === 'web3-registered') return children(user)
  if (user._tag === 'registered') return children(user)
  return <ConnectWalletBtn onClick={() => tryWeb3SignIn()} />
}

const ConnectWalletBtn = ({
  btnText,
  onClick,
}: {
  btnText?: string
  onClick: () => void
}) => {
  const { t } = useTranslation()
  return (
    <div
      className="grid h-full shrink-0 place-items-center"
      style={{ flex: 99 }}
    >
      <Button colorScheme="primary-1" size="md" onClick={onClick}>
        {btnText ?? t('Connect a wallet')}
      </Button>
    </div>
  )
}
