import clsx from 'clsx'
import { CSSProperties } from 'react'

import { Breakpoint, breakpoints } from '@/hooks'
import {
  CdnFile,
  CdnSize,
  cdnSizes,
  isCdnFileGif,
  isCdnPath,
  resizedCdnUrl,
} from '@/lib/cdn'

import { Img } from './Img'

const srcSet = (type: string, src: string) =>
  isCdnPath(src)
    ? ((type === 'gif' ? cdnSizes.slice(0, 3) : cdnSizes) as CdnSize[]) // only 200,400,600 for gif
        .map((s) => `${resizedCdnUrl(src, s, type)} ${s}w`)
        .join(', ')
    : src

const buildSrcSet = (src: CdnFile) => {
  const type = isCdnFileGif(src) ? 'gif' : 'png'
  const orig = srcSet(type, src.path)

  return {
    orig,
    src: resizedCdnUrl(src.path, 280, type),
    width: src.width,
    height: src.height,
  }
}

const arrayToObjectNotation = <T,>(values: T[], bps: Breakpoint[]) => {
  const result: Partial<Record<Breakpoint, T>> = {}
  values.forEach((value, index) => {
    const key = bps[index]
    if (value == null) return
    result[key] = value
  })
  return result
}

type Sizes = string[]
export const useSizes = (sizes: Sizes | undefined) => {
  if (!sizes) return
  // Get the non-number breakpoint keys from the provided breakpoints
  // Reference: https://github.com/chakra-ui/chakra-ui/blob/54cbed02ab66da4a74de76bba081322859587dc4/packages/media-query/src/use-breakpoint-value.ts#L29
  const bps: Breakpoint[] = ['sm', 'md', 'lg', 'xl', '2xl']
  const values = arrayToObjectNotation(sizes, bps)
  return (Object.keys(values) as Breakpoint[])
    .map((bp, i) =>
      i === 0 ? values[bp] : `(min-width: ${breakpoints[bp]}) ${values[bp]}`,
    )
    .reverse()
    .join(', ')
}

export type CdnImageProps = {
  className?: string
  cdnSrc: CdnFile
  alt?: string
  sizes?: Sizes
  objectFit?: CSSProperties['objectFit']
}
export const CdnImage = ({
  className,
  cdnSrc,
  alt,
  sizes: sizes0,
  objectFit = 'cover',
}: CdnImageProps): JSX.Element => {
  const sizes = useSizes(sizes0)
  const srcSet = buildSrcSet(cdnSrc)
  return (
    <picture>
      <source srcSet={srcSet.orig} sizes={sizes} />
      <Img
        className={clsx(className, 'h-full w-full')}
        style={{ objectFit }}
        src={srcSet.src}
        loading="lazy"
        alt={alt}
        aria-label={alt}
      />
    </picture>
  )
}
