import { RefObject } from 'react'

import { useSizes } from './CdnImage'
import { Img, ImgProps } from './Img'

type Sizes = string[]

const cdnSizes = [280, 720, 1440, 2400] as const
type CdnSize = (typeof cdnSizes)[number]

export const buildUrl = (src: string, size: CdnSize) =>
  `https://imgproxy.x2y2.io/image?url=${encodeURIComponent(src)}&w=${size}`

const buildSrcSet = (src: string) =>
  cdnSizes
    .map(
      (s) => `${buildUrl(src, s)} ${s >= 1440 ? s * 1.5 : s}w`, // smaller on large size
    )
    .join(', ')

type Props = {
  className?: string
  src: string
  sizes?: Sizes
  imgRef?: RefObject<HTMLImageElement>
  imgProps?: Omit<ImgProps, 'ref'>
}

const ProxiedImage = ({
  className,
  src,
  sizes: sizes0,
  imgRef,
  imgProps,
}: Props) => {
  const sizes = useSizes(sizes0)
  // SVG
  // if (src.startsWith('data:')) {
  //   return <Img className={className} ref={imgRef} src={src} {...imgProps} />
  // }
  const srcSet = buildSrcSet(src)
  return (
    <picture>
      <source srcSet={srcSet} sizes={sizes} />
      <Img
        className={className}
        ref={imgRef}
        src={buildUrl(src, 280)}
        loading="lazy"
        {...imgProps}
      />
    </picture>
  )
}

export default ProxiedImage
