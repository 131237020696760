import {
  createContext,
  createElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import * as api from '@/lib/api'
import { getUserAddress, useAuth } from '@/lib/auth'
import { E, O } from '@/lib/fp'

type NotificationCtx = {
  activity: boolean
  offer: boolean
  clear: (k: 'activities' | 'offers') => void
}

type NotificationData = {
  activity: number
  offer: number
}

const DEFAULT_VALUE = {
  activity: false,
  offer: false,
}

const DEFAULT_LOCAL_DATA = {
  activity: 0,
  offer: 0,
}

const NOTIFICATION_STORAGE_KEY_PREFIX = 'user_notification_badge_latest_id_v2_'

const NotificationContext = createContext<NotificationCtx>({
  ...DEFAULT_VALUE,
  clear: () => undefined,
})
type Props = {
  children: ReactNode
}
export const NotificationContextProvider = ({
  children,
}: Props): JSX.Element => {
  const [remoteData, setRemoteData] = useState<NotificationData | null>(null)
  const [localData, setLocalData] =
    useState<NotificationData>(DEFAULT_LOCAL_DATA)

  const { user } = useAuth()

  useEffect(() => {
    const address = getUserAddress(user)
    if (O.isNone(address)) {
      setRemoteData(null)
      return
    }
    try {
      const data = localStorage.getItem(
        `${NOTIFICATION_STORAGE_KEY_PREFIX}${address.value}`,
      )
      if (data) {
        setLocalData(JSON.parse(data))
      }
    } catch {}
  }, [user])

  const task = useMemo(() => {
    const userAddr = O.toNullable(getUserAddress(user))
    if (!userAddr) return null
    return api.latestByUser({ userAddr })
  }, [user])

  useEffect(() => {
    if (!task) return
    const fetch = async () => {
      const _resp = await task()
      if (E.isRight(_resp)) {
        setRemoteData(_resp.right)
      }
    }
    fetch()
  }, [task])

  const setLocalStorage = useCallback(
    (v: NotificationData) => {
      const address = getUserAddress(user)
      if (O.isNone(address)) return
      try {
        localStorage.setItem(
          `${NOTIFICATION_STORAGE_KEY_PREFIX}${address.value}`,
          JSON.stringify(v),
        )
      } catch {}
    },
    [user],
  )

  const clear = useCallback(
    (k: 'activities' | 'offers') => {
      if (!remoteData) {
        return
      }
      if (k === 'activities') {
        setLocalData((prev) => {
          const res = {
            ...prev,
            activity: remoteData.activity,
          }
          setLocalStorage(res)
          return res
        })
      }
      if (k === 'offers') {
        setLocalData((prev) => {
          const res = {
            ...prev,
            offer: remoteData.offer,
          }
          setLocalStorage(res)
          return res
        })
      }
      return
    },
    [remoteData, setLocalStorage],
  )

  const value = useMemo(() => {
    if (remoteData === null) {
      return { ...DEFAULT_VALUE, clear }
    }
    return {
      activity: localData.activity < remoteData.activity,
      offer: localData.offer < remoteData.offer,
      clear,
    }
  }, [remoteData, localData, clear])

  return createElement(NotificationContext.Provider, { value }, children)
}

export const useNotificationContext = () => useContext(NotificationContext)
