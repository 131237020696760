import { BigNumber, BigNumberish, constants, providers, utils } from 'ethers'

import {
  NETWORK_ARBITRUM,
  NETWORK_ARBITRUM_TESTNET,
  NETWORK_AVAX,
  NETWORK_AVAX_TESTNET,
  NETWORK_BSC,
  NETWORK_BSC_TESTNET,
  NETWORK_ETH,
  NETWORK_FANTOM,
  NETWORK_FANTOM_TESTNET,
  NETWORK_OPTIMISM,
  NETWORK_OPTIMISM_TESTNET,
  NETWORK_POLYGON,
  NETWORK_POLYGON_TESTNET,
  NETWORK_RINKEBY,
} from '@/consts'
import {
  Endpoint__factory,
  LayerZeroable1,
  LayerZeroable1__factory,
  LayerZeroable2,
  LayerZeroable2__factory,
  LayerZeroReceiver,
  LayerZeroReceiver__factory,
  LzApp,
  LzApp__factory,
  NonblockingReceiver,
  NonblockingReceiver1,
  NonblockingReceiver1__factory,
  NonblockingReceiver2,
  NonblockingReceiver2__factory,
  NonblockingReceiver3,
  NonblockingReceiver3__factory,
  NonblockingReceiver4,
  NonblockingReceiver4__factory,
  NonblockingReceiver5,
  NonblockingReceiver5__factory,
  NonblockingReceiver__factory,
  ONFT721Core,
  ONFT721Core__factory,
} from '@/lib/contract'
import { ON_PROD } from '@/lib/env'

import {
  TRAVERSE_CONTRACT,
  TRAVERSE_CONTRACTS,
  TRAVERSE_TYPE,
} from './constants'

type TraverseConfig = {
  networkId: number
  chainId: number
  endpoint: string
}

type ReciverWithType =
  | {
      type: 'default'
      reciver: NonblockingReceiver
    }
  | {
      type: 'receiver1'
      reciver: NonblockingReceiver1
    }
  | {
      type: 'receiver2'
      reciver: NonblockingReceiver2
    }
  | {
      type: 'receiver3'
      reciver: NonblockingReceiver3
    }
  | {
      type: 'receiver4'
      reciver: NonblockingReceiver4
    }
  | {
      type: 'receiver5'
      reciver: NonblockingReceiver5
    }
  | {
      type: 'receiver2022'
      reciver: NonblockingReceiver
    }
  | {
      type: 'LayerZeroable1'
      reciver: LayerZeroable1
    }
  | {
      type: 'LayerZeroable2'
      reciver: LayerZeroable2
    }
  | {
      type: 'LayerZeroReceiver'
      reciver: LayerZeroReceiver
    }
  | {
      type: 'LzApp'
      reciver: LzApp
    }
  | {
      type: 'onft721Core'
      reciver: ONFT721Core
    }

export const TRAVERSE_CONFIGS: TraverseConfig[] = ON_PROD
  ? [
      {
        networkId: NETWORK_ETH,
        chainId: 1,
        endpoint: '0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675',
      },
      {
        networkId: NETWORK_BSC,
        chainId: 2,
        endpoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
      },
      {
        networkId: NETWORK_AVAX,
        chainId: 6,
        endpoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
      },
      {
        networkId: NETWORK_POLYGON,
        chainId: 9,
        endpoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
      },
      {
        networkId: NETWORK_ARBITRUM,
        chainId: 10,
        endpoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
      },
      {
        networkId: NETWORK_OPTIMISM,
        chainId: 11,
        endpoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
      },
      {
        networkId: NETWORK_FANTOM,
        chainId: 12,
        endpoint: '0xb6319cC6c8c27A8F5dAF0dD3DF91EA35C4720dd7',
      },
    ]
  : [
      {
        networkId: NETWORK_RINKEBY,
        chainId: 10001,
        endpoint: '0x79a63d6d8BBD5c6dfc774dA79bCcD948EAcb53FA',
      },
      {
        networkId: NETWORK_BSC_TESTNET,
        chainId: 10002,
        endpoint: '0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1',
      },
      {
        networkId: NETWORK_AVAX_TESTNET,
        chainId: 10006,
        endpoint: '0x93f54D755A063cE7bB9e6Ac47Eccc8e33411d706',
      },
      {
        networkId: NETWORK_POLYGON_TESTNET,
        chainId: 10009,
        endpoint: '0xf69186dfBa60DdB133E91E9A4B5673624293d8F8',
      },
      {
        networkId: NETWORK_ARBITRUM_TESTNET,
        chainId: 10010,
        endpoint: '0x4D747149A57923Beb89f22E6B7B97f7D8c087A00',
      },
      {
        networkId: NETWORK_OPTIMISM_TESTNET,
        chainId: 10011,
        endpoint: '0x72aB53a133b27Fa428ca7Dc263080807AfEc91b5',
      },
      {
        networkId: NETWORK_FANTOM_TESTNET,
        chainId: 10012,
        endpoint: '0x7dcAD72640F835B0FA36EFD3D6d3ec902C7E5acf',
      },
    ]

export class TraverseError extends Error {
  public constructor(errorMsg: string) {
    super()
    this.name = this.constructor.name
    this.message = errorMsg
  }
}

const ERROR_MESSAGE = 'Not supported'

const fetchTraverseType = (
  networkId: number,
  contract: string,
): TRAVERSE_TYPE | undefined => {
  let result: TRAVERSE_TYPE | undefined = undefined
  Object.keys(TRAVERSE_CONTRACTS).forEach((key) => {
    if (!result) {
      const contracts = TRAVERSE_CONTRACTS[key]
      if (contracts) {
        contracts.forEach((data: TRAVERSE_CONTRACT[]) => {
          if (!result) {
            const traverseContract = data.find(
              (d: { networkId: number; contract: string }) =>
                d.networkId === networkId &&
                d.contract.toLowerCase() === contract.toLowerCase(),
            )
            if (traverseContract) {
              result = key as TRAVERSE_TYPE
            }
          }
        })
      }
    }
  })
  return result
}

const calcReciverWithType = (
  tt: TRAVERSE_TYPE,
  user: providers.JsonRpcSigner,
  contract: string,
): ReciverWithType | undefined => {
  if (tt === 'default') {
    return {
      type: tt,
      reciver: NonblockingReceiver__factory.connect(contract, user),
    }
  } else if (tt === 'receiver1') {
    return {
      type: tt,
      reciver: NonblockingReceiver1__factory.connect(contract, user),
    }
  } else if (tt === 'receiver2') {
    return {
      type: tt,
      reciver: NonblockingReceiver2__factory.connect(contract, user),
    }
  } else if (tt === 'receiver3') {
    return {
      type: tt,
      reciver: NonblockingReceiver3__factory.connect(contract, user),
    }
  } else if (tt === 'receiver4') {
    return {
      type: tt,
      reciver: NonblockingReceiver4__factory.connect(contract, user),
    }
  } else if (tt === 'receiver5') {
    return {
      type: tt,
      reciver: NonblockingReceiver5__factory.connect(contract, user),
    }
  } else if (tt === 'receiver2022') {
    return {
      type: tt,
      reciver: NonblockingReceiver__factory.connect(contract, user),
    }
  } else if (tt === 'LayerZeroable1') {
    return {
      type: tt,
      reciver: LayerZeroable1__factory.connect(contract, user),
    }
  } else if (tt === 'LayerZeroable2') {
    return {
      type: tt,
      reciver: LayerZeroable2__factory.connect(contract, user),
    }
  } else if (tt === 'LayerZeroReceiver') {
    return {
      type: tt,
      reciver: LayerZeroReceiver__factory.connect(contract, user),
    }
  } else if (tt === 'LzApp') {
    return {
      type: tt,
      reciver: LzApp__factory.connect(contract, user),
    }
  } else if (tt === 'onft721Core') {
    return {
      type: tt,
      reciver: ONFT721Core__factory.connect(contract, user),
    }
  }
  return undefined
}

const lookupRemoteContract = async (
  rwt: ReciverWithType,
  chainId: number,
): Promise<string> => {
  if (rwt.type === 'default') {
    return await rwt.reciver.trustedRemoteLookup(chainId)
  } else if (rwt.type === 'receiver1') {
    return await rwt.reciver.trustedSourceLookup(chainId)
  } else if (rwt.type === 'receiver2') {
    return await rwt.reciver.trustedSourceLookup(chainId)
  } else if (rwt.type === 'receiver3') {
    return await rwt.reciver.trustedSourceLookup(chainId)
  } else if (rwt.type === 'receiver4') {
    return await rwt.reciver.trustedSourceLookup(chainId)
  } else if (rwt.type === 'receiver5') {
    return await rwt.reciver.trustedRemoteLookup(chainId)
  } else if (rwt.type === 'receiver2022') {
    return await rwt.reciver.trustedRemoteLookup(chainId)
  } else if (rwt.type === 'LayerZeroable1') {
    return await rwt.reciver.remotes(chainId)
  } else if (rwt.type === 'LayerZeroable2') {
    return await rwt.reciver.remotes(chainId)
  } else if (rwt.type === 'LayerZeroReceiver') {
    return await rwt.reciver.uaMap(chainId)
  } else if (rwt.type === 'LzApp') {
    return await rwt.reciver.getTrustedRemote(chainId)
  } else if (rwt.type === 'onft721Core') {
    return await rwt.reciver.trustedRemoteLookup(chainId)
  }
  return '0x'
}

const estimateFees = async (
  rwt: ReciverWithType,
  chainId: number,
  user: providers.JsonRpcSigner,
  tc: TraverseConfig,
  rc: string,
  contract: string,
  tokenId: BigNumberish,
): Promise<BigNumber> => {
  const version = 1
  const address = await user.getAddress()
  let userApplication: string = contract
  const payload = utils.defaultAbiCoder.encode(
    ['address', 'uint256'],
    [address, tokenId],
  )
  let adapterParams = ''
  if (rwt.type === 'default') {
    adapterParams = utils.solidityPack(['uint16', 'uint'], [version, 350000])
  } else if (rwt.type === 'receiver1') {
    adapterParams = utils.solidityPack(['uint16', 'uint'], [version, 350000])
  } else if (rwt.type === 'receiver2') {
    const gasForDestination = await rwt.reciver.gasForDestinationLzReceive()
    adapterParams = utils.solidityPack(
      ['uint16', 'uint256'],
      [version, gasForDestination],
    )
  } else if (rwt.type === 'receiver3') {
    adapterParams = utils.solidityPack(['uint16', 'uint'], [version, 225000])
  } else if (rwt.type === 'receiver4') {
    adapterParams = utils.solidityPack(['uint16', 'uint'], [version, 450000])
  } else if (rwt.type === 'receiver5') {
    const currentLZGas = await rwt.reciver.currentLZGas()
    adapterParams = utils.solidityPack(
      ['uint16', 'uint'],
      [version, currentLZGas],
    )
  } else if (rwt.type === 'receiver2022') {
    // setGasForDestinationLzReceive(350000)
    adapterParams = utils.solidityPack(['uint16', 'uint'], [version, 350000])
  } else if (rwt.type === 'LayerZeroable1') {
    userApplication = rc
    const gasForDestination = await rwt.reciver.destGasAmount()
    adapterParams = utils.solidityPack(
      ['uint16', 'uint256'],
      [version, gasForDestination],
    )
  } else if (rwt.type === 'LayerZeroable2') {
    const [nativeFee, _] = await rwt.reciver.estimateFee(
      tokenId,
      address,
      chainId,
    )
    return nativeFee
  } else if (rwt.type === 'LayerZeroReceiver') {
    adapterParams = utils.solidityPack(['uint16', 'uint256'], [version, 350000])
  } else if (rwt.type === 'LzApp') {
    adapterParams = utils.solidityPack(['uint16', 'uint256'], [version, 350000])
  } else if (rwt.type === 'onft721Core') {
    adapterParams = utils.solidityPack(['uint16', 'uint256'], [version, 350000])
    const [nativeFee, _] = await rwt.reciver.estimateSendFee(
      chainId,
      address,
      tokenId,
      false,
      adapterParams,
    )
    return nativeFee
  }
  if (adapterParams) {
    const endpoint = Endpoint__factory.connect(tc.endpoint, user)
    const [nativeFee, _] = await endpoint.estimateFees(
      chainId,
      userApplication,
      payload,
      false,
      adapterParams,
    )
    return nativeFee
  }
  return constants.Zero
}

const transferToChain = async (
  rwt: ReciverWithType,
  fee: BigNumber,
  chainId: number,
  user: providers.JsonRpcSigner,
  tokenId: BigNumberish,
): Promise<string> => {
  if (fee.gt(0)) {
    if (rwt.type === 'default') {
      const tx = await rwt.reciver.traverseChains(chainId, tokenId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'receiver1') {
      const tx = await rwt.reciver.traverseChains(chainId, tokenId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'receiver2') {
      const tx = await rwt.reciver.traverseChains(chainId, tokenId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'receiver3') {
      const tx = await rwt.reciver.transferOmnichainNFT(chainId, tokenId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'receiver4') {
      const tx = await rwt.reciver.traverseChains(chainId, tokenId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'receiver5') {
      const tx = await rwt.reciver.traverseChains(chainId, tokenId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'receiver2022') {
      const tx = await rwt.reciver.traverseChains(chainId, tokenId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'LayerZeroable1') {
      const address = await user.getAddress()
      const tx = await rwt.reciver.transferToChain(tokenId, address, chainId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'LayerZeroable2') {
      const address = await user.getAddress()
      const tx = await rwt.reciver.transferToChain(tokenId, address, chainId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'LayerZeroReceiver') {
      const tx = await rwt.reciver.crossChain(chainId, tokenId, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    } else if (rwt.type === 'LzApp' || rwt.type === 'onft721Core') {
      const address = await user.getAddress()
      const ap = utils.solidityPack(['uint16', 'uint256'], [1, 350000])
      const za = constants.AddressZero
      const sendParams = [
        address,
        chainId,
        address,
        tokenId,
        address,
        za,
        ap,
      ] as const
      // onft721Core & LzApp(Gregs) not support send()
      const tx = await rwt.reciver.sendFrom(...sendParams, {
        value: fee,
      })
      await tx.wait()
      return tx.hash
    }
  }
  return ''
}

export const isTargetContract = (
  networkId: number,
  contract: string,
): boolean => {
  const type = fetchTraverseType(networkId, contract)
  return type !== undefined
}

export const traverseChains = async (
  networkId: number,
  user: providers.JsonRpcSigner,
  chainId: number,
  contract: string,
  tokenId: BigNumberish,
): Promise<string> => {
  const tc = TRAVERSE_CONFIGS.find((tc) => tc.networkId === networkId)
  const tt = fetchTraverseType(networkId, contract)
  const rwt = tt ? calcReciverWithType(tt, user, contract) : undefined
  const rc = rwt ? await lookupRemoteContract(rwt, chainId) : '0x'
  if (!utils.isAddress(rc) || !tc || !tt || !rwt) {
    throw new TraverseError(ERROR_MESSAGE)
  }
  const fee = await estimateFees(rwt, chainId, user, tc, rc, contract, tokenId)
  const txHash = await transferToChain(rwt, fee, chainId, user, tokenId)
  if (!txHash) {
    throw new TraverseError(ERROR_MESSAGE)
  }
  return txHash
}
