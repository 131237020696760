import { Web3Provider } from '@ethersproject/providers'
import { BigNumber, constants, PayableOverrides, utils } from 'ethers'

import { Presale__factory } from '@/lib/contract'
import { http } from '@/lib/http'
import { PRESALE_CONTRACT_MAPS } from '@/lib/x2y2'
import { requestConfig } from '@/utils/http'
import { getProviderByNetworkId } from '@/utils/network'

import { NonAnonymousUser, RegisteredUser } from '../auth/types/user'
import { MarketError } from '../market'
import * as Err from '../market/errors'
import { invalidAddress, x2y2RequestConfig } from './utils'

export const STAGE_NOT_READY = 0
export const STAGE_WHITELIST_ONLY = 1
export const STAGE_PUBLIC_SALE = 2
export const STAGE_SALE_OVER = 3

export type Signature = {
  r: string
  s: string
  v: number
}

export type ILOUserInfo = {
  tokensClaimed: BigNumber // WITHDREW
  rewardDebt: BigNumber // HARVESTED
  hasShare: boolean
}

export const whitelisted = async (): Promise<string[]> => {
  try {
    const resp = await http.get('/api/ilo/whitelist', requestConfig())
    if (resp.status === 200 && resp.data.success) {
      return resp.data.data.whitelist
    }
  } catch (e) {
    console.error('api error: ', e)
  }
  return []
}

export const saleStage = async (networkId: number): Promise<number> => {
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    return STAGE_NOT_READY
  }
  try {
    const resp = await http.get('/api/ilo/stage', x2y2RequestConfig(null))
    if (resp.status === 200 && resp.data.success) {
      return resp.data.data.stage
    }
  } catch (ignored) {}
  return STAGE_NOT_READY
}

export const totalShareSold = async (networkId: number): Promise<number> => {
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    return 0
  }
  const provider = getProviderByNetworkId(networkId)
  const presale = Presale__factory.connect(presaleContract, provider)
  return (await presale.totalShareSold()).toNumber()
}

export const totalStaked = async (networkId: number): Promise<BigNumber> => {
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    return constants.Zero
  }
  const provider = getProviderByNetworkId(networkId)
  const presale = Presale__factory.connect(presaleContract, provider)
  return await presale.getTotalStaked()
}

export const maxShares = async (networkId: number): Promise<number> => {
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    return 1000
  }
  const provider = getProviderByNetworkId(networkId)
  const presale = Presale__factory.connect(presaleContract, provider)
  return (await presale.MAX_SHARES()).toNumber()
}

export const pricePerShare = async (networkId: number): Promise<string> => {
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    return '1.5'
  }
  const provider = getProviderByNetworkId(networkId)
  const presale = Presale__factory.connect(presaleContract, provider)
  const pricePerShare = await presale.PRICE_PER_SHARE()
  return utils.formatEther(pricePerShare)
}

export const hasShare = async (provider: Web3Provider): Promise<boolean> => {
  const networkId = provider.network.chainId
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    return false
  }
  const jsonRpcProvider = getProviderByNetworkId(networkId)
  const presale = Presale__factory.connect(presaleContract, jsonRpcProvider)
  const address = await provider.getSigner().getAddress()
  const userInfo = await presale.userInfo(address)
  return userInfo.hasShare
}

const iloSign = async (
  address: string,
  token: string,
): Promise<Signature | undefined> => {
  try {
    const resp = await http.post(
      '/api/ilo/sign',
      { address },
      x2y2RequestConfig(token),
    )
    if (resp.status === 200 && resp.data.success) {
      return resp.data.data as Signature
    }
  } catch (ignored) {}
  return undefined
}

export const join = async (user: RegisteredUser): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const signer = provider.getSigner()
  const address = await signer.getAddress()
  const presale = Presale__factory.connect(presaleContract, signer)
  const pricePerShare = await presale.PRICE_PER_SHARE()
  const sig = await iloSign(address, user.token)
  if (sig) {
    const tx = await presale.deposit(
      sig.v,
      utils.hexZeroPad(sig.r, 32),
      utils.hexZeroPad(sig.s, 32),
      {
        value: pricePerShare,
      },
    )
    await tx.wait()
    return tx.hash
  } else {
    throw new MarketError(Err.ERR_0014)
  }
}

// TOTAL(UNLOCKABLE + LOCKED)
export const tokensPerShare = async (networkId: number): Promise<BigNumber> => {
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (!invalidAddress(presaleContract)) {
    try {
      const provider = getProviderByNetworkId(networkId)
      const presale = Presale__factory.connect(presaleContract, provider)
      return await presale.TOKENS_PER_SHARE()
    } catch (ignored) {}
  }
  return utils.parseEther('15000')
}

// UNLOCKABLE - WITHDREW
export const pendingTokens = async (
  user: NonAnonymousUser,
): Promise<BigNumber> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (!invalidAddress(presaleContract)) {
    try {
      const jsonRpcProvider = getProviderByNetworkId(networkId)
      const presale = Presale__factory.connect(presaleContract, jsonRpcProvider)
      const address = await provider.getSigner().getAddress()
      return await presale.pendingTokens(address)
    } catch (ignored) {}
  }
  return constants.Zero
}

// Harvestable
export const pendingReward = async (
  user: NonAnonymousUser,
): Promise<BigNumber> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (!invalidAddress(presaleContract)) {
    try {
      const jsonRpcProvider = getProviderByNetworkId(networkId)
      const presale = Presale__factory.connect(presaleContract, jsonRpcProvider)
      const address = await provider.getSigner().getAddress()
      return await presale.pendingReward(address)
    } catch (ignored) {}
  }
  return constants.Zero
}

export const harvest = async (user: RegisteredUser): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const presale = Presale__factory.connect(
    presaleContract,
    provider.getSigner(),
  )
  const options: PayableOverrides = {}
  try {
    const gasLimit = await presale.estimateGas.harvest()
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await presale.harvest(options)
  await tx.wait()
  return tx.hash
}

export const withdraw = async (user: RegisteredUser): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const presale = Presale__factory.connect(
    presaleContract,
    provider.getSigner(),
  )
  const options: PayableOverrides = {}
  try {
    const gasLimit = await presale.estimateGas.withdraw()
    options.gasLimit = gasLimit.mul(3).div(2)
  } catch (ignored) {
    // console.log(ignored)
  }
  const tx = await presale.withdraw(options)
  await tx.wait()
  return tx.hash
}

export const userInfo = async (
  user: NonAnonymousUser,
): Promise<ILOUserInfo> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const presaleContract = PRESALE_CONTRACT_MAPS[networkId]
  if (invalidAddress(presaleContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const jsonRpcProvider = getProviderByNetworkId(networkId)
  const presale = Presale__factory.connect(presaleContract, jsonRpcProvider)
  const address = await provider.getSigner().getAddress()
  return await presale.userInfo(address)
}
