import { Svg, SvgProps } from '@/components/media'
import { Tooltip } from '@/components/overlay'
import { ETH, QuestionCircleLine, USDC, WETH, X2Y2 } from '@/icons'

const icons: Record<string, Svg> = {
  ETH,
  WETH,
  X2Y2,
  USDC,
}

type Props = SvgProps & {
  symbol: string
  disableTooltip?: boolean
}
export const TokenIcon = ({
  symbol,
  disableTooltip = false,
  ...rest
}: Props) => {
  const Icon = icons[symbol] ?? QuestionCircleLine
  return (
    <Tooltip
      label={disableTooltip ? undefined : symbol}
      placement="top"
      hasArrow
    >
      <Icon {...rest} />
    </Tooltip>
  )
}
