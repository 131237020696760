import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import {
  closeFeaturePromptCard,
  FeaturePromptCard,
} from '@/components/FeaturePromptCard'
import { NavBarLink } from '@/components/navbar/NavBarLinks'
import { ReactComponent as LoanFeaturePromptBanner } from '@/images/LoanFeaturePromptBanner.svg'
import {
  loanURL,
  rankingURL,
  rewardsURL,
  settingsURL,
  userURL,
  userURLMatcher,
} from '@/lib/url'

export const useSiteLinks = (address?: string) => {
  const { t } = useTranslation()

  const nftLoanPromptCardRenderFunc = useCallback(
    (children: ReactElement) => (
      <FeaturePromptCard
        key="nftLoan"
        feature="nftLoan"
        placement="bottom"
        arrowColor="#7082D8"
        offset={0}
        image={<LoanFeaturePromptBanner />}
        title={t('Meet NFT Loan, our new feature callout!')}
        desc={t(
          'NFT Loan is a new way to unlock value from your NFTs. Have a lot of blue-chip NFTs but want some liquidity? You can use them as collateral to borrow more ETH via NFT Loan now.',
        )}
        learnMoreHref="https://docs.x2y2.io/fi/borrow-eth-with-nfts"
      >
        {children}
      </FeaturePromptCard>
    ),
    [t],
  )

  const market: NavBarLink[] = useMemo(
    () => [
      {
        title: t('Collections'),
        href: rankingURL('top'),
        match: /^\/collections/,
        gaLabel: 'GA-nav-ranking',
      },
      {
        title: t('Rewards'),
        href: rewardsURL({}),
        match: /^\/rewards$/,
        gaLabel: 'GA-nav-rewards',
      },
      {
        title: t('Loan'),
        href: 'https://fi.x2y2.io',
        gaLabel: 'GA-nav-loan',
        onClick: () => closeFeaturePromptCard('nftLoan'),
        featurePromptCardRenderFunc: nftLoanPromptCardRenderFunc,
      },
    ],
    [nftLoanPromptCardRenderFunc, t],
  )

  const settings: NavBarLink[] = useMemo(
    () => [
      {
        title: t('Profile'),
        href: settingsURL('profile'),
        match: /^\/settings\/profile$/,
      },
      {
        title: t('Notifications'),
        href: settingsURL('notification'),
        match: /^\/settings\/notification$/,
      },
    ],
    [t],
  )

  const user: NavBarLink[] = useMemo(
    () =>
      address
        ? [
            {
              title: t('My Items'),
              href: userURL(address, { tab: 'items' }),
              match: userURLMatcher(address, {
                tab: 'items',
              }),
            },
            {
              title: t('Notifications'),
              href: userURL(address, { tab: 'activities' }),
              match: userURLMatcher(address, { tab: 'activities' }),
            },
          ]
        : [],
    [address, t],
  )

  const userTab: NavBarLink[] = useMemo(
    () =>
      address
        ? [
            {
              title: t('Items'),
              href: userURL(address, { tab: 'items' }),
              match: userURLMatcher(address, {
                tab: 'items',
              }),
            },
            {
              title: t('Favorites'),
              href: userURL(address, { tab: 'favorites' }),
              match: userURLMatcher(address, {
                tab: 'favorites',
              }),
            },
            {
              title: t('Offers'),
              href: userURL(address, { tab: 'offers' }),
              match: userURLMatcher(address, {
                tab: 'offers',
              }),
            },
            {
              title: t('Activities'),
              href: userURL(address, { tab: 'activities' }),
              match: userURLMatcher(address, { tab: 'activities' }),
            },
          ]
        : [],
    [address, t],
  )

  return { market, settings, userTab, user }
}

export const pathMatched = (path: string, groups: NavBarLink[][]) => {
  for (const g of groups) {
    for (const item of g) {
      if (item.match?.test(path)) return true
    }
  }
  return false
}
