import dayjs from 'dayjs'
import en from 'dayjs/locale/en.js'
import es from 'dayjs/locale/es.js'
import fr from 'dayjs/locale/fr.js'
import id from 'dayjs/locale/id.js'
import ja from 'dayjs/locale/ja.js'
import ko from 'dayjs/locale/ko.js'
import pt from 'dayjs/locale/pt.js'
import ru from 'dayjs/locale/ru.js'
import th from 'dayjs/locale/th.js'
import vi from 'dayjs/locale/vi.js'
import cn from 'dayjs/locale/zh-cn.js'
import tw from 'dayjs/locale/zh-tw.js'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

// if the following is wrong, it's all github copiolet's fault
ja.relativeTime.s = '%d秒'
cn.relativeTime.s = '%d秒'
tw.relativeTime.s = '%d秒'
ko.relativeTime.s = '%d초'
id.relativeTime.s = '%d detik'
vi.relativeTime.s = '%d giây'
es.relativeTime.s = '%d segundos'
fr.relativeTime.s = '%d secondes'
ru.relativeTime.s = '%d секунд'
pt.relativeTime.s = '%d segundos'
th.relativeTime.s = '%d วินาที'
// Full config here since no default config in en
en.relativeTime = {
  future: 'in %s',
  past: '%s ago',
  s: '%d seconds',
  m: 'a minute',
  mm: '%d minutes',
  h: 'an hour',
  hh: '%d hours',
  d: 'a day',
  dd: '%d days',
  M: 'a month',
  MM: '%d months',
  y: 'a year',
  yy: '%d years',
}

// TODO: Use shorter format (not working, need further investigation)
// en.formats = { LL: 'MMM d, yyyy' }

export const useFromNow = () => {
  const dj = useDayjs()
  return useCallback(
    (date: dayjs.ConfigType, withoutSuffix = false) =>
      date ? dj(date).fromNow(withoutSuffix) : '-',
    [dj],
  )
}

// TODO: merge
export const useToNow = () => {
  const dj = useDayjs()
  return useCallback(
    (date: dayjs.ConfigType, withoutPrefix = false) =>
      date ? dj(date).toNow(withoutPrefix) : '-',
    [dj],
  )
}

const getDayjsLocale = (lang: string) => {
  if (lang === 'zh-Hans') return 'zh-cn'
  if (lang === 'zh-Hant') return 'zh-tw'
  return lang.toLowerCase()
}

export const useDayjs = () => {
  const { i18n } = useTranslation()
  const locale = getDayjsLocale(i18n.language)
  dayjs.locale(locale)
  return dayjs
}
