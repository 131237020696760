import { Switch as HlSwitch } from '@headlessui/react'
import clsx from 'clsx'

export type SwitchProps = {
  className?: string
  colorScheme?: 'primary-1' | 'primary-4'
  size: 'sm' | 'lg'
  isChecked: boolean
  name?: string
  onChange: (checked: boolean) => void
}
export const Switch = ({
  className,
  colorScheme = 'primary-1',
  size,
  isChecked,
  name,
  onChange,
}: SwitchProps) => {
  return (
    <HlSwitch
      className={clsx(
        className,
        'relative inline-flex items-center rounded-full',
        size === 'sm' ? 'h-4 w-[26px]' : 'h-7 w-[50px]',
        isChecked
          ? colorScheme === 'primary-1'
            ? 'bg-primary-1'
            : 'bg-primary-4'
          : 'bg-gray-300',
      )}
      checked={isChecked}
      name={name}
      onChange={onChange}
    >
      {/* <span className="sr-only"></span> */}
      <span
        className={clsx(
          'inline-block rounded-full bg-white transition',
          size === 'sm' ? 'h-3 w-3' : 'h-6 w-6',
          isChecked
            ? size === 'sm'
              ? 'translate-x-3'
              : 'translate-x-6'
            : 'translate-x-0.5',
        )}
      />
    </HlSwitch>
  )
}
