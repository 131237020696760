import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'

import { LoadingLine } from '@/icons'

type LoaderProps = {
  className?: string
  hidden?: boolean
}
export const Loader = ({ className, hidden }: LoaderProps) => {
  return (
    <motion.div
      animate={{ opacity: hidden ? 0 : 1 }}
      transition={{ ease: 'easeInOut', duration: 0.3, repeat: 1 }}
    >
      <motion.div
        className="flex"
        animate={{ rotate: 360 }}
        transition={{ ease: 'anticipate', duration: 2, repeat: Infinity }}
      >
        <LoadingLine className={className} />
      </motion.div>
    </motion.div>
  )
}

type Props = {
  label?: string
  size?: 'lg' | 'md'
  height?: number
}
export const Loading = ({ label, size = 'lg', height }: Props) => {
  const { t } = useTranslation()
  const [defaultHeight, className, spacing, textStyle] =
    size === 'lg'
      ? [300, 'w-8 h-8', 'gap-3', 'ts-body-1']
      : [150, 'w-5 h-5', 'gap-2', 'ts-body-2']

  return (
    <div
      className="grid place-items-center text-gray-500"
      style={{ height: height ?? defaultHeight }}
    >
      <div className={clsx('flex items-center', spacing)}>
        <Loader className={className} />
        <div className={textStyle}>{label ?? t('Loading') + '…'}</div>
      </div>
    </div>
  )
}
