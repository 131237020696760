import { LoanParams } from './types'
import { Query } from './url'

export const LOAN_TYPE = 'loanType'
export const DATA_TYPE = 'dataType'

export const ALL_EXPIRE_POINTS: LoanParams['expireRange'] = [
  900, 3600, 10800, 21600, 86400, 259200, 604800,
]

export const DEFAULT_QUERY: Query = { category: 'borrow' }
