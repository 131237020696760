import FormData from 'form-data'
import { pipe } from 'fp-ts/function'

import { O, R } from './fp'

type Method = 'GET' | 'POST'

export interface HttpRequestConfig {
  url?: string
  method?: Method
  baseURL?: string
  headers?: Record<string, string>
  data?: any
  timeout?: number
}

export interface HttpResponse<T = any> {
  data: T
  status: number
  statusText: string
  headers: any
}

type HttpInstance = {
  get<T = any>(
    url: string,
    config?: HttpRequestConfig,
  ): Promise<HttpResponse<T>>
  post<T = any>(
    url: string,
    data?: any,
    config?: HttpRequestConfig,
  ): Promise<HttpResponse<T>>
}

class HttpError extends Error {
  public origin: unknown
  public config: HttpRequestConfig

  public constructor(origin: unknown, config: HttpRequestConfig) {
    super()
    this.name = this.constructor.name
    this.origin = origin
    this.config = config
  }
}

const sensitiveHeaders = ['authorization', 'x-cj-token', 'x-tofu-api-key']

const sanitizeConfigForLogging = (
  config: HttpRequestConfig,
): HttpRequestConfig => {
  const headers = pipe(
    O.fromNullable(config.headers),
    O.map(
      R.mapWithIndex((k, a) =>
        sensitiveHeaders.includes(k.toLowerCase()) ? '[redacted]' : a,
      ),
    ),
    O.toUndefined,
  )
  return { ...config, headers }
}

const isOrdersApiUrl = (url: string): boolean =>
  ['/api/orders/add', '/api/orders/sign', '/api/orders/cancel'].includes(url)

const isXy3ApiUrl = (url: string): boolean => url.startsWith('/xy3/')

const checkBadResponse = (url: string, fetchRes: Response) => {
  let hasError = false
  if (!isOrdersApiUrl(url) && !isXy3ApiUrl(url) && !fetchRes.ok) {
    hasError = true
  }
  if (isOrdersApiUrl(url) && ![200, 400, 429, 503].includes(fetchRes.status)) {
    hasError = true
  }
  if (
    isXy3ApiUrl(url) &&
    ![200, 400, 401, 403, 429].includes(fetchRes.status)
  ) {
    hasError = true
  }
  if (hasError) {
    throw new Error('bad response')
  }
}

const request = async <T = any>(
  config: HttpRequestConfig,
): Promise<HttpResponse<T>> => {
  const { method, url = '', baseURL = '' } = config
  const isForm = config.data instanceof FormData
  const body = isForm
    ? config.data
    : config.data
    ? JSON.stringify(config.data)
    : null
  const headers =
    config.data && !isForm
      ? { ...config.headers, 'content-type': 'application/json' }
      : config.headers
  const ac = new AbortController()
  const req = new Request(baseURL + url, {
    method,
    headers,
    body,
    signal: ac.signal,
  })
  const timeout = setTimeout(() => ac.abort(), config.timeout)
  try {
    const fetchRes = await fetch(req)
    // Don't bother data on error here since we're going to adopt duan's method
    checkBadResponse(url, fetchRes)
    // Can be 'application/json' or 'application/json; charset=utf-8'
    const data = fetchRes.headers
      .get('content-type')
      ?.startsWith('application/json')
      ? await fetchRes.json()
      : await fetchRes.text()
    const response = {
      data,
      status: fetchRes.status,
      statusText: fetchRes.statusText,
      headers: fetchRes.headers,
    }
    return response
  } catch (e) {
    throw new HttpError(e, sanitizeConfigForLogging(config))
  } finally {
    clearTimeout(timeout)
  }
}

const create = (initConfig: HttpRequestConfig): HttpInstance => ({
  get: (url, config) =>
    request({ ...initConfig, ...config, method: 'GET', url }),
  post: (url, data, config) =>
    request({ ...initConfig, ...config, method: 'POST', url, data }),
})

export const http: HttpInstance = create({
  timeout: 5000,
})
