import { increment } from 'fp-ts/lib/function'
import { useCallback, useEffect, useState } from 'react'

import { getExt as getExt0, ShoppingCartAsset } from '@/lib/openseaShoppingCart'

const getExt = () => {
  try {
    return getExt0()
  } catch {}
}

export const useOpenSeaShoppingCart = () => {
  const [items, setItems] = useState<ShoppingCartAsset[]>([])

  const [nonce, setNonce] = useState(0)
  useEffect(() => {
    const action = async () => {
      const ext = getExt()
      if (!ext) return
      const items = await ext.getItems()
      setItems(items)
    }
    action()
  }, [nonce])
  // TODO: Maybe no longer needed?
  const reload = useCallback(() => setNonce(increment), [])

  useEffect(() => {
    const ext = getExt()
    if (!ext) return
    ext.setOnChangeHandler(setItems)
  }, [])

  const removeItem = useCallback(async (item: ShoppingCartAsset) => {
    const ext = getExt()
    if (!ext) return
    await ext.removeItem(item)
  }, [])
  const clear = useCallback(async () => {
    const ext = getExt()
    if (!ext) return
    await ext.clear()
  }, [])

  const applyChanges = useCallback(
    async (changed: ShoppingCartAsset[], removed: ShoppingCartAsset[]) => {
      const ext = getExt()
      if (!ext) return null
      await ext.editItems(changed)
      return await ext.removeItems(removed)
    },
    [],
  )

  return { items, reload, removeItem, clear, applyChanges }
}
