import { useCallback } from 'react'

import { NETWORK_ETH, NETWORK_GOERLI } from '@/consts'
import { O } from '@/lib/fp'
import {
  DEFAULT_NETWORK,
  getProviderURLByNetworkId,
  networkNativeCurrency,
  scannerBaseURL,
} from '@/utils/network'
import { networkTitles } from '@/utils/network/meta'

import { X2Y2_TOKEN_CONTRACT_MAPS } from '../x2y2'
import { useAuth } from './authProvider'
import * as U from './types/user'

export const userKey = (user: U.User): string => {
  switch (user._tag) {
    case 'pending':
      return 'pending'
    case 'anonymous':
      return 'anonymous'
    case 'web3-registered':
      return `web3-registered-${user.meta.id}`
    case 'registered':
      return `registered-${user.meta.id}`
  }
}

const isAtLeastWeb3User = (
  user: U.User,
): user is U.Web3RegisteredUser | U.RegisteredUser => {
  return user._tag === 'web3-registered' || user._tag === 'registered'
}
export const getUserWeb3Provider = (user: U.User) => {
  if (isAtLeastWeb3User(user)) return O.some(user.web3Provider)
  return O.none
}
export const getUserAddress = (user: U.User) => {
  if (isAtLeastWeb3User(user)) return O.some(user.meta.address)
  return O.none
}
export const getUserAddressN = (user: U.User) =>
  isAtLeastWeb3User(user) ? user.meta.address : null
export const getUserNickname = (user: U.User) => {
  if (isAtLeastWeb3User(user)) return O.fromNullable(user.meta.nickname)
  return O.none
}
export const getUserId = (user: U.User) => {
  if (isAtLeastWeb3User(user)) return O.some(user.meta.id)
  return O.none
}

export const useUserRequire = () => {
  const { user, tryWeb3SignIn, tryServerSignIn } = useAuth()
  const requireWeb3RegisteredUser = useCallback(
    (action: (u: U.Web3RegisteredUser | U.RegisteredUser) => void) => {
      switch (user._tag) {
        case 'pending':
          return
        case 'anonymous':
          tryWeb3SignIn(action)
          return
        case 'web3-registered':
        case 'registered':
          action(user)
          return
      }
    },
    [user, tryWeb3SignIn],
  )
  const requireRegisteredUser = useCallback(
    (action: (u: U.RegisteredUser) => void) => {
      switch (user._tag) {
        case 'pending':
          return
        case 'anonymous':
          tryWeb3SignIn((u) => {
            tryServerSignIn(action, u)
          })
          return
        case 'web3-registered':
          tryServerSignIn(action, user)
          return
        case 'registered':
          action(user)
          return
      }
    },
    [user, tryWeb3SignIn, tryServerSignIn],
  )

  return { requireWeb3RegisteredUser, requireRegisteredUser }
}

export const isRabbyWallet = () => {
  const ethereum = (window as any).ethereum
  if (!ethereum) return false
  if (ethereum['isRabby']) return true
  return false
}

export const switchNetwork = async (networkId: number) => {
  const meta = (() => {
    const chainId = '0x' + networkId.toString(16)
    const currency = networkNativeCurrency(networkId)
    const rpcUrl = getProviderURLByNetworkId(networkId)
    switch (networkId) {
      case NETWORK_ETH:
        return { chainId }
      default:
        return {
          chainId,
          chainName: networkTitles(networkId).long,
          nativeCurrency: { name: currency, symbol: currency, decimals: 18 },
          rpcUrls: [
            networkId === NETWORK_GOERLI
              ? 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
              : rpcUrl,
          ],
          blockExplorerUrls: [scannerBaseURL(networkId)],
        }
    }
  })()
  const ethereum = (window as any).ethereum as any
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: meta.chainId }],
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((switchError as { code: number }).code === 4902) {
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [meta],
      })
    } else {
      throw switchError
    }
  }
}

export const addX2Y2ToMetamask = async () => {
  const tokenAddress = X2Y2_TOKEN_CONTRACT_MAPS[DEFAULT_NETWORK]
  const tokenSymbol = 'X2Y2'
  const tokenDecimals = 18
  const tokenImage = 'https://x2y2.io/icon.png'

  try {
    const ethereum = (window as any).ethereum as any
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage,
        },
      },
    })
  } catch (error) {
    console.error(error)
  }
}
