import { Interface } from '@ethersproject/abi'

import { getProviderByNetworkId } from '@/utils/network'

import { Multicall2__factory } from './contract/factories/Multicall2__factory'

// Mainnet, Kovan, Rinkeby, Görli, Ropsten
const CONTRACT_V2 = '0x5ba1e12693dc8f9c48aad8770482f4739beed696'

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (exemple: balanceOf)
  params?: any[] // Function params
}

export const multicall = async (
  networkId: number,
  abi: any[],
  calls: Call[],
  allowPartialFailure = false,
) => {
  const provider = getProviderByNetworkId(networkId)
  const contract = CONTRACT_V2
  const multicall = Multicall2__factory.connect(contract, provider)
  const itf = new Interface(abi)
  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }))
  if (allowPartialFailure) {
    const aggrResp = await multicall.callStatic.tryAggregate(false, calldata)
    const rv = Array(calls.length).fill(undefined)
    aggrResp.map((a, i) => {
      if (a.success) {
        try {
          rv[i] = itf.decodeFunctionResult(calls[i].name, a.returnData)
        } catch {
          // This could happen when contract suisided, such as: 0xea1c7020903637665bc6970f46b1b03c92a7876c
          // Treat it as failure
        }
      }
    })
    return rv
  } else {
    const aggrResp = await multicall.callStatic.aggregate(calldata)
    return aggrResp.returnData.map((callReturn, i) =>
      itf.decodeFunctionResult(calls[i].name, callReturn),
    )
  }
}

export const multicallAndBlock = async (
  networkId: number,
  abi: any[],
  calls: Call[],
  allowPartialFailure = false,
) => {
  const provider = getProviderByNetworkId(networkId)
  const contract = CONTRACT_V2
  const multicall = Multicall2__factory.connect(contract, provider)
  const itf = new Interface(abi)
  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }))
  if (allowPartialFailure) {
    const aggrResp = await multicall.callStatic.tryBlockAndAggregate(
      false,
      calldata,
    )
    const data = Array(calls.length).fill(undefined)
    aggrResp.returnData.map((a, i) => {
      if (a.success) {
        try {
          data[i] = itf.decodeFunctionResult(calls[i].name, a.returnData)
        } catch {}
      }
    })
    return { blockNumber: aggrResp.blockNumber, data }
  } else {
    const aggrResp = await multicall.callStatic.aggregate(calldata)
    const data = aggrResp.returnData.map((callReturn, i) =>
      itf.decodeFunctionResult(calls[i].name, callReturn),
    )
    return { blockNumber: aggrResp.blockNumber, data }
  }
}
