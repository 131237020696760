import { useCallback, useState } from 'react'

export type UseDisclosureProps = {
  defaultIsOpen?: boolean
}
export const useDisclosure = ({
  defaultIsOpen = false,
}: UseDisclosureProps = {}) => {
  const [isOpen, setOpen] = useState(defaultIsOpen)

  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])

  const onToggle = useCallback(() => {
    if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }, [isOpen, onOpen, onClose])

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>
