import { FloatingPortal } from '@floating-ui/react'
import { Menu } from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode, useCallback } from 'react'

import { Svg } from '@/components/media'
import { usePopover } from '@/hooks/usePopover'
import { ArrowDownSimpleLine } from '@/icons'

import { Button } from '../form'

type Variant = 'text' | 'rich'

export type PopoverSelectItem<T> = {
  title: ReactNode
  value: T
  icon?: Svg
}

export type PopoverSelectProps<T> = {
  className?: string
  variant?: Variant
  items: PopoverSelectItem<T>[]
  value: T
  isDisabled?: boolean
  onChange: (v: T) => void
}
export const PopoverSelect = <T,>({
  className,
  variant = 'text',
  items,
  value,
  isDisabled = false,
  onChange,
}: PopoverSelectProps<T>) => {
  const { referenceProps, floatingProps } = usePopover({
    width: 'match-reference',
  })

  const selected = items.find((i) => i.value === value)

  const handleSelect = useCallback(
    (v: PopoverSelectItem<T>) => onChange(v.value),
    [onChange],
  )

  return (
    <Menu>
      <Menu.Button
        as={Button}
        className={clsx(
          className,
          'w-full text-[16px] hover:border-gray-400 hover:bg-transparent',
          variant === 'text' ? '!pr-2 !pl-4' : '!rounded-xl !px-6',
        )}
        {...referenceProps}
        variant="outline"
        size="sm"
        rightIcon={
          <ArrowDownSimpleLine className="h-6 w-6 text-gray-500 ui-open:rotate-180 motion-safe:duration-200" />
        }
        isDisabled={isDisabled}
      >
        <span
          className={clsx(
            'flex min-w-0 flex-1 items-center',
            !isDisabled && 'text-gray-800',
          )}
        >
          {selected?.icon && <selected.icon className="mr-2 h-6 w-6" />}
          <span
            className={clsx('truncate break-all', !selected && 'text-gray-400')}
          >
            {selected?.title ?? ''}
          </span>
        </span>
      </Menu.Button>
      <FloatingPortal>
        <Menu.Items
          className={clsx(
            'z-[1500] flex min-w-[80px] flex-col overflow-y-auto bg-modal-bg',
            'focus-visible:outline-0',
            '!shadow-light-1 dark:!shadow-dark-1',
            variant === 'text'
              ? 'rounded-lg border-2 border-gray-200 p-3'
              : 'rounded-xl border border-gray-300',
          )}
          {...floatingProps}
        >
          {items.map((e, i) => (
            <Menu.Item
              as="button"
              className={clsx(
                'ts-input-1 flex items-center py-3',
                'ui-active:bg-modal-section-bg',
                variant === 'text' ? 'rounded-full px-4' : 'px-6',
                e.value !== value && 'text-gray-500',
              )}
              onClick={() => handleSelect(e)}
              key={i}
            >
              {e.icon && <e.icon className="mr-2 h-6 w-6" />}
              <p className="truncate break-all">{e.title}</p>
            </Menu.Item>
          ))}
        </Menu.Items>
      </FloatingPortal>
    </Menu>
  )
}
