import { NETWORK_ETH, NETWORK_GOERLI, NETWORK_RINKEBY } from '@/consts'

export const MARKET_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0x74312363e45DCaBA76c59ec49a7Aa8A65a67EeD3',
  [NETWORK_RINKEBY]: '0xe56CBf281Af7aa5d53748CBa13b7DdD23C4dA0b3',
  [NETWORK_GOERLI]: '0x1891EcD5F7b1E751151d857265D6e6D08ae8989e',
}

export const ERC721_DELEGATE_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xf849de01b080adc3a814fabe1e2087475cf2e354',
  [NETWORK_RINKEBY]: '0x095be13D86000260852E4F92eA48dc333fa35249',
  [NETWORK_GOERLI]: '0x095be13D86000260852E4F92eA48dc333fa35249',
}

export const ERC1155_DELEGATE_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0x024ac22acdb367a3ae52a3d94ac6649fdc1f0779',
  [NETWORK_RINKEBY]: '0x675B92ed07184635dEA2EF6fB403875DfA09d74A',
  [NETWORK_GOERLI]: '0x675B92ed07184635dEA2EF6fB403875DfA09d74A',
}

export const WETH_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  [NETWORK_RINKEBY]: '0xc778417e063141139fce010982780140aa0cd5ab',
  [NETWORK_GOERLI]: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
}

export const PRESALE_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xc2F44Bc508b6b50047a2F3AfB1984eD105070bE1',
  [NETWORK_RINKEBY]: '0xc8d15F2C13cEadB3505253E5DB901D8912A87EB1',
  [NETWORK_GOERLI]: '',
}

export const X2Y2_TOKEN_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0x1E4EDE388cbc9F4b5c79681B7f94d36a11ABEBC9',
  [NETWORK_RINKEBY]: '0x4e4FA3dE1bAd9e233c913ac1A53dC8727dC753d6',
  [NETWORK_GOERLI]: '',
}

export const X2Y2_DROP_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xe6949137B24ad50CcE2cF6B124B3b874449a41Fa',
  [NETWORK_RINKEBY]: '0xd259F56B433df1ca56Ed42AbDCA2c46228EDf888',
  [NETWORK_GOERLI]: '',
}

export const FEE_SHARING_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xc8C3CC5be962b6D281E4a53DBcCe1359F76a1B85',
  [NETWORK_RINKEBY]: '0x7870AACe96b987B6C8Eb56F92D7Ab0136EF7a49C',
  [NETWORK_GOERLI]: '',
}

export const TOKEN_DISTRIBUTOR_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xB329e39Ebefd16f40d38f07643652cE17Ca5Bac1',
  [NETWORK_RINKEBY]: '0x74312363e45DCaBA76c59ec49a7Aa8A65a67EeD3',
  [NETWORK_GOERLI]: '',
}

export const X2Y2_CART_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0x56dd5bbede9bfdb10a2845c4d70d4a2950163044',
  [NETWORK_RINKEBY]: '0x50c0Cb42c973785D364ba76CDFC5516D333F78CE',
  [NETWORK_GOERLI]: '0x6944acA829C5743DAcA4Dd365d50879A761A501f',
}

export const X2Y2_REWARD_V3_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0x897249FEf87Fa6D1E7FeDCB960c2A01Ec99ecC6C',
  [NETWORK_RINKEBY]: '0xC3ab1620F1FFF869eb23490ED958B8d3b9cC02d4',
  [NETWORK_GOERLI]: '',
}

export const X2Y2_COMPOUNDER_CONTRACT_MAPS: Record<number, string> = {
  [NETWORK_ETH]: '0xac010690e41fb5c6f9d66cc33bd78c2f8eca9a2f',
  [NETWORK_RINKEBY]: '0x59d9744d40d217df40a8fd8c9ec799affb9a270c',
  [NETWORK_GOERLI]: '',
}
