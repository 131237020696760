import * as RD from '@devexperts/remote-data-ts'
import { identity, increment, pipe, tuple } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as TE from 'fp-ts/lib/TaskEither'
import { useCallback, useRef, useState } from 'react'
import * as Rx from 'rxjs'
import { useObservable } from 'rxjs-hooks'
import * as RxO from 'rxjs/operators'

export type Options = Partial<{
  debounceTime: number
  skipPending: boolean
  resetOnNone: boolean
}>

const useOptionalTask = <E, A>(
  $task: O.Option<TE.TaskEither<E, A>>,
  options: Options = {},
) => {
  const [reloadToken, setReloadToken] = useState(0)
  const [{ debounceTime, skipPending, resetOnNone }] = useState(options)

  const isInitialFetch = useRef(true)
  const inputs = tuple($task, reloadToken)
  const data = useObservable<RD.RemoteData<E, A>, typeof inputs>(
    (_, $inputs) =>
      $inputs.pipe(
        debounceTime ? RxO.debounceTime(debounceTime) : identity,
        RxO.switchMap(([task]) => {
          return pipe(
            task,
            O.fold(
              () => {
                if (resetOnNone) {
                  return Rx.of(RD.initial)
                } else {
                  return Rx.EMPTY
                }
              },
              (task) => {
                if (skipPending && !isInitialFetch.current)
                  return Rx.from(task()).pipe(RxO.map(RD.fromEither))
                if (isInitialFetch.current) isInitialFetch.current = false
                return Rx.concat(
                  Rx.of(RD.pending),
                  Rx.from(task()).pipe(RxO.map(RD.fromEither)),
                )
              },
            ),
          )
        }),
      ),
    RD.initial,
    inputs,
  )
  const reload = useCallback(() => setReloadToken(increment), [])

  return tuple(data, reload)
}

export default useOptionalTask
