import { ON_STAG } from './env'

export const stagingOnly = ON_STAG // NOTE: import keeper

export const enableShowOngoingTransactions = true
export const enableBuyWithCrossmint = true
export const enableAcceptOfferRoyalty = true
export const enableLoanDownPay = stagingOnly
export const enableOkxWallet = true
export const enableRecentMint = false // remove tarzan
