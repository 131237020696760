import { useTranslation } from 'next-i18next'
import { useEffect, useMemo } from 'react'

import { LinkTabsProps, Option } from '@/components/LinkTabs'
import { useAuth } from '@/lib/auth'
import { isSameAddress } from '@/lib/auth/types/user'
import { D } from '@/lib/fp'
import { userURL } from '@/lib/url'
import { useNotificationContext } from '@/lib/user/NotificationProvider'

export const ALL_TABS = [
  'portfolio',
  'items',
  'favorites',
  'offers',
  'activities',
] as const
export type Tab = (typeof ALL_TABS)[number]
export const tabDecoder = D.literal(...ALL_TABS)
export const DEFAULT_USER_TAB: Tab = 'items'

export const useSegmentControlProps = (
  address: string,
  tab: Tab,
): LinkTabsProps<Tab> => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const { activity, offer, clear } = useNotificationContext()

  useEffect(() => {
    if (tab === 'activities' || tab === 'offers') clear(tab)
  }, [tab, clear])

  const showBadge =
    (user._tag === 'registered' || user._tag === 'web3-registered') &&
    isSameAddress(user.meta.address, address)

  const tabs = useMemo(
    (): Option<Tab>[] => [
      {
        key: 'portfolio',
        title: t('Portfolio'),
      },
      {
        key: 'items',
        title: t('Items'),
      },
      {
        key: 'favorites',
        title: t('Favorites'),
      },
      {
        key: 'offers',
        title: t('Offers'),
        badge: showBadge && offer,
      },
      {
        key: 'activities',
        title: t('Activities'),
        badge: showBadge && activity,
      },
    ],
    [t, activity, offer, showBadge],
  )
  return {
    options: tabs.filter((a) => a.key !== 'portfolio'),
    selected: tab,
    href: (newTab) => userURL(address, { tab: newTab }),
    scrollPageOnChange: false,
  }
}
