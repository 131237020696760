import { D } from '@/lib/fp'

export const ALL_CONNECT_OPTIONS = [
  'MetaMask',
  'WalletConnect',
  'Coinbase Wallet',
  'Phantom',
  'OKX Wallet',
  'OneKey Wallet',
  'Fortmatic',
  'BitKeep',
  'TokenPocket',
  'Trust Wallet',
  'imToken',
  'SafePal',
  'MathWallet',
  'HyperPay',
  'Brave Wallet',
] as const
export type ConnectOption = (typeof ALL_CONNECT_OPTIONS)[number]

export const connectOptionDecoder = D.literal(...ALL_CONNECT_OPTIONS)
