import { pipe } from 'fp-ts/lib/function'
import { ParsedUrlQuery } from 'querystring'

import { A, D, E, O } from '@/lib/fp'
import { kvToUrlQuery } from '@/lib/search/utils'

import { DEFAULT_QUERY } from './constants'

const categoryDecoder = D.literal(
  'borrow',
  'lend',
  'supported',
  'offers',
  'loans',
)

const offerDecoder = D.literal('offer', 'collection')
export type LoanOfferType = D.TypeOf<typeof offerDecoder>
const loanDecoder = D.literal('borrow', 'lend')
export type LoanType = D.TypeOf<typeof loanDecoder>
const statusDecoder = D.literal('current', 'history')
export type StatusType = D.TypeOf<typeof statusDecoder>
const listDecoder = D.literal('terms', 'loans')

export const queryDecoder = pipe(
  D.struct({
    category: categoryDecoder,
  }),
  D.intersect(
    D.partial({
      offer: offerDecoder,
      loan: loanDecoder,
      status: statusDecoder,
      list: listDecoder,
    }),
  ),
)
export type Query = D.TypeOf<typeof queryDecoder>

export const parsePageQuery = (query: ParsedUrlQuery) =>
  pipe(
    queryDecoder.decode(query),
    E.getOrElse((): Query => DEFAULT_QUERY),
  )

export const pageURL = (query: Query = DEFAULT_QUERY) => {
  if (
    query.category === DEFAULT_QUERY.category &&
    query.offer === DEFAULT_QUERY.offer &&
    query.loan === DEFAULT_QUERY.loan &&
    query.status === DEFAULT_QUERY.status &&
    query.list === DEFAULT_QUERY.list
  ) {
    return '/loan'
  }
  const urlQuery = kvToUrlQuery(
    pipe(
      [
        O.some({ k: 'category', v: query.category }),
        pipe(
          O.fromNullable(query.category === 'offers' ? query.offer : null),
          O.map((v: string) => ({ k: 'offer', v })),
        ),
        pipe(
          O.fromNullable(query.category === 'lend' ? query.list : null),
          O.map((v: string) => ({ k: 'list', v })),
        ),
        pipe(
          O.fromNullable(query.category === 'loans' ? query.loan : null),
          O.map((v: string) => ({ k: 'loan', v })),
        ),
        pipe(
          O.fromNullable(query.category === 'loans' ? query.status : null),
          O.map((v: string) => ({ k: 'status', v })),
        ),
      ],
      A.compact,
    ),
  )
  return `/loan${urlQuery}`
}

type fiURLProps = {
  path?:
    | '/borrow'
    | '/lend/my-offers'
    | '/borrow/loans-received/current'
    | '/lend/loans-given/current'
  contractAddress?: string
  tokenId?: string
}
export const fiURL = ({ path, contractAddress, tokenId }: fiURLProps) => {
  const urlQuery = kvToUrlQuery(
    pipe(
      [
        pipe(
          O.fromNullable(contractAddress),
          O.map((v: string) => ({ k: 'contractAddress', v })),
        ),
        pipe(
          O.fromNullable(tokenId),
          O.map((v: string) => ({ k: 'tokenId', v })),
        ),
      ],
      A.compact,
    ),
  )
  return `${process.env.NEXT_PUBLIC_X2Y2_FI_BASE_URL}${path ?? ''}${urlQuery}`
}
