import { identity, pipe } from 'fp-ts/function'
import { NumberFromString } from 'io-ts-types'
import { ParsedUrlQuery } from 'querystring'

import { EsMapping } from '@/lib/es/mapping'
import { DEFAULT_SORT, SearchPayload, sortDecoder } from '@/lib/es/nft'
import {
  attrKeywordsFilterDecoder,
  attrMinMaxFilterDecoder,
  AttrsFilter,
} from '@/lib/es/types'
import { A, D, O, R } from '@/lib/fp'

import { keywordsURLQueryCodec, minmaxURLQueryCodec } from './utils'

export const attributesFilterKVGen = (attributes: AttrsFilter) => {
  return pipe(
    attributes,
    R.toArray,
    A.map(([k, v]) => {
      return pipe(
        pipe(
          attrMinMaxFilterDecoder.decode(v),
          O.fromEither,
          O.chain(minmaxURLQueryCodec.encode),
          O.map((encodedV) => ({ k, v: encodedV })),
        ),
        O.alt(() =>
          pipe(
            attrKeywordsFilterDecoder.decode(v),
            O.fromEither,
            O.chain(keywordsURLQueryCodec.encode),
            O.map((encodedV) => ({ k, v: encodedV })),
          ),
        ),
      )
    }),
  )
}

export const filterAttributesDecode = (
  q: ParsedUrlQuery,
  esMapping: EsMapping,
) =>
  pipe(
    esMapping,
    A.map(({ es_key, type }) => {
      type KV = { key: string; value: AttrsFilter[string] }
      if (type === 'double' || type === 'date') {
        return pipe(
          D.string.decode(q[es_key]),
          O.fromEither,
          O.chain(minmaxURLQueryCodec.decode),
          O.map((value): KV => ({ key: es_key, value })),
        )
      }
      if (type === 'text') {
        return pipe(
          D.string.decode(q[es_key]),
          O.fromEither,
          O.chain(keywordsURLQueryCodec.decode),
          O.map((value): KV => ({ key: es_key, value })),
        )
      }
      return O.none
    }),
    A.compact,
    A.reduce<
      { key: string; value: AttrsFilter[string] },
      Record<string, AttrsFilter[string]>
    >({}, (acc, entity) => {
      acc[entity.key] = entity.value
      return acc
    }),
  )

export const basicURLQueryKVGen = (
  query: Pick<SearchPayload, 'sort' | 'filters'>,
  deafultSort = DEFAULT_SORT,
) =>
  A.compact([
    pipe(
      O.fromNullable(query.sort),
      O.filter((a) => a !== deafultSort),
      O.map((v) => ({ k: 'sort', v })),
    ),
    pipe(
      O.fromNullable(query.filters?.buyNow),
      O.filter(identity),
      O.map(() => ({ k: 'buyNow', v: '1' })),
    ),
    pipe(
      O.fromNullable(query.filters?.priceMin),
      O.map((v) => ({ k: 'priceMin', v: v.toString() })),
    ),
    pipe(
      O.fromNullable(query.filters?.priceMax),
      O.map((v) => ({ k: 'priceMax', v: v.toString() })),
    ),
    pipe(
      O.fromNullable(query.filters?.currency),
      O.map((v) => ({ k: 'currency', v })),
    ),
    pipe(
      O.fromNullable(query.filters?.query),
      O.map((v) => ({ k: 'q', v })),
    ),
    ...attributesFilterKVGen(query.filters?.attributes ?? {}),
  ])

export const basicURLQueryDecode = (
  q: ParsedUrlQuery,
  esMapping: EsMapping,
): Pick<SearchPayload, 'sort' | 'filters'> => {
  return {
    sort: pipe(sortDecoder.decode(q['sort']), O.fromEither, O.toUndefined),
    filters: {
      buyNow: pipe(
        D.literal('1').decode(q['buyNow']),
        O.fromEither,
        O.map(() => true),
        O.toUndefined,
      ),
      priceMin: pipe(
        NumberFromString.decode(q['priceMin']),
        O.fromEither,
        O.toUndefined,
      ),
      priceMax: pipe(
        NumberFromString.decode(q['priceMax']),
        O.fromEither,
        O.toUndefined,
      ),
      currency: pipe(
        D.string.decode(q['currency']),
        O.fromEither,
        O.toUndefined,
      ),
      query: pipe(D.string.decode(q['q']), O.fromEither, O.toUndefined),
      attributes: filterAttributesDecode(q, esMapping),
    },
  }
}
