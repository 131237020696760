import { ReactElement } from 'react'

import { metadataAssetsAsNftImageProps, NftImage } from '@/components/media'
import { TokenIcon } from '@/components/TokenIcon'
import { VerifiedFilled } from '@/icons'
import { Bundle } from '@/lib/nft/detail'
import { getTokenMeta } from '@/lib/token'

export type NftItemProps = {
  item: Bundle[0]
  price: string | null
  currency: string | null
  priceLabel?: ReactElement | null
  withImage?: boolean
}
export const NftItem = ({
  item,
  price,
  currency,
  priceLabel,
  withImage = true,
}: NftItemProps) => {
  const { networkId, asset, creator, verified, name } = item.info
  return (
    <div className="flex w-full min-w-0 items-center space-x-3">
      {withImage && (
        <NftImage
          className="h-12 w-12 shrink-0"
          {...metadataAssetsAsNftImageProps(asset)}
        />
      )}
      <div className="min-w-0 flex-1">
        <div className="flex items-center space-x-1">
          <p className="ts-caption-2 truncate break-all text-gray-500">
            {creator}
          </p>
          {verified && <VerifiedFilled className="h-4 w-4 text-primary-1" />}
        </div>
        <p className="ts-body-3 truncate break-all font-bold">{name}</p>
      </div>
      {price && currency && (
        <div className="min-w-0">
          <div className="flex items-center space-x-1">
            <TokenIcon
              className="h-4 w-4"
              symbol={getTokenMeta(networkId, currency).symbol}
            />
            <p className="ts-body-2 font-bold">{price}</p>
          </div>
          <div className="float-right">{priceLabel}</div>
        </div>
      )}
    </div>
  )
}
