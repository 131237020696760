import { useEffect, useState } from 'react'

export const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
}
export type Breakpoint = keyof typeof breakpoints

export const useOnPC = (breakpoint: Breakpoint = 'md') => {
  const [onPC, setOnPC] = useState(false)
  useEffect(() => {
    const mql = window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`)
    setOnPC(mql.matches)
    const handler = (e: MediaQueryListEvent) => setOnPC(e.matches)
    mql.addEventListener('change', handler)
    return () => mql.removeEventListener('change', handler)
  }, [breakpoint])
  return onPC
}
