import type { ChaindexNftInfo } from '@/lib/chaindex'
import type { SearchPayload as ActivitiesSearchPayload } from '@/lib/es/activity'
import { EsMapping } from '@/lib/es/mapping'
import type { SearchPayload as NftsSearchPayload } from '@/lib/es/nft'
import type { AttrsSearchOptions } from '@/lib/es/types'
import { D } from '@/lib/fp'
import type {
  ContractInfoInSearchResultFragment,
  GetAllOpenSellOrdersByUserQuery,
  GetContractDetailByAddrQuery,
  GetContractsByAddrsQuery,
  GetLikedContractsQuery,
  GetOpenedPrivateOrdersByTakerQuery,
  GetOrderByIdQuery,
  NftDetailIsLikeQuery,
  OrderInfoFragment,
  PrivateUserInfoFragment,
  PublicUserInfoFragment,
} from '@/lib/gql/types'
import type {
  CancelOrderPayload,
  MarketOrderPayload,
  MarketReportErrorPayload,
  NftLikePayload,
  NftRefreshPayload,
  NftResp,
  NFTToken,
  OrderDetailPayload,
  OrderDetailResp,
} from '@/lib/market'
import {
  SearchActivitiesResp,
  SearchByKeywordResp,
  SearchNftsResp,
} from '@/lib/search'
import { NotificationSettings } from '@/lib/settings'
import { GetOrdersPayload, GetOrdersResp } from '@/lib/x2y2'

import {
  BatchGetMetaPayload,
  GetMetaPayload,
  OpenSeaMetadata,
} from '../unimeta'
import type { TgContactPayload, TgContactResp } from '../user/types'
import { AuthEntry, authTaskGen, Entry, taskGen } from './entry'

// MARK: User

export const addressMsgToSign: Entry<
  {
    address: string
  },
  {
    id: string
    address: string
    msg: string
  }
> = taskGen('/user/addressMsgToSign')
export const signIn: Entry<
  {
    id: string
    address: string
    signedMsg: string
    isEOA: boolean
  },
  { refreshToken: string }
> = taskGen('/user/signIn')
export const refreshToken: Entry<{ refreshToken: string }, { token: string }> =
  taskGen('/user/refreshToken')

export const addressUserMeta: Entry<
  { address: string },
  PublicUserInfoFragment
> = taskGen('/user/addressUserMeta')
export const addressUserNfts: Entry<{ address: string }, ChaindexNftInfo[]> =
  taskGen('/user/addressUserNfts')
export const addressUserMetaWithAuth: AuthEntry<
  unknown,
  PrivateUserInfoFragment
> = authTaskGen('/user/addressUserMetaWithAuth')
export const addressUnreadMessageCount: Entry<{ address: string }, number> =
  taskGen('/user/addressUnreadMessageCount')

export const userOpenSellOrders: AuthEntry<
  undefined,
  GetAllOpenSellOrdersByUserQuery
> = authTaskGen('/user/openSellOrders')

export const updateMetaPayloadDecoder = D.struct({
  rmAvatar: D.boolean,

  nickname: D.string,
  intro: D.string,

  twitterURL: D.string,
  youtubeURL: D.string,
  instagramURL: D.string,
  homepageURL: D.string,
})
export const updateMeta: AuthEntry<
  D.TypeOf<typeof updateMetaPayloadDecoder>,
  unknown
> = authTaskGen('/user/updateMeta')
export const updateNotificationSettings: AuthEntry<
  NotificationSettings,
  unknown
> = authTaskGen('/user/updateNotificationSettings')
export const updateEnableBlockscanNotification: AuthEntry<boolean, unknown> =
  authTaskGen('/user/updateEnableBlockscanNotification')

export const updateAvatarPayloadDecoder = D.number
export const updateAvatar: AuthEntry<
  D.TypeOf<typeof updateAvatarPayloadDecoder>,
  unknown
> = authTaskGen('/user/updateAvatar')
export const updateAllowDirectMessage: AuthEntry<boolean, unknown> =
  authTaskGen('/user/updateAllowDirectMessage')
export const updateHiddenContractsPayloadDecoder = D.array(
  D.struct({
    network: D.number,
    address: D.string,
  }),
)
export const updateHiddenContracts: AuthEntry<
  D.TypeOf<typeof updateHiddenContractsPayloadDecoder>,
  unknown
> = authTaskGen('/user/updateHiddenContracts')

export const bindTelegram: AuthEntry<unknown, { code: string }> =
  authTaskGen('/user/bindTelegram')
export const unbindTelegram: AuthEntry<unknown, unknown> = authTaskGen(
  '/user/unbindTelegram',
)
export const bindEmail: AuthEntry<{ email: string }, undefined> =
  authTaskGen('/user/bindEmail')
export const bindEmailConfirm: AuthEntry<{ code: string }, undefined> =
  authTaskGen('/user/bindEmailConfirm')
export const unbindEmail: AuthEntry<undefined, undefined> =
  authTaskGen('/user/unbindEmail')
export const updateAntiPhishingCode: AuthEntry<{ code: string }, unknown> =
  authTaskGen('/user/updateAntiPhishingCode')
export const contactUser: AuthEntry<TgContactPayload, TgContactResp> =
  authTaskGen('/user/contact')

// MARK: Market

export const cancelOrder: AuthEntry<CancelOrderPayload, OrderDetailResp> =
  authTaskGen('/market/cancel')

export const newOrder: AuthEntry<MarketOrderPayload, OrderDetailResp> =
  authTaskGen('/market/order')

export const orderDetail: AuthEntry<OrderDetailPayload, OrderDetailResp> =
  authTaskGen('/market/detail')
export const orderCheck: Entry<{ id: number }, void> =
  taskGen(`/market/orderCheck`)
export const getOrders: AuthEntry<GetOrdersPayload, GetOrdersResp> =
  authTaskGen('/market/getOrders')

export const likeNft: AuthEntry<NftLikePayload, NftResp> =
  authTaskGen('/market/likeNft')
export const isNftLiked: Entry<
  { nftId: number; userId: number },
  NftDetailIsLikeQuery
> = taskGen('/market/isNftLiked')
export const likeContract: AuthEntry<
  { contractId: number; like: boolean },
  null
> = authTaskGen('/market/likeContract')
export const isContractLiked: Entry<
  { contractId: number; userId: number },
  boolean
> = taskGen('/market/isContractLiked')
export const likedContracts: Entry<{ userId: number }, GetLikedContractsQuery> =
  taskGen('/market/likedContracts')
export const refreshNft: Entry<NftRefreshPayload, NftResp> =
  taskGen('/market/refreshNft')
export const marketReportError: Entry<MarketReportErrorPayload, null> =
  taskGen('/market/error')
type ReportReq = {
  type: 'copyright' | 'sensitive' | 'other'
  url: string
  desc: string
  nftId: number
  token: string | null
  address: string | null
}
export const marketReport: Entry<ReportReq, null> = taskGen('/market/report')

export const checkNfts: Entry<
  {
    networkId: number
    owner: string
    data: NFTToken[]
  },
  string
> = taskGen('/market/checkNfts')

// MARK: Reward

export const rewardData: Entry<
  { networkId: number; lastBlock: number },
  { block: number; timestamp: number; hash: string }[]
> = taskGen('/rewardData')

// MARK: Explore

export const getMeta: Entry<GetMetaPayload, unknown> = taskGen('/getMeta')
export const batchGetMeta: Entry<
  BatchGetMetaPayload,
  { [key: string]: { metadata_content: OpenSeaMetadata } }
> = taskGen('/batchGetMeta')

export const searchNfts: Entry<NftsSearchPayload, SearchNftsResp> =
  taskGen('/searchNfts')
export const searchNftsOptions: Entry<
  { id: number },
  {
    contract: ContractInfoInSearchResultFragment
    esMapping: EsMapping
    options: AttrsSearchOptions
  }
> = taskGen('/searchNftsOptions')
export const searchActivities: Entry<
  ActivitiesSearchPayload,
  SearchActivitiesResp
> = taskGen('/searchActivities')
export const searchByKeyword: Entry<{ keyword: string }, SearchByKeywordResp> =
  taskGen('/searchByKeyword')

export const latestByUser: Entry<
  { userAddr: string },
  {
    activity: number
    offer: number
  }
> = taskGen('/explore/latestByUser')
export const contractPopover: Entry<
  { addr: string },
  GetContractDetailByAddrQuery['nft_contract'][0]
> = taskGen('/explore/contractPopover')
export const contractsByAddrs: Entry<
  { addrs: string[] },
  GetContractsByAddrsQuery
> = taskGen('/explore/contractsByAddrs')
export const ordersByIds: Entry<number[], OrderInfoFragment[]> = taskGen(
  '/explore/ordersByIds',
)
export const orderById: Entry<number, GetOrderByIdQuery['nft_order_by_pk']> =
  taskGen('/explore/orderById')
export const openedPrivateOrdersByTaker: Entry<
  { addr: string },
  GetOpenedPrivateOrdersByTakerQuery
> = taskGen('/explore/openedPrivateOrdersByTaker')
