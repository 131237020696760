import { utils } from 'ethers'

import * as api from '@/lib/api'
import { E } from '@/lib/fp'

export const waitForDbIndex = async (orderId: number) => {
  // Wait for several seconds before polling to save some requests
  await new Promise((res) => setTimeout(res, 3_000))
  // Poll with exponential back-off strategy
  return utils.poll(
    async () => {
      const r = await api.orderById(orderId)()
      if (E.isRight(r) && r.right?.status === 'cancelled') {
        return true
      }
      console.log(`wait_for_db_index order=${orderId}`)
    },
    {
      floor: 1_000,
      ceiling: 10_000,
      interval: 1_000,
    },
  )
}
