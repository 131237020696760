import meta from '@/data/meta'

export const cdnUrl = (src: string) => `${meta.cdnRoot}${src}`

export type CdnFile = {
  path: string
  width: number
  height: number
  format: string
}

export const cdnSizes = [36, 128, 280, 720, 1440, 2400] as const
export type CdnSize = (typeof cdnSizes)[number]

export const isCdnPath = (path: string): boolean =>
  !!path && !path.startsWith('http') && !path.startsWith('blob:')

export const isCdnFileGif = (src: CdnFile) =>
  src.format === 'gif' || src.path.endsWith('.gif')

export const resizedCdnUrl = (
  src: string | CdnFile,
  size: CdnSize = 720,
  type = 'jpg',
): string => {
  if (typeof src !== 'string') {
    if (isCdnFileGif(src)) type = 'gif'
    src = src.path
    if (type === 'gif' && size > 720) size = 720
  }
  if (src.endsWith('.svg')) type = 'svg'
  return isCdnPath(src)
    ? cdnUrl(type === 'svg' ? '/' + src : `/${src}/${size}.${type}`)
    : src
}
