import { SerializedEthereumRpcError } from 'eth-rpc-errors/dist/classes'
import { providers } from 'ethers'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { reportError } from '@/lib/market'
import toast from '@/lib/toast'

type Props = {
  user: providers.JsonRpcSigner
  networkId: number
  tokenContract: string
  tokenId?: string
  error: string | SerializedEthereumRpcError
}

const HIDE_REPORT = true

const ReportToast = ({
  user,
  networkId,
  tokenContract,
  tokenId,
  error,
}: Props) => {
  const { t } = useTranslation()
  const [active, setActive] = useState(true)

  const faqUrl = `#`
  const sthWrong = t("Something's wrong")
  const errReported = t('The error has been reported to X2Y2 team.')

  const handleReportBtnClick = async () => {
    if (!active) {
      return
    }
    setActive(false)
    const res = await reportError({
      user,
      networkId,
      contract: tokenContract,
      tokenId,
      desc: JSON.stringify(error, null, 2),
    })
    if (res === 'ok') {
      toast({
        status: 'success',
        duration: 3000,
        title: errReported,
      })
      setTimeout(() => window.open(faqUrl), 500)
    } else {
      setActive(true)
    }
  }

  let title = ''
  try {
    if (typeof error === 'string') {
      title = error
    } else if (
      error.code === -32603 &&
      error.data &&
      typeof error.data === 'object'
    ) {
      const data = error.data as Record<string, unknown>
      if (Object.prototype.hasOwnProperty.call(data, 'originalError')) {
        const originalError = JSON.stringify(data['originalError'])
        if (['{}', '"cancel"'].includes(originalError)) {
          title = sthWrong
        }
      }
    }
  } catch (e) {}

  return HIDE_REPORT ? (
    <p>{title ? title : sthWrong}</p>
  ) : title ? (
    <p>{title}</p>
  ) : (
    <div className="-mr-6">
      <div className="flex w-full items-center gap-5">
        <p>{sthWrong}</p>
        {/* <Button
          rightIcon={<ShareSquareLine className="h-4 w-4" />}
          bg="#B51811"
          _hover={{ bg: '#B51811' }}
          textStyle="headline_3"
          fontSize={'xs'}
          iconSpacing={2.5}
          lineHeight={3}
          h={6}
          px={2}
          py={1.5}
          rounded="4px"
          whiteSpace="nowrap"
          onClick={() => handleReportBtnClick()}
          disabled={!active}
        >
          {t('REPORT')}
        </Button> */}
        <button onClick={() => handleReportBtnClick()} disabled={!active}>
          {t('REPORT')}
        </button>
      </div>
    </div>
  )
}

export default ReportToast
