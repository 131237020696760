import { identity, pipe } from 'fp-ts/lib/function'

import { E, O } from '@/lib/fp'
import {
  CollectionDisplayOptions,
  collectionDisplayOptionsDecoder,
  OpenSeaMetadata,
  openseaMetadataDecoder,
  UnimetaConfig,
} from '@/lib/unimeta'

import { convertIpfsUrl } from '../utils'

export const extractNftMetadata = (src: unknown) =>
  pipe(
    openseaMetadataDecoder.decode(src),
    E.getOrElse((): OpenSeaMetadata => ({})),
  )

export type NftMetadataAssets = {
  image?: string
  gifImage?: string
  spriteImage?: string
  video?: string
  gltf?: string
  iframe?: string
  config?: UnimetaConfig
  collectionConfig?: CollectionDisplayOptions
}
export const extractNftMetadataAssets = (
  rawMetadata: unknown,
  collectionDisplayOptions?: unknown,
): NftMetadataAssets => {
  const metadata = extractNftMetadata(rawMetadata)
  const config = metadata._config ?? {}
  const collectionConfig = pipe(
    collectionDisplayOptionsDecoder.decode(collectionDisplayOptions),
    E.getOrElse((): CollectionDisplayOptions => ({})),
  )
  // NOTE: Use collectionDisplayOptions when config is missing
  if (
    config.pixelated === undefined &&
    collectionConfig.pixelated !== undefined
  ) {
    config.pixelated = collectionConfig.pixelated
  }

  if (metadata.background_color) {
    config.background_color = `#${metadata.background_color}`
  }
  const image = pipe(
    O.fromNullable(metadata.image),
    O.filter((a) => !/\.mp4$/i.test(a)),
    // Maybe these craps are no longer needed, let's see
    // O.alt(() =>
    //   pipe(
    //     O.fromNullable(metadata.animation_url),
    //     O.filter((a) => /(\.png)$/i.test(a)),
    //     O.map(convertIpfsUrl),
    //   ),
    // ),
    // O.alt(() =>
    //   pipe(
    //     O.fromNullable(metadata.image_data),
    //     O.map((image_data) => {
    //       const prefix = 'data:image/svg+xml;base64,'
    //       if (image_data.startsWith(prefix)) return image_data
    //       return `${prefix}${Buffer.from(image_data).toString('base64')}`
    //     }),
    //   ),
    // ),
    O.toUndefined,
  )
  const gifImage = pipe(
    O.fromNullable(metadata.animation_url),
    O.alt(() => O.fromNullable(metadata.image)),
    collectionConfig.use_gif ? identity : O.filter((a) => /\.gif$/i.test(a)),
    O.toUndefined,
  )
  const spriteImage = config?.sprite_animation
    ? pipe(
        O.fromNullable(metadata.animation_url),
        O.filter((a) => /\.jpe?g$/i.test(a)),
        O.map(convertIpfsUrl),
        O.toUndefined,
      )
    : undefined
  const video = pipe(
    pipe(
      O.fromNullable(metadata.animation_url),
      collectionConfig.is_video ? identity : O.filter((a) => /\.mp4$/i.test(a)),
    ),
    O.alt(() =>
      pipe(
        O.fromNullable(metadata.image),
        O.filter((a) => /\.mp4$/i.test(a)),
      ),
    ),
    O.map(convertIpfsUrl),
    O.toUndefined,
  )
  const gltf = pipe(
    O.fromNullable(metadata.animation_url),
    O.filter((a) => /\.glb$/i.test(a)),
    O.toUndefined,
  )
  const iframe = collectionConfig?.is_iframe
    ? metadata.animation_url
    : undefined
  return {
    image,
    gifImage,
    spriteImage,
    video,
    gltf,
    iframe,
    config,
    collectionConfig,
  }
}
