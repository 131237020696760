import { providers } from 'ethers'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'

import { NftItem } from '@/components/detail/shared'
import {
  AccordionStep,
  AccordionStepItem,
  AccordionStepPanel,
} from '@/components/disclosure'
import { Button } from '@/components/form'
import { BundleWithPrice } from '@/components/nft/detail/BulkListModal'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { UseDisclosureReturn, useLoading } from '@/hooks'
import { CheckFilled, CheckLine, LockFilled } from '@/icons'
import { O } from '@/lib/fp'
import {
  approveNft721,
  approveNft1155,
  ApproveTarget,
  getErrorMessage,
  isApprovedNft721,
  isApprovedNft1155,
  TOKEN_721,
  TOKEN_1155,
  TokenKind,
} from '@/lib/market'
import { Contract } from '@/lib/nft/detail'
import toast from '@/lib/toast'

type Approvals = Record<string, boolean>

const isAllApproved = (approvals: Approvals) =>
  Object.values(approvals).every((a) => a)

export type ApproveNftProps = {
  provider: providers.Web3Provider
  networkId: number
  tokenKind: TokenKind
  contracts: Contract[]
  skipCheck?: boolean // For sending one NFT
  item?: BundleWithPrice[0]
  target?: ApproveTarget
  title: string
  subtitle: string
  desc: string
  loadingText: string
  actionText: string
  doneText: string
  onAction: (setLoading: (a: boolean) => void, onDone: () => void) => void
}
type Props = ApproveNftProps & {
  disclosure: UseDisclosureReturn
}
export const ApproveNftModal = ({
  disclosure,
  provider,
  networkId,
  tokenKind,
  contracts,
  item,
  target = 'x2y2',
  title,
  subtitle,
  desc,
  loadingText,
  actionText,
  doneText,
  onAction,
}: Props) => {
  const { t } = useTranslation()

  const [step, setStep] = useState<0 | 1 | 2>(0)
  const { isLoading: isLoading0, setLoading, guardLoading } = useLoading()
  const [approvals, setApprovals] = useState<O.Option<Approvals>>(O.none)
  const isLoading = O.isNone(approvals) || isLoading0
  const approveBtnRef = useRef<HTMLButtonElement>(null)
  const actionBtnRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (isLoading) return
    // Wait until refs available
    setTimeout(() => {
      const btnRef = step === 0 ? approveBtnRef : actionBtnRef
      btnRef.current?.focus()
    }, 0)
  }, [step, isLoading])

  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if (checked) return
    const action = async () => {
      try {
        const user = provider.getSigner()
        const isApprovedNft = {
          [TOKEN_721]: isApprovedNft721,
          [TOKEN_1155]: isApprovedNft1155,
        }[tokenKind]
        const approved = await Promise.all(
          contracts.map((a) =>
            isApprovedNft(networkId, a.contract, user, target),
          ),
        )
        const r = Object.fromEntries(
          contracts.map((a, i) => [a.contract, approved[i] ?? false]),
        )
        setApprovals(O.some(r))
        setStep(isAllApproved(r) ? 1 : 0)
        setChecked(true)
      } catch (e) {
        console.error(e)
        const error = getErrorMessage(t, e)
        toast({
          status: 'error',
          title: (
            <ReportToast
              user={provider.getSigner()}
              networkId={networkId}
              tokenContract=""
              tokenId=""
              error={error}
            />
          ),
        })
      }
    }
    action()
  }, [t, provider, networkId, tokenKind, checked, contracts, target])

  const onApprove = async (contract: string) => {
    if (O.isNone(approvals)) return
    setLoading(true)
    try {
      const approveNft = {
        [TOKEN_721]: approveNft721,
        [TOKEN_1155]: approveNft1155,
      }[tokenKind]
      await approveNft(networkId, contract, provider.getSigner(), target)
      setLoading(false)
      const r = { ...approvals.value, [contract]: true }
      setApprovals(O.some(r))
      setStep(isAllApproved(r) ? 1 : 0)
    } catch (e) {
      console.error(e)
      setLoading(false)
      const error = getErrorMessage(t, e)
      toast({
        status: 'error',
        title: (
          <ReportToast
            user={provider.getSigner()}
            networkId={networkId}
            tokenContract={contract}
            tokenId=""
            error={error}
          />
        ),
      })
    }
  }
  const onDone = () => setStep(2)

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardLoading(disclosure.onClose)}
      initialFocus={approveBtnRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-6 lg:space-y-8">
          {item && (
            <NftItem item={item} price={item.price} currency={item.currency} />
          )}
          <AccordionStep index={Math.min(step, 1)}>
            <AccordionStepItem title={t('Approve NFT')} step={step} index={0}>
              <AccordionStepPanel className="space-y-6">
                <p>
                  <Trans t={t}>
                    We’ll ask your approval for the marketplace to access your
                    token. This is a one-time only operation.
                  </Trans>
                </p>
                <div className="flex flex-col space-y-3">
                  {contracts.map((a, i) => {
                    const isApproved = O.isNone(approvals)
                      ? true
                      : approvals.value[a.contract] // TODO: ?
                    return (
                      <Button
                        ref={i === 0 ? approveBtnRef : undefined}
                        variant="outline"
                        isLoading={isLoading}
                        isDisabled={isApproved}
                        loadingText={
                          O.isNone(approvals)
                            ? undefined
                            : t('Waiting for transaction…')
                        }
                        leftIcon={
                          isApproved ? (
                            <CheckLine className="h-5 w-5" />
                          ) : (
                            <LockFilled className="h-4 w-4" />
                          )
                        }
                        onClick={() => onApprove(a.contract)}
                        key={i}
                      >
                        {/* NOTE: Work around for very long coll names */}
                        <span className="truncate">
                          {t('Approve {{contract}}', { contract: a.name })}
                        </span>
                      </Button>
                    )
                  })}
                </div>
              </AccordionStepPanel>
            </AccordionStepItem>
            <AccordionStepItem title={subtitle} step={step} index={1}>
              <AccordionStepPanel className="space-y-6">
                <p>{desc}</p>
                <Button
                  ref={actionBtnRef}
                  colorScheme="primary-1"
                  isLoading={isLoading}
                  loadingText={loadingText}
                  leftIcon={
                    step < 2 ? undefined : <CheckFilled className="h-4 w-4" />
                  }
                  onClick={
                    step < 2
                      ? () => onAction(setLoading, onDone)
                      : disclosure.onClose
                  }
                >
                  {step < 2 ? actionText : doneText}
                </Button>
              </AccordionStepPanel>
            </AccordionStepItem>
          </AccordionStep>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
