import { useWeb3React } from '@web3-react/core'
import * as ethers from 'ethers'

import * as connectors from './connectors'
import { ConnectOption } from './types/connectOption'
import { ConnectType } from './types/connectType'

export const INJECTED_PROVIDER_MAP: Partial<Record<ConnectOption, any>> = {}
;(function setUpInjectedProviderMap() {
  if (typeof window !== 'undefined') {
    // Reference: https://developer.onekey.so/supported-chains/eth/provider-api
    if ((window as any).$onekey?.ethereum) {
      INJECTED_PROVIDER_MAP['OneKey Wallet'] = (window as any).$onekey.ethereum
    }
    // Reference: https://www.okx.com/web3-docs/extension/getting-start.html#detecting-okx
    const okxWallet = (window as any).okxwallet
    if (typeof okxWallet !== 'undefined' && okxWallet.isOkxWallet) {
      INJECTED_PROVIDER_MAP['OKX Wallet'] = okxWallet
    }
    // Reference: https://docs.metamask.io/guide/mobile-best-practices.html#the-provider-window-ethereum
    const ethereum = (window as any).ethereum
    if (typeof ethereum !== 'undefined' && ethereum.isMetaMask) {
      INJECTED_PROVIDER_MAP['MetaMask'] = ethereum
    }
  }
})()

export const getWeb3Provider = async (
  w3ctx: ReturnType<typeof useWeb3React>,
  type: ConnectType,
  options: {
    triggeredByUser: boolean
  },
) => {
  switch (type) {
    case 'injected': {
      await w3ctx.activate(connectors.injected, undefined, true)
      return new ethers.providers.Web3Provider(
        await connectors.injected.getProvider(),
        'any',
      )
    }
    case 'wallet-connect': {
      if (options.triggeredByUser) {
        // NOTE: Remove previous session
        try {
          connectors.walletConnect.clearStorage(localStorage)
        } catch {}
        // w3ctx.deactivate()
      }
      const connector = connectors.walletConnect
      // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
      // Reference: https://github.com/Uniswap/uniswap-interface/blob/8fd894f2d1b03921c5519c050aed164343c47fb1/src/components/WalletModal/index.tsx#L183
      // if (connector.walletConnectProvider?.wc?.uri) {
      //   connector.walletConnectProvider = undefined
      // }
      await w3ctx.activate(connector, undefined, true)
      return new ethers.providers.Web3Provider(await connector.getProvider())
    }
    case 'wallet-link': {
      await w3ctx.activate(connectors.walletLink, undefined, true)
      return new ethers.providers.Web3Provider(
        await connectors.walletLink.getProvider(),
        'any',
      )
    }
    case 'fortmatic': {
      await w3ctx.activate(connectors.fortmatic, undefined, true)
      return new ethers.providers.Web3Provider(
        await connectors.fortmatic.getProvider(),
        'any',
      )
    }
  }
}
