import { D } from '../fp'

export const recentlySearchedCollectionItemDecoder = D.struct({
  network_id: D.number,
  id: D.number,
  icon_url: D.nullable(D.string),
  contract: D.string,
  verified: D.boolean,
  stats: D.struct({
    total_supply: D.number,
  }),
  name: D.string,
  slug: D.nullable(D.string),
})
