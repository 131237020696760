import { NETWORK_ETH, NETWORK_GOERLI } from '@/consts'
import { ON_PROD } from '@/lib/env'
import { D } from '@/lib/fp'

export const networkSlugDecoder = D.literal('eth')
export type NetworkSlug = D.TypeOf<typeof networkSlugDecoder>

export const networkSlugToId = (slug: NetworkSlug) => {
  switch (slug) {
    case 'eth':
      return ON_PROD ? NETWORK_ETH : NETWORK_GOERLI
  }
}

export const networkIdToSlug = (networkId: number): NetworkSlug => {
  switch (networkId) {
    case NETWORK_ETH:
    case NETWORK_GOERLI:
      return 'eth'
    default:
      throw new Error(`Unsupported networkId: ${networkId}`)
  }
}

export const networkNativeCurrency = (networkId: number): string => {
  switch (networkId) {
    case NETWORK_ETH:
    case NETWORK_GOERLI:
      return 'ETH'
    default:
      throw new Error(`Unsupported networkId: ${networkId}`)
  }
}

export const ALL_MAINNETS = [NETWORK_ETH]
export const ALL_TESTNETS = [NETWORK_GOERLI]
export const DEFAULT_NETWORK: number = ON_PROD ? NETWORK_ETH : NETWORK_GOERLI

export * from './providers'
export * from './scannerSites'
