import { pipe } from 'fp-ts/lib/function'
import { TFunction } from 'next-i18next'
import { ParsedUrlQuery } from 'querystring'

import { categoryTitle, SearchPayload } from '@/lib/es/activity'
import { A, O } from '@/lib/fp'
import { ContractInfoFragment } from '@/lib/gql/types'
import {
  activityCategoriesURLQueryCodec,
  contractsURLQueryCodec,
  kvToUrlQuery,
} from '@/lib/search/utils'

export const activitiesTabUrlQueryCodec = {
  decode: (q: ParsedUrlQuery): Pick<SearchPayload, 'filters'> => {
    return {
      filters: {
        categories: activityCategoriesURLQueryCodec.decode(q),
        contracts: contractsURLQueryCodec.decode(q),
      },
    }
  },
  encode: (query: SearchPayload) => {
    return pipe(
      [
        activityCategoriesURLQueryCodec.encode(query.filters?.categories),
        contractsURLQueryCodec.encode(query.filters?.contracts ?? []),
      ],
      A.compact,
      kvToUrlQuery,
    )
  },
}

export const activitiesTabFilterLabelsVMGen = ({
  t,
  filters,
  setFilters,
  contracts,
}: {
  t: TFunction
  filters: SearchPayload['filters']
  setFilters: (a: SearchPayload['filters']) => void
  contracts: Pick<ContractInfoFragment, 'id' | 'name'>[]
}) => {
  return {
    options: A.compact([
      ...pipe(
        filters?.categories ?? [],
        A.map((category) => {
          return O.some({
            title: categoryTitle(t, category),
            onDel: () => {
              setFilters({
                ...filters,
                categories: (filters?.categories ?? []).filter(
                  (a) => a !== category,
                ),
              })
            },
          })
        }),
      ),
      ...(() => {
        const contractsInQuery = filters?.contracts ?? []
        return pipe(
          contractsInQuery,
          A.map((a) => {
            const c = contracts.find((b) => b.id === a)
            if (!c) return O.none
            return O.some({
              title: c.name,
              onDel: () => {
                setFilters({
                  ...filters,
                  contracts: contractsInQuery.filter((a) => a !== c.id),
                })
              },
            })
          }),
        )
      })(),
    ]),
    onClear: () => setFilters({}),
  }
}
