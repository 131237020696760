import { BigNumber, constants, providers, utils } from 'ethers'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'

import { NftItem } from '@/components/detail/shared'
import {
  AccordionStep,
  AccordionStepItem,
  AccordionStepPanel,
} from '@/components/disclosure'
import { Button } from '@/components/form'
import { BundleWithPrice } from '@/components/nft/detail/BulkListModal'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { UseDisclosureReturn, useLoading } from '@/hooks'
import { CheckFilled, CheckLine, LockFilled } from '@/icons'
import { O } from '@/lib/fp'
import {
  approveErc20,
  approveNft721,
  approveNft1155,
  ApproveTarget,
  getErrorMessage,
  isApprovedErc20,
  isApprovedNft721,
  isApprovedNft1155,
  TOKEN_721,
  TOKEN_1155,
  TokenKind,
} from '@/lib/market'
import { Contract } from '@/lib/nft/detail'
import toast from '@/lib/toast'
import { getTokenMeta } from '@/lib/token'

type Approvals = Record<string, boolean>

const isAllApproved = (approvals: Approvals) =>
  Object.values(approvals).every((a) => a)

export type ApproveNftAndTokenProps = {
  provider: providers.Web3Provider
  networkId: number
  title: string
  subtitle: string
  desc: string
  actionLoadingText: string
  nftOptions: {
    tokenKind: TokenKind
    contracts: Contract[]
    skipCheck?: boolean // For sending one NFT
    item?: BundleWithPrice[0]
    target: ApproveTarget
  }
  tokenOptions: {
    currency: string
    amount: BigNumber
    approveAmount?: BigNumber
    ethAmount?: BigNumber
    isCart?: boolean
    target: ApproveTarget
  }
  actionText: string
  doneText: string
  onAction: (setLoading: (a: boolean) => void, onDone: () => void) => void
}
type Props = ApproveNftAndTokenProps & {
  disclosure: UseDisclosureReturn
}
export const ApproveNftAndTokenModal = ({
  disclosure,
  provider,
  networkId,
  title,
  subtitle,
  desc,
  actionLoadingText,
  nftOptions,
  tokenOptions,
  actionText,
  doneText,
  onAction,
}: Props) => {
  const { t } = useTranslation()

  const [step, setStep] = useState<0 | 1 | 2 | 3>(0)
  const actionBtnRef = useRef<HTMLButtonElement>(null)

  const {
    isLoading: isActionLoading,
    setLoading: setActionLoading,
    guardLoading: guardActionLoading,
  } = useLoading()
  const {
    isLoading: isNftLoading0,
    setLoading: setNftLoading,
    guardLoading: guardNftLoading,
  } = useLoading()
  const [nftApprovals, setNftApprovals] = useState<O.Option<Approvals>>(O.none)
  const isNftLoading = O.isNone(nftApprovals) || isNftLoading0
  const nftApproveBtnRef = useRef<HTMLButtonElement>(null)
  const {
    isLoading: isTokenLoading0,
    setLoading: setTokenLoading,
    guardLoading: guardTokenLoading,
  } = useLoading()
  const [isApproved, setApproved] = useState<O.Option<boolean>>(O.none)
  const isTokenLoading = O.isNone(isApproved) || isTokenLoading0
  const tokenApproveBtnRef = useRef<HTMLButtonElement>(null)

  const { tokenKind, contracts, item } = nftOptions
  const {
    currency,
    amount,
    approveAmount = amount,
    ethAmount = constants.Zero,
    isCart = false,
  } = tokenOptions
  const { symbol, decimals } = getTokenMeta(networkId, currency)

  useEffect(() => {
    if (isNftLoading || isTokenLoading) return
    // Wait until refs available
    setTimeout(() => {
      const btnRef =
        step === 0
          ? nftApproveBtnRef
          : step === 1
          ? tokenApproveBtnRef
          : actionBtnRef
      btnRef.current?.focus()
    }, 0)
  }, [step, isNftLoading, isTokenLoading])

  const [nftChecked, setNftChecked] = useState(false)
  useEffect(() => {
    if (nftChecked) return
    const action = async () => {
      try {
        const user = provider.getSigner()
        const isApprovedNft = {
          [TOKEN_721]: isApprovedNft721,
          [TOKEN_1155]: isApprovedNft1155,
        }[tokenKind]
        const approved = await Promise.all(
          contracts.map((a) =>
            isApprovedNft(networkId, a.contract, user, nftOptions.target),
          ),
        )
        const r = Object.fromEntries(
          contracts.map((a, i) => [a.contract, approved[i] ?? false]),
        )
        setNftApprovals(O.some(r))
        setStep(isAllApproved(r) ? 1 : 0)
        setNftChecked(true)
      } catch (e) {
        console.error(e)
        const error = getErrorMessage(t, e)
        toast({
          status: 'error',
          title: (
            <ReportToast
              user={provider.getSigner()}
              networkId={networkId}
              tokenContract=""
              tokenId=""
              error={error}
            />
          ),
        })
      }
    }
    action()
  }, [
    t,
    provider,
    networkId,
    tokenKind,
    contracts,
    nftChecked,
    nftOptions.target,
  ])

  const [tokenChecked, setTokenChecked] = useState(false)
  useEffect(() => {
    if (step === 0 || tokenChecked) return
    const action = async () => {
      try {
        const approved = await isApprovedErc20(
          networkId,
          currency,
          approveAmount,
          provider.getSigner(),
          isCart,
          tokenOptions.target,
        )
        setApproved(O.some(approved))
        setStep(approved ? 2 : 1)
        setTokenChecked(true)
      } catch (e) {
        console.error(e)
        const error = getErrorMessage(t, e)
        toast({
          status: 'error',
          title: (
            <ReportToast
              user={provider.getSigner()}
              networkId={networkId}
              tokenContract={currency}
              tokenId="-"
              error={error}
            />
          ),
        })
      }
    }
    action()
  }, [
    t,
    provider,
    networkId,
    currency,
    amount,
    isCart,
    approveAmount,
    tokenChecked,
    tokenOptions.target,
    step,
  ])

  const onApproveNft = async (contract: string) => {
    if (O.isNone(nftApprovals)) return
    setNftLoading(true)
    try {
      const approveNft = {
        [TOKEN_721]: approveNft721,
        [TOKEN_1155]: approveNft1155,
      }[tokenKind]
      await approveNft(
        networkId,
        contract,
        provider.getSigner(),
        nftOptions.target,
      )
      setNftLoading(false)
      const r = { ...nftApprovals.value, [contract]: true }
      setNftApprovals(O.some(r))
      setStep(isAllApproved(r) ? 1 : 0)
    } catch (e) {
      console.error(e)
      setNftLoading(false)
      const error = getErrorMessage(t, e)
      toast({
        status: 'error',
        title: (
          <ReportToast
            user={provider.getSigner()}
            networkId={networkId}
            tokenContract={contract}
            tokenId=""
            error={error}
          />
        ),
      })
    }
  }

  const onApproveToken = async () => {
    setTokenLoading(true)
    try {
      await approveErc20(
        networkId,
        currency,
        provider.getSigner(),
        isCart,
        tokenOptions.target,
      )
      setTokenLoading(false)
      setApproved(O.some(true))
      setStep(2)
    } catch (e) {
      console.error(e)
      setTokenLoading(false)
      const error = getErrorMessage(t, e)
      toast({
        status: 'error',
        title: (
          <ReportToast
            user={provider.getSigner()}
            networkId={networkId}
            tokenContract={currency}
            tokenId="-"
            error={error}
          />
        ),
      })
    }
  }

  const onDone = () => setStep(3)

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={guardActionLoading(
        guardNftLoading(guardTokenLoading(disclosure.onClose)),
      )}
      initialFocus={nftApproveBtnRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col space-y-6 lg:space-y-8">
          {item && (
            <NftItem item={item} price={item.price} currency={item.currency} />
          )}
          <AccordionStep index={Math.min(step, 2)}>
            <AccordionStepItem title={t('Approve NFT')} step={step} index={0}>
              <AccordionStepPanel className="space-y-6">
                <p>
                  <Trans t={t}>
                    We’ll ask your approval for the marketplace to access your
                    token. This is a one-time only operation.
                  </Trans>
                </p>
                <div className="flex flex-col space-y-3">
                  {contracts.map((a, i) => {
                    const isApproved = O.isNone(nftApprovals)
                      ? true
                      : nftApprovals.value[a.contract] // TODO: ?
                    return (
                      <Button
                        ref={i === 0 ? nftApproveBtnRef : undefined}
                        variant="outline"
                        isLoading={isNftLoading}
                        isDisabled={isApproved}
                        loadingText={
                          O.isNone(nftApprovals)
                            ? undefined
                            : t('Waiting for transaction…')
                        }
                        leftIcon={
                          isApproved ? (
                            <CheckLine className="h-5 w-5" />
                          ) : (
                            <LockFilled className="h-4 w-4" />
                          )
                        }
                        onClick={() => onApproveNft(a.contract)}
                        key={i}
                      >
                        {/* NOTE: Work around for very long coll names */}
                        <span className="truncate">
                          {t('Approve {{contract}}', { contract: a.name })}
                        </span>
                      </Button>
                    )
                  })}
                </div>
              </AccordionStepPanel>
            </AccordionStepItem>
            <AccordionStepItem
              title={t('Approve {{symbol}}', { symbol })}
              step={step}
              index={1}
            >
              <AccordionStepPanel className="space-y-6">
                <p>
                  <Trans t={t}>
                    We’ll ask your approval for the marketplace to access your{' '}
                    {{ symbol }} token. This is a one-time only operation.
                  </Trans>
                </p>
                <Button
                  ref={tokenApproveBtnRef}
                  variant="outline"
                  leftIcon={<LockFilled className="h-4 w-4" />}
                  isLoading={isTokenLoading}
                  loadingText={
                    O.isNone(isApproved)
                      ? undefined
                      : t('Waiting for transaction…')
                  }
                  onClick={onApproveToken}
                >
                  {t('Approve')}
                </Button>
              </AccordionStepPanel>
            </AccordionStepItem>
            <AccordionStepItem title={subtitle} step={step} index={2}>
              <AccordionStepPanel className="space-y-6">
                <p>{desc}</p>
                {!(amount.isZero() && ethAmount.isZero()) && (
                  <div className="flex items-center justify-between space-x-3">
                    <p className="ts-hairline-3 text-gray-600">{t('Price')}</p>
                    <p className="ts-headline-5 break-all text-gray-800">
                      {!ethAmount.isZero()
                        ? utils.formatEther(ethAmount) + ' ETH + '
                        : ''}
                      {utils.formatUnits(amount, decimals)} {symbol}
                    </p>
                  </div>
                )}
                <Button
                  ref={actionBtnRef}
                  colorScheme="primary-1"
                  isLoading={isActionLoading}
                  loadingText={actionLoadingText}
                  leftIcon={
                    step < 3 ? undefined : <CheckFilled className="h-4 w-4" />
                  }
                  onClick={
                    step < 3
                      ? () => onAction(setActionLoading, onDone)
                      : disclosure.onClose
                  }
                >
                  {step < 3 ? actionText : doneText}
                </Button>
              </AccordionStepPanel>
            </AccordionStepItem>
          </AccordionStep>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
