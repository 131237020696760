import { useTranslation } from 'next-i18next'

import { UseDisclosureReturn } from '@/hooks'

import { FloorWarningModal } from './FloorWarningModal'

export type BulkListFloorWarningProps = {
  onContinue: () => void
}
type Props = BulkListFloorWarningProps & {
  disclosure: UseDisclosureReturn
}
export const BulkListFloorWarningModal = ({
  disclosure,
  onContinue,
}: Props) => {
  const { t } = useTranslation()
  return (
    <FloorWarningModal
      disclosure={disclosure}
      title={t('Low Price Warning')}
      desc={t(
        'Are you sure to list at a price lower than the floor price of the collection?',
      )}
      action={t('Continue Listing')}
      onContinue={onContinue}
    />
  )
}
