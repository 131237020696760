import { BigNumber, constants, providers } from 'ethers'

import * as api from '@/lib/api'
import { ERC_TYPE_721 } from '@/lib/collection/utils'
import { X2Y2R1__factory } from '@/lib/contract'
import { E } from '@/lib/fp'
import { HttpRequestConfig } from '@/lib/http'
import {
  checkOperatorFilters,
  DATA_ERRORS,
  decodeRunInput,
  fixSignature,
  handleOrderResp,
  isNative,
  MarketError,
  RunInput,
  SERVER_ERRORS,
  TOKEN_721,
  TOKEN_1155,
} from '@/lib/market'
import * as Err from '@/lib/market/errors'
import { canSkipCheck, getListingSupport } from '@/lib/opensea'

import { MARKET_CONTRACT_MAPS } from './contracts'
import {
  CartItem,
  GetOrdersResp,
  LooksrareOrderResult,
  SeaportOrderResult,
  TokenStandard,
  X2Y2OrderResult,
} from './types'

export const x2y2RequestConfig = (
  token: string | null,
  isForm = false,
): HttpRequestConfig => {
  const config: HttpRequestConfig = {
    baseURL: process.env.NEXT_PUBLIC_X2Y2_API_BASE_URL,
    headers: isForm
      ? {}
      : { 'Content-Type': 'application/json; charset=utf-8' },
  }
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}

export const invalidAddress = (address: string): boolean => {
  return !address || address === constants.AddressZero
}

export const parseVolNumber = (vol: string): BigNumber => {
  return BigNumber.from(vol)
}

export const handleX2Y2Resp = (
  data: { success: boolean; code: number } | undefined,
  defaultError: string,
) => {
  if (!data) {
    throw new MarketError(defaultError)
  }
  if (!data.success) {
    const serverError = SERVER_ERRORS[data.code]
    if (serverError) {
      throw new MarketError(serverError)
    } else {
      throw new MarketError(DATA_ERRORS[data.code] ?? defaultError)
    }
  }
}

const signOrdersByType = async (
  token: string,
  accountAddress: string,
  signItems: CartItem[],
  data: any[],
  errors: any[],
) => {
  if (signItems.length === 0) {
    return
  }
  const OP_COMPLETE_SELL_OFFER = 1
  const defaultError = Err.ERR_0005
  // post orderId & caller to server, get orderdetail(OP_COMPLETE_SELL_OFFER)
  const orderDetailResp = await api.orderDetail(
    {
      caller: accountAddress,
      op: OP_COMPLETE_SELL_OFFER,
      amountToEth: '0',
      amountToWeth: '0',
      items: signItems.map((item) => ({
        orderId: item.orderId,
        currency: item.currency,
        price: item.price.toString(),
      })),
    },
    token,
  )()
  handleOrderResp(orderDetailResp, defaultError)

  const resp = E.isRight(orderDetailResp) ? orderDetailResp.right : undefined

  const success = resp ? resp.success : false
  if (success && resp && resp.data) {
    const rd = resp.data as any as any[]
    rd.forEach((r: any) => {
      data.push(r)
    })
  }
  if (!success && resp && resp.errors) {
    const re = resp.errors as any as any[]
    re.forEach((r: any) => {
      errors.push(r)
    })
  }
}

export const signX2Y2Orders = async (
  token: string,
  accountAddress: string,
  items: CartItem[],
): Promise<Record<number, X2Y2OrderResult>> => {
  const result: Record<number, X2Y2OrderResult> = {}
  const getOrdersResp = await api.getOrders(
    items.map((item) => item.orderId),
    token,
  )()
  const ordersResp = E.isRight(getOrdersResp) ? getOrdersResp.right : undefined
  const orders: GetOrdersResp['data'] = ordersResp?.data ?? []
  items.forEach((item) => {
    try {
      const order = orders.find(
        (o) =>
          o.nft.token.toLowerCase() === item.contract.toLowerCase() &&
          o.nft.token_id.toLowerCase() === item.tokenId.toLowerCase(),
      )
      if (order) {
        const orderPrice = BigNumber.from(order.price)
        if (!orderPrice.eq(item.price)) {
          result[item.orderId] = {
            order: {},
            value: orderPrice,
            invalidReason: null,
          }
        }
      }
    } catch (ignored) {
      console.log(ignored)
    }
  })
  const signItems = items.filter((item) => !result[item.orderId])
  if (signItems.length > 0) {
    const OP_COMPLETE_SELL_OFFER = 1
    const AMOUNT_0 = BigNumber.from(0)
    const data: any[] = []
    const errors: any[] = []
    // sign erc721 items
    await signOrdersByType(
      token,
      accountAddress,
      signItems.filter((item) => item.tokenStandard === 'erc721'),
      data,
      errors,
    )
    // sign erc1155 items
    await signOrdersByType(
      token,
      accountAddress,
      signItems.filter((item) => item.tokenStandard === 'erc1155'),
      data,
      errors,
    )

    const inputData = data as { input: string; order_id: number }[]
    const errorData = errors as { code: number; order_id: number }[]
    signItems.forEach((item) => {
      const input = inputData.find((d) => d.order_id === item.orderId)
      const runInput: RunInput | undefined = input
        ? decodeRunInput(input.input)
        : undefined
      if (runInput && runInput.orders.length && runInput.details.length) {
        let value: BigNumber = AMOUNT_0
        let valid = true
        runInput.details.forEach((detail) => {
          const order =
            runInput.orders[(detail.orderIdx as BigNumber).toNumber()]
          const orderItem =
            order?.items[(detail.itemIdx as BigNumber).toNumber()]
          if (detail.op !== OP_COMPLETE_SELL_OFFER || !orderItem) {
            valid = false
          } else if (isNative(order.currency)) {
            value = value.add(detail.price)
          }
        })
        if (valid) {
          fixSignature(runInput)
          runInput.orders.forEach((order) => fixSignature(order))
          result[item.orderId] = { order: runInput, value, invalidReason: null }
        }
      }
      if (!result[item.orderId]) {
        const hasError = errorData.find(
          (error) => error.order_id === item.orderId,
        )
        if (hasError) {
          result[item.orderId] = {
            order: undefined,
            value: AMOUNT_0,
            invalidReason: 'Order Cancelled',
          }
        } else {
          result[item.orderId] = {
            order: {},
            value: item.price,
            invalidReason: null,
          }
        }
      }
    })
  }
  return result
}

export const calcX2Y2CallData = async (
  networkId: number,
  signer: providers.JsonRpcSigner,
  runInput: RunInput,
  value: BigNumber,
): Promise<
  { tag: 'x2y2'; addr: string; value: BigNumber; data: string } | undefined
> => {
  const marketContract = MARKET_CONTRACT_MAPS[networkId]
  const market = X2Y2R1__factory.connect(marketContract, signer)
  const result = await market.populateTransaction.run(runInput, { value })
  return result.data
    ? { tag: 'x2y2', addr: marketContract, value, data: result.data }
    : undefined
}

export const getSeaportOrders = async (
  buyer: string,
  items: CartItem[],
): Promise<Record<string, SeaportOrderResult>> => {
  const result: Record<string, SeaportOrderResult> = {}
  const parseOrder = (await import('./seaport')).parseOrder
  // todo
  const orders = {} as any
  items.forEach((item) => {
    const key = `${item.contract.toLowerCase()}_${item.tokenId}`
    result[key] = parseOrder(buyer, orders[key])
  })
  return result
}

export const getLooksrareOrders = async (
  buyer: string,
  items: CartItem[],
): Promise<Record<string, LooksrareOrderResult>> => {
  const result: Record<string, LooksrareOrderResult> = {}
  const parseOrder = (await import('./looksrare')).parseOrder
  // todo
  const orders = {} as any
  items.forEach((item) => {
    const key = `${item.contract.toLowerCase()}_${item.tokenId}`
    result[key] = parseOrder(buyer, orders[key])
  })
  return result
}

export const checkReported = async (
  nfts: CartItem[],
): Promise<Record<number, boolean>> => {
  let delay = 0
  const delayIncrement = 100
  const supportedPromises: Promise<boolean>[] = nfts.map((nft) => {
    if (canSkipCheck(nft.contract, nft.tokenId)) {
      return new Promise((resolve) => resolve(true))
    } else {
      delay += delayIncrement
      return new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
        getListingSupport(nft.contract.toLowerCase(), nft.tokenId),
      )
    }
  })
  const reportedResult: Record<number, boolean> = {}
  const supportedResult = await Promise.all(supportedPromises)
  supportedResult.map((r, i: number) => {
    reportedResult[nfts[i].orderId] = !r
  })
  return reportedResult
}

export const checkFiltered = async (
  networkId: number,
  nfts: CartItem[],
): Promise<Record<number, boolean>> => {
  const checkItems: { contract: string; tokenStandard: TokenStandard }[] = []
  nfts.forEach((item: CartItem) => {
    checkItems.push({
      contract: item.contract,
      tokenStandard: item.tokenStandard,
    })
  })
  const filteredResult: Record<number, boolean> = {}
  const result = await checkOperatorFilters(networkId, checkItems)
  result.map((r, i: number) => {
    filteredResult[nfts[i].orderId] = r
  })
  return filteredResult
}

export const parseKindToTokenStandard = (
  kind: typeof TOKEN_721 | typeof TOKEN_1155,
): TokenStandard => {
  return kind === TOKEN_721 ? 'erc721' : 'erc1155'
}

export const parseErcTypeToTokenStandard = (ercType: number): TokenStandard => {
  return ercType === ERC_TYPE_721 ? 'erc721' : 'erc1155'
}
