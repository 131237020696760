import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import {
  ApproveNftProps,
  ChangePriceModal,
  FloorPrice,
  ListFloorWarningModal,
  RelistModal,
  SendModal,
  SendModal721,
} from '@/components/detail/modals'
import { ReportModal } from '@/components/nft/detail/ReportModal'
import { useDisclosure } from '@/hooks'
import { useUserRequire } from '@/lib/auth'
import { getErrorMessage, refreshNft } from '@/lib/market'
import { Bundle } from '@/lib/nft/detail'
import toast from '@/lib/toast'
import { defaultErrorHandler, guardNetwork } from '@/utils/network/guard'

import { useModal, UseModalReturn } from '../useModal'

type Props = {
  nftId: number
  bundle: Bundle
  sellOrder: {
    id: number
    price: string
    currency: string
    end_at?: string | null | undefined
    is_private: boolean
    taker?: string | null | undefined
    royalty_fee: number
  } | null
  isOwner: boolean
  floorPrice: FloorPrice | null
  approveNftModal: UseModalReturn<ApproveNftProps>
}
export const useActions = ({
  nftId,
  bundle,
  sellOrder,
  isOwner,
  floorPrice,
  approveNftModal,
}: Props) => {
  const { t } = useTranslation()

  const router = useRouter()
  const changePriceModal = useModal(ChangePriceModal)
  const floorWarningModal = useModal(ListFloorWarningModal)
  const relistModal = useModal(RelistModal)
  const sendModal = useModal(SendModal721)
  const reportDisclosure = useDisclosure()

  const { token: tokenContract, tokenId } = bundle[0].tokenPair
  const { networkId } = bundle[0].info
  const { requireRegisteredUser, requireWeb3RegisteredUser } = useUserRequire()

  const onRefreshMetadata = async (onClose: () => void) => {
    onClose()
    const toastId = toast({
      status: 'loading',
      title: t('Refreshing…'),
    })
    const err = await refreshNft(networkId, tokenContract, tokenId)
    toastId && toast.close(toastId)
    if (err) {
      const error = getErrorMessage(t, new Error(err))
      toast({
        status: 'error',
        title: typeof error === 'string' ? error : t('An error has occurred'),
      })
    } else {
      router.replace(router.asPath, undefined, { scroll: false })
    }
  }
  const onReport = reportDisclosure.onOpen

  const onChangePrice =
    isOwner && sellOrder
      ? () =>
          requireRegisteredUser((user) =>
            guardNetwork(
              user,
              networkId,
              () =>
                changePriceModal.onOpen({
                  user,
                  bundle,
                  orderId: sellOrder.id,
                  price: sellOrder.price,
                  currency: sellOrder.currency,
                  isPrivate: sellOrder.is_private,
                  taker: sellOrder.taker ?? null,
                  royaltyFee: sellOrder.royalty_fee,
                  deadline: sellOrder.end_at
                    ? Math.trunc(new Date(sellOrder.end_at).valueOf() / 1000)
                    : 0,
                  floorPrice,
                  floorWarningModal,
                  relistModal,
                }),
              defaultErrorHandler(t),
            ),
          )
      : null
  const onSend = isOwner
    ? () =>
        requireWeb3RegisteredUser((user) =>
          guardNetwork(
            user,
            networkId,
            () => sendModal.onOpen({ bundle, approveNftModal }),
            defaultErrorHandler(t),
          ),
        )
    : null

  const component = (
    <>
      <ReportModal disclosure={reportDisclosure} nftId={nftId} />
      {changePriceModal.component}
      {floorWarningModal.component}
      {relistModal.component}
      {sendModal.component}
    </>
  )
  return { component, onChangePrice, onSend, onRefreshMetadata, onReport }
}

// TODO: try merge with 721
export const useActions1155 = ({
  nftId,
  bundle,
  balance,
  approveNftModal,
}: Pick<Props, 'nftId' | 'bundle'> & {
  balance: number
  approveNftModal: UseModalReturn<ApproveNftProps>
}) => {
  const { t } = useTranslation()

  const router = useRouter()
  const reportDisclosure = useDisclosure()

  const { token: tokenContract, tokenId } = bundle[0].tokenPair
  const { networkId } = bundle[0].info

  const onRefreshMetadata = async (onClose: () => void) => {
    onClose()
    const toastId = toast({
      status: 'loading',
      title: t('Refreshing…'),
    })
    const err = await refreshNft(networkId, tokenContract, tokenId)
    toastId && toast.close(toastId)
    if (err) {
      const error = getErrorMessage(t, new Error(err))
      toast({
        status: 'error',
        title: typeof error === 'string' ? error : t('An error has occurred'),
      })
    } else {
      router.replace(router.asPath, undefined, { scroll: false })
    }
  }
  const onReport = reportDisclosure.onOpen

  const { requireWeb3RegisteredUser } = useUserRequire()
  const sendModal = useModal(SendModal)
  const onSend =
    balance > 0
      ? () =>
          requireWeb3RegisteredUser((user) =>
            guardNetwork(
              user,
              networkId,
              () => sendModal.onOpen({ bundle, balance, approveNftModal }),
              defaultErrorHandler(t),
            ),
          )
      : null

  const component = (
    <>
      <ReportModal disclosure={reportDisclosure} nftId={nftId} />
      {sendModal.component}
    </>
  )
  return { component, onRefreshMetadata, onReport, onSend }
}
