import { utils } from 'ethers'
import { pipe } from 'fp-ts/function'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
} from '@/components/form'
import { FormLabel } from '@/components/form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import ReportToast from '@/components/ReportToast'
import { UseDisclosureReturn, useLoading, UseModalReturn } from '@/hooks'
import { CloseCircleFilled, GiftLine, InfoCircleFilled } from '@/icons'
import { useUserRequire } from '@/lib/auth'
import { NonAnonymousUser } from '@/lib/auth/types/user'
import { NEA } from '@/lib/fp'
import { getErrorMessage, isContract, send } from '@/lib/market'
import { Bundle, getBundleContracts } from '@/lib/nft/detail'
import toast from '@/lib/toast'
import { DEFAULT_NETWORK, getProviderByNetworkId } from '@/utils/network'

import { NftItemList } from '../../detail/shared'
import { ApproveNftProps } from './ApproveNftModal'

// TODO: Support 721

type Inputs = {
  amount: string
  address: string
}
const defaultValues: Inputs = {
  amount: '',
  address: '',
}

export type SendProps = {
  bundle: Bundle
  balance: number
  approveNftModal: UseModalReturn<ApproveNftProps>
  onDone?: () => void
}
type Props = SendProps & {
  disclosure: UseDisclosureReturn
}
export const SendModal = ({
  disclosure,
  bundle,
  balance,
  approveNftModal,
  onDone,
}: Props) => {
  const { t } = useTranslation()

  const count = bundle.length
  const title = t('Transfer tokens', { count })
  const { requireWeb3RegisteredUser } = useUserRequire()
  const { reloadPage } = useLoading()

  const { token: tokenContract, tokenId, kind: tokenKind } = bundle[0].tokenPair
  const { networkId } = bundle[0].info

  const validateAddress = async (value: string) => {
    const provider = getProviderByNetworkId(DEFAULT_NETWORK)
    try {
      const resolved = await provider.resolveName(value)
      if (!utils.isAddress(resolved || value)) {
        throw Error('not address')
      }
    } catch {
      return t('Invalid address')
    }
  }

  const onSend =
    (user: NonAnonymousUser, address: string, amount: number) =>
    async (setLoading: (a: boolean) => void, approveModalCb: () => void) => {
      setLoading(true)
      try {
        const tokens = bundle.map((a) => ({ ...a.tokenPair, amount }))
        const networkId = NEA.head(bundle).info.networkId
        await send(networkId, user.web3Provider.getSigner(), tokens, address)
        onDone ? onDone() : await reloadPage()
        approveModalCb() // Show done in approve modal
      } catch (e) {
        console.error(e)
        const error = getErrorMessage(t, e)
        toast({
          status: 'error',
          title: (
            <ReportToast
              user={user.web3Provider.getSigner()}
              networkId={networkId}
              tokenContract={tokenContract}
              tokenId={tokenId}
              error={error}
            />
          ),
        })
      } finally {
        setLoading(false)
      }
    }

  const {
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting, touchedFields, errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues,
  })
  const [isContr, setContr] = useState(false)

  const onSubmit: SubmitHandler<Inputs> = (values) =>
    requireWeb3RegisteredUser((user) => {
      disclosure.onClose()
      const contracts =
        bundle.length === 1
          ? [] // skip approval check as different transfer method used
          : getBundleContracts(bundle)
      approveNftModal.onOpen({
        networkId,
        tokenKind,
        contracts,
        provider: user.web3Provider,
        title,
        subtitle: title,
        desc: t(
          'Confirm the transaction to send your tokens to the designated address.',
          { count },
        ),
        loadingText: t('Waiting for transaction…'),
        actionText: t('Accept'),
        doneText: t('Your tokens are sent successfully', { count }),
        // TODO: review error handling
        onAction: onSend(user, values.address, parseInt(values.amount)),
      })
    })

  const address = watch('address')
  useEffect(() => {
    const action = async () => {
      const isContr = await isContract(networkId, address)
      setContr(isContr)
    }
    action()
  }, [networkId, address])

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            className="flex flex-col space-y-6 lg:space-y-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <NftItemList
              bundle={pipe(
                bundle,
                NEA.map((a) => ({ ...a, price: null, currency: null })),
              )}
            />
            <FormControl>
              <FormLabel htmlFor="amount">
                {t('Quantity')} ({t('You have {{count}}', { count: balance })})
              </FormLabel>
              <InputGroup>
                <Input
                  id="amount"
                  type="number"
                  placeholder="0"
                  {...register('amount', { min: 1, max: balance })}
                  isInvalid={!!(errors.amount && touchedFields.amount)}
                  autoComplete="off"
                />
                {touchedFields.amount && errors.amount && (
                  <InputRightElement>
                    <CloseCircleFilled className="h-6 w-6 text-primary-3" />
                  </InputRightElement>
                )}
              </InputGroup>
              {errors.amount && (
                <p className="ts-caption-2 mt-1 text-primary-3">
                  {errors.amount.type === 'max'
                    ? t('Insufficient balance.')
                    : t('Invalid input.')}
                </p>
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="address">{t('Send to')}</FormLabel>
              <InputGroup>
                <Input
                  id="address"
                  className="pr-12"
                  spellCheck={false}
                  placeholder="0xadabc…"
                  {...register('address', { validate: validateAddress })}
                  isInvalid={!!(errors.address && touchedFields.address)}
                />
                {touchedFields.address && errors.address && (
                  <InputRightElement>
                    <CloseCircleFilled className="h-6 w-6 text-primary-3" />
                  </InputRightElement>
                )}
              </InputGroup>
              {errors.address ? (
                <p className="ts-caption-2 mt-1 text-primary-3">
                  {errors.address.message}
                </p>
              ) : isContr ? (
                <div className="mt-1 flex items-center text-primary-3">
                  <InfoCircleFilled className="mr-2 h-5 w-5" />
                  <p className="ts-caption-2">
                    {t('The address seems to be a contract address.')}
                  </p>
                </div>
              ) : (
                <p className="ts-caption-2 mt-1 text-gray-500">
                  {t(
                    'You won’t be able to take back the NFT after the transaction.',
                  )}
                </p>
              )}
            </FormControl>
            <Button
              className="GA-transfer"
              colorScheme="primary-1"
              leftIcon={<GiftLine className="h-4 w-4" />}
              isLoading={isSubmitting}
              type="submit"
            >
              {t('Transfer')}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
