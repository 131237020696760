import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  Side,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { pipe } from 'fp-ts/lib/function'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import {
  Children,
  cloneElement,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import mergeRefs from 'react-merge-refs'

import { Button } from '@/components/form'
import { useOnPC } from '@/hooks'
import {
  featurePromptDefaultOptions,
  FeaturePromptOptions,
} from '@/lib/featurePromptDefaultOptions'
import { E, O } from '@/lib/fp'
import { featurePromptOptions as featurePromptOptionsStore } from '@/lib/store'

import Link from './TwLink'

export const closeFeaturePromptCard = (feature: keyof FeaturePromptOptions) => {
  if (featurePromptDefaultOptions[feature]) {
    const options = featurePromptOptionsStore.get()

    featurePromptOptionsStore.set({
      ...pipe(
        options,
        E.getOrElseW(() => ({})),
      ),
      [feature]: false,
    })()
  }
}

const variants: Variants = {
  exit: {
    opacity: 0,
    transition: {
      opacity: { duration: 0.3, easings: 'easeInOut' },
    },
  },
  enter: {
    opacity: 1,
    transition: {
      opacity: { duration: 0.3, easings: 'easeOut' },
    },
  },
}

type Props = {
  feature: keyof FeaturePromptOptions
  placement?: Placement
  showBorder?: boolean
  arrowColor?: string
  offset: number
  children: ReactElement
  image?: ReactNode
  title: string
  desc: string
  learnMoreHref?: string
}
export const FeaturePromptCard = ({
  feature,
  placement,
  showBorder,
  arrowColor,
  offset: offsetNumber,
  children,
  image,
  title,
  desc,
  learnMoreHref,
}: Props) => {
  const { t } = useTranslation()

  const promptStatus = useMemo(
    () =>
      pipe(
        featurePromptOptionsStore.get(),
        O.fromEither,
        O.chain((a) => O.fromNullable(a[feature])),
        O.getOrElseW(() => featurePromptDefaultOptions[feature] ?? false),
      ),
    [feature],
  )
  const [open, setOpen] = useState(promptStatus)

  const closePromptCard = useCallback(() => {
    setOpen(false)
    closeFeaturePromptCard(feature)
  }, [feature])

  const arrowRef = useRef<HTMLSpanElement>(null)

  const {
    x,
    y,
    placement: finalPlacement,
    reference,
    floating,
    strategy,
    context,
    middlewareData,
  } = useFloating({
    placement,
    open,
    middleware: [
      offset(offsetNumber),
      flip(),
      shift({ padding: 16 }),
      arrow({ element: arrowRef, padding: 8 }),
    ],
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  })

  const staticSideOfFloating = finalPlacement.split('-')[0] as Side
  const staticSideOfArrowMap: Record<Side, Side> = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }
  const staticSideOfArrow = staticSideOfArrowMap[staticSideOfFloating]
  const noBorderSideKeysOfArrowMap: Record<Side, Capitalize<Side>[]> = {
    top: ['Left', 'Top'],
    right: ['Top', 'Right'],
    bottom: ['Right', 'Bottom'],
    left: ['Bottom', 'Left'],
  }
  const noBorderSideKeysOfArrow =
    noBorderSideKeysOfArrowMap[staticSideOfFloating]

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context, { role: 'tooltip' }),
  ])

  const ref = useMemo(
    () => mergeRefs([reference, (children as any).ref]),
    [reference, children],
  )

  const child = Children.only(children)
  const trigger = cloneElement(
    Children.only(children),
    getReferenceProps({ ref, ...child.props }),
  )

  const onPC = useOnPC('lg')

  return (
    <>
      {trigger}
      <FloatingPortal>
        <AnimatePresence>
          {onPC && open && (
            <motion.div
              className="z-[1100] w-[373px]"
              variants={variants}
              initial="exit"
              animate="enter"
              exit="exit"
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
              })}
            >
              <span
                className={clsx('absolute h-2 w-2 rotate-45 bg-modal-bg', {
                  'border border-gray-300': showBorder,
                })}
                ref={arrowRef}
                style={{
                  left: `${middlewareData.arrow?.x ?? 0}px`,
                  top: `${middlewareData.arrow?.y ?? 0}px`,
                  [staticSideOfArrow]: '-4px',
                  ...noBorderSideKeysOfArrow.reduce(
                    (prev, curr) => ({ ...prev, [`border${curr}Width`]: 0 }),
                    {},
                  ),
                  ...(arrowColor && {
                    backgroundColor: arrowColor,
                  }),
                }}
              />
              <div
                className={clsx(
                  'flex flex-col rounded-lg bg-modal-bg shadow-[0px_16px_64px_-48px_rgba(31,47,70,0.5)]',
                  { 'border border-gray-300': showBorder },
                )}
              >
                {image}
                <div className="p-6">
                  <p className="ts-body-2 font-bold">{title}</p>
                  <p className="ts-caption mt-2">{desc}</p>
                  <div className="ts-button-2 mt-4 flex items-center justify-end space-x-4 text-primary-1">
                    {learnMoreHref && (
                      <Link
                        hoverStyle="opacity"
                        href={learnMoreHref}
                        isExternal
                        onClick={closePromptCard}
                      >
                        {t('Learn more')}
                      </Link>
                    )}
                    <Button
                      size="sm"
                      colorScheme="primary-1"
                      onClick={closePromptCard}
                    >
                      {t('Got it')}
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}
