import { BigNumber, constants, utils } from 'ethers'
import { parseEther } from 'ethers/lib/utils'
import { LayoutGroup, motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Subject } from 'rxjs'

import { Empty } from '@/components/feedback'
import {
  DrawerContent,
  Modal,
  ModalBody,
  ModalOverlay,
} from '@/components/overlay'
import { useDisclosure, useModal } from '@/hooks'
import { useOpenSeaShoppingCart, usePayWithBoth } from '@/hooks/nft'
import useOptionalTask from '@/hooks/useOptionalTask'
import { CloseLine, LightningLine } from '@/icons'
import { useAuth, useUserRequire } from '@/lib/auth'
import { isSameAddress, RegisteredUser } from '@/lib/auth/types/user'
import { O, RD, TE } from '@/lib/fp'
import {
  checkTxResult,
  getErrorMessage,
  MarketError,
  ORDER_ERRORS,
} from '@/lib/market'
import { itemDisplayName } from '@/lib/nft'
import { extractNftMetadataAssets } from '@/lib/nft/metadata'
import { ShoppingCartAsset } from '@/lib/openseaShoppingCart'
import { Item as XyItem } from '@/lib/shoppingCart'
import toast from '@/lib/toast'
import { getTokenMeta } from '@/lib/token'
import { detailURL } from '@/lib/url'
import { isNonNullable } from '@/lib/utils'
import { ItemWithOrder, WETH_CONTRACT_MAPS } from '@/lib/x2y2'
import { checkCartItems, checkout } from '@/lib/x2y2/cart'
import { DEFAULT_NETWORK } from '@/utils/network'
import { defaultErrorHandler, guardNetwork } from '@/utils/network/guard'

import { BuyWithCreditCard } from '../detail/action/BuyWithCreditCard'
import { ApproveTokenModal, ApproveTokenProps } from '../detail/modals'
import { PayWithBoth } from '../detail/shared'
import { Button, IconButton } from '../form'
import { Img, metadataAssetsAsNftImageProps, NftImage } from '../media'
import ReportToast from '../ReportToast'
import { CartItem, ItemCheckResult } from './CartItem'
import { CheckingStatus } from './CheckingStatus'
import { SuccessModal, SuccessProps } from './SuccessModal'
import { useX2Y2Cart } from './useX2Y2Cart'

const wethContract = WETH_CONTRACT_MAPS[DEFAULT_NETWORK]
const CHECK_RESULT_CACHE_EXPIRE = 60_000

type CartState = {
  itemCount: number
  itemInCart: (id: number) => boolean
  addItem: (item: XyItem) => void
  addItems: (items: XyItem[]) => boolean
  removeItem: (id: number) => void
  clearItems: () => void
  openDrawer: () => void
  openSuccessModal: (data: SuccessProps) => void
  // TODO: Maybe we can rearchitect the animation to avoid replying on rxjs?
  animationSubject: Subject<void>
}

const DEFAULT_CART_STATE: CartState = {
  itemCount: 0,
  itemInCart: () => false,
  addItem: () => undefined,
  addItems: () => false,
  removeItem: () => undefined,
  clearItems: () => undefined,
  openDrawer: () => undefined,
  openSuccessModal: () => undefined,
  animationSubject: new Subject(),
}

export const CartContext = createContext<CartState>(DEFAULT_CART_STATE)
export const useCart = () => useContext(CartContext)

type TokenResult = { contract: string; tokenId: string; value: ItemCheckResult }
type OsItem = Omit<ShoppingCartAsset, 'contractAddr'> & { contract: string }
type BuyNowResp =
  | {
      tag: 'accepted'
      items: (XyItem & { success: boolean })[]
      osItems: (OsItem & { success: boolean })[]
      total: {
        amountToEth: BigNumber
        amountToWeth: BigNumber
        amountTotal: BigNumber
      }
      txHash: string
      gasRebate: BigNumber
    }
  | { tag: 'rejected' }

type AllItem =
  | { tag: 'x2y2'; value: XyItem }
  | { tag: 'opensea'; value: OsItem }

const getAllItems = (xyItems: XyItem[], osItems: OsItem[]) => {
  const xy = xyItems.map<AllItem>((a) => ({ tag: 'x2y2', value: a }))
  const os = osItems.map<AllItem>((a) => ({ tag: 'opensea', value: a }))
  return [...xy, ...os]
}

const toOsItem = ({ contractAddr, ...a }: ShoppingCartAsset): OsItem => ({
  ...a,
  contract: contractAddr,
})
const fromOsItem = ({ contract, ...a }: OsItem): ShoppingCartAsset => ({
  ...a,
  contractAddr: contract,
})

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation()
  const animateSubject = useRef(new Subject<void>()).current

  const { requireRegisteredUser } = useUserRequire()

  const drawerDisclosure = useDisclosure()
  const successDisclosure = useDisclosure()
  const approveTokenModal = useModal(ApproveTokenModal as FC<ApproveTokenProps>)

  const [buyNowTask, setBuyNowTask] = useState<
    O.Option<TE.TaskEither<string, BuyNowResp>>
  >(O.none)
  const [buyNowTaskResp] = useOptionalTask(buyNowTask)
  const isLoading = RD.isPending(buyNowTaskResp)

  const xyCart = useX2Y2Cart({ isLoading })
  const osCart = useOpenSeaShoppingCart()
  const successModal = useModal(SuccessModal)

  const clearXyCart = xyCart.clear
  const clearOsCart = osCart.clear
  const clearItems = useCallback(() => {
    clearXyCart()
    clearOsCart()
  }, [clearXyCart, clearOsCart])

  const [checkResult, setCheckResult] = useState<TokenResult[] | null>([])
  const isChecking = checkResult === null

  const allItems = useMemo(
    () => getAllItems(xyCart.items, osCart.items.map(toOsItem)),
    [xyCart.items, osCart.items],
  )

  const getCacheKey = (user: RegisteredUser, cartItems: AllItem[]) =>
    JSON.stringify([
      user.meta.id,
      cartItems.map((a) => [a.value.contract, a.value.tokenId, a.value.price]),
    ])
  type Cache = {
    key: string
    value: { result: ItemWithOrder[] | null; rejs: TokenResult[] }
    expire: Date
  }
  const cache = useRef<Cache | null>(null)
  const checkItems = useCallback(
    async (user: RegisteredUser, cartItems: AllItem[]) => {
      const k = getCacheKey(user, cartItems)
      const now = new Date()
      if (
        cache.current &&
        k === cache.current.key &&
        now.valueOf() - cache.current.expire.valueOf() <
          CHECK_RESULT_CACHE_EXPIRE
      ) {
        return cache.current.value
      }
      const result = await checkCartItems(
        user,
        cartItems.map((a) => ({
          tag: a.tag,
          orderId: a.tag === 'x2y2' ? a.value.id : 0,
          currency: a.tag === 'x2y2' ? a.value.currency : constants.AddressZero,
          contract: a.value.contract,
          tokenId: a.value.tokenId,
          tokenStandard: a.tag === 'x2y2' ? a.value.tokenStandard : 'erc721',
          price: parseEther(a.value.price),
        })),
        true,
      )
      const rejs = result
        .map((r): TokenResult | null => {
          const { contract, tokenId } = r
          if (!r.order || r.value.eq(0)) {
            return {
              contract,
              tokenId,
              value: { tag: 'not-valid', reason: r.invalidReason },
            }
          } else if (r.priceChanged) {
            return {
              contract,
              tokenId,
              value: {
                tag: 'price-changed',
                price: utils.formatEther(r.value),
              },
            }
          } else if (r.reported) {
            return { contract, tokenId, value: { tag: 'os-reported' } }
          }
          return null
        })
        .filter(isNonNullable)
      const hasInvalid =
        rejs.filter((a) => a.value.tag === 'not-valid').length > 0
      const r = { result: hasInvalid ? null : result, rejs }
      cache.current = {
        key: k,
        value: r,
        expire: new Date(),
      }
      return r
    },
    [],
  )

  const { user } = useAuth()
  const [nonce, setNonce] = useState(0)
  const checkRef = useRef(false)
  useEffect(() => {
    if (!drawerDisclosure.isOpen || user._tag !== 'registered') return
    // A bit of hacky to skip if we are already checking...
    if (checkRef.current) return
    checkRef.current = true
    const action = async () => {
      setCheckResult(null)
      try {
        const r = await checkItems(user, allItems)
        setCheckResult(r.rejs)
      } catch (e) {
        console.error(e)
        setCheckResult([])
      } finally {
        checkRef.current = false
      }
    }
    action()
  }, [drawerDisclosure.isOpen, user, allItems, checkItems, nonce])

  const getCheckResult = useCallback(
    (contract: string, tokenId: string) =>
      checkResult?.find(
        (a) => isSameAddress(a.contract, contract) && a.tokenId === tokenId,
      )?.value ?? null,
    [checkResult],
  )

  const byTag = (tag: ItemCheckResult['tag']) => (a: AllItem) =>
    getCheckResult(a.value.contract, a.value.tokenId)?.tag === tag
  const invalidItems = allItems.filter(byTag('not-valid'))
  const reportedItems = allItems.filter(byTag('os-reported'))

  const onRemove = (items: AllItem[]) => {
    const xyItems = items.flatMap((a) => (a.tag === 'x2y2' ? a.value : []))
    xyCart.remove(xyItems.map((a) => a.id))
    const osItems = items.flatMap((a) => (a.tag === 'opensea' ? a.value : []))
    osCart.applyChanges([], osItems.map(fromOsItem))
  }

  const totalPrice = useMemo(
    () =>
      allItems
        .map((a) => {
          // Adjust price according to check result
          const r = getCheckResult(a.value.contract, a.value.tokenId)
          if (!r || r.tag === 'os-reported') return a.value.price
          return r.tag === 'not-valid' ? '0' : r.price
        })
        .reduce((acc, val) => acc.add(parseEther(val)), constants.Zero),
    [allItems, getCheckResult],
  )
  const vm = usePayWithBoth({ isActive: drawerDisclosure.isOpen, totalPrice })
  const isInsufficientBalance = vm.amountInsufficient.gt(0)

  const getChanged = () => {
    const xyItems = xyCart.items.flatMap((a) => {
      const r = getCheckResult(a.contract, a.tokenId)
      if (!r || r.tag !== 'price-changed') return []
      return { ...a, price: r.price }
    })
    const osItems = osCart.items.flatMap((a) => {
      const r = getCheckResult(a.contractAddr, a.tokenId)
      if (!r || r.tag !== 'price-changed') return []
      return { ...a, price: r.price }
    })
    return { xyItems, osItems }
  }

  const onPressBuyNow = (
    user: RegisteredUser,
    setLoading?: (a: boolean) => void,
    approveModalCb?: () => void,
  ) => {
    if (checkRef.current) return
    checkRef.current = true

    const xyItemsToBuy0 = [...xyCart.items]
    const osItemsToBuy0 = [...osCart.items.map(toOsItem)]
    // NOTE: Must be called outside of task, before checkResult changed
    const changed = getChanged()

    const buyCartItems = async (user: RegisteredUser, cartItems: AllItem[]) => {
      const { result, rejs } = await checkItems(user, cartItems)
      if (!result) return { tx: null, rejs }
      const tx = await checkout(user, result, vm.amountToEth, vm.amountToWeth)
      return { tx, rejs }
    }

    const task = TE.tryCatch(
      async (): Promise<BuyNowResp> => {
        setLoading?.(true)
        const xyItemsToBuy1 =
          changed.xyItems.length > 0
            ? xyCart.applyChanges(changed.xyItems)
            : null
        const xyItemsToBuy = xyItemsToBuy1 ?? xyItemsToBuy0
        const osItemsToBuy1 =
          changed.osItems.length > 0
            ? await osCart.applyChanges(changed.osItems, [])
            : null
        const osItemsToBuy = osItemsToBuy1?.map(toOsItem) ?? osItemsToBuy0
        const allItems = getAllItems(xyItemsToBuy, osItemsToBuy)
        const { tx, rejs } = await buyCartItems(user, allItems)
        setCheckResult(rejs)
        if (!tx) {
          toast({
            status: 'warning',
            title: t(
              'Some orders are no longer valid and are removed from your cart.',
            ),
          })
          approveModalCb?.()
          setLoading?.(false)
          checkRef.current = false
          return { tag: 'rejected' }
        }
        await tx.wait()
        const txResult = await checkTxResult(
          DEFAULT_NETWORK,
          tx.hash,
          allItems.map((item) => ({
            tag: item.tag,
            price: item.value.price,
          })),
          {
            amountToEth: vm.amountToEth,
            amountToWeth: vm.amountToWeth,
          },
          true,
        )
        const failedIndice = new Set(txResult.failed)
        const items = xyItemsToBuy0.map((item, idx) => {
          return { ...item, success: !failedIndice.has(idx) }
        })
        const osItems = osItemsToBuy.map((a, idx) => ({
          ...a,
          success: !failedIndice.has(idx + xyItemsToBuy0.length),
        }))
        drawerDisclosure.onClose()
        approveModalCb?.()
        successDisclosure.onOpen()
        clearItems()
        setLoading?.(false)
        checkRef.current = false
        return {
          tag: 'accepted',
          items,
          osItems,
          total: txResult.total,
          txHash: tx.hash,
          gasRebate: txResult.gasRebate,
        }
      },
      (e) => {
        checkRef.current = false
        setLoading?.(false)
        console.log(e)
        // Check if need refresh by error type
        if (e instanceof MarketError) {
          if (ORDER_ERRORS.includes((e as MarketError).message)) {
            xyCart.reload()
          }
        }
        const error = getErrorMessage(t, e)
        toast({
          status: 'error',
          title: (
            <ReportToast
              user={user.web3Provider.getSigner()}
              networkId={DEFAULT_NETWORK}
              tokenContract=""
              tokenId=""
              error={error}
            />
          ),
        })
        return 'failed'
      },
    )
    setBuyNowTask(O.some(task))
  }

  const MotionButton = motion(Button)

  const [isHover, setHover] = useState(false)
  const cardButton = !invalidItems.length && allItems.length > 0 && (
    <BuyWithCreditCard
      price={totalPrice}
      currency={
        allItems[0].tag === 'x2y2'
          ? allItems[0].value.currency
          : constants.AddressZero
      }
      isHover={isHover}
      setHover={setHover}
      allItems={allItems.map((item) => ({
        contractAddress: item.value.contract,
        tokenId: item.value.tokenId,
        price: item.value.price,
      }))}
    />
  )
  const buyButton = (
    <MotionButton
      className="GA-cart-buy-now w-full !rounded-none"
      colorScheme="primary-1"
      isLoading={isLoading}
      isDisabled={
        isChecking || isInsufficientBalance || invalidItems.length > 0
      }
      leftIcon={<LightningLine className="h-[1em] w-[1em]" />}
      onClick={() =>
        requireRegisteredUser((user) =>
          guardNetwork(
            user,
            DEFAULT_NETWORK,
            () =>
              vm.amountToWeth.isZero()
                ? onPressBuyNow(user)
                : approveTokenModal.onOpen({
                    provider: user.web3Provider,
                    networkId: DEFAULT_NETWORK,
                    currency: wethContract,
                    amount: vm.amountToWeth,
                    ethAmount: vm.amountToEth,
                    isCart: true,
                    title: t('Buy'),
                    subtitle: t('Complete payment'),
                    desc: t('Click the Pay button to finish your payment.'),
                    loadingText: t('Waiting for transaction…'),
                    stayWhenDone: false,
                    actionText: t('Pay'),
                    onAction: (sl, cb) => onPressBuyNow(user, sl, cb),
                  }),
            defaultErrorHandler(t),
          ),
        )
      }
    >
      {isInsufficientBalance
        ? t('Insufficient funds')
        : // t('Insufficient funds: {{value}}', {
          //   value: `${fmtNumber(
          //     parseFloat(utils.formatEther(vm.amountInsufficient)),
          //     { max: 4 },
          //   )} ETH`,
          // })
          t('Buy Now')}
    </MotionButton>
  )
  return (
    <CartContext.Provider
      value={{
        itemCount: allItems.length,
        addItems: xyCart.addItems,
        itemInCart: xyCart.itemInCart,
        addItem: xyCart.addItem,
        removeItem: xyCart.removeItem,
        clearItems,
        openDrawer: drawerDisclosure.onOpen,
        openSuccessModal: successModal.onOpen,
        animationSubject: animateSubject,
      }}
    >
      {children}
      <Modal {...drawerDisclosure} motionPreset="slide">
        <ModalOverlay blur />
        <DrawerContent className="sm:max-w-[375px]">
          <header className="flex justify-end p-6 pb-3">
            <IconButton
              className="h-10 w-10 rounded-full"
              icon={<CloseLine className="h-6 w-6" />}
              aria-label={t('Close Menu')}
              onClick={drawerDisclosure.onClose}
            />
          </header>
          <ModalBody className="pb-10">
            <div className="flex items-center justify-between">
              <div className="ts-headline-6">{t('My Cart')}</div>
              {allItems.length > 0 && (
                <button
                  onClick={() => {
                    if (isLoading) return
                    clearItems()
                  }}
                  className="ts-button-2 flex items-center space-x-1 text-primary-1 hover:opacity-60"
                >
                  <CloseLine className="h-4 w-4" />
                  <span>{t('Clear')}</span>
                </button>
              )}
            </div>
            {allItems.length === 0 && <Empty label={t('No item in cart')} />}
            {allItems.length > 0 && (
              <div className="mt-6">
                {user._tag === 'registered' && (
                  <CheckingStatus
                    isChecking={isChecking}
                    invalid={invalidItems.length}
                    reported={reportedItems.length}
                    total={allItems.length}
                    onRemoveInvalid={() => onRemove(invalidItems)}
                    onRemoveReported={() => onRemove(reportedItems)}
                    onCheck={() => {
                      cache.current = null
                      setNonce((a) => a + 1)
                    }}
                  />
                )}
                <div className="-mx-3 mt-6 space-y-3">
                  {allItems.map((a, i) => {
                    const { contractName, contract, tokenId, price } = a.value
                    const props = {
                      key: i,
                      contractName,
                      price,
                      result: getCheckResult(contract, tokenId),
                      onLinkClick: drawerDisclosure.onClose,
                    }
                    return a.tag === 'x2y2' ? (
                      <CartItem
                        {...props}
                        image={
                          <NftImage
                            className="h-16 w-16 shrink-0"
                            {...metadataAssetsAsNftImageProps(
                              extractNftMetadataAssets(a.value.meta),
                            )}
                            imageSizes={['128px']}
                          />
                        }
                        contractVerified={a.value.contractVerified}
                        name={itemDisplayName(
                          a.value.meta,
                          contractName,
                          tokenId,
                        )}
                        symbol={
                          getTokenMeta(DEFAULT_NETWORK, a.value.currency).symbol
                        }
                        href={detailURL({
                          networkId: DEFAULT_NETWORK,
                          tokenContract: contract,
                          tokenId,
                        })}
                        onRemove={() => xyCart.removeItem(a.value.id)}
                      />
                    ) : (
                      <CartItem
                        isOS
                        {...props}
                        image={
                          <Img
                            className="h-4 w-4 shrink-0"
                            src={a.value.image}
                          />
                        }
                        contractVerified={false}
                        name={a.value.name}
                        symbol="ETH"
                        href={`https://opensea.io/assets/${contract}/${tokenId}`}
                        onRemove={() => osCart.removeItem(fromOsItem(a.value))}
                      />
                    )
                  })}
                </div>
                <PayWithBoth className="mt-6" style="shopping-cart" {...vm} />
                <LayoutGroup>
                  <div className="relative mt-6 flex flex-1 overflow-hidden rounded-lg">
                    {buyButton}
                    {cardButton}
                  </div>
                </LayoutGroup>
              </div>
            )}
          </ModalBody>
        </DrawerContent>
      </Modal>
      {successModal.component}
      {approveTokenModal.component}
      {RD.isSuccess(buyNowTaskResp) &&
        buyNowTaskResp.value.tag === 'accepted' && (
          <SuccessModal
            disclosure={successDisclosure}
            {...buyNowTaskResp.value}
            // TODO: Use OsItem in success modal when legacy cart removed
            osItems={buyNowTaskResp.value.osItems.map(({ contract, ...a }) => ({
              ...a,
              contractAddr: contract,
            }))}
          />
        )}
    </CartContext.Provider>
  )
}
