import { useCallback, useEffect, useRef, useState } from 'react'

import { AspectRatio } from '@/components/layout'
import { SpriteAnimationConfig } from '@/lib/unimeta'
import { useRaf } from '@/lib/useRaf'

type Props = {
  src: string
  config: SpriteAnimationConfig
}
export const NftSprite = ({ src, config }: Props) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const frameRef = useRef<number>(0)

  useEffect(() => {
    setImage(null)
    const image = new Image()
    image.src = src
    image.onload = () => {
      setImage(image)
    }
  }, [src])

  const draw = useCallback(() => {
    if (!image) return
    const index = frameRef.current % config.frames.length
    const pos = config.frames[index]
    const context = canvasRef.current?.getContext('2d')
    context?.clearRect(0, 0, config.frame_width, config.frame_height)
    context?.drawImage(
      image,
      pos.x,
      pos.y,
      config.frame_width,
      config.frame_height,
      0,
      0,
      config.frame_width,
      config.frame_height,
    )
    frameRef.current += 1
  }, [image, config])

  useRaf(draw, config.framerate)

  return (
    <div className="h-full w-full" style={{ transform: config.transform }}>
      <AspectRatio
        className="top-1/2 -translate-y-1/2"
        ratio={config.frame_width / config.frame_height}
      >
        <canvas
          style={{ width: '100%', height: '100%', imageRendering: 'pixelated' }}
          width={`${config.frame_width}px`}
          height={`${config.frame_height}px`}
          ref={canvasRef}
        />
      </AspectRatio>
    </div>
  )
}
