import { useMemo } from 'react'

import { useColorModeValue } from '@/components/colorMode'

// Use tailwind instead of this whenever possible
// TODO: DRY with tailwind colors
export const useChartColors = () => {
  const isDark = useColorModeValue(false, true)
  return useMemo(
    () => ({
      gray: {
        200: isDark ? '#23262F' : '#F4F5F6',
        300: isDark ? '#353945' : '#E6E8EC',
        500: '#777E90',
      },
      buttonText: '#FCFCFD',
    }),
    [isDark],
  )
}
