import { TFunction } from 'next-i18next'

export const ERR_4001 = '4001'

export const ethErrorMessages = (t: TFunction): Record<number, string> => ({
  '-32000': t('Invalid input.'),
  '-32001': t('Resource not found.'),
  '-32002': t('Resource unavailable.'),
  '-32003': t('Transaction rejected.'),
  '-32004': t('Method not supported.'),
  '-32005': t('Request limit exceeded.'),
  [ERR_4001]: t('User rejected the request.'),
  '4100': t(
    'The requested account and/or method has not been authorized by the user.',
  ),
  '4200': t('The requested method is not supported by this Ethereum provider.'),
  '4900': t('The provider is disconnected from all chains.'),
  '4901': t('The provider is disconnected from the specified chain.'),
})
