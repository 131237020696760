import { useTranslation } from 'next-i18next'
import { NextSeo, NextSeoProps } from 'next-seo'
import { useRouter } from 'next/router'

import { fullSiteURL } from '@/lib/url'

type Props = NextSeoProps & {
  title: string
  image?: string
}

export const useDefaultDesc = () => {
  const { t } = useTranslation()
  return t(
    'Unlock the full potential of your NFTs with X2Y2: trade, buy, loan, and more.',
  )
}

export const Seo = ({
  title,
  description,
  image,
  ...rest
}: Props): JSX.Element => {
  const defaultDesc = useDefaultDesc()
  const { asPath, locale, locales = [] } = useRouter()
  const langAlts = locales
    .filter((a) => a !== locale)
    .map((a) => ({
      href: a === 'en' ? asPath : `/${a}${asPath}`,
      hrefLang: a,
    }))
  const route = asPath.split('/')?.[1]
  const slug = asPath.split('/')?.[2]
  const imgUrl = fullSiteURL(`/api/og/${slug}.png`)
  image = route === 'collection' ? imgUrl : image
  return (
    <NextSeo
      {...{ title, description: description || defaultDesc }}
      {...rest}
      openGraph={{
        type: 'website',
        images: image ? [{ url: image }] : [],
        ...rest.openGraph,
      }}
      twitter={{ cardType: 'summary_large_image', ...rest.twitter }}
      languageAlternates={langAlts}
    />
  )
}
